import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Home2 from "./Home2";
import Clientes from "./Clientes";
import Nosotros from "./Nosotros";
import Faq from "./FAQ";
import VideoSection from "./VideoSection";
import { useHomeApi } from "../admin/api/HomeDataApi";

function Home() {
  const { getHomeData } = useHomeApi();
  const [titles, setTitles] = useState({ title_es: "", copy_es: "", about_us_title_es: "", about_us_desc_es: "" });
  const [faqs, setFaqs] = useState([
    {
      question_es: "",
      answer_es: "",
    },

  ]);
  useEffect(() => {
    getHomeData().then((data) => {
      if (data && data.title_es) {
        setTitles({
          title_es: data.title_es || "",
          copy_es: data.copy_es || "",
          about_us_title_es: data.about_us_title_es || "",
          about_us_desc_es: data.about_us_desc_es || "",
        });
        setFaqs(data.faqs || []);
        console.log(data.faqs);
      }
    });
  }, []);

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={12} className="home-header">

              <h1 style={{
                textAlign: "center",
                fontWeight: 500,
                fontSize: "2rem",
              }}>
                {titles.title_es || ""}
              </h1>
              <h2 style={{ fontSize: '1rem', textAlign: "center", fontWeight: 400, lineHeight: '22px' }} dangerouslySetInnerHTML={{ __html: titles.copy_es }}></h2>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
      <Clientes />
      <VideoSection />
      <Nosotros data={
        {
          about_us_title_es: titles.about_us_title_es || "",
          about_us_desc_es: titles.about_us_desc_es || "",
        }
      } />
      <Faq data={faqs} />
    </section>
  );
}

export default Home;
