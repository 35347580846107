import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './DataBox.css';

const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
};


const DataBox = ({ dataBox, onClick }) => {
    if (!dataBox) {
        return null;
    }
    return (
        <div className="data-box">
            <span className="data-box-title">Preview</span>
            <Slider {...settings}>
                {dataBox.map((data) => (
                    <div key={data.id} className={
                        data.visible ? "data-box__item" : "data-box__item data-box__item--hidden"
                    } onClick={() => onClick(data)}>
                        <div className="data-box__item__image">
                            <img src={data.image} alt="icon" className="img-fluid" />
                        </div>
                        <div className="data-box__item__content">
                            <span>{data.amount}</span>
                        </div>
                        <div className="data-box__item__header">
                            <span>{data.title_es}</span>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default DataBox;