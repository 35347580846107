export const linkStep = {
    color: "white",
    fontSize: "0.8rem",
    textDecoration: "underline",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
}
export const titleStyle = {
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "2rem",
    marginTop: "2.5rem",
}
export const linkStyle = {
    color: 'rgba(0, 62, 255, 0.25)',
    fontSize: "0.8rem",
    fontWeight: "500",
    textDecoration: "underline",
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
}