import React, { useState, useEffect, useContext } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import White from "../Assets/White.png";
import { Link } from 'react-router-dom';
import { Container } from "react-bootstrap";
import { UserContext } from "../contexts/UserContext";

const navLinkStyle = {
  color: 'white',
  padding: '10px 15px',
  textDecoration: 'none',
  border: '1px solid white',
  borderRadius: '8px',
  cursor: 'pointer',
};

const navContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

const navLinkNoBorderStyle = {
  ...navLinkStyle,
  border: 'none',
  marginRight: '10px',
  backgroundColor: 'transparent',
};

const NavBarComponent = () => {
  const [isSticky, setIsSticky] = useState(false);
  const { userData } = useContext(UserContext);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userData');
    localStorage.removeItem('talentLangs');
    localStorage.removeItem('workPreferences');
    window.location.reload();
  };

  const isLoggedIn = localStorage.getItem('userToken') || localStorage.getItem('userData');

  const stickyStyles = isSticky ? {
    backgroundColor: '#212038d8',
    transition: 'all 0.3s ease-out',
    boxShadow: '0px 10px 10px 0px rgba(9, 5, 29, 0.171)',
    backdropFilter: 'blur(15px)',
    zIndex: 100,
  } : {};

  return (
    <Navbar
      variant="dark"
      style={{ width: '100%', ...stickyStyles, backgroundColor: isSticky ? '#212038d8' : 'transparent' }}
    >
      <Container fluid style={navContainerStyle}>
        <Link to="/" className="navbar-brand">
          <img
            src={White}
            height="40"
            className="d-inline-block align-top"
            alt="logo"
          />
        </Link>
        <Nav className="ml-auto" style={{ display: 'flex', alignItems: 'center' }}>
          {isLoggedIn ? (
            <>
              <Link to={userData?.company ? '/company-profile' : '/profile'} style={navLinkStyle}>Cuenta</Link>
              <button onClick={handleLogout} style={navLinkNoBorderStyle}>Cerrar Sesión</button>
            </>
          ) : (
            <>
              <Link to="/login" style={navLinkNoBorderStyle}>Iniciar Sesión</Link>
              <Link to="/register" style={navLinkStyle}>Registro</Link>
            </>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavBarComponent;