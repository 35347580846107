import React from "react";
import "./StepperComponent.css";
import CheckBlue from "../../../Assets/CheckBlue.svg"
import isBorderlessIcon from "../../../Assets/YellowNotification.svg"

export default function StepperComponent({ className, steps, currentStep, title, subtitle, style, borderless, isSixthStep }) {
    const stepsArray = Array.from({ length: steps }, (_, index) => index + 1);



    return (
        <div className={'d-flex' + (borderless ? ' row borderless align-items-center' : ' flex-column stepperMain') + (className ? ' ' + className : '')} style={style}>
            <div className={`d-flex align-items-center stepperTitle ${isSixthStep ? 'justify-content-center w-100 mb-2' : ''}`} >
                <img src={borderless ? isBorderlessIcon : CheckBlue} alt="CheckBlue" />
                <span
                    style={isSixthStep ? { fontWeight: 'bold', color: 'black' } : {}}
                >
                    {title}
                </span>
            </div>
            <span className="stepperSubtitle" style={
                {
                    fontWeight: borderless ? 'bold' : '',
                }
            }>{subtitle}</span>
            <div className="d-flex align-items-center steps">
                {stepsArray.map((stepNumber) => (
                    <div
                        key={stepNumber}
                        style={{
                            backgroundColor: stepNumber <= currentStep ? 'blue' : 'lightgray',
                        }}
                        className="stepperRectangle"
                    >
                    </div>
                ))}
            </div>
        </div >
    );
}

