import React, { useState, useRef, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ButtonComponent from '../Shared/ButtonComponent';
import InputComponent from '../Shared/InputComponent';
import styles from './LoginScreen.module.css';
import White from '../../Assets/White.png';
import Back from '../../Assets/Back.svg';
import { useAuthApi } from '../../api/ForgotPassApi';
import { toast } from 'react-toastify';

const ForgotPass = () => {
    const [step, setStep] = useState(1);
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const history = useHistory();
    const { resetPassword, sendResetPasswordEmail } = useAuthApi();
    const [codeResponse, setCodeResponse] = useState('');
    const [mailDisabled, setMailDisabled] = useState(false);
    const [codeData, setCodeData] = useState({});


    const emailRef = useRef();
    const codeRef = useRef();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();

    const handleSendEmail = useCallback(async () => {
        setMailDisabled(true);
        const emailValidation = await emailRef.current.validateExternal();
        if (emailValidation.isValid) {
            const email = emailValidation.value;
            setEmail(email);
            await sendResetPasswordEmail(email).then(
                (response) => {
                    console.log(response);
                    setCodeResponse(response.validation_code);
                    setStep(2);
                    setMailDisabled(false);
                }
            ).catch(
                (error) => {
                    console.error(error);
                    toast.error('Error enviando el correo de recuperación.');
                    setMailDisabled(false);
                }
            );
        }
    }, [emailRef, sendResetPasswordEmail, setStep]);

    const validateCode = (code) => {
        console.log(code, codeResponse);
        return code === codeResponse;

    };

    const validateCodeHandle = useCallback(async () => {
        const codeValidation = await codeRef.current.validateExternal();
        if (codeValidation.isValid) {
            const code = codeValidation.value;
            if (validateCode(code)) {
                setCode(code);
                setStep(3);
            } else {
                toast.error('Código incorrecto.');
            }
        }
    }
        , [codeRef, validateCode]);


    const validatePassword = useCallback(async () => {
        const passwordValidation = await passwordRef.current.validateExternal();
        const confirmPasswordValidation = await confirmPasswordRef.current.validateExternal();
        if (passwordValidation.isValid && confirmPasswordValidation.isValid) {
            const password = passwordValidation.value;
            const confirmPassword = confirmPasswordValidation.value;
            if (password === confirmPassword) {
                await resetPassword(email, password, code).then(
                    (response) => {
                        console.log(response);
                        toast.success('Contraseña cambiada con éxito.');
                        history.push('/login');
                    }
                ).catch(
                    (error) => {
                        console.error(error);
                        toast.error('Error cambiando la contraseña.');
                    }
                );
            } else {
                toast.error('Las contraseñas no coinciden.');
            }
        }
    }, [passwordRef, confirmPasswordRef, resetPassword, email, code, history]);


    const StepContent = ({ headerText, opportunityText, forgotPassText, inputType = "text", buttonText, onResend }) => (
        <>
            <button className={styles.goBackButton} onClick={
                step === 1 ? () => history.push('/login') : () => setStep(step - 1)
            }>
                <img src={Back} alt="back" className={styles.goBackImage} />
            </button>
            <Row className="justify-content-center align-items-center">
                <Col className="text-center">
                    <img src={White} alt="Logo" className={styles.logo} />
                    <h1 className={styles.messageHeader}>{headerText}</h1>
                </Col>
            </Row>
            <span className={styles.opportunityText}>{opportunityText}</span>
            <div className={styles.loginFooter}>
                <span className={styles.forgotPassText}>{forgotPassText}</span>
                <Col xs={12} className="gap-after">
                    <InputComponent type={inputType} placeholder={inputType === "email" ? "Email" : inputType === "number" ? "Código de verificación" : "Contraseña"}
                        ref={inputType === "email" ? emailRef : inputType === "number" ? codeRef : passwordRef} />
                </Col>
                {
                    step === 3 && (
                        <Col xs={12} className="gap-after">
                            <InputComponent type="password" placeholder="Repetir contraseña" ref={confirmPasswordRef} />
                        </Col>
                    )
                }
                {onResend && (
                    <Col xs={12} className="gap-after">
                        <button className={styles.resendCodeButton} onClick={onResend}>Reenviar código</button>
                    </Col>
                )}
                <Col xs={12} className="gap-after">
                    <ButtonComponent text={buttonText} large isHover onClick={step === 1 ? handleSendEmail : step === 2 ? validateCodeHandle : validatePassword} disabled={
                        step === 1 ? mailDisabled : false
                    } />
                </Col>
            </div>
        </>
    );

    return (
        <Container fluid className={styles.loginContainer}>
            {step === 1 && <StepContent
                headerText="Recuperemos tu contraseña"
                opportunityText="Vamos a verificar tu casilla de correo. Sigue los pasos."
                forgotPassText="Ingresa tu dirección de correo electrónico."
                inputType="email"
                buttonText="Enviar"
            />}
            {step === 2 && <StepContent
                headerText="¡Vas muy bien!"
                opportunityText="Es momento de verificar tu mail."
                forgotPassText="Ingresa el código que te enviamos."
                inputType="number"
                buttonText="Confirmar"
                onResend={() => setStep(1)}
            />}
            {step === 3 && <StepContent
                headerText="Correcto!"
                opportunityText="Es momento de ingresar tus nuevos datos."
                forgotPassText="Ingresa tu nueva contraseña."
                inputType="password"
                buttonText="Confirmar"
            />}
        </Container>
    );
};

export default ForgotPass;
