import React from "react";
import CompanyReusableComponent from "../Components/CompanyReusable/CompanyReusableComponent";

import MainSection5 from "./Step5/MainSection5";
import EmployeeSection5 from "./Step5/EmployeeSection5";
import SectorSection5 from "./Step5/SectorSection5";
import LocationSection5 from "./Step5/LocationSection5";
import DescriptionSection5 from "./Step5/DescriptionSection5";

const RenderStep5 = ({
    handleSetStep,
    setCurrentSection,
    currentSection,
    setStep,
    handleClick,
    imgUrl,
    handleInputChange,
    handleSubmit,
    fileInputRef,
    uploadedImage,
    setUploadedImage,
    setImgUrl,
    handleImageUpload,
    selectedEmployees,
    handleSelectEmployees,
    handleSaveEmployees,
    descriptions,
    formDataStep5,
    handleSaveSector,
    selectedWorkArea,
    workAreas,
    handleAddWorkArea,
    handleSelectWorkArea,
    workAreaName,
    handleOptionSelected,
    currentPosition,
    fetchCurrentLocation,
    isLoaded,
    handleOptionSelectedLocation,
    handleSaveLocation,
    isRemoteChange,
    nameRef,
    setFormDataStep5
}) => {
    const sections = {
        main: (
            <MainSection5
                handleClick={handleClick}
                imgUrl={imgUrl}
                formDataStep5={formDataStep5}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                fileInputRef={fileInputRef}
                uploadedImage={uploadedImage}
                setUploadedImage={setUploadedImage}
                setImgUrl={setImgUrl}
                handleImageUpload={handleImageUpload}
                handleSetStep={handleSetStep}
                setCurrentSection={setCurrentSection}
                setStep={setStep}
                workAreaName={workAreaName}
                nameRef={nameRef}
            />
        ),
        employees: (
            <EmployeeSection5
                setCurrentSection={setCurrentSection}
                handleSetStep={handleSetStep}
                selectedEmployees={selectedEmployees}
                handleSelectEmployees={handleSelectEmployees}
                handleSaveEmployees={handleSaveEmployees}
                descriptions={descriptions}
            />
        ),
        sector: (
            <SectorSection5
                handleSetStep={handleSetStep}
                setCurrentSection={setCurrentSection}
                handleSaveSector={handleSaveSector}
                handleAddWorkArea={handleAddWorkArea}
                handleSelectWorkArea={handleSelectWorkArea}
                selectedWorkArea={selectedWorkArea}
                workAreas={workAreas}
                handleOptionSelected={handleOptionSelected}
            />
        ),
        location: (
            <LocationSection5
                handleSetStep={handleSetStep}
                setCurrentSection={setCurrentSection}
                currentPosition={currentPosition}
                fetchCurrentLocation={fetchCurrentLocation}
                formDataStep5={formDataStep5}
                isLoaded={isLoaded}
                handleOptionSelectedLocation={handleOptionSelectedLocation}
                handleSaveLocation={handleSaveLocation}
                isRemoteChange={isRemoteChange}
            />
        ),
        description: (
            <CompanyReusableComponent
                company="WhiteScreen"
                step={handleSetStep}
                title="Describe tu empresa en 50 palabras"
                goBack={() => setCurrentSection("main")}
            >
                <DescriptionSection5
                    formDataStep5={formDataStep5}
                    handleInputChange={handleInputChange}
                    handleSetStep={handleSetStep}
                    setCurrentSection={setCurrentSection}
                    setFormDataStep5={setFormDataStep5}
                />
            </CompanyReusableComponent>
        ),
    };

    return sections[currentSection];
};

export default RenderStep5;
