import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/talents';

export const useTalentsApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;


    const createTalent = async (talentData) => {
        try {
            const response = await axios.post(`${apiBaseUrl}`, talentData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Talent created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating Talent:', error.response ? error.response.data : error);
        }
    };

    const fetchTalent = async (talentId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${talentId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching Talent:', error);
            return null;
        }
    };

    const updateTalent = async (talentId, talentData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${talentId}`, talentData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Talent updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating Talent:', error.response ? error.response.data : error);
        }
    };

    const fetchFullProfile = async (talentId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${talentId}/full-profile`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 500) {
                toast.error('Backend error fetching full profile');
            } else {
                toast.error('Error fetching full profile:', error);
            }
            return null;
        }
    };

    return {
        createTalent,
        fetchTalent,
        updateTalent,
        fetchFullProfile,
    };
};
