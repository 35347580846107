import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './DropdownComponent.css';

const Chevron = ({ isOpen }) => (
    <svg
        className={`chevron ${isOpen ? 'open' : ''}`}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M16.5303 8.96967C16.8232 9.26256 16.8232 9.73744 16.5303 10.0303L12.5303 14.0303C12.2374 14.3232 11.7626 14.3232 11.4697 14.0303L7.46967 10.0303C7.17678 9.73744 7.17678 9.26256 7.46967 8.96967C7.76256 8.67678 8.23744 8.67678 8.53033 8.96967L12 12.4393L15.4697 8.96967C15.7626 8.67678 16.2374 8.67678 16.5303 8.96967Z" fill="#003EFF" />
    </svg>
);

const DropdownComponent = ({ isBordered, starSVG, placeholder, options, onOptionSelect, onOptionSelectIcon, selectedOption, style, paddingY, transparent }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [internalSelectedOption, setInternalSelectedOption] = useState(null);

    useEffect(() => {
        if (selectedOption) {
            setInternalSelectedOption(selectedOption);
        } else {
            setInternalSelectedOption(null);
        }
    }, [selectedOption]);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        setInternalSelectedOption(option);
        setIsOpen(false);
        if (onOptionSelect) {
            onOptionSelect(option.title);
        }
        if (onOptionSelectIcon) {
            onOptionSelectIcon(option.icon);
        }
    };

    const displayText = internalSelectedOption ? internalSelectedOption.title : placeholder;
    const currentIcon = internalSelectedOption && internalSelectedOption.icon ? internalSelectedOption.icon : starSVG;

    return (
        <div className='dropdown-container' style={style}>
            <div className={`dropdown ${isBordered ? 'bordered' : ''} ${transparent ? 'transparent' : ''}`} onClick={toggleDropdown} style={
                {
                    padding: paddingY ? paddingY : "",
                }
            }>
                {currentIcon && <img src={currentIcon} alt="icon" className="dropdown-icon" />}
                <span className="dropdown-placeholder">{displayText}</span>
                <Chevron isOpen={isOpen} />
            </div>
            {isOpen && (
                <div className='dropdown-options'>
                    {options.map((option) => (
                        <div key={option.title} className="dropdown-option" onClick={() => handleOptionClick(option)}>
                            {option.icon && <img src={option.icon} alt="option icon" className="option-icon" />}
                            <div className="option-content">
                                <div className="option-title">{option.title}</div>
                                <div className="option-description">{option.description}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

DropdownComponent.propTypes = {
    isBordered: PropTypes.bool,
    starSVG: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string,
            icon: PropTypes.string,
        })
    ),
    onOptionSelect: PropTypes.func,
    onOptionSelectIcon: PropTypes.func,
    selectedOption: PropTypes.object,
    style: PropTypes.object,
};

export default DropdownComponent;