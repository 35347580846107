import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(() => {
    const localData = localStorage.getItem('userData');
    return localData
      ? JSON.parse(localData)
      : { user: {}, token: '', talent: {}, companyID: '' };
  });

  const updateUserData = useCallback((newUserData) => {
    setUserData(newUserData);
    localStorage.setItem('userData', JSON.stringify(newUserData));
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const localData = localStorage.getItem('userData');
      if (localData) {
        const newUserData = JSON.parse(localData);
        if (JSON.stringify(newUserData) !== JSON.stringify(userData)) {
          setUserData(newUserData);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [userData]);

  const contextValue = useMemo(
    () => ({ userData, updateUserData }),
    [userData, updateUserData]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
