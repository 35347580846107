import React, { createContext, useContext, useState, useMemo } from 'react';

const ProfileDataContext = createContext();

export const ProfileDataProvider = ({ children }) => {
    const [profileData, setProfileData] = useState({
        location: '',
        name: '',
        age: '',
        imgProfile: '',
        seniority: '',
        especialitation: '',
        salary: '',
        talents: [],
        hiring: '',
        langs: [],
        hardSkills: [],
        techSkills: [],
        aboutMe: '',
        education: '',
        certs: [],
        exp: [],
        refs: [],
        talent: '',
        visibility: '',
        surname: '',
        modHiring: '',
        dataSoftSkills: [],
        corporateCulture: '',
    });

    const updateProfileData = (newData) => {
        setProfileData((prevData) => ({ ...prevData, ...newData }));
    };

    const memoizedValue = useMemo(() => ({ profileData, updateProfileData }), [profileData]);


    return (
        <ProfileDataContext.Provider value={memoizedValue}>
            {children}
        </ProfileDataContext.Provider>
    );
};

export const useProfileData = () => useContext(ProfileDataContext);
