import React from "react";
import { Row, Col } from "react-bootstrap";
import CompanyReusableComponent from "../Components/CompanyReusable/CompanyReusableComponent";
import ButtonComponent from "../../../Shared/ButtonComponent";


const renderStep4 = ({ setStep, handleSetStep }) => (
    <CompanyReusableComponent
        company="BlueScreen"
        step={handleSetStep}
        title="¡Estamos listos!"
        subtitle="Por favor, proporciona unos pocos datos esenciales sobre ti y tu empresa."
        goBack={() => setStep(3)}
    >
        <Row>
            <Col xs={12} className="text-center">
                <span className="mb-2 color-black">Vas a ingresar una empresa nueva o una empresa que ya está registrada.</span>
            </Col>
            <Col xs={12} className="gap-after">
                <ButtonComponent text="Nueva empresa" version="outline" isLeft large isHover onClick={() => setStep(5)} />
            </Col>
            <Col xs={12} className="gap-after">
                <ButtonComponent text="Mi empresa ya está registrada" isLeft version="outline" large isHover onClick={() => setStep("companyRegistered")} />
            </Col>
        </Row>
    </CompanyReusableComponent>
);

export default renderStep4;