import React from "react";
import './CompanyReusableComponent.css'
import BlueLogo from '../../../../../Assets/BlueLogo.svg';
import White from '../../../../../Assets/White.png';
import { Col, Container, Row } from "react-bootstrap";
import GoBackButton from "../GoBackButton/GoBackButton";
import { fullScreenStyles } from "../../../Talento/steps/ProfilePersStyles";
import { linkStep, titleStyle } from "../../../Talento/talentoStyles";
import Loader from '../../../../../Assets/Loader.svg';
import NavBarComponentProfile from "../../../../Shared/NavBarComponent/NavBarComponent";
import PropTypes from 'prop-types';



const CompanyReusableComponent = ({ company, title, children, subtitle, hrefLoaderScreen, linkLoaderScreen, goBack, imgProfile }) => {
    CompanyReusableComponent.propTypes = {
        company: PropTypes.string.isRequired,
        title: PropTypes.string,
        children: PropTypes.node,
        subtitle: PropTypes.string,
        hrefLoaderScreen: PropTypes.func,
        linkLoaderScreen: PropTypes.string,
        goBack: PropTypes.func,
        imgProfile: PropTypes.string,
    };


    switch (company) {
        case "BlueScreen": {
            return (
                <Container fluid className="companyReusableComponent">
                    <GoBackButton type="white" goBack={goBack} />
                    <Row className="justify-content-center align-items-center">
                        <Col className="text-center">
                            <img src={White} alt="Logo" style={{ width: '150px' }} />
                            <h1 style={titleStyle}>
                                {title}
                            </h1>
                        </Col>
                    </Row>
                    {
                        subtitle && (
                            <span className="companyReusableComponentSubtitle">
                                {subtitle}
                            </span>
                        )
                    }
                    <div className="companyReusableComponentContent">
                        {children}
                    </div>
                </Container>
            )

        }

        case "WhiteScreen": {
            return (
                <div style={fullScreenStyles}>
                    <img src={BlueLogo} alt="BlueLogo" style={{ width: '150px' }} />
                    <Container fluid className="h-100 d-flex align-items-center justify-content-between flex-column">
                        <GoBackButton type="blue" goBack={goBack} />
                        <Row className="d-flex justify-content-center">
                            {children}
                        </Row>
                    </Container>
                </div>
            )
        }

        case "LoaderScreen": {
            return (
                <Container fluid className="companyReusableComponent">
                    <Row className="justify-content-center align-items-center flex-column">
                        <Col className="text-center mb-4">
                            <img src={White} alt="Logo" style={{ width: '150px' }} />
                        </Col>
                        <div
                            className="companyLoaderScreenContent"
                        >
                            <div className="d-flex justify-content-center">
                                <img src={Loader} alt="Loader" className="Loader" />
                            </div>
                            <div
                                style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                            >
                                <h1 style={titleStyle}>{title}</h1>
                                <span
                                    style={{
                                        fontSize: "0.8rem",
                                        color: "white",
                                        textAlign: "center",
                                    }}
                                >
                                    {subtitle}
                                </span>
                            </div>
                        </div>
                        <div
                            style={{
                                position: "fixed",
                                bottom: "10px",
                                width: "100%",
                                textAlign: "center",
                            }}
                        >
                            {
                                linkLoaderScreen && (
                                    <button onClick={hrefLoaderScreen} style={linkStep}>
                                        {linkLoaderScreen}
                                    </button>
                                )
                            }
                        </div>
                    </Row>
                </Container>
            )
        }

        case "ProfileScreen": {
            return (
                <Container fluid className="companyReusableComponent">
                    <Row className="justify-content-center align-items-center">
                        <Col xs={'12'} className="p-0">
                            <NavBarComponentProfile imgProfile={imgProfile || 'https://via.placeholder.com/150'} goBack={goBack} />
                        </Col>
                    </Row>
                    <div className="companyReusableComponentContent profile">
                    </div>
                    {children}
                </Container>
            )
        }

        default: {
            return null
        }
    }

}

export default CompanyReusableComponent;