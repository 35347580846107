import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { RegisterDataContext } from '../../../contexts/RegisterDataContext';



const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/videos';

export const useVideosApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;


    // Create a video
    const createVideo = async (videoData) => {
        try {
            const response = await axios.post(apiBaseUrl, videoData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Video created:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error creating video:', error.response ? error.response.data.toString() : error);
            throw error;
        }
    };

    // Retrieve all videos
    const getVideos = async () => {
        try {
            const response = await axios.get(apiBaseUrl, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Videos retrieved:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error retrieving videos:', error);
            return [];
        }
    };

    // Retrieve a specific video
    const getVideo = async (videoId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${videoId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Video retrieved:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error retrieving video:', error);
            return null;
        }
    };

    // Update a video
    const updateVideo = async (videoId, videoData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${videoId}`, videoData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Video updated:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error updating video:', error.response ? error.response.data.toString() : error);
            throw error;
        }
    };

    // Delete a video
    const deleteVideo = async (videoId) => {
        try {
            const response = await axios.delete(`${apiBaseUrl}/${videoId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Video deleted');
            return response.data;
        } catch (error) {
            console.error('Error deleting video:', error.response ? error.response.data.toString() : error);
            throw error;
        }
    };

    return {
        createVideo,
        getVideos,
        getVideo,
        updateVideo,
        deleteVideo
    };
};
