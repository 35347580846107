import React, { useCallback, useEffect, useState } from 'react';
import { useToolsApi } from '../../../../../api/ToolsApi';

import {
  backButtonIconStyle,
  backButtonStyle,
  fullScreenStyles,
} from '../../../../Register/Talento/steps/ProfilePersStyles';
import { Col, Container, Row } from 'react-bootstrap';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import SearchComponent from '../../../../Shared/SearchComponent/SearchComponent';
import BlueLogo from '../../../../../Assets/BlueLogo.svg';
import BackBlue from '../../../../../Assets/BackBlue.svg';
import useEmployments from '../../../api/Employments';

const MAX_TOOLS = 5;

const Case5Step3CreateJob = ({ goBack, benefits = [], save }) => {
  const [selectedTools, setSelectedTools] = useState([]);
  const [toolsList, setToolsList] = useState([]);
  const { getbenefits } = useEmployments();

  useEffect(() => {
    const fetchToolsData = async () => {
      const data = await getbenefits();
      if (Array.isArray(data)) {
        setToolsList(data);
      } else {
        console.error('Expected data to be an array', data);
      }
    };

    fetchToolsData();
  }, []);

  useEffect(() => {
    if (benefits.length) {
      setSelectedTools(benefits);
    }
  }, [benefits]);

  const handleToolSelect = useCallback(
    (tool) => {
      if (!selectedTools.some((t) => t.name === tool.name_es)) {
        setSelectedTools((prev) =>
          prev.length < MAX_TOOLS
            ? [...prev, { name: tool.name_es, id: tool.id }]
            : prev
        );
      }
    },
    [selectedTools]
  );

  const handleToolsSelection = useCallback(() => {
    save(selectedTools);
    goBack();
  }, [goBack, save, selectedTools]);

  return (
    <div style={fullScreenStyles}>
      <img
        src={BlueLogo}
        alt="BlueLogo"
        style={{ cursor: 'pointer', marginBottom: '2rem' }}
      />
      <Container
        fluid
        className="h-100 d-flex align-items-center justify-content-between flex-column"
      >
        <button style={backButtonStyle} onClick={goBack}>
          <img src={BackBlue} alt="back" style={backButtonIconStyle} />
        </button>
        <Row>
          <Col xs={12} className="mb-3">
            <span className="text-center">
              Indica tus cinco herramientas técnicas más fuertes.
            </span>
          </Col>
          <Col xs={12} className="mb-3">
            {selectedTools.length > 0 && (
              <ButtonComponent
                isColorBlack
                text={
                  selectedTools.map((t) => `${t.name}`).join(', ') ||
                  'Selecciona hasta cinco'
                }
                large
                paddingY="15px"
                isLeft
                version="outline"
                isEditIcon
              />
            )}
          </Col>
          {toolsList.map((tool) => (
            <Col xs={6} key={tool.id} className="mb-3">
              <ButtonComponent
                text={tool.name_es}
                onClick={() => handleToolSelect(tool)}
                version={
                  selectedTools.some((t) => t.name === tool.name_es)
                    ? 'fill'
                    : 'outline'
                }
                large
                isColorBlack
                height={'100%'}
                paddingY="15px"
                disabled={
                  selectedTools.length === MAX_TOOLS &&
                  !selectedTools.some((t) => t.name === tool.name_es)
                }
              />
            </Col>
          ))}
          <Col xs={12} className="mb-3">
            <SearchComponent
              options={toolsList.map((s) => s.name_es)}
              placeholder="Buscar herramienta técnicas"
              addOption={true}
              onOptionSelected={handleToolSelect}
              isWorkArea={true}
            />
          </Col>
          <Col xs={12} className="text-center mt-3">
            <ButtonComponent
              text="Guardar"
              onClick={handleToolsSelection}
              large
              version={selectedTools.length === 0 ? 'outline' : 'fill'}
              isHover
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Case5Step3CreateJob;
