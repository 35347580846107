import React, { useState } from 'react';
import CompanyReusableComponent from '../../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import { Row, Col } from 'react-bootstrap';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import { linkStyle } from '../../../../Register/Talento/talentoStyles';

const Case3Step4CreateJob = ({ goBack, schedules, save }) => {
  const [selectedSchedule, setSelectedSchedule] = useState(schedules || null);
  const handleSave = () => {
    save(selectedSchedule);
    goBack();
  };
  return (
    <CompanyReusableComponent goBack={goBack} company={'WhiteScreen'}>
      <Row style={{ padding: '0 20px' }}>
        <Col xs={12} className="gap-after">
          <span>Los candidatos finalistas, deben presentar referencias.</span>
        </Col>

        <Col xs={12} className="gap-after">
          <ButtonComponent
            text="Si"
            version={selectedSchedule ? 'fill' : 'outline'}
            onClick={() => setSelectedSchedule(true)}
            large
            isHover
          />
        </Col>
        <Col xs={12} className="gap-after">
          <ButtonComponent
            text="No"
            version={selectedSchedule === false ? 'fill' : 'outline'}
            onClick={() => setSelectedSchedule(false)}
            large
            isHover
          />
        </Col>

        <Col xs={12} className="gap-after">
          <ButtonComponent
            text="Continuar"
            version={selectedSchedule === null ? 'grey' : 'fill'}
            onClick={handleSave}
            disabled={selectedSchedule === null}
            large
            isHover
          />
        </Col>

        <Col xs={12} className="gap-after">
          <button style={linkStyle} onClick={() => save(false)}>
            Omitir
          </button>
        </Col>
      </Row>
    </CompanyReusableComponent>
  );
};

export default Case3Step4CreateJob;
