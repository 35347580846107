import axios from 'axios';

export const apiClient = axios.create({
  baseURL: 'https://api-stg.empleosmarketingdigital.com/v1/',
  headers: {
    'Content-Type': 'application/json',
  },
});

export function birthDateTransform(birthDateString) {
  if (!birthDateString) return null;
  return new Date(birthDateString);
}

export const daysMap = {
  MONDAY: 'Lun',
  TUESDAY: 'Mar',
  WEDNESDAY: 'Mié',
  THURSDAY: 'Jue',
  FRIDAY: 'Vie',
  SATURDAY: 'Sáb',
  SUNDAY: 'Dom',
};

export const reverseDaysMap = {
  Lun: 'MONDAY',
  Mar: 'TUESDAY',
  Mié: 'WEDNESDAY',
  Jue: 'THURSDAY',
  Vie: 'FRIDAY',
  Sáb: 'SATURDAY',
  Dom: 'SUNDAY',
};
