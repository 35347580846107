import React, { useCallback } from 'react';
import { fullScreenStyles } from './ProfilePersStyles';
import ButtonComponent from '../../../Shared/ButtonComponent';
import { Row, Col, Container } from 'react-bootstrap';
import StepperComponent from '../../../Shared/StepperComponent/StepperComponent';
import BlueLogo from '../../../../Assets/BlueLogo.svg';
import PortfolioNotification from '../../../../Assets/PortfolioNotification.svg';
import SalaryNotification from '../../../../Assets/SalaryNotification.svg';
import { BRicon, ARicon, USicon } from '../../../Shared/iconsComponent';
import InputComponent from '../../../Shared/InputComponent';
import InfoIcon from '../../../../Assets/InfoIcon.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const selectableOptions = [
  [
    { title: 'Relación de dependencia' },
    { title: 'Freelance / Autónomo' },
    { title: 'Prácticas / Pasantía' },
  ],
  [{ title: 'Puesto fijo' }, { title: 'Proyecto' }, { title: 'Temporal' }],
  [
    { title: 'Full Time' },
    { title: 'Part Time' },
    { title: 'Horas estipuladas' },
    { title: 'Turnos' },
    { title: 'Voluntariado' },
  ],
];

const modHiringOptions = [
  {
    title: 'Presencial',
    description: 'Deseo trabajar en las instalaciones de la empresa.',
  },
  {
    title: 'Híbrido',
    description: 'Deseo trabajar tanto en la empresa como en mi casa.',
  },
  {
    title: 'Remoto',
    description: 'Me siento más cómodo trabajando fuera de la oficina.',
  },
];

const remoteModHiringOptions = [
  {
    title: 'Remoto global',
    description: 'Quiero trabajar desde cualquier parte del mundo.',
  },
  {
    title: 'Remoto nacional',
    description: 'Quiero trabajar desde cualquier lugar del país.',
  },
  {
    title: 'Horas estipuladas',
    description: 'Deseo trabajar una cantidad de horas específicas por día.',
  },
  {
    title: 'Huso horario específico',
    description: 'Trabajar remoto dentro de un husos horario determinado',
  },
];

const salaryOptions = [
  {
    title: 'Por proyecto',
    description: 'Se remunera al finalizar cada proyecto asignado.',
  },
  {
    title: 'Pago mensual',
    description: 'Se remunera el salario al finalizar cada mes.',
  },
  {
    title: 'Pago anual',
    description: 'Este es el presupuesto anual para este proyecto.',
  },
  {
    title: 'Indiferente',
  },
];

const ProfilePersWindow = ({
  step,
  setStep,
  isHiring,
  isModHiring,
  isSalary,
  onClose,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = React.useState([
    true,
    true,
    true,
  ]);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [selectedRemoteOption, setSelectedRemoteOption] = React.useState(null);
  const [showRemoteOptions, setShowRemoteOptions] = React.useState(false);
  const dropdownRef = React.useRef();
  const salaryRef = React.useRef(true);
  const [salarySelectedOption, setSalarySelectedOption] = React.useState('ARS');
  const [salaryOptionsFlag, setSalaryOptionsFlag] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const [maxSalary, setMaxSalary] = React.useState('');

  const handleMaxSalaryChange = (e) => {
    setMaxSalary(e.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    handleHiringWithDates();
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleHiringWithDates();
  };

  const formatDate = (date) => {
    if (!date) return '';

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().substr(-2);

    return `${day}/${month}/${year}`;
  };

  const handleHiringWithDates = () => {
    if (
      selectedOption &&
      selectedOption.title === 'Temporal' &&
      startDate &&
      endDate
    ) {
      const hiringInfo = `${selectedOption.title} ${formatDate(
        startDate
      )} - ${formatDate(endDate)}`;
      console.log('Hiring Option Selected:', hiringInfo);
      if (typeof isHiring === 'function') {
        isHiring(hiringInfo);
      }
    }
  };

  const handleRadioChange = useCallback(
    (option, stepIndex) => {
      if (stepIndex === 1) {
        setSelectedOption(option);
        setShowRemoteOptions(option.title === 'Remoto');
        setIsButtonDisabled(
          isButtonDisabled.map((item, index) =>
            index !== step - 1 ? item : option.title === 'Remoto'
          )
        );

        if (option.title === 'Temporal') {
          setShowDatePicker(true);
        } else {
          setShowDatePicker(false);
          setStartDate(null);
          setEndDate(null);
        }

        if (option.title === 'Presencial' || option.title === 'Híbrido') {
          if (typeof isModHiring === 'function') {
            isModHiring(option.title);
          }
        }

        if (
          selectedOption &&
          selectedOption.title === 'Temporal' &&
          startDate &&
          endDate
        ) {
          const hiringInfo = `${selectedOption.title} ${formatDate(
            startDate
          )} - ${formatDate(endDate)}`;
          console.log('Hiring Option Selected:', hiringInfo);
          if (typeof isHiring === 'function') {
            isHiring(hiringInfo);
          }
        }
        if (option.title !== 'Temporal') {
          if (typeof isHiring === 'function') {
            isHiring(option.title);
          }
        }
        console.log('Hiring Option SelecteeeSd:', option.title);
      } else if (stepIndex === 2) {
        setSelectedRemoteOption(option);
        setIsButtonDisabled(
          isButtonDisabled.map((item, index) =>
            index !== step - 1 ? item : false
          )
        );
        if (typeof isModHiring === 'function') {
          isModHiring(option.title);
        }
      } else if (stepIndex === 3) {
        setIsButtonDisabled(
          isButtonDisabled.map((item, index) =>
            index !== step - 1 ? item : false
          )
        );
      } else if (stepIndex === 4) {
        setSalaryOptionsFlag(option.title);
        setIsButtonDisabled(
          isButtonDisabled.map((item, index) =>
            index !== step - 1 ? item : false
          )
        );
        if (typeof isSalary === 'function') {
          isSalary([option.title, maxSalary, salarySelectedOption]);
        }
      }
    },
    [
      isButtonDisabled,
      step,
      isModHiring,
      isHiring,
      selectedOption,
      startDate,
      endDate,

      maxSalary,
      salarySelectedOption,
      setShowRemoteOptions,
      setShowDatePicker,
      setStartDate,
      setEndDate,
      setSelectedRemoteOption,
      setSalaryOptionsFlag,
      isSalary,
    ]
  );

  const handleOptionChange = (option) => {
    salarySelectedOption === option
      ? setSalarySelectedOption('')
      : setSalarySelectedOption(option);
    console.log('la opcion es: ', option);
  };
  let placeholderText;
  if (salarySelectedOption === 'ARS') {
    placeholderText = 'Pesos Argentinos';
  } else if (salarySelectedOption === 'US') {
    placeholderText = 'Dólares Estadounidenses';
  } else {
    placeholderText = 'Reales Brasileños';
  }

  const handleSaveClick = () => {
    const selectedSalaryOption = salaryOptions.find(
      (option) => option.title === salaryOptionsFlag
    );

    const salary = [
      selectedSalaryOption ? selectedSalaryOption.title : '',

      maxSalary,
      salarySelectedOption,
    ];
    console.log('Salario seleccionado: ', salary);

    onClose();
  };

  React.useEffect(() => {
    if (salaryOptionsFlag && maxSalary && salarySelectedOption) {
      isSalary([salaryOptionsFlag, maxSalary, salarySelectedOption]);
    }
  }, [salaryOptionsFlag, maxSalary, salarySelectedOption]);

  const renderRemoteOptions = () => {
    if (!showRemoteOptions) return null;

    return remoteModHiringOptions.map((option, index) => (
      <Col xs={11} key={index} className="mb-5">
        <div
          className="form-check"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <input
            className="form-check-input"
            type="radio"
            name="remote-options"
            id={`remote-option-${index}`}
            onChange={() => handleRadioChange(option, 2)}
            checked={selectedRemoteOption === option}
          />
          <label
            className="form-check-label"
            htmlFor={`remote-option-${index}`}
            style={{ marginLeft: '1rem' }}
          >
            <div style={{ fontWeight: 'bold', textAlign: 'start' }}>
              {option.title}
            </div>
            <div style={{ textAlign: 'start' }}>{option.description}</div>
          </label>
        </div>
      </Col>
    ));
  };

  const renderOptions = (options, stepIndex) =>
    options.map((option, index) => (
      <Col xs={11} key={index} className="mb-5">
        <div
          className="form-check"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <input
            className="form-check-input"
            type="radio"
            name={`options-${stepIndex}`}
            id={`option-${stepIndex}-${index}`}
            onChange={() => handleRadioChange(option, stepIndex)}
            checked={selectedOption === option}
          />
          <label
            className="form-check-label"
            htmlFor={`option-${stepIndex}-${index}`}
          >
            <div style={{ fontWeight: 'bold', textAlign: 'start' }}>
              {option.title}
            </div>
            <div style={{ textAlign: 'start' }}>{option.description}</div>
          </label>
        </div>
      </Col>
    ));

  const renderHiringOptions = () =>
    selectableOptions.map((options, index) => (
      <React.Fragment key={index}>
        {step === index + 1 && (
          <>
            {renderOptions(options, 1)}
            {showDatePicker && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '1rem',
                }}
              >
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Fecha de inicio"
                  className="DatePicker-date"
                />
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="Fecha de fin"
                  className="DatePicker-date"
                />
              </div>
            )}

            <Col xs={12} className="gap-after">
              <ButtonComponent
                text="Guardar"
                onClick={() => {
                  if (step === 3) {
                    onClose();
                  } else {
                    setStep(step + 1);
                    if (step === 2 && selectedOption.title === 'Temporal') {
                      setShowDatePicker(false);
                    }
                  }
                }}
                large
                disabled={isButtonDisabled[index]}
                isHover
                version={isButtonDisabled[index] ? 'grey' : 'fill'}
              />
            </Col>
          </>
        )}
      </React.Fragment>
    ));

  const renderSalaryOptions = () =>
    salaryOptions.map((option, index) => (
      <Col xs={11} key={index} className="mb-5">
        <div
          className="form-check"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <input
            className="form-check-input"
            type="radio"
            name="salary-options"
            id={`salary-option-${index}`}
            onChange={() => handleRadioChange(option, 4)}
            checked={salaryOptionsFlag === option.title}
          />
          <label
            className="form-check-label"
            htmlFor={`salary-option-${index}`}
            style={{ marginLeft: '1rem' }}
          >
            <div style={{ fontWeight: 'bold', textAlign: 'start' }}>
              {option.title}
            </div>
            <div style={{ textAlign: 'start' }}>{option.description}</div>
          </label>
        </div>
      </Col>
    ));

  return (
    <div style={fullScreenStyles}>
      <img
        src={BlueLogo}
        alt="BlueLogo"
        style={{ cursor: 'pointer', marginBottom: '2rem' }}
      />
      {isHiring && (
        <Container
          fluid
          className="h-100 d-flex align-items-center justify-content-between flex-column"
        >
          <Row className="justify-content-center ">
            <StepperComponent
              className="mb-5"
              steps={3}
              currentStep={step}
              subtitle="Por favor, especifica tipo de empleo deseado"
              borderless
            />
            {renderHiringOptions()}
          </Row>
        </Container>
      )}
      {isModHiring && (
        <Container
          fluid
          className="h-100 d-flex align-items-center justify-content-between flex-column"
        >
          <Row className="justify-content-center ">
            <Col xs={12} className="gap-after mb-5">
              <img src={PortfolioNotification} alt="PortfolioNotification" />
              <span style={{ fontWeight: 'bold', textAlign: 'start' }}>
                Por favor, especifica modalidad laboral.
              </span>
            </Col>
            {renderOptions(modHiringOptions, 1)}
            {renderRemoteOptions()}

            <Col xs={12} className="gap-after">
              <ButtonComponent
                text="Guardar"
                onClick={() => onClose()}
                large
                disabled={isButtonDisabled[0]}
                isHover
                version={isButtonDisabled[0] ? 'grey' : 'fill'}
              />
            </Col>
          </Row>
        </Container>
      )}
      {isSalary && (
        <Container
          fluid
          className="h-100 d-flex align-items-center justify-content-between flex-column"
        >
          <Row className="justify-content-center ">
            <Col xs={12} className="gap-after mb-5">
              <img src={SalaryNotification} alt="SalaryNotification" />
              <span style={{ fontWeight: 'bold', textAlign: 'start' }}>
                Indicanos la moneda y rango salarial deseado.
              </span>
            </Col>
            <Col xs={6} className="gap-after">
              <InputComponent
                type="text"
                placeholder="ARS"
                isDropdown
                options={['ARS', 'US', 'BR']}
                iconOptions={{ icons: [ARicon, USicon, BRicon] }}
                onOptionChange={handleOptionChange}
                ref={dropdownRef}
              />
            </Col>
            <Col xs={12} className="gap-after">
              {renderSalaryOptions()}
            </Col>
            <Col
              xs={12}
              className="gap-after text-start"
              style={{ textAlign: 'start' }}
            >
              <span style={{ fontWeight: 'bold' }}>
                Aspiracional o Pretendido (obligatorio)
              </span>
            </Col>
            <Col xs={12} className="gap-after d-flex align-items-start">
              <div style={{ width: '45%' }} className="py-3">
                <InputComponent
                  type="number"
                  placeholder="80"
                  ref={salaryRef}
                  onChange={handleMaxSalaryChange}
                  isMedium
                  isBorderBlue
                />
              </div>
            </Col>
            <Col xs={12} className="gap-after">
              <ButtonComponent
                text="Siempre es una buena idea indicar el salario que deseas recibir para poder aparecer en mejores ofertas de empleos."
                version="outline"
                large
                icon={InfoIcon}
                isLeft
                paddingY={'15px'}
                disabled
                isColorBlack
              />
            </Col>
            <Col xs={12} className="gap-after">
              <ButtonComponent
                text="Guardar"
                onClick={handleSaveClick}
                large
                disabled={!maxSalary || isButtonDisabled[0]}
                isHover
                version={!maxSalary || isButtonDisabled[0] ? 'grey' : 'fill'}
              />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default function ProfilePersTwo({
  isHiring,
  isModHiring,
  isSalary,
  gotostep,
}) {
  const [isProfilePersWindow, setIsProfilePersWindow] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [currentSection, setCurrentSection] = React.useState(null);
  const [hiringButtonVersion, setHiringButtonVersion] = React.useState('grey');
  const [hiringOptions, setHiringOptions] = React.useState(
    'Tipo de contratación'
  );
  const [modHiringButtonDisabled, setModHiringButtonDisabled] =
    React.useState(true);
  const [modHiringOptions, setModHiringOptions] = React.useState(
    'Modalidad de la contratación'
  );
  const [salaryButtonDisabled, setSalaryButtonDisabled] = React.useState(true);
  const [modHiringButtonVersion, setModHiringButtonVersion] =
    React.useState('grey');
  const [salaryButtonVersion, setSalaryButtonVersion] = React.useState('grey');
  const [salaryOptions, setSalaryOptions] = React.useState(
    'Pretensión salarial'
  );
  const [stepperCurrentStep, setStepperCurrentStep] = React.useState(0);

  const isHiringHandle = useCallback(
    (option) => {
      setHiringOptions((prevOptions) => {
        let optionsArray = prevOptions ? prevOptions.split(', ') : [];
        if (optionsArray[0] === 'Tipo de contratación') {
          optionsArray = [];
        }
        optionsArray.push(option);
        if (optionsArray.length > 3) {
          optionsArray.shift();
        }
        setHiringButtonVersion('outline');
        setModHiringButtonDisabled(false);
        setSalaryButtonDisabled(true);
        setSalaryButtonVersion('grey');
        setModHiringOptions('Modalidad de la contratación');
        setSalaryOptions('Pretensión salarial');
        setModHiringButtonVersion('grey');
        setStepperCurrentStep(1);
        if (typeof isHiring === 'function') {
          isHiring(optionsArray);
        }
        return optionsArray.join(', ');
      });
    },
    [
      setHiringOptions,
      setHiringButtonVersion,
      setModHiringButtonDisabled,
      setSalaryButtonDisabled,
      setSalaryButtonVersion,
      setModHiringOptions,
      setSalaryOptions,
      setModHiringButtonVersion,
      setStepperCurrentStep,
      isHiring,
    ]
  );

  const modHiringHandle = (option) => {
    setModHiringOptions(option);
    console.log('Modalidad de contratación seleccionada: ' + option);
    setSalaryButtonDisabled(false);
    setModHiringButtonVersion('outline');
    setStepperCurrentStep(2);
    if (typeof isModHiring === 'function') {
      isModHiring(option);
    }
  };

  const salaryHandle = (salary) => {
    setSalaryOptions(`${salary[0]} - ${salary[1]} ${salary[2]}`);
    setSalaryButtonVersion('outline');
    setStepperCurrentStep(3);
    if (typeof isSalary === 'function') {
      isSalary(salary);
    }
  };

  const handleButtonClick = (section) => {
    setIsProfilePersWindow(true);
    setCurrentSection(section);
    setStep(1);
  };

  const onCloseWindow = () => {
    setIsProfilePersWindow(false);
    setCurrentSection(null);
  };

  if (isProfilePersWindow) {
    return (
      <ProfilePersWindow
        step={step}
        setStep={setStep}
        isHiring={currentSection === 'hiring' && isHiringHandle}
        isModHiring={currentSection === 'modHiring' && modHiringHandle}
        isSalary={currentSection === 'salary' && salaryHandle}
        onClose={onCloseWindow}
      />
    );
  }

  return (
    <div>
      <StepperComponent
        steps={3}
        currentStep={stepperCurrentStep}
        title="Preferencias laborales"
        subtitle="Pasos a completar"
      />
      <Row>
        <Col xs={12} className="gap-after">
          <ButtonComponent
            version={hiringButtonVersion}
            text={hiringOptions}
            isLeft
            large
            onClick={() => handleButtonClick('hiring')}
            disabled={false}
            isEditIcon={hiringOptions !== 'Tipo de contratación'}
            isColorBlack={hiringOptions !== 'Tipo de contratación'}
          />
        </Col>
        <Col xs={12} className="gap-after">
          <ButtonComponent
            version={modHiringButtonVersion}
            text={modHiringOptions}
            isLeft
            large
            onClick={() => handleButtonClick('modHiring')}
            disabled={modHiringButtonDisabled}
            isEditIcon={modHiringOptions !== 'Modalidad de la contratación'}
            isColorBlack={modHiringOptions !== 'Modalidad de la contratación'}
          />
        </Col>
        <Col xs={12} className="gap-after">
          <ButtonComponent
            version={salaryButtonVersion}
            text={salaryOptions}
            isLeft
            large
            onClick={() => handleButtonClick('salary')}
            disabled={salaryButtonDisabled}
            isEditIcon={salaryOptions !== 'Pretensión salarial'}
            isColorBlack={salaryOptions !== 'Pretensión salarial'}
          />
        </Col>

        <Col xs={12} className="gap-after">
          <ButtonComponent
            version={
              salaryButtonVersion === 'outline' &&
              modHiringButtonVersion === 'outline' &&
              hiringButtonVersion === 'outline'
                ? 'fill'
                : 'grey'
            }
            text={'Continuar'}
            isHover
            large
            onClick={gotostep}
            disabled={
              hiringButtonVersion === 'grey' ||
              modHiringButtonVersion === 'grey' ||
              salaryButtonVersion === 'grey'
            }
          />
        </Col>
      </Row>
    </div>
  );
}
