import React, { useState, createRef, useCallback, useEffect } from 'react';
import InterfaceAdmProject from '../InterfaceAdmProject';
import { Col } from 'react-bootstrap';
import InputComponent from '../../../../../Shared/InputComponent';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { endPointInterface } from '../AdmProjectsStep1';
import AutocompleteSelect from '../../../../../Shared/AutocompleteSelect/AutocompleteSelect';
import { useWorkAreas } from '../../../../../../api/WorkAreaApi';
import { toast } from 'react-toastify';

const InfoProject = ({ handleBackStep, dataInfoProject }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const refs = {
    name: createRef(),
    customer: createRef(),
    role: createRef(),
    work_area_id: createRef(),
    duration: createRef(),
    start_date: createRef(),
    end_date: createRef(),
  };
  const [fields, setFields] = useState(endPointInterface);
  const [positions, setPositions] = useState([]);

  const workAreasData = useWorkAreas();

  const handleStartDateChange = (date) => {
    setStartDate(date);
    refs.start_date.current.value = date;
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    refs.end_date.current.value = date;
  };

  const handleSave = useCallback(() => {
    const data = {
      ...fields,
      start_date: startDate,
      end_date: endDate,
    };
    console.log(data);
    if (dataInfoProject) {
      dataInfoProject(data);
    }
    handleBackStep();
  }, [startDate, endDate]);

  const manejarSeleccionado = (item) => {
    setFields({ ...fields, work_area_id: item.id });
  };

  useEffect(() => {
    workAreasData()
      .then((response) => {
        const processedData = response.map((item) => ({
          id: item.id,
          name_es: item.name || item.name_es,
        }));
        setPositions(processedData);
      })
      .catch((error) => {
        toast(
          'Error fetching work areas:',
          error.response ? error.response.data.toString() : error
        );
      });
  }, []);

  return (
    <InterfaceAdmProject
      subtitle="* El asterisco indica que es obligatorio"
      textLastButton="Siguiente"
      versionLastButton={
        fields.name &&
        fields.customer &&
        fields.role &&
        fields.duration &&
        startDate &&
        endDate
          ? 'fill'
          : 'grey'
      }
      actionLastButton={handleSave}
      flexEndLastButton={false}
      handleBack={handleBackStep}
    >
      <Col xs={12} className="gap-after">
        <InputComponent
          placeholder="Nombre del proyecto *"
          ref={refs.name}
          onChange={(e) => setFields({ ...fields, name: e.target.value })}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <InputComponent
          placeholder="Cliente  *"
          ref={refs.customer}
          onChange={(e) => setFields({ ...fields, customer: e.target.value })}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <AutocompleteSelect
          datos={positions}
          onSeleccionar={manejarSeleccionado}
          placeholder="Cargo (Area de especialización)"
        />
      </Col>
      <Col xs={12} className="gap-after">
        <InputComponent
          placeholder="Rol  *"
          ref={refs.role}
          onChange={(e) => setFields({ ...fields, role: e.target.value })}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <InputComponent
          placeholder="Duración  *"
          ref={refs.duration}
          onChange={(e) => setFields({ ...fields, duration: e.target.value })}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <DatePicker
          placeholderText="Fecha de inicio  *"
          className="DatePicker-date"
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          ref={refs.start_date}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <DatePicker
          placeholderText="Fecha de inicio  *"
          className="DatePicker-date"
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          ref={refs.end_date}
        />
      </Col>
    </InterfaceAdmProject>
  );
};

export default InfoProject;
