import React from "react";
import CompanyReusableComponent from "../Components/CompanyReusable/CompanyReusableComponent";


const renderCompanyRegisteredLoading = ({ loadCompany, setStep, handleSetStep }) => (
    <CompanyReusableComponent
        company="LoaderScreen"
        step={handleSetStep}
        title={!loadCompany ? "Esperando que acepten tu solicitud." : "Solicitud aprobada."}
        subtitle={!loadCompany ? "Dile al admin que vea su correo. Chequea el correo en spam." : "¡Bienvenido!"}
        hrefLoaderScreen="https://www.google.com/"
        linkLoaderScreen="Si necesitas ayuda ingresa aquí."
        goBack={() => setStep("companyRegistered")}
    />
);


export default renderCompanyRegisteredLoading;