import axios from 'axios';
import { useContext } from 'react';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { UserContext } from '../contexts/UserContext';
import { toast } from 'react-toastify';

const apiBaseUrl =
  'https://api-stg.empleosmarketingdigital.com/v1/talent-languages';
const apiBaseUrl2 =
  'https://api-stg.empleosmarketingdigital.com/v1/talent-work-preferences';

export const useTalentLangsApi = () => {
  const [registerData] = useContext(RegisterDataContext);
  const { userData } = useContext(UserContext);
  const token = localStorage.getItem('tokenRegister') || registerData.token;

  const createTalentLanguage = async (talentId, languageId, level) => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}`,
        {
          talent_id: talentId,
          language_id: languageId,
          level: level,
        },
        {
          headers: {
            Authorization: `Bearer ${
              registerData.token || userData.token || token
            }`,
          },
        }
      );
      console.log('Talent-Language relation created:', response.data);
      return response.data;
    } catch (error) {
      const errorType = error.response?.statusText || 'Error';
      const errorMessage = error.response?.data || error.message;
      toast.error(
        `Error creating Talent-Language relation: ${errorType} - ${errorMessage}`
      );
    }
  };

  const fetchTalentLanguages = async (talentId) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/talent/${talentId}`, {
        headers: {
          Authorization: `Bearer ${
            registerData.token || userData.token || token
          }`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error('Error fetching Talent-Language relations:', error);
      return [];
    }
  };

  const updateTalentLanguage = async (talentId, languageId, level) => {
    try {
      const response = await axios.put(
        `${apiBaseUrl}/talent/${talentId}/language/${languageId}`,
        {
          level: level,
        },
        {
          headers: {
            Authorization: `Bearer ${
              registerData.token || userData.token || token
            }`,
          },
        }
      );
      console.log('Talent-Language relation updated:', response.data);
      return response.data;
    } catch (error) {
      toast.error(
        'Error updating Talent-Language relation:',
        error.response ? error.response.data : error
      );
    }
  };

  const deleteTalentLanguage = async (talentId, languageId) => {
    try {
      await axios.delete(
        `${apiBaseUrl}/talent/${talentId}/language/${languageId}`,
        {
          headers: {
            Authorization: `Bearer ${
              registerData.token || userData.token || token
            }`,
          },
        }
      );
      console.log('Talent-Language relation deleted');
    } catch (error) {
      toast.error(
        'Error deleting Talent-Language relation:',
        error.response ? error.response.data : error
      );
    }
  };

  const talentWorkPreferencesGet = async (talentId) => {
    try {
      const response = await axios.get(`${apiBaseUrl2}/talent/${talentId}`, {
        headers: {
          Authorization: `Bearer ${
            registerData.token || userData.token || token
          }`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error('Error fetching Talent-Language relations:', error);
      return [];
    }
  };

  return {
    createTalentLanguage,
    fetchTalentLanguages,
    updateTalentLanguage,
    deleteTalentLanguage,
    talentWorkPreferencesGet,
  };
};
