import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useVideosApi } from "../admin/api/VideoApi";

function VideoSection() {
    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 4000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
        ]
    };

    const { getVideos } = useVideosApi();
    const [videos, setVideos] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        getVideos()
            .then((response) => {
                setVideos(response);
            })
            .catch((error) => {
                setError(error);
            });
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Container fluid className="about-section">
            <Container>

                <Slider {...settings}>
                    {videos.filter(video => video.visible).map((video, index) => (
                        <div key={index} style={{ width: '500px' }}>
                            <h1 className="home-titulos" style={{ textAlign: "left", paddingTop: '6rem', paddingBottom: '1rem' }}>
                                {video.title_es}
                            </h1>
                            <video controls style={{ width: '100%' }}>
                                <source src={video.video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ))}
                </Slider>
            </Container>
        </Container>
    );
}

export default VideoSection;
