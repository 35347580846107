import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';


const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/positions';

export const usePositionsApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);


    const createPosition = async (positionData) => {
        try {
            const response = await axios.post(apiBaseUrl, positionData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                },
            });
            console.log('Position created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating position:', error.response ? error.response.data : error);
        }
    };

    const fetchPositions = async (queryParams = {}) => {
        try {
            const response = await axios.get(apiBaseUrl, {
                params: queryParams,
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching positions:', error);
            return [];
        }
    };

    const fetchPosition = async (positionId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${positionId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching position:', error);
            return null;
        }
    };

    const updatePosition = async (positionId, positionData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${positionId}`, positionData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Position updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating position:', error.response ? error.response.data : error);
        }
    };

    const deletePosition = async (positionId) => {
        try {
            await axios.delete(`${apiBaseUrl}/${positionId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Position deleted');
        } catch (error) {
            toast.error('Error deleting position:', error.response ? error.response.data : error);
        }
    };

    return {
        createPosition,
        fetchPositions,
        fetchPosition,
        updatePosition,
        deletePosition,
    };
};
