import React, { useState, useCallback, useEffect } from "react";
import ButtonComponent from "../../../Shared/ButtonComponent";

import CameraIcon from '../../../../Assets/CameraIcon.svg';
import { toast } from "react-toastify";
import { useUploadsApi } from '../../../../api/UploadsApi';
import ClientBox from "../../components/ClientBox/ClientBox";
import { useCustomersApi } from "../../api/CustomersApi";

async function resizeImage(file) {
    const MAX_WIDTH = 800; // Ancho máximo para la imagen
    const MAX_HEIGHT = 600; // Alto máximo para la imagen
    const MIME_TYPE = "image/jpeg"; // Tipo de imagen de salida
    const QUALITY = 0.7; // Calidad de la imagen de salida

    const img = document.createElement('img');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const imgPromise = new Promise((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
    });

    const imgResult = await imgPromise;

    let width = imgResult.width;
    let height = imgResult.height;

    if (width > height) {
        if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
        }
    } else {
        if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
        }
    }

    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(imgResult, 0, 0, width, height);
    URL.revokeObjectURL(img.src);

    return new Promise((resolve, reject) => {
        canvas.toBlob(resolve, MIME_TYPE, QUALITY);
    });
}

const InputField = React.memo(({ label, value, onChange, id }) => (
    <div className="d-flex w-100 align-items-center" style={{ gap: '40px' }}>
        <span className="prp-field-label" style={{ wordWrap: 'break-word', whiteSpace: 'nowrap' }}>{label}</span>
        <input type="text" id={id} className="prp-title-input" value={value || ''} onChange={onChange} />
    </div>
));

const LogoModHomePage = () => {
    const { createCustomer, deleteCustomer, getCustomers, updateCustomer } = useCustomersApi();
    const { uploadCustomerImage } = useUploadsApi();
    const [imageFile, setImageFile] = useState(null);
    const [imageURL, setImageURL] = useState('');
    const [copy, setClient] = useState({ id: null, name: "", image: "", visible: true });
    const [allDataBoxes, setAllDataBoxes] = useState([]);

    useEffect(() => {
        const fetchDataCustomers = async () => {
            try {
                const dataCustomers = await getCustomers();
                setAllDataBoxes(dataCustomers);
            } catch (error) {
                toast.error(`Error fetching data customers: ${error}`);
            }
        };
        fetchDataCustomers();
    }, []);

    const handleInputChange = useCallback((field) => (e) => {
        setClient((prevCopy) => ({ ...prevCopy, [field]: e.target.value }));
    }, []);
    const handleSave = useCallback(async () => {


        let imageUrl = null;
        if (imageFile) {
            try {
                const resizedImage = await resizeImage(imageFile);
                const uploadResult = await uploadCustomerImage(resizedImage);
                imageUrl = uploadResult.url;
            } catch (error) {
                toast.error(`Error uploading image: ${error}`);
                return;
            }
        }


        const dataCustomersData = {
            ...copy,
            image: imageUrl || copy.image || 'URL existente o default si es necesario',
        };

        if (copy.id) {
            try {
                await updateCustomer(copy.id, dataCustomersData);
                toast.success('Data box updated');
                window.location.reload();
            } catch (error) {
                toast.error(`Error updating data box: ${error.toString()}`);
            }
        } else {
            try {
                await createCustomer(dataCustomersData);
                toast.success('Data box created');
                window.location.reload();
            } catch (error) {
                toast.error(`Error creating data box: ${error}`);
            }
        }
    }, [copy, imageFile]);

    const handleDataBoxClick = useCallback((data) => {
        setClient({
            id: data.id,
            name: data.name,
            image: data.image,
            visible: data.visible,
        });
        setImageURL(data.image);
        setImageFile(null);
    }, []);

    const handleFileChange = useCallback(event => {
        setImageFile(event.target.files[0]);
        setImageURL(URL.createObjectURL(event.target.files[0]));
    }, []);

    const handleClickVisible = useCallback((id) => {
        setAllDataBoxes(prevDataBoxes => {
            const newDataBoxes = prevDataBoxes.map(data =>
                data.id === id ? { ...data, visible: !data.visible } : data
            );

            const updatedClient = newDataBoxes.find(data => data.id === id);

            if (updatedClient) {
                updateCustomer(id, { visible: updatedClient.visible })
                    .then(() => {
                        toast.success('Visibilidad actualizada');
                    })
                    .catch(error => {
                        toast.error(`Error al actualizar la visibilidad: ${error}`);
                        setAllDataBoxes(prevDataBoxes);
                    });
            }

            return newDataBoxes;
        });
    }, []);

    const handleClickDelete = useCallback((id) => {
        deleteCustomer(id)
            .then(() => {
                toast.success('Cliente eliminado');
                setAllDataBoxes(prevDataBoxes => prevDataBoxes.filter(data => data.id !== id));
            })
            .catch(error => {
                toast.error(`Error al eliminar el cliente: ${error}`);
            });
    }, []);





    const labels = {
        Client: "Cliente",
    };

    return (
        <div className="main-dashboard">
            <div className="main-dashboard__header">
                <span className="main-dashboard__title"><button style={{
                    background: 'none',
                    border: 'none',
                    color: 'white',
                }}
                    onClick={
                        () => {
                            window.history.back();
                        }
                    }
                >Super Admin Home </button> / Logo de clientes</span>
            </div>
            <div className="main-dashboard__content pl-4 pr-4 pb-4" style={{ gap: '40px', alignItems: 'flex-start', justifyContent: 'flex-start', height: 'auto' }}>
                <div className="d-flex w-100 justify-content-between pt-3">
                    <h2>Agregar Logo</h2>
                </div>
                <label htmlFor="file-upload" className="prp-upload-label">
                    {imageURL ? <img src={imageURL} alt="Upload" className="prp-upload-image" /> : <img src={CameraIcon} alt="Upload" />}
                    <span>Cargar foto</span>
                </label>
                <input id="file-upload" type="file" onChange={handleFileChange} className="prp-file-input" />
                <InputField label={labels.Client} value={copy.name} onChange={handleInputChange('name')} id="client" />
                <ButtonComponent text="Guardar" isHover onClick={handleSave} />
                <ClientBox dataBox={allDataBoxes} onClick={handleDataBoxClick} deleteCustomer={handleClickDelete} handleClickVisible={handleClickVisible} />
            </div>
        </div>
    );
}

export default LogoModHomePage;