import React, { useState, useRef } from 'react';
import './SliderComponent.css';
import Check from '../../../Assets/check.svg';

const SliderComponent = ({ initialCheck = false, initialStep = 0, step = 1, currentNumber, setNumber, maxValue, disabled }) => {
    const [check, setCheck] = useState(initialCheck);
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [isOrigin, setIsOrigin] = useState(true);
    const sliderRef = useRef(null);

    const handleButtonClick = () => {
        const newStep = currentStep + step;
        setCurrentStep(newStep);
        setNumber(newStep);
        setCheck(true);
        setIsOrigin(false);
        setTimeout(() => {
            setCheck(false);
            setIsOrigin(true);
        }, 1000);
    };


    return (
        <div className={`slider-container ${check ? 'checked' : ''} ${disabled ? 'disabled-slider' : ''}`} ref={sliderRef}>
            <button
                className={`slider-button ${isOrigin ? 'origin' : ''}`}
                onClick={handleButtonClick}
                tabIndex={0}
                style={{ position: 'absolute', left: `${isOrigin ? '5px' : 'calc(100% - 86px)'}`, transition: `${isOrigin ? 'left .5s ease-out' : 'left .5s ease-in'}` }}
                disabled={disabled}
            >
                {check ? <img draggable="false" className="no-select" src={Check} alt="check" /> : <span style={{
                    color: 'white',
                }}>OK!</span>}
            </button>
            <div className="step-info">
                {currentNumber} / {maxValue}
            </div>
        </div>
    );
};

export default SliderComponent;
