import React, { useState } from 'react';
import CompanyReusableComponent from '../../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import { Form, Row, Col } from 'react-bootstrap';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import InputComponent from '../../../../Shared/InputComponent';
import {
  BRicon,
  ARicon,
  USicon,
} from '../../../../../components/Shared/iconsComponent';
import { salaryOptions } from '../../../../Register/Talento/savedModules/HiringModuleFolder/HiringConfig';

const Case2Step4CreateJob = ({ goBack, salary = {}, save }) => {
  const [currency, setCurrency] = useState(salary.currency || 'ARS');
  const [paymentType, setPaymentType] = useState(salary.payment || '');
  const [minSalary, setMinSalary] = useState(salary.min_salary || '');
  const [maxSalary, setMaxSalary] = useState(salary.max_salary || '');

  const handleSave = () => {
    const updatedSalary = {
      currency,
      payment: paymentType,
      min_salary: minSalary,
      max_salary: maxSalary,
    };
    save(updatedSalary);
    goBack();
  };

  const handleOptionChange = (option) => {
    setCurrency(option);
  };

  const dropdownRef = React.createRef();

  const currencyPlaceholder = (currency) => {
    switch (currency) {
      case 'ARS':
        return '(ARS) Peso Argentino';
      case 'US':
        return '(USD) Dólar Estadounidense';
      case 'BR':
        return '(BRL) Real Brasileño';
      default:
        return '';
    }
  };

  return (
    <CompanyReusableComponent goBack={goBack} company={'WhiteScreen'}>
      <Row className="p-4">
        <Col xs={12} className="gap-after">
          <h6>
            Por favor, proporciona el rango salarial que se ofrece para este
            puesto.
          </h6>
        </Col>
        <Col xs={6} className="gap-after">
          <InputComponent
            type="text"
            placeholder="ARS"
            isDropdown
            options={['ARS', 'US', 'BR']}
            iconOptions={{ icons: [ARicon, USicon, BRicon] }}
            onOptionChange={handleOptionChange}
            ref={dropdownRef}
          />
        </Col>
        <Col xs={6} className="gap-after">
          <InputComponent
            placeholder={currencyPlaceholder(currency)}
            readOnly
          />
        </Col>
        <Col xs={12} className="gap-after">
          <Form>
            <Form.Group>
              <Form.Group>
                {salaryOptions
                  .filter((option) => option.title !== 'Indiferente')
                  .map((option, index) => (
                    <div
                      key={option.title + index}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '30px',
                        paddingTop: '20px',
                      }}
                    >
                      <Form.Check
                        key={option.title + index}
                        type="radio"
                        label={option.title}
                        name="paymentTypeDescription"
                        id={option.title}
                        style={{
                          fontWeight: '600',
                          display: 'flex',
                        }}
                        checked={
                          (paymentType === 'PROJECT' &&
                            option.title === 'Por proyecto') ||
                          (paymentType === 'MONTHLY' &&
                            option.title === 'Pago mensual') ||
                          (paymentType === 'YEARLY' &&
                            option.title === 'Pago anual')
                        }
                        onChange={(e) =>
                          setPaymentType(
                            option.title === 'Por proyecto'
                              ? 'PROJECT'
                              : option.title === 'Pago mensual'
                              ? 'MONTHLY'
                              : option.title === 'Pago anual'
                              ? 'YEARLY'
                              : ''
                          )
                        }
                      />
                      <span
                        key={option.title + index + '-desc'}
                        style={{
                          display: 'flex',
                          paddingLeft: '30px',
                          paddingTop: '5px',
                        }}
                      >
                        {option.description}
                      </span>
                    </div>
                  ))}
              </Form.Group>
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  display: 'flex',
                  fontWeight: '600',
                  fontSize: '18px',
                  marginBottom: '20px',
                }}
              >
                Rango Salarial (obligatorio)
              </Form.Label>
              <div className="d-flex align-items-center">
                <InputComponent
                  type="number"
                  value={minSalary}
                  onChange={(e) => setMinSalary(e.target.value)}
                  placeholder="Min"
                />
                <span style={{ margin: '0 1rem', color: '#cfcccc' }}> a </span>

                <InputComponent
                  type="number"
                  value={maxSalary}
                  onChange={(e) => setMaxSalary(e.target.value)}
                  placeholder="Max"
                />
              </div>
            </Form.Group>

            <ButtonComponent
              text={'Guardar'}
              large
              isHover
              onClick={handleSave}
              version={minSalary && maxSalary ? 'fill' : 'grey'}
              disabled={!(minSalary && maxSalary)}
            />
          </Form>
        </Col>
      </Row>
    </CompanyReusableComponent>
  );
};

export default Case2Step4CreateJob;
