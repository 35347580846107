import React, { useContext, useState, useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import './ProfileCardModule.css';
import InputComponent from '../../../Shared/InputComponent';
import ButtonComponent from '../../../Shared/ButtonComponent';
import { RegisterDataContext } from '../../../../contexts/RegisterDataContext';
import { birthDateTransform, apiClient } from '../../../../functions/SharedFunctions';
import { useUsersApi } from '../../../../api/UpdateUserApi';
import { UserContext } from '../../../../contexts/UserContext';
import { toast } from 'react-toastify';

const ProfileCardModule = ({ profileData, goBack }) => {
    const [registerData] = useContext(RegisterDataContext);
    const [isChecked, setIsChecked] = useState(false);
    const [isDropdownValidated, setIsDropdownValidated] = useState(false);
    const { updateUser } = useUsersApi();
    const { userData, updateUserData } = useContext(UserContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;


    const refs = useRef({
        dropdown: useRef(),
        name: useRef(),
        lastName: useRef(),
        calendar: useRef(),
        password: useRef(),
        confirmPassword: useRef(),
        mail: useRef(),
    });

    useEffect(() => {
        apiClient.defaults.headers['Authorization'] = `Bearer ${registerData.token || userData.token || token}`;
    }, [registerData.token, userData.token]);

    const toggleChecked = () => setIsChecked(!isChecked);
    const validateDropdown = () => setIsDropdownValidated(true);

    const handleVerificationStep = async () => {
        const { isValid } = await validatePasswords();
        if (isValid) {
            const validations = await Promise.all(
                Object.entries(refs.current).map(async ([, ref]) => await ref.current.validateExternal())
            );

            if (validations.every(v => v.isValid)) {
                const [dropdownValue, nameValue, lastNameValue, selectedDate, mailValue, passValue] = await Promise.all(
                    [refs.current.dropdown, refs.current.name, refs.current.lastName, refs.current.calendar, refs.current.mail, refs.current.password].map(async ref => await ref.current.getValue())
                );
                const userIDLocal = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null;
                try {
                    const date = new Date(selectedDate);
                    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
                    const response = await updateUser(
                        (userIDLocal.user.id || registerData.user.id || userData.talent.user_id), {
                        f_name: nameValue,
                        l_name: lastNameValue,
                        gender: dropdownValue,
                        birth_date: formattedDate,
                        phone: '',
                        email: mailValue,
                        role: 'TALENT',
                        language: userIDLocal.user.language || registerData.user.language,
                        password: passValue,
                    });

                    console.log('Registro exitoso:', response);

                    if (typeof profileData === 'function') {
                        profileData(response);
                    }

                    updateUserData({
                        ...userData,
                        talent: {
                            ...userData.talent,
                        },
                        user: {
                            ...userData.user,
                            gender: response.gender,
                            f_name: response.f_name,
                            l_name: response.l_name,
                            birth_date: response.birth_date,
                            email: response.email,
                        }
                    });

                    goBack();
                } catch (error) {
                    toast.error('Error en el registro: ' + error.toString());
                    console.log(userIDLocal.user);
                }
            }
        }
    };

    const validatePasswords = async () => {
        const [password, confirmPassword] = await Promise.all(
            [refs.current.password, refs.current.confirmPassword].map(async ref => await ref.current.validateExternal())
        );

        if (password.isValid && confirmPassword.isValid) {
            if (password.value === confirmPassword.value) {
                return { isValid: true, value: password.value };
            } else {
                return { isValid: false, message: 'Las contraseñas no coinciden' };
            }
        } else {
            return { isValid: false, message: 'Las contraseñas no son válidas' };
        }
    }

    return (
        <div className="profileCard-wrapper">
            <Row>
                <Col xs={12} >
                    <InputComponent
                        type="email"
                        placeholder="Correo electrónico"
                        ref={refs.current.mail}
                        externalValue={registerData.user?.email || ''}
                    />
                </Col>
                <Col xs={6} className="gap-after">
                    <InputComponent
                        type="text"
                        autoComplete="given-name"
                        placeholder="Nombre"
                        ref={refs.current.name}
                        externalValue={registerData.user?.f_name || ''}
                    />
                </Col>
                <Col xs={6} className="gap-after">
                    <InputComponent
                        type="text"
                        autoComplete="family-name"
                        placeholder="Apellido"
                        ref={refs.current.lastName}
                        externalValue={registerData.user?.l_name || ''}
                    />
                </Col>
                <Col xs={12} className="gap-after">
                    <InputComponent
                        type="dropdown"
                        placeholder="Como te percibes"
                        isDropdown
                        options={[
                            "Masculino",
                            "Femenino",
                            "No Binario",
                            "Género fluido",
                            "Agénero (sin género)",
                            "Prefiero no decirlo",
                        ]}
                        onOptionChange={validateDropdown}
                        isValidDrop={isDropdownValidated}
                        ref={refs.current.dropdown}
                        externalValue={registerData.user?.gender || ''}
                    />
                </Col>
                <Col xs={12} className="gap-after">
                    <InputComponent
                        type="date"
                        placeholder="Fecha de nacimiento"
                        isCalendar
                        autoComplete="bday"
                        ref={refs.current.calendar}
                        externalValue={birthDateTransform(registerData.user?.birth_date) || ''}
                    />
                </Col>
                <Col xs={12} className="gap-after">
                    <InputComponent
                        type="password"
                        autoComplete="new-password"
                        placeholder="Contraseña"
                        ref={refs.current.password}
                    />
                </Col>
                <Col xs={12} className="gap-after">
                    <InputComponent
                        type="password"
                        autoComplete="new-password"
                        placeholder="Repetir contraseña"
                        ref={refs.current.confirmPassword}
                    />
                </Col>
                <Col xs={12} className="profileCard-confirmationRow gap-after">
                    <div className="profileCard-rowWithGap">
                        <input type="checkbox" value="" id="flexCheckDefault" onChange={toggleChecked} />
                        <span className="profileCard-termsText">
                            He leído y acepto los <a href="ww.w.w">términos y condiciones *</a>
                        </span>
                    </div>
                </Col>
                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        text="Confirmar"
                        version={isChecked ? "fill" : "grey"}
                        large
                        onClick={handleVerificationStep}
                        isFontBold
                        disabled={!isChecked}
                        isHover
                    />
                </Col>
            </Row>
        </div>
    );
}

export default ProfileCardModule;
