import React, { useState, useContext } from "react";
import "./ProfileDropdown.css";
import { UserContext } from "../../../../contexts/UserContext";

const ProfileDropdown = ({ user, className }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { userData } = useContext(UserContext);

    if (!user) {
        user = {
            user: {
                f_name: userData.user.f_name,
                email: userData.user.email,
            },
            profile_image: 'https://www.gravatar.com/avatar/',
        };
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`profile-dropdown ${className || ''}`} >
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <img
                    src={user?.profile_image}
                    alt="User avatar"
                    className="profile-dropdown__avatar"
                />
                <div className={`profile-dropdown__info ${isOpen ? 'open' : ''}`}>
                    <div className="d-flex w-100 justify-content-between align-items-center">
                        <span className="profile-dropdown__name">{user?.user?.f_name}</span>
                        <button className={`chevron-button ${isOpen ? 'open' : ''}`} onClick={toggleDropdown}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_4507_30327)">
                                    <path d="M10 10.9767L14.125 6.85168L15.3033 8.03002L10 13.3334L4.69667 8.03002L5.875 6.85168L10 10.9767Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4507_30327">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                    <span className="profile-dropdown__email">{user?.user?.email}</span>
                </div>
            </div>
        </div >
    );
}

export default ProfileDropdown;
