import React from "react";
import './MainDashboard.css';
import ProfileDropdown from "../../components/ProfileDropdown/ProfileDropdown";
import InfoStats from "../../components/InfoStats/InfoStats";
import SiteStats from "../../components/SiteStats/SiteStats";
import SiteNotification from "../../components/SiteNotification/SiteNotification";
import PressRelease from "../../components/PressRelease/PressRelease";

const MainDashboard = ({ user, stats, dates, pressReleases }) => {
    return (
        <div className="main-dashboard">
            <div className="main-dashboard__header">
                <span className="main-dashboard__title">Super Admin</span>
                <ProfileDropdown user={user} className={'main-dashboard__profile'} />
            </div>
            <div className="main-dashboard__content pl-4 pr-4">
                <div className="main-dashboard__info">
                    <InfoStats isTalent data={stats} />
                    <InfoStats isCompany data={stats} />
                    <InfoStats isRecluter data={stats} />
                </div>
                <div className="main-dashboard__content__stats w-100 ">
                    <SiteStats data={stats} dates={dates} />
                    <SiteNotification />
                </div>
                <div className="main-dashboard__content__stats w-100">
                    <PressRelease pressReleases={pressReleases} />
                </div>
            </div>
        </div>
    );
}

export default MainDashboard;