import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './NotificationComponent.css';

const NotificationComponent = ({ icon, text, onClose, zindex, isMainScreen }) => {
    const [isVisible, setIsVisible] = useState(true);


    if (!isVisible) {
        return null;
    }

    const handleClose = () => {
        setIsVisible(false);
        if (onClose) {
            onClose();
        }
    };

    const { boldPart, normalText } = text;

    return (
        <div className={
            isMainScreen
                ? "notification-container main-screen-notification"
                : "notification-container"
        } style={{ zIndex: zindex }}>
            <div className="notification-content">
                <img src={icon} alt="Notification Icon" className="notification-icon" />
                <span className="notification-text"><strong>{boldPart}{' '}</strong>{normalText}</span>
            </div>
            <button className="close-button" onClick={handleClose}>
                &#10006;
            </button>
            <div className="notification-arrow"></div>
        </div>
    );
};

NotificationComponent.propTypes = {
    icon: PropTypes.string.isRequired,
    text: PropTypes.shape({
        boldPart: PropTypes.string.isRequired,
        normalText: PropTypes.string
    }).isRequired,
    onClose: PropTypes.func,
    zindex: PropTypes.string
};

export default NotificationComponent;
