import React from "react";
import { Row, Col } from "react-bootstrap";
import CompanyReusableComponent from "../Components/CompanyReusable/CompanyReusableComponent";
import InputComponent from "../../../Shared/InputComponent";
import ButtonComponent from "../../../Shared/ButtonComponent";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";



const RenderStep0 = ({ email, setEmail, handleNextStep, handleSetStep, loading }) => {
    RenderStep0.propTypes = {
        email: PropTypes.string.isRequired,
        setEmail: PropTypes.func.isRequired,
        handleNextStep: PropTypes.func.isRequired,
        handleSetStep: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired
    };

    const history = useHistory();

    const goBack = () => {
        history.goBack();
    };

    return (
        <CompanyReusableComponent
            company="BlueScreen"
            step={handleSetStep}
            title="¡Estamos listos!"
            subtitle="Encontraremos juntos el profesional ideal para tu empresa."
            goBack={goBack}
        >
            <Row>
                <Col xs={12} className="text-center">
                    <span className="mb-2 color-black font-weight-bold">
                        Ingresa tu dirección de correo electrónico. Recuerda que tiene que ser un dominio corporativo. No gmail, hotmaill, outlook, etc
                    </span>
                </Col>
                <Col xs={12} className="gap-after">
                    <InputComponent
                        type="email"
                        placeholder="Correo electrónico"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Col>
                <Col xs={12} className="gap-after pb-5">
                    <ButtonComponent
                        text="Enviar"
                        version={loading ? "grey" : "fill"}
                        large
                        isFontBold
                        isHover
                        onClick={handleNextStep}
                        disabled={loading}
                    />
                </Col>
            </Row>
        </CompanyReusableComponent>
    )
};


export default RenderStep0;