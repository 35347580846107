import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    const { userData } = React.useContext(UserContext);
    const { registerData } = React.useContext(RegisterDataContext);

    const isLoggedIn = (userData && !!userData.token) || (registerData && !!registerData.token);

    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn && restricted ? (
                    <Redirect to="/home" />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

export default PublicRoute;