import React from "react";
import "./AdmStyles.css";
import ButtonComponent from "../../../../Shared/ButtonComponent";
import { Container, Row, Col } from "react-bootstrap";
import BlueLogo from "../../../../../Assets/BlueLogo.svg";
import BackBlue from "../../../../../Assets/BackBlue.svg";
import {
    fullScreenStyles,
    logoStyle,
    backButtonStyle,
    backButtonIconStyle,
} from "../../../Talento/steps/ProfilePersStyles";

const InterfaceAdmProject = ({
    handleBack,
    title,
    subtitle,
    children,
    textLastButton,
    versionLastButton,
    actionLastButton,
    icon,
    disabledLastButton,
    flexEndLastButton,
    lastInfo,
    alignItems,
}) => {
    return (
        <div style={fullScreenStyles}>
            <img src={BlueLogo} alt="BlueLogo" style={logoStyle} />
            <Container
                fluid
                className={`h-100 d-flex justify-content-between flex-column ${alignItems ? 'align-items-center' : ''}`}
            >
                <button style={backButtonStyle} onClick={handleBack}>
                    <img src={BackBlue} alt="back" style={backButtonIconStyle} />
                </button>
                <Row>
                    {title && (
                        <Col xs={12} className="gap-after">
                            <span className="textADMtitle">{title}</span>
                        </Col>
                    )}
                    {subtitle && (
                        <Col xs={12} className="gap-after">
                            <span className="textADMSubtitle">{subtitle}</span>
                        </Col>
                    )}
                    {children}
                    {flexEndLastButton === false && (
                        <Col xs={12} className="gap-after">
                            <ButtonComponent
                                text={textLastButton}
                                large
                                version={versionLastButton}
                                paddingY="11px"
                                isHover
                                onClick={actionLastButton}
                                secondIcon={icon}
                                gapIcon={"10px"}
                                disabled={disabledLastButton}
                            />
                        </Col>
                    )}
                </Row>
                {flexEndLastButton === true && (
                    <Row className="pb-4">
                        {lastInfo && (
                            <Col xs={12} className="gap-after">
                                <div className="text-center">
                                    <span >Desbloquea</span>
                                    <a href="#info"> 1 espacio gratis</a>
                                    <span > para tu proyectos compartiendo nos en tus redes sociales</span>
                                </div>
                            </Col>
                        )}
                        <Col xs={12} className="gap-after">
                            <ButtonComponent
                                text={textLastButton}
                                large
                                version={versionLastButton}
                                paddingY="11px"
                                isHover
                                onClick={actionLastButton}
                                secondIcon={icon}
                                gapIcon={"10px"}
                                disabled={disabledLastButton}
                            />
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
};

export default InterfaceAdmProject;