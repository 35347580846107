import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ButtonComponent from '../../../Shared/ButtonComponent';
import StepperComponent from '../../../Shared/StepperComponent/StepperComponent';
import BlueLogo from '../../../../Assets/BlueLogo.svg';
import { fullScreenStyles } from './ProfilePersStyles';
import { useFetchLanguages } from '../../../../api/LangApi';
import SearchComponent from '../../../Shared/SearchComponent/SearchComponent';
import { useHardSkillsApi } from '../../../../api/HardSkills.api';
import { useToolsApi } from '../../../../api/ToolsApi';
import { UserContext } from '../../../../contexts/UserContext';
import { useTalentLangsApi } from '../../../../api/TalentLangsApi';
import { useTalentToolsApi } from '../../../../api/TalentToolsApi';
import { useTalentHardSkillsApi } from '../../../../api/TalentHardSkillsApi';

const FullScreen = ({
  onClose,
  showLanguageScreen,
  showHardSkillsScreen,
  showTechSkillsScreen,
  isLanguage,
  isHardSkills,
  isTechSkills,
  langsLevelIdData,
  techLevelIdData,
  hardSkillsIdData,
}) => {
  const fetchedLanguages = useFetchLanguages();
  const { fetchHardSkills } = useHardSkillsApi();
  const { fetchTools } = useToolsApi();
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState('');
  const [inputText, setInputText] = useState('');
  const [showLevels, setShowLevels] = useState(false);
  const [selectedSoftSkills, setSelectedSoftSkills] = useState([]);
  const [currentSoftSkill, setCurrentSoftSkill] = useState('');
  const [showSoftSkillLevels, setShowSoftSkillLevels] = useState(false);
  const [softSkillInputText, setSoftSkillInputText] = useState('');
  const [selectedHardSkills, setSelectedHardSkills] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [languagesData, setLanguagesData] = useState(null);
  const [hardSkills, setHardSkills] = useState([]);
  const [hardData, setHardData] = useState(null);
  const [softSkills, setSoftSkills] = useState([]);
  const [expertiseData, setExpertiseData] = useState(null);
  const [langId, setLangId] = useState();
  const [langsLevelId, setLangsLevelId] = useState([]);
  const [techId, setTechId] = useState();
  const [techLevelId, setTechLevelId] = useState([]);
  const [hardSkillsIds, setHardSkillsIds] = useState([]);

  useEffect(() => {
    const fetchAndSetLanguages = async () => {
      const data = await fetchedLanguages();
      setLanguagesData(data);
    };

    fetchAndSetLanguages();
  }, []);

  useEffect(() => {
    if (showLanguageScreen && languagesData) {
      setLanguages(languagesData);
    }
  }, [showLanguageScreen, languagesData]);

  useEffect(() => {
    const fetchHardSkillsData = async () => {
      const data = await fetchHardSkills();
      setHardData(data);
    };
    fetchHardSkillsData();
  }, []);

  useEffect(() => {
    if (showHardSkillsScreen && hardData) {
      setHardSkills(hardData);
    }
  }, [showHardSkillsScreen, hardData]);

  useEffect(() => {
    const fetchExpertiseAreasData = async () => {
      const data = await fetchTools();
      setExpertiseData(data);
    };

    fetchExpertiseAreasData();
  }, []);

  useEffect(() => {
    if (showTechSkillsScreen && expertiseData) {
      setSoftSkills(expertiseData);
    }
  }, [showTechSkillsScreen, expertiseData]);

  const levels = [
    { id: 1, name: 'Básico' },
    { id: 2, name: 'Lectura' },
    { id: 3, name: 'Conversacional' },
    { id: 4, name: 'Nativo' },
  ];
  const softSkillLevels = ['Básico', 'Intermedio', 'Avanzado'];

  const handleHardSkillSelect = (skill) => {
    setSelectedHardSkills((prev) => {
      const index = prev.indexOf(skill.name_es);
      if (index === -1) {
        if (prev.length < 5) {
          return [...prev, skill.name_es];
        } else {
          return prev;
        }
      } else {
        return prev.filter((s) => s !== skill.name_es);
      }
    });
    hardSkillsIdData([...hardSkillsIds, skill.id]);
  };

  useEffect(() => {
    const newHardSkillsIds = hardSkills
      .filter((skill) => selectedHardSkills.includes(skill.name_es))
      .map((skill) => skill.id);
    setHardSkillsIds(newHardSkillsIds);
  }, [selectedHardSkills]);

  useEffect(() => {
    console.log(hardSkillsIds);
  }, [hardSkillsIds]);

  const renderSelectedSkills = () => selectedHardSkills.join(' | ');

  const handleLanguageSelect = (language) => {
    setCurrentLanguage(language.name_es);
    setShowLevels(true);
    setLangId(language.id);
  };

  const handleLevelSelect = (level) => {
    const newSelection = { langname: currentLanguage, levelname: level.name };
    setSelectedLanguages((prev) => [...prev, newSelection]);
    setInputText(
      (prev) => prev + (prev ? ', ' : '') + `${currentLanguage} / ${level.name}`
    );
    setCurrentLanguage('');
    setShowLevels(false);

    const newLangsLevelId = {
      language_id: langId,
      level_id: level.id,
    };

    setLangsLevelId((prev) => [...prev, newLangsLevelId]);
    console.log([...langsLevelId, newLangsLevelId]);
    langsLevelIdData([...langsLevelId, newLangsLevelId]);
  };

  const handleLanguageAndLevelSelection = () => {
    onClose();
    isLanguage(
      selectedLanguages.map((l) => `${l.langname} / ${l.levelname}`).join(', ')
    );
  };

  const isLanguageDisabled = (language) => {
    return selectedLanguages.some((l) => l.langname === language);
  };

  const handleSoftSkillSelect = (skill) => {
    setCurrentSoftSkill(skill.name_es);
    setShowSoftSkillLevels(true);
    setTechId(skill.id);
  };

  const handleSoftSkillLevelSelect = (level) => {
    const newSelection = { skillName: currentSoftSkill, level: level };
    setSelectedSoftSkills((prev) => [...prev, newSelection]);
    setSoftSkillInputText(
      (prev) => prev + (prev ? ', ' : '') + `${currentSoftSkill} / ${level}`
    );
    setCurrentSoftSkill('');
    setShowSoftSkillLevels(false);

    const newTechLevelId = {
      tool_id: techId,
      level: level,
    };

    setTechLevelId((prev) => [...prev, newTechLevelId]);
    console.log([...techLevelId, newTechLevelId]);

    techLevelIdData([...techLevelId, newTechLevelId]);
  };

  const handleSoftSkillsAndLevelSelection = () => {
    console.log(
      selectedSoftSkills.map((s) => `${s.skillName} / ${s.level}`).join(', ')
    );
    onClose();
    isTechSkills(
      selectedSoftSkills.map((s) => `${s.skillName} / ${s.level}`).join(', ')
    );
  };

  const handleHardSkillsSelection = () => {
    console.log(selectedHardSkills.join(', '));
    onClose();
    isHardSkills(selectedHardSkills.join(', '));
  };

  const handleDeleteLastLanguageLevelSelection = () => {
    const lastLanguage = selectedLanguages[selectedLanguages.length - 1];
    setSelectedLanguages((prev) => prev.slice(0, prev.length - 1));
    setInputText(
      selectedLanguages
        .slice(0, selectedLanguages.length - 1)
        .map((l) => `${l.langname} / ${l.levelname}`)
        .join(', ')
    );

    const newLangsLevelId = langsLevelId.filter(
      (l) => l.language_id !== lastLanguage.langname
    );
    setLangsLevelId(newLangsLevelId);
    langsLevelIdData(newLangsLevelId);

    console.log(newLangsLevelId);
  };

  return (
    <div style={fullScreenStyles}>
      <img
        src={BlueLogo}
        alt="BlueLogo"
        style={{ cursor: 'pointer', marginBottom: '2rem' }}
      />
      <Container
        fluid
        className="h-100 d-flex align-items-center justify-content-between flex-column"
      >
        {showLanguageScreen && !showLevels && (
          <Row>
            {selectedLanguages.length > 0 && (
              <Col xs={12} className="mb-3">
                <ButtonComponent
                  isColorBlack
                  text={inputText}
                  large
                  paddingY="15px"
                  isLeft
                  version="outline"
                  isEditIcon
                  onClick={
                    handleDeleteLastLanguageLevelSelection
                  }
                />
              </Col>
            )}
            {Array.isArray(languages) &&
              languages.map((language) => (
                <Col xs={6} key={language.id} className="mb-3">
                  <ButtonComponent
                    text={language.name_es}
                    onClick={() => handleLanguageSelect(language)}
                    large
                    isColorBlack
                    disabled={isLanguageDisabled(language.name_es)}
                    version={
                      currentLanguage === language.name_es ? 'fill' : 'outline'
                    }
                    paddingY="15px"
                    textOverflowed
                  />
                </Col>
              ))}
            <Col xs={12} className="mb-3">
              <SearchComponent
                options={languages.map((l) => l.name_es)}
                placeholder="Buscar idioma"
                addOption={true}
                onOptionSelected={(language) =>
                  handleLanguageSelect({ name_es: language })
                }
                isLang={true}
              />
            </Col>
            <Col xs={12} className="text-center mt-3">
              <ButtonComponent
                text="Continuar"
                onClick={handleLanguageAndLevelSelection}
                large
                disabled={selectedLanguages.length === 0}
                version={selectedLanguages.length === 0 ? 'grey' : 'fill'}
                isHover
              />
            </Col>
          </Row>
        )}
        {showLanguageScreen && showLevels && (
          <Row>
            {selectedLanguages.length > 0 && (
              <Col xs={12} className="mb-3">
                <ButtonComponent
                  isColorBlack
                  text={inputText}
                  large
                  paddingY="15px"
                  isLeft
                  version="outline"
                  isEditIcon
                />
              </Col>
            )}
            {levels.map((level, index) => (
              <Col
                xs={6}
                key={`level-${level.name}-${level.id}`}
                className="mb-3"
              >
                <ButtonComponent
                  text={level.name}
                  onClick={() => handleLevelSelect(level)}
                  large
                  isColorBlack
                  version="outline"
                  paddingY="15px"
                />
              </Col>
            ))}
          </Row>
        )}
        {showTechSkillsScreen && !showSoftSkillLevels && (
          <Row>
            <Col xs={12} className="mb-3">
              <span className="text-center">
                Indica hasta cinco Herramientas técnicas que mejor manejas.{' '}
              </span>
            </Col>
            <Col xs={12} className="mb-3">
              <ButtonComponent
                isColorBlack
                text={softSkillInputText || 'Seleccion de un skill técnico'}
                large
                paddingY="15px"
                isLeft
                version="outline"
                isEditIcon
              />
            </Col>
            {Array.isArray(softSkills) &&
              softSkills.map((skill) => (
                <Col xs={6} key={skill.id} className="mb-3">
                  <ButtonComponent
                    text={skill.name_es}
                    onClick={() => handleSoftSkillSelect(skill)}
                    large
                    isColorBlack
                    version="outline"
                    paddingY="15px"
                    disabled={
                      selectedSoftSkills.some(
                        (s) => s.skillName === skill.name_es
                      ) || selectedSoftSkills.length === 5
                    }
                  />
                </Col>
              ))}
            <Col xs={12} className="mb-3">
              <SearchComponent
                options={softSkills.map((s) => s.name_es)}
                placeholder="Buscar herramienta técnicas"
                addOption={true}
                onOptionSelected={handleSoftSkillSelect}
                isWorkArea={true}
              />
            </Col>
            <Col xs={12}>
              <ButtonComponent
                text="Continuar"
                onClick={handleSoftSkillsAndLevelSelection}
                large
                isColorBlack
                version={selectedSoftSkills.length === 0 ? 'grey' : 'fill'}
                paddingY="15px"
                disabled={selectedSoftSkills.length === 0}
                isHover
              />
            </Col>
          </Row>
        )}
        {showTechSkillsScreen && showSoftSkillLevels && (
          <Row>
            <Col xs={12} className="mb-3">
              <span className="text-center">
                Indica hasta cinco Herramientas técnicas que mejor manejas.
              </span>
            </Col>
            <Col xs={12} className="mb-3">
              <ButtonComponent
                isColorBlack
                text={currentSoftSkill}
                large
                paddingY="15px"
                isLeft
                version="outline"
                isEditIcon
              />
            </Col>
            {softSkillLevels.map((level, index) => (
              <React.Fragment key={index}>
                {index === 0 && (
                  <Col xs={12} className="mb-3 text-left">
                    <span>Selecciona tu nivel</span>
                  </Col>
                )}
                {index === 1 && (
                  <Col xs={12} className="mb-3 text-left">
                    <span>Capaz de realizar tareas simples y rutinarias.</span>
                  </Col>
                )}
                {index === 2 && (
                  <Col xs={12} className="mb-3 text-left">
                    <span>
                      Utiliza características avanzadas y personaliza la
                      interfaz.
                    </span>
                  </Col>
                )}
                <Col xs={12} className="mb-3">
                  <ButtonComponent
                    text={level}
                    onClick={() => handleSoftSkillLevelSelect(level)}
                    large
                    isColorBlack
                    version="outline"
                    paddingY="15px"
                  />
                </Col>
                {index === 2 && (
                  <Col xs={12} className="mb-3 text-left">
                    <span>
                      Domina el software completamente, incluyendo programación
                      y personalización a nivel de sistema.
                    </span>
                  </Col>
                )}
              </React.Fragment>
            ))}
            <Col xs={12}>
              <ButtonComponent
                text="Guardar"
                onClick={handleSoftSkillsAndLevelSelection}
                large
                isColorBlack
                version={selectedSoftSkills.length === 0 ? 'grey' : 'fill'}
                paddingY="15px"
                disabled={selectedSoftSkills.length === 0}
                isHover
              />
            </Col>
          </Row>
        )}
        {showHardSkillsScreen && (
          <Row>
            <Col xs={12} className="mb-3">
              <span className="text-center">
                Indica tus cinco habilidades duras más fuertes.
              </span>
            </Col>
            <Col xs={12} className="mb-3">
              <ButtonComponent
                isColorBlack
                text={renderSelectedSkills() || 'Selecciona hasta cinco'}
                large
                paddingY="15px"
                isLeft
                version="outline"
                isEditIcon
              />
            </Col>
            {Array.isArray(hardSkills) &&
              hardSkills.map((skill) => (
                <Col xs={6} key={skill.id} className="mb-3">
                  <ButtonComponent
                    text={skill.name_es}
                    onClick={() => handleHardSkillSelect(skill)}
                    version={
                      selectedHardSkills.includes(skill.name_es)
                        ? 'fill'
                        : 'outline'
                    }
                    large
                    isColorBlack
                    paddingY="15px"
                    disabled={
                      selectedHardSkills.length === 5 &&
                      !selectedHardSkills.includes(skill.name_es)
                    }
                  />
                </Col>
              ))}
            <Col xs={12} className="mb-3">
              <SearchComponent
                options={hardSkills.map((s) => s.name_es)}
                placeholder="Buscar herramienta técnicas"
                addOption={true}
                onOptionSelected={handleHardSkillSelect}
                isWorkArea={true}
              />
            </Col>
            <Col xs={12} className="text-center mt-3">
              <ButtonComponent
                text="Guardar"
                onClick={handleHardSkillsSelection}
                disabled={
                  selectedHardSkills.length === 0 ||
                  selectedHardSkills.length > 5
                }
                large
                version={
                  selectedHardSkills.length === 0 ||
                  selectedHardSkills.length > 5
                    ? 'grey'
                    : 'fill'
                }
                isHover
              />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default function ProfilePersThree({
  gotostep,
  isLanguage,
  isHardSkills,
  isTechSkills,
  setLangScreen,
}) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showLanguageScreen, setShowLanguageScreen] = useState(false);
  const [showHardSkillsScreen, setShowHardSkillsScreen] = useState(false);
  const [showTechSkillsScreen, setShowTechSkillsScreen] = useState(false);
  const [langText, setLangText] = useState('Idiomas que manejas');
  const [hardSkillsText, setHardSkillsText] = useState(
    'Habilidades duras y expertis'
  );
  const [techSkillsText, setTechSkillsText] = useState('Herramientas Técnicas');
  const [hardSkillsDisabled, setHardSkillsDisabled] = useState(true);
  const [techSkillsDisabled, setTechSkillsDisabled] = useState(true);
  const [langVersion, setLangVersion] = useState('grey');
  const [hardSkillsVersion, setHardSkillsVersion] = useState('grey');
  const [techSkillsVersion, setTechSkillsVersion] = useState('grey');
  const [stepperCurrentStep, setStepperCurrentStep] = useState(0);
  const { userData } = React.useContext(UserContext);
  const talentLangsApi = useTalentLangsApi();
  const { createTalentToolRelation } = useTalentToolsApi();
  const { createTalentHardSkillRelation } = useTalentHardSkillsApi();
  const [langsLevelId, setLangsLevelId] = useState([]);
  const [techLevelId, setTechLevelId] = useState([]);
  const [hardId, setHardId] = useState([]);

  const isLanguageHandle = (text) => {
    setLangText(text);
    setHardSkillsDisabled(false);
    setLangVersion('outline');
    setStepperCurrentStep(1);
    if (typeof isLanguage === 'function') isLanguage(text);
  };

  const isHardSkillsHandle = (text) => {
    setHardSkillsText(text);
    setTechSkillsDisabled(false);
    setHardSkillsVersion('outline');
    setStepperCurrentStep(2);
    if (typeof isHardSkills === 'function') isHardSkills(text);
  };

  const isSoftSkillsHandle = (text) => {
    setTechSkillsText(text);
    setTechSkillsVersion('outline');
    setStepperCurrentStep(3);
    if (typeof isTechSkills === 'function') isTechSkills(text);
  };

  const handleButtonClick = (screenType) => {
    setIsFullScreen(true);
    if (screenType === 'language') {
      setShowLanguageScreen(true);
      setShowHardSkillsScreen(false);
      setShowTechSkillsScreen(false);
    } else if (screenType === 'hardSkills') {
      setShowLanguageScreen(false);
      setShowHardSkillsScreen(true);
      setShowTechSkillsScreen(false);
    } else if (screenType === 'techSkills') {
      setShowLanguageScreen(false);
      setShowHardSkillsScreen(false);
      setShowTechSkillsScreen(true);
    }
  };

  useEffect(() => {
    if (setLangScreen) {
      setShowLanguageScreen(true);
    }
    console.log(setLangScreen);
  }, [setLangScreen]);

  const goNextStepHandler = async () => {
    if (userData.talent) {
      for (const langLevel of langsLevelId) {
        const data = await talentLangsApi.createTalentLanguage(
          userData.talent.id,
          langLevel.language_id,
          langLevel.level_id
        );
        console.log(data);
      }
      const softSkillLevels = {
        Básico: 1,
        Intermedio: 2,
        Avanzado: 3,
      };

      for (const techLevel of techLevelId) {
        const data = await createTalentToolRelation(
          userData.talent.id,
          techLevel.tool_id,
          softSkillLevels[techLevel.level]
        );
        console.log(data);
      }
      for (const hardSkillId of hardId) {
        const data = await createTalentHardSkillRelation(
          userData.talent.id,
          hardSkillId
        );
        console.log(data);
      }
    }
    gotostep(9);
  };

  if (isFullScreen) {
    return (
      <FullScreen
        onClose={() => setIsFullScreen(false)}
        showLanguageScreen={showLanguageScreen}
        showHardSkillsScreen={showHardSkillsScreen}
        showTechSkillsScreen={showTechSkillsScreen}
        isLanguage={isLanguageHandle}
        isHardSkills={isHardSkillsHandle}
        isTechSkills={isSoftSkillsHandle}
        langsLevelIdData={(langsLevelId) => setLangsLevelId(langsLevelId)}
        techLevelIdData={(techLevelId) => setTechLevelId(techLevelId)}
        hardSkillsIdData={(hardId) => setHardId(hardId)}
      />
    );
  }

  return (
    <Row>
      <Col xs={12} className="gap-after">
        <StepperComponent
          steps={3}
          currentStep={stepperCurrentStep}
          title="Aptitudes técnicas"
          subtitle="Estado de tu perfil"
        />
      </Col>
      <Col xs={12} className="gap-after">
        <ButtonComponent
          version={langVersion}
          text={langText}
          onClick={() => handleButtonClick('language')}
          large
          isLeft
          isColorBlack={langText !== 'Idiomas que manejas'}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <ButtonComponent
          version={hardSkillsVersion}
          text={hardSkillsText}
          onClick={() => handleButtonClick('hardSkills')}
          large
          isLeft
          isColorBlack={hardSkillsText !== 'Habilidades duras y expertis'}
          disabled={hardSkillsDisabled}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <ButtonComponent
          version={techSkillsVersion}
          text={techSkillsText}
          onClick={() => handleButtonClick('techSkills')}
          large
          isLeft
          isColorBlack={techSkillsText !== 'Herramientas Técnicas'}
          disabled={techSkillsDisabled}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <ButtonComponent
          text="Continuar"
          onClick={goNextStepHandler}
          large
          disabled={
            langText === 'Idiomas que manejas' ||
            hardSkillsText === 'Habilidades duras y expertis' ||
            techSkillsText === 'Herramientas Técnicas'
          }
          version={
            langText === 'Idiomas que manejas' ||
            hardSkillsText === 'Habilidades duras y expertis' ||
            techSkillsText === 'Herramientas Técnicas'
              ? 'grey'
              : 'fill'
          }
          isHover
        />
      </Col>
    </Row>
  );
}
