import React from 'react';


export const CommonPage = ({ maintitle, subtitle, children }) => {

    return (
        <div className="main-dashboard">
            <div className="main-dashboard__header">
                <span className="main-dashboard__title">{maintitle}</span>
            </div>
            <div className="main-dashboard__content pl-4 pr-4 pb-4" style={{ gap: '40px', alignItems: 'flex-start', justifyContent: 'flex-start', height: 'auto', overflowY: 'scroll' }}>
                <h2 className="pt-4">{subtitle}</h2>
                {children}
            </div>
        </div>
    );
};

