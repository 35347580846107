import React from "react";
import isEditIcon from "../../../Assets/isEditIcon.svg";
import SoftSkillIcon from "../../../Assets/SoftSkillsIcon.svg";
import cultureIcon from "../../../Assets/cultureIcon.svg";

const DataSoftSkillsComponent = ({ dataSoftSkills, goBack, goBackDataSoftSkills, text, img, notEditIcon }) => {
    return (
        <div style={{ width: '100%', padding: '10px', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', background: 'white', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
            {
                img === 'softSkills' ? <img src={SoftSkillIcon} alt="Soft Skills" /> : <img src={cultureIcon} alt="Culture" />
            }
            <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>{text}</span>
            {
                notEditIcon ? (
                    <div style={{ visibility: 'hidden' }}>
                        <button onClick={goBack || goBackDataSoftSkills} style={{ border: 'none', background: 'white', cursor: 'pointer' }}>
                            <img src={isEditIcon} alt="Go back" />
                        </button>
                    </div>
                ) : (
                    <button onClick={goBack || goBackDataSoftSkills} style={{ border: 'none', background: 'white', cursor: 'pointer' }}>
                        <img src={isEditIcon} alt="Go back" />
                    </button>
                )
            }
        </div>
    );
};

export default DataSoftSkillsComponent;
