import React from "react";
import "./PressRelease.css";
import { Link } from "react-router-dom";

const PressReleaseCard = ({ pressRelease }) => {
    return (
        <div className="press-release-card">
            <img
                src={pressRelease.image}
                alt={pressRelease.title}
                className="press-release-image"
            />
            <h2 className="press-release-title">{pressRelease.title}</h2>
            <div
                className="press-release-text"
                dangerouslySetInnerHTML={{ __html: pressRelease.text }}
            ></div>
            <Link
                to={`/admin/press-release/${pressRelease.id}`}
                className="press-release-link"
            >
                <button className="press-release-button">Modificar</button>
            </Link>
        </div>
    );
};

export default PressReleaseCard;
