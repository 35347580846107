import React, { useEffect, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { usePositionsApi } from '../../../../../api/PositionsApi';
import BluePecil from '../../../../../Assets/isEditIcon.svg';
import Clock from '../../../../../Assets/Clock.svg';
import Ubi from '../../../../../Assets/locationIcon.svg';
import ButtonComponent from '../../../../Shared/ButtonComponent';

const JobCard = ({ job, goEdit }) => {
  const [translatePositionId, setTranslatePositionId] = useState('');
  const { fetchPosition } = usePositionsApi();
  const { created_at, id, position_id, seniority, city, country } = job;

  const dateSinceTranslated = (date) => {
    const fecha = new Date(date);
    const hoy = new Date();
    const diferencia = hoy - fecha;
    const minutos = Math.floor(diferencia / (1000 * 60));
    const horas = Math.floor(diferencia / (1000 * 60 * 60));
    const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
    const meses = Math.floor(dias / 30);
    const años = Math.floor(meses / 12);

    if (años > 0) {
      return `${años} años`;
    }
    if (meses > 0) {
      return `${meses} meses`;
    }
    if (dias > 0) {
      return `${dias} días`;
    }
    if (horas > 0) {
      return `${horas} horas`;
    }
    if (minutos > 0) {
      return `${minutos} minutos`;
    }
    return 'Hace unos segundos';
  };

  const handleGoEdit = ({
    job = { id, position_id, seniority, city, country, created_at },
  }) => {
    goEdit(job);
  };

  useEffect(() => {
    const fetchPositionById = async () => {
      const position = await fetchPosition(position_id);
      setTranslatePositionId(position.name_es);
    };
    fetchPositionById();
  }, []);

  const createdAtTranslated = dateSinceTranslated(created_at);

  return (
    <Card
      className="mb-3"
      style={{
        borderColor: 'rgba(0, 62, 255, 0.25)',
        borderRadius: '20px',
      }}
    >
      <Card.Body
        style={{ gap: '10px', display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Card.Text
            style={{ display: 'flex', gap: '5px' }}
            className="mb-0 my-2"
          >
            <img src={Clock} alt="clock" style={{ width: '15PX' }} />
            <span
              style={{
                color: '#1CB93F',
              }}
            >
              Tiempo de publicación: {createdAtTranslated}
            </span>
            <span>·</span>
            <span>N° Solicitudes</span>
          </Card.Text>
          <button
            style={{ border: 'none', background: 'none' }}
            onClick={handleGoEdit}
          >
            <img src={BluePecil} alt="pencil" />
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Card.Text
            style={{ display: 'flex', gap: '5px' }}
            className="mb-0 my-2"
          >
            <img src={Ubi} alt="clock" />
            <span>
              Ubicación: {city}, {country}
            </span>
          </Card.Text>
          <button style={{ border: 'none', background: 'none' }}></button>
        </div>
        <span
          style={{ display: 'flex', fontWeight: 600 }}
        >{`Cargo: ${translatePositionId}`}</span>
        <div
          style={{
            display: 'flex',
          }}
        >
          <Col xs={6}>
            <ButtonComponent
              text="Ver Seleccionados"
              style={{
                background: 'transparent',
                borderRadius: '10px',
                padding: '14px 20px',
                border: '1px solid #E2E2E2',
                color: '#E2E2E2',
              }}
              large
            />
          </Col>
          <Col xs={6}>
            <ButtonComponent
              text="Ver Candidatos"
              style={{
                background: '#FFA500',
                color: 'white',
                borderRadius: '10px',
                padding: '14px 20px',
                border: '1px solid #E2E2E2',
              }}
              large
            />
          </Col>
        </div>
      </Card.Body>
    </Card>
  );
};

export default JobCard;
