import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/users';

export const useUsersApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;

    // Actualizar datos del usuario
    const updateUser = async (userId, updateData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${userId}`, updateData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('User updated successfully:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating user:', error);
            throw error;
        }
    };

    return {
        updateUser
    };
};
