import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { CustomToggle } from "../../../Home/FAQ";
import TrashIcon from '../../../../Assets/RedTrash.svg';
import VisibleIcon from '../../../../Assets/VisibleIcon.svg';
import eyeIcon from '../../../../Assets/EyeIcon.svg';
import { useHomeApi } from "../../api/HomeDataApi";

const cardStyle = {
    marginBottom: "1rem",
    borderRadius: "0.5rem",
    border: "1px solid rgba(0, 62, 255, 0.25)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    cursor: 'pointer',
};

const cardHeader = {
    backgroundColor: "#fff",
    width: '100%',
};

const headerAndActionsStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    width: '100%',
};

const actionButtons = (faqItem, handleDelete, setVisible) => (
    <div className="prp-actions-container">
        <button className="prp-action-visible" onClick={() => setVisible(faqItem)}>
            <img src={faqItem.visible ? eyeIcon : VisibleIcon} alt="Visible" />
        </button>
        <button className="prp-action-delete" onClick={() => handleDelete(faqItem.question_es)}>
            <img src={TrashIcon} alt="Delete" />
        </button>
    </div>
);

const FaqBox = ({ data, onEditFaq }) => {
    const [faqs, setFaqs] = useState([]);
    const language = 'es';
    const { setHomeData } = useHomeApi();



    useEffect(() => {
        if (data && data.faqs) {
            setFaqs(JSON.parse(data.faqs));
        }
    }, [data.faqs]);


    const handleDelete = (question) => {
        const updatedFaqs = faqs.filter(faq => faq[`question_${language}`] !== question);
        setFaqs(updatedFaqs);
        setHomeData({ faqs: JSON.stringify(updatedFaqs) });
        console.log('Delete', question);
        window.location.reload();
    };

    const setVisible = (faqItem) => {
        const updatedFaqs = faqs.map(faq => {
            if (faq[`question_${language}`] === faqItem[`question_${language}`]) {
                return { ...faq, visible: !faq.visible };
            }
            return faq;
        });
        setFaqs(updatedFaqs);
        console.log('Set Visible', faqItem);
    };

    return (
        <div className="pt-5" style={{ width: '100%' }}>
            <h2 className='pb-4' style={{ display: 'flex', justifyContent: 'flex-start' }}>Preguntas frecuentes</h2>
            <Accordion defaultActiveKey={null} style={{ width: '100%' }}>
                {faqs.map((faq) => (
                    <Card key={faq.id} style={cardStyle} onClick={() => onEditFaq(faq)} >
                        <div style={headerAndActionsStyle}>
                            {actionButtons(faq, handleDelete, setVisible)}
                            <Card.Header style={cardHeader}>
                                <CustomToggle isEdit eventKey={faq.id} >
                                    {faq[`question_${language}`]}
                                </CustomToggle>
                            </Card.Header>
                        </div>
                        <Accordion.Collapse eventKey={faq.id}>
                            <Card.Body>
                                {faq[`answer_${language}`]}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </div>
    );

}

export default FaqBox;