import React, { useCallback, useEffect, useState } from 'react';
import { useToolsApi } from '../../../../../api/ToolsApi';

import {
  backButtonIconStyle,
  backButtonStyle,
  fullScreenStyles,
} from '../../../../Register/Talento/steps/ProfilePersStyles';
import { Col, Container, Row } from 'react-bootstrap';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import SearchComponent from '../../../../Shared/SearchComponent/SearchComponent';
import BlueLogo from '../../../../../Assets/BlueLogo.svg';
import BackBlue from '../../../../../Assets/BackBlue.svg';

const MAX_TOOLS = 5;

export const toolsLevels = [
  { id: 1, name: 'Básico' },
  { id: 2, name: 'Intermedio' },
  { id: 3, name: 'Avanzado' },
];

const Case4Step3CreateJob = ({ goBack, tools = [], save }) => {
  const [selectedTools, setSelectedTools] = useState([]);
  const [toolsList, setToolsList] = useState([]);
  const [showToolsScreen, setShowToolsScreen] = useState(true);
  const [currentTool, setCurrentTool] = useState(null);
  const { fetchTools } = useToolsApi();

  useEffect(() => {
    const fetchToolsData = async () => {
      const data = await fetchTools();
      if (data) setToolsList(data);
    };

    fetchToolsData();
  }, []);

  useEffect(() => {
    if (tools.length) {
      setSelectedTools(tools);
    }
  }, [tools]);

  const handleToolSelect = useCallback(
    (tool) => {
      if (!selectedTools.some((t) => t.name === tool.name_es)) {
        setSelectedTools((prev) =>
          prev.length < MAX_TOOLS
            ? [...prev, { name: tool.name_es, level: '', id: tool.id }]
            : prev
        );
        setCurrentTool(tool.name_es);
        setShowToolsScreen(false);
      }
    },
    [selectedTools]
  );

  const handleLevelSelect = useCallback(
    (level) => {
      setSelectedTools((prev) =>
        prev.map((t) =>
          t.name === currentTool
            ? { ...t, level: level.name, levelId: level.id }
            : t
        )
      );
      setShowToolsScreen(true);
      setCurrentTool(null);
    },
    [currentTool]
  );

  const handleToolsSelection = useCallback(() => {
    save(selectedTools);
    goBack();
  }, [goBack, save, selectedTools]);

  return (
    <div style={fullScreenStyles}>
      <img
        src={BlueLogo}
        alt="BlueLogo"
        style={{ cursor: 'pointer', marginBottom: '2rem' }}
      />
      <Container
        fluid
        className="h-100 d-flex align-items-center justify-content-between flex-column"
      >
        <button style={backButtonStyle} onClick={goBack}>
          <img src={BackBlue} alt="back" style={backButtonIconStyle} />
        </button>
        {showToolsScreen ? (
          <Row>
            <Col xs={12} className="mb-3">
              <span className="text-center">
                Indica tus cinco herramientas técnicas más fuertes.
              </span>
            </Col>
            <Col xs={12} className="mb-3">
              {selectedTools.length > 0 && (
                <ButtonComponent
                  isColorBlack
                  text={
                    selectedTools
                      .map((t) => `${t.name} / ${t.level}`)
                      .join(', ') || 'Selecciona hasta cinco'
                  }
                  large
                  paddingY="15px"
                  isLeft
                  version="outline"
                  isEditIcon
                />
              )}
            </Col>
            {toolsList.map((tool) => (
              <Col xs={6} key={tool.id} className="mb-3">
                <ButtonComponent
                  text={tool.name_es}
                  onClick={() => handleToolSelect(tool)}
                  version={
                    selectedTools.some((t) => t.name === tool.name_es)
                      ? 'fill'
                      : 'outline'
                  }
                  large
                  isColorBlack
                  height={'100%'}
                  paddingY="15px"
                  disabled={
                    selectedTools.length === MAX_TOOLS &&
                    !selectedTools.some((t) => t.name === tool.name_es)
                  }
                />
              </Col>
            ))}
            <Col xs={12} className="mb-3">
              <SearchComponent
                options={toolsList.map((s) => s.name_es)}
                placeholder="Buscar herramienta técnicas"
                addOption={true}
                onOptionSelected={handleToolSelect}
                isWorkArea={true}
              />
            </Col>
            <Col xs={12} className="text-center mt-3">
              <ButtonComponent
                text="Guardar"
                onClick={handleToolsSelection}
                large
                version={selectedTools.length === 0 ? 'outline' : 'fill'}
                isHover
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12} className="mb-1">
              <ButtonComponent
                text={currentTool}
                large
                isColorBlack
                paddingY="15px"
                isLeft
                version="outline"
                isEditIcon
                onClick={() => {
                  setSelectedTools((prev) =>
                    prev.filter((t) => t.name !== currentTool)
                  );
                  setShowToolsScreen(true);
                  setCurrentTool(null);
                }}
              />
            </Col>
            <Col xs={12} className="mb-3">
              <span
                className="
              text-right d-flex
              "
              >
                Selecciona tu nivel
              </span>
            </Col>

            {toolsLevels.map((level) => (
              <React.Fragment key={level.id}>
                <Col xs={12} className="mt-3 mb-1">
                  <ButtonComponent
                    text={level.name}
                    onClick={() => handleLevelSelect(level)}
                    version={
                      selectedTools.find((t) => t.name === currentTool)
                        ?.level === level.name
                        ? 'fill'
                        : 'outline'
                    }
                    large
                    isColorBlack
                    isLeft
                  />
                </Col>
                <Col xs={12}>
                  <span
                    style={{
                      fontSize: '10px',
                      color: '#E2E2E2',
                      textAlign: 'left',
                      display: 'block',
                    }}
                  >
                    {level.name === 'Básico' &&
                      'Capaz de realizar tareas simples y rutinarias.'}
                    {level.name === 'Intermedio' &&
                      'Utiliza características avanzadas y personaliza la interfaz.'}
                    {level.name === 'Avanzado' &&
                      'Domina el software completamente, incluyendo programación y personalización a nivel de sistema.'}
                  </span>
                </Col>
              </React.Fragment>
            ))}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default Case4Step3CreateJob;
