import React, { createContext, useState, useMemo, useCallback, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        const isUserLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        return isUserLoggedIn;
    });
    const [userData, setUserData] = useState(() => {
        const savedUserData = localStorage.getItem('userData');
        return savedUserData ? JSON.parse(savedUserData) : null;
    });

    const login = useCallback(async (email, password) => {
        try {
            const response = await axios.post('/auth/login', { email, password });
            setIsLoggedIn(true);
            setUserData(response.data);
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('userData', JSON.stringify(response.data));
            return true;
        } catch (error) {
            toast.error('Error al iniciar sesión:', error);
            return false;
        }
    }, []);

    const logout = useCallback(() => {
        setIsLoggedIn(false);
        setUserData(null);
        localStorage.removeItem('userToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('talentLangs');
        localStorage.removeItem('workPreferences');
        localStorage.removeItem('tokenRegister');
    }, []);

    useEffect(() => {
        const logoutTimer = setTimeout(logout, 4 * 60 * 60 * 1000);

        return () => {
            clearTimeout(logoutTimer);
            window.location.href = '/login';
        };
    }, [logout]);

    const authContextValue = useMemo(() => ({ isLoggedIn, userData, login, logout }), [isLoggedIn, userData, login, logout]);

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    );
};