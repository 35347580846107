import React, { useState, useCallback, useEffect } from 'react';
import DefaultStep2CreateJob from './DefaultStep2CreateJob';
import Case1CreateJob from './Case1CreateJob';
import Case2CreateJob from './Case2CreateJob';
import Case3CreateJob from './Case3CreateJob';

const Step2CreateJob = ({
  CurrentStep,
  isGoBack,
  vacancy,
  contrat,
  modality,
  handleSaveFormStep2,
}) => {
  const [internalSteps, setInternalSteps] = useState(0);
  const [vacancyData, setVacancyData] = useState(vacancy);
  const [contratData, setContratData] = useState(contrat);
  const [modalityData, setModalityData] = useState(modality);
  const [translatedContratData, setTranslatedContratData] = useState('');
  const [translatedModalityData, setTranslatedModalityData] = useState('');

  const handleSaveCase1 = (selectedVacancy) => {
    console.log('Selected Vacancy:', selectedVacancy);
    setVacancyData(selectedVacancy);
  };

  const handleSaveCase2 = (selectedContrat) => {
    console.log('Selected Contrat:', selectedContrat);
    setContratData(selectedContrat);
  };

  const handleSaveCase3 = (selectedModality) => {
    console.log('Selected Modality:', selectedModality);
    setModalityData(selectedModality);
  };

  const TranslateData = useCallback((data) => {
    const translations = {
      BETWEEN_1_2: 'De 1 a 2 Personas',
      BETWEEN_3_5: 'De 3 a 5 Personas',
      MORE_THAN_6: 'De 6 o más Personas',
      IN_RELATION: 'Relación de Dependencia',
      FREELANCE: 'Freelance / Autónomo',
      PRACTICE: 'Prácticas / Pasantías',
      LONG_TERM: 'Largo Plazo',
      PROJECT: 'Proyecto',
      PERIOD: 'Período',
      FULL_TIME: 'Tiempo Completo',
      PART_TIME: 'Medio Tiempo',
      SPECIFIC_HOURS: 'Horas Específicas',
      SHIFTS: 'Turnos',
      VOLUNTEERING: 'Voluntariado',
      TIMEZONE_REMOTE: 'Remoto con Zona Horaria',
      TIMEZONE_ONSITE: 'Presencial con Zona Horaria',
      ON_SITE: 'Presencial',
      HIBRYD: 'Híbrido',
      GLOBAL_REMOTE: 'Remoto Global',
      LOCAL_REMOTE: 'Remoto Local',
      FRIDAY: 'Viernes',
      WEDNESDAY: 'Miércoles',
      THURSDAY: 'Jueves',
      MONDAY: 'Lunes',
      TUESDAY: 'Martes',
      SATURDAY: 'Sábado',
      SUNDAY: 'Domingo',
    };
    return translations[data] || data;
  }, []);

  const translateContratDataToString = useCallback(
    (data) => {
      if (!data) return '';
      const contract = TranslateData(data.contract);
      const duration = TranslateData(data.duration);
      const dedication = TranslateData(data.dedication);
      return `${contract}, ${duration}, ${dedication}`;
    },
    [TranslateData]
  );

  const translateModalityDataToString = useCallback(
    (data) => {
      if (!data) return '';
      const modality = TranslateData(data.modality);
      const timezone = data.timezone ? `Zona Horaria: ${data.timezone}` : '';
      const onsiteDays = data.work_days?.onsite
        ? data.work_days.onsite.map((day) => TranslateData(day)).join(', ')
        : '';
      const remoteDays = data.work_days?.remote
        ? data.work_days.remote.map((day) => TranslateData(day)).join(', ')
        : '';

      const formatTime = (timeString) => {
        const date = new Date(timeString);
        return date.toLocaleTimeString('es-ES', {
          hour: '2-digit',
          minute: '2-digit',
        });
      };

      const fromHour = data.work_days?.from_hour
        ? formatTime(data.work_days.from_hour)
        : '';
      const toHour = data.work_days?.to_hour
        ? formatTime(data.work_days.to_hour)
        : '';

      return `${modality}${timezone ? `, ${timezone}` : ''}${
        onsiteDays ? `, Presencial: ${onsiteDays}` : ''
      }${remoteDays ? `, Remoto: ${remoteDays}` : ''}${
        fromHour && toHour ? `, desde las ${fromHour} hasta las ${toHour}` : ''
      }`;
    },
    [TranslateData]
  );

  useEffect(() => {
    setTranslatedContratData(translateContratDataToString(contratData));
  }, [contratData, translateContratDataToString]);

  useEffect(() => {
    setTranslatedModalityData(translateModalityDataToString(modalityData));
  }, [modalityData, translateModalityDataToString]);

  switch (internalSteps) {
    case 0:
      return (
        <DefaultStep2CreateJob
          CurrentStep={CurrentStep}
          isGoBack={isGoBack}
          vacancy={TranslateData(vacancyData)}
          contrat={translatedContratData}
          modality={translatedModalityData}
          goToStep={(step) => {
            setInternalSteps(step);
          }}
          handleSaveFormStep2={() => {
            handleSaveFormStep2(vacancyData, contratData, modalityData);
          }}
        />
      );
    case 1:
      return (
        <Case1CreateJob
          save={handleSaveCase1}
          goBack={() => setInternalSteps(0)}
          vacancyData={vacancyData}
          setVacancyData={setVacancyData}
        />
      );
    case 2:
      return (
        <Case2CreateJob
          contratData={contratData}
          setContratData={setContratData}
          goBack={() => setInternalSteps(0)}
          save={handleSaveCase2}
        />
      );
    case 3:
      return (
        <Case3CreateJob
          modalityData={modalityData}
          setModalityData={setModalityData}
          goBack={() => setInternalSteps(0)}
          save={handleSaveCase3}
        />
      );
    default:
      return null;
  }
};

export default Step2CreateJob;
