
import React, { useState, useCallback, useEffect } from "react";
import ButtonComponent from "../../../Shared/ButtonComponent";
import { useHomeApi } from "../../api/HomeDataApi";
import TrashIcon from '../../../../Assets/RedTrash.svg';
import { toast } from "react-toastify";

const InputField = React.memo(({ label, value, onChange, id }) => (
    <div className="d-flex w-100 align-items-center" style={{ gap: '40px' }}>
        <span className="prp-field-label" style={{ wordWrap: 'break-word', whiteSpace: 'nowrap' }}>{label}</span>
        <input
            type="text"
            id={id}
            className="prp-title-input"
            value={value}
            onChange={onChange}
        />
    </div>
));

const CopyHomePage = () => {
    const { setHomeData, getHomeData } = useHomeApi();
    const [copy, setCopy] = useState({ copy_es: "", copy_en: "", copy_pt: "" });

    const handleInputChange = useCallback((lang) => (e) => {
        setCopy(prevCopy => ({ ...prevCopy, [lang]: e.target.value }));
    }, []);

    const handleSave = useCallback(() => {
        setHomeData(copy).then(() => {
            toast.success('Copy guardado correctamente.');
        }).catch((error) => {
            toast.error('Error guardando copy:', error.response ? error.response.data.toString() : error);
        });
    }, [copy, setHomeData]);

    const handleDelete = async () => {
        try {
            await setHomeData({ copy_es: "", copy_en: "", copy_pt: "" });
            setCopy({ copy_es: "", copy_en: "", copy_pt: "" });
            toast.success('Copy eliminado correctamente.');
        } catch (error) {
            toast.error('Error eliminando copy:', error.response ? error.response.data.toString() : error);
        }
    };

    useEffect(() => {
        getHomeData().then((data) => {
            if (data) {
                setCopy({
                    copy_es: data.copy_es || "",
                    copy_en: data.copy_en || "",
                    copy_pt: data.copy_pt || ""
                });
            }
        });
    }, []);


    return (
        <div className="main-dashboard">
            <div className="main-dashboard__header">
                <span className="main-dashboard__title"><button style={{
                    background: 'none',
                    border: 'none',
                    color: 'white',
                }}
                    onClick={
                        () => {
                            window.history.back();
                        }
                    }
                >Super Admin Home </button> / Copy</span>
            </div>
            <div className="main-dashboard__content pl-4 pr-4 pt-4" style={{ gap: '40px', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <div className="d-flex w-100 justify-content-between">
                    <h2>Modificar</h2>
                    <div className="prp-actions-container">
                        <span>Acciones</span>
                        <button className="prp-action-delete" onClick={handleDelete}>
                            <img src={TrashIcon} alt="Delete" />
                        </button>
                    </div>
                </div>
                <InputField label="Copy Español" id="titleEs" value={copy.copy_es} onChange={handleInputChange('copy_es')} />
                <InputField label="Copy Ingles" id="titleEn" value={copy.copy_en} onChange={handleInputChange('copy_en')} />
                <InputField label="Copy Portugues" id="titlePt" value={copy.copy_pt} onChange={handleInputChange('copy_pt')} />
                <ButtonComponent text="Guardar" isHover onClick={handleSave} />
            </div>
        </div>
    );
}

export default CopyHomePage;
