import React from "react";
import "./SiteNotification.css";

const notifications = [
    { id: 1, company: "Unilever", location: "Buenos Aires", type: "empresa" },
    { id: 2, company: "Mercado Libre", location: "Buenos Aires", type: "empresa" },
    { id: 3, company: "Globant", location: "Buenos Aires", type: "empresa" },
    { id: 4, company: "Accenture", location: "Buenos Aires", type: "empresa" },
];

const SiteNotification = () => {
    return (
        <div className="site-notification-container">
            <h1 className="site-notification-title">Notificaciones</h1>
            {notifications.map((notification) => (
                <div key={notification.id} className="site-notification-message">
                    <p className="site-notification-text">Se ha registrado un nuevo usuario {notification.type} - {notification.company} - {notification.location}</p>
                    <button className="site-notification-button">Ver/Eliminar</button>
                </div>
            ))}
        </div>
    );
}

export default SiteNotification;
