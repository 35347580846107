import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CompanyReusableComponent from '../../Components/CompanyReusable/CompanyReusableComponent';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import PortfolioNotification from "../../../../../Assets/PortfolioNotification.svg";


const EmployeeSection5 = ({
    setCurrentSection,
    handleSetStep,
    selectedEmployees,
    handleSelectEmployees,
    handleSaveEmployees,
    descriptions
}) => {
    return (
        <CompanyReusableComponent
            company="WhiteScreen"
            step={handleSetStep}
            title="Cantidad de empleados"
            goBack={() => setCurrentSection("main")}
        >
            <Row className="m-0">
                <Col xs={12} className="gap-after my-5">
                    <img src={PortfolioNotification} alt="PortfolioNotification" />
                    <span style={{ fontWeight: 'bold', textAlign: 'start' }}>
                        Especifica cantidad de empleados de tu empresa.
                    </span>
                </Col>
                {["Microempresa", "Pequeña empresa", "Mediana empresa", "Gran empresa"].map((emp) => (
                    <Col xs={12} className="gap-after" key={emp}>
                        <label htmlFor={emp} style={{ cursor: "pointer", display: 'flex', gap: '14px' }}>
                            <input
                                type="radio"
                                id={emp}
                                name="companyRole"
                                value={emp}
                                checked={selectedEmployees === emp}
                                onChange={() => handleSelectEmployees(emp)}
                                style={{ marginRight: "10px" }}
                            />
                            <div className="d-flex flex-column">
                                <span className="text-left font-weight-bold">{emp}</span>
                                <span>{descriptions[emp]}</span>
                            </div>
                        </label>
                    </Col>
                ))}
                <Col xs={12} className="gap-after pb-5">
                    <label style={{ cursor: "pointer", display: 'flex', gap: '14px' }}>
                        <input
                            type="checkbox"
                            name="required"
                            style={{ marginRight: "10px" }}
                        />
                        {'Requisito obligatorio *'}
                    </label>
                </Col>
                <Col xs={12} className="gap-after pb-5">
                    <ButtonComponent
                        text="Guardar"
                        version="fill"
                        large
                        isFontBold
                        isHover
                        onClick={handleSaveEmployees}
                        disabled={!selectedEmployees}
                    />
                </Col>
            </Row>
        </CompanyReusableComponent>
    );
}

export default EmployeeSection5;