import React from "react";
import "./HomeList.css";
import ButtonComponent from "../../../Shared/ButtonComponent";

const HomeList = () => {
    const rows = [
        { moduleId: "#4", name: "Titular inicial", actionUrl: "/admin/Home/modificar-titular" },
        { moduleId: "#4", name: "Copy", actionUrl: "/admin/Home/modificar-copy" },
        { moduleId: "#4", name: "Cuadros de datos", actionUrl: "/admin/Home/modificar-cuadros" },
        { moduleId: "#1", name: "Logos de clientes", actionUrl: "/admin/Home/modificar-logos" },
        { moduleId: "#3", name: "Galería de videos", actionUrl: "/admin/Home/modificar-videos" },
        { moduleId: "#4", name: "Acerca de nosotros", actionUrl: "/admin/Home/modificar-nosotros" },
        { moduleId: "#2", name: "Preguntas frecuentes", actionUrl: "/admin/Home/modificar-faq" }
    ];

    return (
        <div className="home-list">
            <table className="home-list-table">
                <thead>
                    <tr>
                        <th>Módulo</th>
                        <th>Nombre</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => (
                        <tr key={`${row.moduleId}-${index}`}>
                            <td>{row.moduleId}</td>
                            <td>{row.name}</td>
                            <td>
                                <ButtonComponent text="Modificar" isHover onClick={() => window.location.href = row.actionUrl} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default HomeList;
