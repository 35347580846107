import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/hard-skills';

export const useHardSkillsApi = () => {
    const [registerData] = useContext(RegisterDataContext);
    const { userData } = useContext(UserContext);

    const createHardSkill = async (data) => {
        try {
            const response = await axios.post(`${apiBaseUrl}`, data, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error creating Hard Skill:', error.response ? error.response.data : error);
        }
    };

    const fetchHardSkills = async (params) => {
        try {
            const response = await axios.get(`${apiBaseUrl}`, {
                params: params,
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching Hard Skills:', error);
            return [];
        }
    };

    const fetchHardSkill = async (hardSkillId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${hardSkillId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching specific Hard Skill:', error);
            return null;
        }
    };

    const updateHardSkill = async (hardSkillId, data) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${hardSkillId}`, data, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error updating Hard Skill:', error.response ? error.response.data : error);
        }
    };

    const deleteHardSkill = async (hardSkillId) => {
        try {
            await axios.delete(`${apiBaseUrl}/${hardSkillId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
        } catch (error) {
            toast.error('Error deleting Hard Skill:', error.response ? error.response.data : error);
        }
    };

    return {
        createHardSkill,
        fetchHardSkills,
        fetchHardSkill,
        updateHardSkill,
        deleteHardSkill
    };
};
