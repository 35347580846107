import React, { useState, useEffect } from 'react';
import CompanyReusableComponent from '../../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import { Col } from 'react-bootstrap';
import StepperComponent from '../../../../Shared/StepperComponent/StepperComponent';
import Form from 'react-bootstrap/Form';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ContractForm = ({
  selectedContract,
  handleContractChange,
  handleNextStep,
}) => (
  <>
    <Col xs={12} className="gap-after my-5">
      <StepperComponent
        steps={3}
        currentStep={1}
        subtitle="Por favor, especifica tipo de contratación."
        borderless
      />
    </Col>
    <Col xs={12} className="gap-after" style={{ height: '100%' }}>
      <Form
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '100%',
          gap: '2rem',
          fontWeight: 'bold',
        }}
        className="gap-after"
      >
        <Form.Check
          type="radio"
          id="in-relation"
          name="contract"
          label="Relación de Dependencia"
          value="IN_RELATION"
          checked={selectedContract === 'IN_RELATION'}
          onChange={handleContractChange}
        />
        <Form.Check
          type="radio"
          id="freelance"
          name="contract"
          label="Freelance / Autónomo"
          value="FREELANCE"
          checked={selectedContract === 'FREELANCE'}
          onChange={handleContractChange}
        />
        <Form.Check
          type="radio"
          id="practice"
          name="contract"
          label="Prácticas / Pasantías"
          value="PRACTICE"
          checked={selectedContract === 'PRACTICE'}
          onChange={handleContractChange}
        />
      </Form>
    </Col>
    <Col xs={12} className="gap-after">
      <ButtonComponent
        text="Continuar"
        version={selectedContract ? 'fill' : 'grey'}
        large
        onClick={handleNextStep}
        disabled={!selectedContract}
        isHover
      />
    </Col>
  </>
);

const EmploymentForm = ({
  employment,
  handleEmploymentChange,
  handleNextStep,
}) => (
  <>
    <Col xs={12} className="gap-after my-5">
      <StepperComponent
        steps={3}
        currentStep={2}
        subtitle="Por favor, especifica la duración del empleo."
        borderless
      />
    </Col>
    <Col xs={12} className="gap-after" style={{ height: '100%' }}>
      <Form
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '100%',
          gap: '2rem',
          fontWeight: 'bold',
        }}
        className="gap-after"
      >
        <Form.Check
          type="radio"
          id="long-term"
          name="duration"
          label="Largo Plazo"
          value="LONG_TERM"
          checked={employment.duration === 'LONG_TERM'}
          onChange={handleEmploymentChange}
        />
        <Form.Check
          type="radio"
          id="project"
          name="duration"
          label="Proyecto"
          value="PROJECT"
          checked={employment.duration === 'PROJECT'}
          onChange={handleEmploymentChange}
        />
        <Form.Check
          type="radio"
          id="period"
          name="duration"
          label="Período"
          value="PERIOD"
          checked={employment.duration === 'PERIOD'}
          onChange={handleEmploymentChange}
        />
        {employment.duration === 'PERIOD' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '1rem',
            }}
          >
            <DatePicker
              selected={employment.from_date}
              onChange={(date) =>
                handleEmploymentChange({
                  target: { name: 'from_date', value: date },
                })
              }
              selectsStart
              startDate={employment.from_date}
              endDate={employment.to_date}
              placeholderText="Fecha de inicio"
              className="DatePicker-date"
            />
            <DatePicker
              selected={employment.to_date}
              onChange={(date) =>
                handleEmploymentChange({
                  target: { name: 'to_date', value: date },
                })
              }
              selectsEnd
              startDate={employment.from_date}
              endDate={employment.to_date}
              minDate={employment.from_date}
              placeholderText="Fecha de fin"
              className="DatePicker-date"
            />
          </div>
        )}
      </Form>
    </Col>
    <Col xs={12} className="gap-after">
      <ButtonComponent
        text="Continuar"
        version={employment.duration ? 'fill' : 'grey'}
        large
        onClick={handleNextStep}
        disabled={!employment.duration}
        isHover
      />
    </Col>
  </>
);

const DedicationForm = ({
  dedication,
  handleDedicationChange,
  handleNextStep,
}) => (
  <>
    <Col xs={12} className="gap-after my-5">
      <StepperComponent
        steps={3}
        currentStep={3}
        subtitle="Por favor, especifica la dedicación del empleo."
        borderless
      />
    </Col>
    <Col xs={12} className="gap-after" style={{ height: '100%' }}>
      <Form
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '100%',
          gap: '2rem',
          fontWeight: 'bold',
        }}
        className="gap-after"
      >
        <Form.Check
          type="radio"
          id="full-time"
          name="dedication"
          label="Tiempo Completo"
          value="FULL_TIME"
          checked={dedication === 'FULL_TIME'}
          onChange={handleDedicationChange}
        />
        <Form.Check
          type="radio"
          id="part-time"
          name="dedication"
          label="Medio Tiempo"
          value="PART_TIME"
          checked={dedication === 'PART_TIME'}
          onChange={handleDedicationChange}
        />
        <Form.Check
          type="radio"
          id="specific-hours"
          name="dedication"
          label="Horas Específicas"
          value="SPECIFIC_HOURS"
          checked={dedication === 'SPECIFIC_HOURS'}
          onChange={handleDedicationChange}
        />
        <Form.Check
          type="radio"
          id="shifts"
          name="dedication"
          label="Turnos"
          value="SHIFTS"
          checked={dedication === 'SHIFTS'}
          onChange={handleDedicationChange}
        />
        <Form.Check
          type="radio"
          id="volunteering"
          name="dedication"
          label="Voluntariado"
          value="VOLUNTEERING"
          checked={dedication === 'VOLUNTEERING'}
          onChange={handleDedicationChange}
        />
      </Form>
    </Col>
    <Col xs={12} className="gap-after">
      <ButtonComponent
        text="Guardar"
        version={dedication ? 'fill' : 'grey'}
        large
        onClick={handleNextStep}
        disabled={!dedication}
        isHover
      />
    </Col>
  </>
);

const Case2CreateJob = ({ contratData = {}, setContratData, goBack, save }) => {
  const [internalSteps, setInternalSteps] = useState(0);
  const [selectedContract, setSelectedContract] = useState('');
  const [employment, setEmployment] = useState({
    duration: '',
    from_date: null,
    to_date: null,
  });
  const [dedication, setDedication] = useState('');

  useEffect(() => {
    if (contratData.contract) {
      setSelectedContract(contratData.contract);
    }
    if (contratData.duration) {
      setEmployment((prev) => ({
        ...prev,
        duration: contratData.duration,
        from_date: contratData.from_date || null,
        to_date: contratData.to_date || null,
      }));
    }
    if (contratData.dedication) {
      setDedication(contratData.dedication);
    }
  }, [contratData]);

  const handleContractChange = (e) => {
    const value = e.target.value;
    setSelectedContract(value);
    setContratData({ ...contratData, contract: value });
  };

  const handleEmploymentChange = (e) => {
    const { name, value } = e.target;
    setEmployment({ ...employment, [name]: value });
    if (name === 'duration' && value !== 'PERIOD') {
      setEmployment({ ...employment, from_date: null, to_date: null });
    }
    setContratData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === 'duration' && value !== 'PERIOD'
        ? { from_date: null, to_date: null }
        : {}),
    }));
  };

  const handleDedicationChange = (e) => {
    const value = e.target.value;
    setDedication(value);
    setContratData({ ...contratData, dedication: value });
  };

  const handleNextStep = () => {
    setInternalSteps(internalSteps + 1);
  };

  switch (internalSteps) {
    case 0:
      return (
        <CompanyReusableComponent goBack={goBack} company={'WhiteScreen'}>
          <ContractForm
            selectedContract={selectedContract}
            handleContractChange={handleContractChange}
            handleNextStep={handleNextStep}
          />
        </CompanyReusableComponent>
      );

    case 1:
      return (
        <CompanyReusableComponent
          goBack={() => setInternalSteps(0)}
          company={'WhiteScreen'}
        >
          <EmploymentForm
            employment={employment}
            handleEmploymentChange={handleEmploymentChange}
            handleNextStep={handleNextStep}
          />
        </CompanyReusableComponent>
      );

    case 2:
      return (
        <CompanyReusableComponent
          goBack={() => setInternalSteps(1)}
          company={'WhiteScreen'}
        >
          <DedicationForm
            dedication={dedication}
            handleDedicationChange={handleDedicationChange}
            handleNextStep={() => {
              save({
                ...contratData,
                selectedContract,
                ...employment,
                dedication,
              });
              goBack();
            }}
          />
        </CompanyReusableComponent>
      );

    default:
      return null;
  }
};

export default Case2CreateJob;
