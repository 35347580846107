import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import BurguerKing from "../../Assets/BurguerKing.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BoxIcon } from "./Home2";
import { useCustomersApi } from "../admin/api/CustomersApi";

function Clientes() {
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 4000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const { getCustomers } = useCustomersApi();
    const [customers, setCustomers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        getCustomers()
            .then((response) => {
                setCustomers(response);
            })
            .catch((error) => {
                setError(error);
            });
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Container fluid className="about-section" >
            <Container >
                <h1 className="home-titulos" style={{ textAlign: "left", paddingTop: '6rem', paddingBottom: '1rem' }}>
                    Clientes
                </h1>
                <Slider {...settings}>
                    {customers.filter(customer => customer.visible).map((customer) => (
                        <BoxIcon
                            image={customer.image}
                            padding="10px"
                            key={customer.id}
                            isCustomer
                        />
                    ))}
                </Slider>
            </Container>
        </Container>
    );
}

export default Clientes;