import React, { useState, useCallback, useEffect } from "react";
import ButtonComponent from "../../../Shared/ButtonComponent";
import { useHomeApi } from "../../api/HomeDataApi";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

import FaqBox from "../../components/FaqBox/FaqBox";

const InputField = React.memo(({ label, value, onChange, id, isTextArea }) => (
    <div className="d-flex w-100 align-items-center" style={{ gap: '40px' }}>
        <span className="prp-field-label" style={{ wordWrap: 'break-word', whiteSpace: 'nowrap' }}>{label}</span>
        {isTextArea ? (
            <textarea style={{
                height: '130px',
                width: '100%',
                border: '1px solid rgba(0, 62, 255, 0.25)',
                borderRadius: '10px',
            }} id={id} className="prp-textarea-input" value={value || ''} onChange={onChange} />
        ) : (
            <input type="text" id={id} className="prp-title-input" value={value || ''} onChange={onChange} />
        )}
    </div>
));


const ModFaq = () => {
    const { setHomeData, getHomeData } = useHomeApi();
    const [faqs, setFaqs] = useState([]);
    const [currentFaq, setCurrentFaq] = useState({
        question_es: '',
        answer_es: '',
        question_en: '',
        answer_en: '',
        question_pt: '',
        answer_pt: ''
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getHomeData();
                if (data && typeof data.faqs === 'string' && data.faqs.trim() !== "") {
                    const parsedFaqs = JSON.parse(data.faqs);
                    setFaqs(parsedFaqs);
                } else if (data && Array.isArray(data.faqs)) {
                    setFaqs(data.faqs);
                } else {
                    setFaqs([]);
                }
            } catch (error) {
                console.error("Failed to load FAQs:", error);
                toast.error(`Error al cargar los datos: ${error.message}`);
            }
        }
        fetchData();
    }, []);

    const handleInputChange = (key, value) => {
        setCurrentFaq(prevFaq => ({ ...prevFaq, [key]: value }));
    };

    const handleSave = useCallback(async () => {
        const updatedFaqs = faqs.map(faq => {
            if (faq.id === currentFaq.id) {
                return currentFaq;
            }
            return faq;
        });
        setFaqs(updatedFaqs);
        try {
            const response = await setHomeData({ faqs: JSON.stringify(updatedFaqs) });
            if (response) {
                toast.success('Datos guardados correctamente');
                console.log('Saved:', updatedFaqs);
            }
        } catch (error) {
            toast.error(`Error al guardar los datos: ${error.message}`);
        }
    }, [faqs, currentFaq, setHomeData]);


    const handleAddFaq = useCallback(() => {
        const newFaq = { ...currentFaq, id: uuidv4() };
        const newFaqs = [...faqs, newFaq];
        setFaqs(newFaqs);
        setCurrentFaq({
            question_es: '',
            answer_es: '',
            question_en: '',
            answer_en: '',
            question_pt: '',
            answer_pt: ''
        });
        setHomeData({ faqs: JSON.stringify(newFaqs) });
    }, [faqs, currentFaq, setHomeData]);



    return (
        <div className="main-dashboard">
            <div className="main-dashboard__header">
                <span className="main-dashboard__title"><button style={{
                    background: 'none',
                    border: 'none',
                    color: 'white',
                }}
                    onClick={
                        () => {
                            window.history.back();
                        }
                    }
                >Super Admin Home </button> / FAQ</span>
            </div>
            <div className="main-dashboard__content pl-4 pr-4 pb-4" style={{ gap: '40px', alignItems: 'flex-start', justifyContent: 'flex-start', height: 'auto', overflowY: 'scroll' }}>
                <FaqBox data={{ faqs: JSON.stringify(faqs) }} onEditFaq={setCurrentFaq} />
                <h2 className="pt-4">Agregar / Modificar pregunta</h2>
                <div style={{ width: '100%', gap: '40px', display: 'flex', flexDirection: 'column' }}>
                    {Object.keys(currentFaq).map(key => (
                        key !== 'id' && (
                            <InputField
                                key={key}
                                label={`Pregunta en ${key.split('_')[1]}`}
                                value={currentFaq[key]}
                                onChange={(e) => handleInputChange(key, e.target.value)}
                                id={`${key}_`}
                                isTextArea={key.includes('answer')}
                            />
                        )
                    ))}
                </div>
                <div style={{
                    display: 'flex',
                    gap: '30px',
                }} >
                    <ButtonComponent text="Guardar" isHover onClick={handleSave} />
                    <ButtonComponent text="Añadir" isHover onClick={handleAddFaq} version={'outline'} />
                </div>
            </div>
        </div>
    );
};

export default ModFaq;
