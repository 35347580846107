import React, { useState, useEffect } from "react";
import { Container, Accordion, Card, useAccordionToggle, Row, Col } from "react-bootstrap";



function ChevronIcon({ isOpen }) {
    const style = {
        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.2s'
    };

    return (
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.53033 0.96967C9.82322 1.26256 9.82322 1.73744 9.53033 2.03033L5.53033 6.03033C5.23744 6.32322 4.76256 6.32322 4.46967 6.03033L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L5 4.43934L8.46967 0.96967C8.76256 0.676777 9.23744 0.676777 9.53033 0.96967Z" fill="black" />
        </svg>
    );
}

export function CustomToggle({ children, eventKey, isEdit }) {
    const [isOpen, setIsOpen] = useState(false);

    const decoratedOnClick = useAccordionToggle(eventKey, () => {
        setIsOpen(!isOpen);
    });

    const toggleStyle = {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "rgba(0, 62, 255, 0.25)",
        gap: isEdit ? '15px' : '0',
    };

    return (
        <div onClick={decoratedOnClick} style={toggleStyle}>
            {children}
            <ChevronIcon isOpen={isOpen} />
        </div>
    );
}

function Faq({ data }) {
    const [isHovered, setIsHovered] = useState(false);
    const [faqs, setFaqs] = useState([]);
    const cardStyle = {
        marginBottom: "1rem",
        borderRadius: "0.5rem",
        border: "1px solid rgba(0, 62, 255, 0.25)",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    };

    const titleStyle = {
        textAlign: "left",
        textShadow: "2px 2px rgba(0, 0, 0, 0.2)",
        marginBottom: "2rem",
        fontWeight: 600,
        fontSize: "1.2rem",
    };

    const cardHeader = {
        backgroundColor: "#fff",
    };

    const cardStyleSupport = {
        padding: '20px',
        border: '1px solid rgba(0, 62, 255, 0.25)',
        borderRadius: '8px',
    };

    const textStyle = {
        color: 'black',
        fontSize: '0.8rem',
        marginBottom: '0',
    };

    const linkStyle = {
        color: '#E2E2E2',
        textDecoration: 'none',
        fontSize: '0.8rem',
    };

    const marginBottom10 = {
        marginBottom: '10px',
    };

    const linkStyleBtn = {
        display: 'inline-block',
        backgroundColor: isHovered ? 'transparent' : '#003EFF',
        color: isHovered ? '#FFC700' : 'white',
        borderRadius: '8px',
        boxShadow: isHovered ? 'inset 0 0 0 1px #FFC700' : 'none',
        width: '100%',
        padding: '10px 0',
        textAlign: 'center',
        textDecoration: 'none',
        transition: 'all 0.3s ease',
    };


    useEffect(() => {
        let faqs = [];

        if (data && typeof data === 'string') {
            faqs = JSON.parse(data);
        } else if (data && Array.isArray(data.faqs)) {
            faqs = data.faqs;
        }

        setFaqs(faqs);
        console.log('FAQs:', faqs);
    }, [data]);

    if (!data || data.length === 0) {
        return null;
    }

    return (
        <Container fluid className="faq-section">
            <Container>
                <h1 className="home-titulos" style={titleStyle}>
                    Preguntas frecuentes
                </h1>

                <Accordion defaultActiveKey={null}>
                    {faqs.map((faq, index) => (
                        <Card key={index} style={cardStyle}>
                            <Card.Header style={cardHeader}>
                                <CustomToggle eventKey={String(index)}>
                                    {faq.question_es}
                                </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={String(index)}>
                                <Card.Body>{faq.answer_es}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>


                <Container className="my-3" style={cardStyleSupport}>
                    <Row>
                        <Col xs={12} md={4} lg={6} className="text-center my-auto">
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}>
                                <div style={{ minHeight: '40px' }}>
                                    <svg width="40" height="40" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M25.7485 17.5357C28.6212 13.2557 27.4785 7.45756 23.1991 4.58442C18.9196 1.71129 13.1223 2.85413 10.2495 7.13421C7.71491 10.9107 8.30723 15.8696 11.4243 18.9575L11.908 21.4135L14.3637 20.9297C18.4013 22.6427 23.2139 21.3123 25.7485 17.5333V17.5357Z" fill="#FFC700" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.99935 1.75073C6.89649 1.75073 4.48932 3.6694 4.0785 6.00389H6.17029C6.5668 6.00389 6.88824 6.32126 6.88824 6.71275V13.3288C6.88824 13.7203 6.5668 14.0376 6.17029 14.0376H3.29849C1.84462 14.0376 0.666016 12.874 0.666016 11.4385V8.60305C0.666016 7.40117 1.49225 6.38982 2.6145 6.09249C2.97966 2.78055 6.24093 0.333008 9.99935 0.333008C13.7578 0.333008 17.019 2.78055 17.3842 6.0925C18.5065 6.38982 19.3327 7.40117 19.3327 8.60305V11.4385C19.3327 12.6437 18.5018 13.6574 17.3748 13.9515C17.0677 16.1362 15.1684 17.8182 12.8712 17.8182H11.7747C11.4907 18.511 10.8028 18.9997 9.99935 18.9997C8.94198 18.9997 8.08482 18.1534 8.08482 17.1094C8.08482 16.0654 8.94198 15.2191 9.99935 15.2191C10.8028 15.2191 11.4907 15.7078 11.7747 16.4005H12.8712C14.3423 16.4005 15.5749 15.3924 15.899 14.0376H13.8284C13.4319 14.0376 13.1105 13.7203 13.1105 13.3288V6.71275C13.1105 6.32126 13.4319 6.00389 13.8284 6.00389H15.9202C15.5094 3.6694 13.1022 1.75073 9.99935 1.75073ZM3.29849 7.42162C2.63764 7.42162 2.10191 7.95056 2.10191 8.60305V11.4385C2.10191 12.091 2.63764 12.6199 3.29849 12.6199H5.45234V7.42162H3.29849ZM17.8968 8.60305C17.8968 7.95056 17.3611 7.42162 16.7002 7.42162H14.5464V12.6199H16.7002C17.3611 12.6199 17.8968 12.091 17.8968 11.4385V8.60305Z" fill="black" />
                                    </svg>
                                </div>

                                <p style={{ ...textStyle, ...marginBottom10 }}>Ingresando con tu cuenta, un asesor técnico estará disponible para ayudarte.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={8} lg={6} style={{ wordBreak: 'break-word' }}>
                            <p style={{ ...textStyle }}>También nos escribir a.</p>
                            <a href="mailto:soporte@empleosmarketingdigital.com" style={linkStyle}>soporte@empleosmarketingdigital.com</a>
                        </Col>
                    </Row>
                </Container>
                <a
                    href="/login"
                    style={linkStyleBtn}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    Ingresar
                </a>
            </Container>
        </Container >
    );
}

export default Faq;

