import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/talent-work-experiences';

export const useTalentWorkExperiencesApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;



    const createTalentWorkExperience = async (workExperienceData) => {
        try {
            const response = await axios.post(apiBaseUrl, workExperienceData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Talent-WorkExperience created:', response.data);
            return response.data;
            } catch (error) {
            const errorType = error.response?.statusText || 'Error';
            const errorMessage = error.response?.data || error.message;
            toast.error(`Error creating Talent-WorkExperience: ${errorType} - ${errorMessage}`);
        }
    };

    const fetchTalentWorkExperiences = async () => {
        try {
            const response = await axios.get(apiBaseUrl, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching Talent-WorkExperiences:', error);
            return [];
        }
    };

    const updateTalentWorkExperience = async (workExperienceId, workExperienceData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${workExperienceId}`, workExperienceData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Talent-WorkExperience updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating Talent-WorkExperience:', error.response ? error.response.data : error);
        }
    };

    const deleteTalentWorkExperience = async (workExperienceId) => {
        try {
            await axios.delete(`${apiBaseUrl}/${workExperienceId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Talent-WorkExperience deleted');
        } catch (error) {
            toast.error('Error deleting Talent-WorkExperience:', error.response ? error.response.data : error);
        }
    };

    return {
        createTalentWorkExperience,
        fetchTalentWorkExperiences,
        updateTalentWorkExperience,
        deleteTalentWorkExperience,
    };
};
