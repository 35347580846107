import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ReusableSlider.css";

const ReusableSlider = ({ config = {}, data = [] }) => {
    return (
        <Slider {...config} className="pb-3">
            {data.map((item, index) => (
                <div key={`${item.id}-${index}`} className="slider-item-main">
                    {item.name && <span>{item.name}</span>}
                    {item.description && <p>{item.description}</p>}
                    {item.image && <img src={item.image} alt={item.name} />}
                </div>
            ))}
        </Slider>
    );
};

export default ReusableSlider;