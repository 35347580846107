import './SkillComponent.css';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Pencil from '../../../Assets/Pencil.svg';
import EMDicon from '../../../Assets/emdIcon.svg';
import BluePecil from '../../../Assets/isEditIcon.svg';

const softSkillLevels = ['Básico', 'Intermedio', 'Avanzado'];
const languageLevels = ['Básico', 'Lectura', 'Conversacional', 'Nativo'];

const SkillLevelDots = ({ level, maxLevel }) => (
  <div style={{ display: 'flex' }}>
    {Array.from({ length: maxLevel }).map((_, i) => {
      const key = `level-dot-${level}-${i}-${
        i < level ? 'active' : 'inactive'
      }`;
      return (
        <span
          key={key}
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: i < level ? '#003EFF' : 'white',
            border: i < level ? 'none' : '1px solid rgba(0, 62, 255, 0.25)',
            margin: '0 2px',
          }}
        />
      );
    })}
  </div>
);

export default function SkillComponent({
  languages = '',
  technicalSkills = '',
  softSkills = '',
  back = () => {},
  goLangModule,
  borderBlue,
  goHardModule,
  goSoftSkillModule,
}) {
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const processedLanguages =
    typeof languages === 'string'
      ? languages.split(', ').map((lang) => {
          const [name, level] = lang.split(' / ');
          return { name, level, levelIndex: languageLevels.indexOf(level) + 1 };
        })
      : [];

  const processedTechnicalSkills =
    typeof technicalSkills === 'string' ? technicalSkills.split(', ') : [];
  const processedSoftSkills =
    typeof softSkills === 'string'
      ? softSkills.split(', ').map((skill) => {
          const [name, level] = skill.split(' / ');
          return {
            name,
            level,
            levelIndex: softSkillLevels.indexOf(level) + 1,
          };
        })
      : [];

  return (
    <div className="skill-component">
      <div className="w-100 d-flex justify-content-between px-4">
        <span className={`text-light ${borderBlue ? 'text-dark' : ''}`}>
          Competencias
        </span>
        <button
          className="p-0"
          style={{ border: 'none', background: 'none' }}
          onClick={goHardModule}
        >
          <img src={borderBlue ? BluePecil : Pencil} alt="pencil" />
        </button>
      </div>
      <Slider {...sliderSettings}>
        {processedTechnicalSkills.map((skill) => (
          <div
            key={skill}
            className={`technical-skill ${borderBlue ? 'borderBlue' : ''}`}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                cursor: 'pointer',
              }}
              aria-label={skill}
            >
              <span className="skill-name" aria-label={skill} title={skill}>
                {skill}
              </span>
            </div>
          </div>
        ))}
      </Slider>
      <div className="w-100 d-flex justify-content-between px-4">
        <span className={`text-light ${borderBlue ? 'text-dark' : ''}`}>
          Software
        </span>
        <button
          className="p-0"
          style={{ border: 'none', background: 'none' }}
          onClick={goSoftSkillModule}
        >
          <img src={borderBlue ? BluePecil : Pencil} alt="pencil" />
        </button>
      </div>
      <Slider {...sliderSettings}>
        {processedSoftSkills.map(({ name, levelIndex }) => (
          <div
            key={name}
            className={`soft-skill ${borderBlue ? 'borderBlue' : ''}`}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                cursor: 'pointer',
              }}
            >
              <img src={EMDicon} alt="skill" />
              <span className="skill-name" aria-label={name} title={name}>
                {name}
              </span>
              <SkillLevelDots level={levelIndex} maxLevel={3} />
            </div>
          </div>
        ))}
      </Slider>
      <div className="w-100 d-flex justify-content-between px-4">
        <span className={`text-light ${borderBlue ? 'text-dark' : ''}`}>
          Idiomas
        </span>
        <button
          className="p-0"
          style={{ border: 'none', background: 'none' }}
          onClick={goLangModule}
        >
          <img src={borderBlue ? BluePecil : Pencil} alt="pencil" />
        </button>
      </div>
      <Slider {...sliderSettings}>
        {processedLanguages.map(({ name, levelIndex }) => (
          <div
            key={name}
            className={`language-skill ${borderBlue ? 'borderBlue' : ''}`}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <span className="skill-name" aria-label={name} title={name}>
                  {name}
                </span>
              </div>
              <SkillLevelDots level={levelIndex} maxLevel={4} />
              <div
                style={{
                  color: 'grey',
                  fontWeight: 'normal',
                  fontSize: '0.8rem',
                }}
                title={languageLevels[levelIndex - 1]}
                className="langSpan"
              >
                {languageLevels[levelIndex - 1]}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
