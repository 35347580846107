import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { RegisterDataContext } from '../../../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/home';

export const useHomeApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;

    // Function to set (create/update) home data
    const setHomeData = async (homeData) => {
        try {
            const response = await axios.post(`${apiBaseUrl}`, homeData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Home data set:', response.data);
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 500) {
                toast.error('Error interno del servidor.');
            } else {
                toast.error('Error setting home data:', error.response ? error.response.data : error);
            }
        }
    };

    // Function to get home data
    const getHomeData = async () => {
        try {
            const response = await axios.get(apiBaseUrl, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Home data retrieved:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error fetching home data:', error.response ? error.response.data : error);
            return null;
        }
    };

    return {
        setHomeData,
        getHomeData
    };
};
