import React, { useState, useEffect } from "react";
import "../../style.css";
import ButtonComponent from "../Shared/ButtonComponent";

const ScreenButton = ({ isSelected, onClick, name }) => {
  return (
    <button
      className={`textPrehome prehome-button ${isSelected ? "selected" : ""}`}
      onClick={onClick}
    >
      {name}
    </button>
  );
};

export const PreHome = () => {
  const [selectedScreen, setSelectedScreen] = useState(1);
  const [activeImage, setActiveImage] = useState(1);

  const screens = [
    { id: 1, name: "Soy Talento", imgUrl: require("../../Assets/talento.svg"), buttonText: "¡Empieza ahora!", description: "Destácate profesionalmente; construye un perfil laboral con nosotros y destaca en el mercado laboral." },
    { id: 2, name: "Soy Empresa", imgUrl: require("../../Assets/empresa.svg"), buttonText: "¡Empieza ahora!", description: "Regístrate y descubre profesionales que impulsarán tu organización hacia el éxito y reconocimiento en el mercado." },
    { id: 3, name: "Soy Reclutador", imgUrl: require("../../Assets/reclutador.svg"), buttonText: "¡Empieza ahora!", description: "Selecciona candidatos para cubrir vacantes, asegurando que se alineen con las necesidades de la empresa." },
  ];

  const handleClick = (screenId) => () => {
    setActiveImage(selectedScreen);
    setSelectedScreen(screenId);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveImage(selectedScreen);
    }, 50);

    return () => clearTimeout(timeout);
  }, [selectedScreen]);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextScreen = selectedScreen % screens.length + 1;
      setSelectedScreen(nextScreen);
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedScreen, screens.length]);

  return (
    <div className="prehome-container">
      <div className="prehome-buttons">
        {screens.map((screen) => (
          <ScreenButton
            key={screen.id}
            isSelected={selectedScreen === screen.id}
            onClick={handleClick(screen.id)}
            name={screen.name}
          />
        ))}
        <div className={`prehome-indicator indicator-position-${selectedScreen}`}></div>
      </div>

      <div className="prehome-fullscreen-card">
        {screens.map((screen) => (
          <div
            key={screen.id}
            className={`prehome-card-content ${selectedScreen === screen.id ? 'actived' : ''}`}
          >
            <div className="prehome-image-container">
              <img
                src={screen.imgUrl.default}
                alt={screen.name}
                className={`fade-in ${activeImage === screen.id ? 'visible' : 'hidden'}`}
              />
            </div>
            {selectedScreen === screen.id && (
              <div className="prehome-text-overlay">
                <span>{screen.name}</span>
                <span className="textPrehome">{screen.description}</span>
                <ButtonComponent text={screen.buttonText} large onClick={
                  () => {
                    if (selectedScreen === 1) {
                      window.location.href = "/register/talento";
                    } else if (selectedScreen === 2) {
                      window.location.href = "/register/empresa";
                    } else {
                      window.location.href = "/register/reclutador";
                    }
                  }
                }
                  isHover
                  version="fill"
                  className="textPrehome"
                />
                <a href="/home" className="prehome-main-page textPrehome">Página Principal</a>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreHome;
