import React, { useState, useEffect, useCallback, useContext } from "react";
import { Container } from "react-bootstrap";

import { useFetchLanguages } from '../../../../../api/LangApi';
import BlueLogo from "../../../../../Assets/BlueLogo.svg";
import { fullScreenStyles } from "../../steps/ProfilePersStyles";
import LanguageSelection from "./LangSelection";
import { useTalentLangsApi } from "../../../../../api/TalentLangsApi";
import { UserContext } from "../../../../../contexts/UserContext";
import { toast } from "react-toastify";
import BackBlue from "../../../../../Assets/BackBlue.svg";
import { backButtonStyle, backButtonIconStyle } from "../../../../../components/Register/Talento/steps/ProfilePersStyles";
import { levels } from "./LangSelection";

const LangModule = ({ isLanguage, langsLevelIdData, showLanguageScreen = true, goBack, prevLangs }) => {
    const fetchedLanguages = useFetchLanguages();
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [currentLanguage, setCurrentLanguage] = useState("");
    const [languages, setLanguages] = useState([]);
    const [showLevels, setShowLevels] = useState(false);
    const [inputText, setInputText] = useState("");
    const [langId, setLangId] = useState();
    const [langsLevelId, setLangsLevelId] = useState([]);
    const { userData } = useContext(UserContext);
    const talentLangsApi = useTalentLangsApi();

    useEffect(() => {
        const fetchAndSetLanguages = async () => {
            const data = await fetchedLanguages();
            setLanguages(data || []);
        };

        fetchAndSetLanguages();

        const getLevelName = (levelId) => {
            const level = levels.find((lvl) => lvl.id === levelId);
            return level ? level.name : '';
        };

        prevLangs.forEach((lang) => {
            const levelName = getLevelName(lang.level);
            setSelectedLanguages((prev) => [...prev, { langname: lang.language.name_es, levelname: levelName }]);
            setInputText((prev) => `${prev}${prev ? ", " : ""}${lang.language.name_es} / ${levelName}`);
            setLangsLevelId((prev) => [...prev, { language_id: lang.language.id, level_id: lang.level }]);
        });
    }, []);


    const handleLanguageSelect = useCallback((language) => {
        const isAlreadySelected = selectedLanguages.some(lang => lang.langname === language.name_es);
        if (!isAlreadySelected) {
            setCurrentLanguage(language.name_es);
            setShowLevels(true);
            setLangId(language.id);
        } else {
            setSelectedLanguages(prev => prev.filter(lang => lang.langname !== language.name_es));
            setInputText(selectedLanguages.filter(lang => lang.langname !== language.name_es).map(lang => `${lang.langname} / ${lang.levelname}`).join(", "));
            setLangsLevelId(prev => prev.filter(lang => lang.language_id !== language.id));
        }
    }, [selectedLanguages, userData.talent.id]);

    const handleLevelSelect = useCallback((level) => {
        const newSelection = { langname: currentLanguage, levelname: level.name };
        setSelectedLanguages((prev) => [...prev, newSelection]);
        setInputText((prev) => `${prev}${prev ? ", " : ""}${currentLanguage} / ${level.name}`);
        setCurrentLanguage("");
        setShowLevels(false);
        setLangsLevelId((prev) => [...prev, { language_id: langId, level_id: level.id }]);
    }, [currentLanguage, langId]);

    const isLanguageDisabled = useCallback((language) => {
        return selectedLanguages.some((l) => l.langname === language);
    }, [selectedLanguages]);


    const handleLanguageAndLevelSelection = useCallback(async () => {
        if (typeof isLanguage === "function") {
            isLanguage(selectedLanguages.map((l) => `${l.langname} / ${l.levelname}`).join(", "));
        }
        if (typeof langsLevelIdData === "function") {
            langsLevelIdData(langsLevelId);
        }

        const operations = [];

        // Mapear prevLangs para comparar más fácilmente
        const prevLangsMap = new Map(prevLangs.map(lang => [lang.language.id, lang.level]));

        // Procesar idiomas para crear o actualizar
        for (const { langname, level_id, language_id } of langsLevelId) {
            const prevLevelId = prevLangsMap.get(language_id);

            if (prevLevelId === undefined) {
                // Idioma nuevo, llamar a createTalentLanguage
                operations.push(
                    talentLangsApi.createTalentLanguage(userData.talent.id, language_id, level_id)
                        .then(() => toast.success(`Idioma ${language_id} agregado correctamente`))
                        .catch(err => toast.error(`Error al agregar el idioma ${langname}: ${err.message}`))
                );
            } else if (prevLevelId !== level_id) {
                // Idioma existente, nivel cambiado, llamar a updateTalentLanguage
                operations.push(
                    talentLangsApi.updateTalentLanguage(userData.talent.id, language_id, level_id)
                        .then(() => toast.success(`Idioma ${langname} actualizado correctamente`))
                        .catch(err => toast.error(`Error al actualizar el idioma ${langname}: ${err.message}`))
                );
            }

            // Eliminar del mapa para no considerarlo para eliminación
            prevLangsMap.delete(language_id);
        }

        // Los idiomas que quedan en prevLangsMap deben ser eliminados
        for (const [language_id] of prevLangsMap) {
            operations.push(
                talentLangsApi.deleteTalentLanguage(userData.talent.id, language_id)
                    .then(() => toast.success("Idioma eliminado correctamente"))
                    .catch(err => toast.error(`Error al eliminar idioma: ${err.message}`))
            );
        }

        // Esperar a que todas las operaciones terminen
        Promise.all(operations).then(() => {
            goBack();
        });
    }, [selectedLanguages, langsLevelId, prevLangs, userData.talent.id]);



    return (
        <div style={fullScreenStyles}>
            <img src={BlueLogo} alt="Logo" style={{ cursor: "pointer", marginBottom: "2rem" }} />
            <Container fluid className="h-100 d-flex align-items-center justify-content-between flex-column">
                <button style={backButtonStyle} onClick={goBack}>
                    <img src={BackBlue} alt="back" style={backButtonIconStyle} />
                </button>
                {showLanguageScreen && (
                    <LanguageSelection
                        showLevels={showLevels}
                        selectedLanguages={selectedLanguages}
                        currentLanguage={currentLanguage}
                        languages={languages}
                        inputText={inputText}
                        onLanguageSelect={handleLanguageSelect}
                        onLevelSelect={handleLevelSelect}
                        isLanguageDisabled={isLanguageDisabled}
                        handleLanguageAndLevelSelection={handleLanguageAndLevelSelection}
                        prevLangs={prevLangs}
                    />
                )}
            </Container>
        </div>
    );
};

export default LangModule;