import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { RegisterDataContext } from '../../../contexts/RegisterDataContext';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/press-releases';

export const usePressReleasesApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;

    // Function to create a press release
    const createPressRelease = async (pressReleaseData) => {
        try {
            const response = await axios.post(`${apiBaseUrl}`, pressReleaseData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Press release created:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error creating press release:', error);
            throw error;
        }
    };

    // Function to get the list of all press releases
    const getAllPressReleases = async () => {
        try {
            const response = await axios.get(apiBaseUrl, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error retrieving press releases:', error);
            return [];
        }
    };

    // Function to get a specific press release
    const getPressRelease = async (pressReleaseId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${pressReleaseId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error retrieving press release:', error);
            return null;
        }
    };

    // Function to update a press release
    const updatePressRelease = async (pressReleaseId, updateData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${pressReleaseId}`, updateData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Press release updated:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error updating press release:', error);
            throw error;
        }
    };

    // Function to delete a press release
    const deletePressRelease = async (pressReleaseId) => {
        try {
            const response = await axios.delete(`${apiBaseUrl}/${pressReleaseId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Press release deleted:', response.status);
            return response.status === 204;
        } catch (error) {
            console.error('Error deleting press release:', error);
            throw error;
        }
    };

    return {
        createPressRelease,
        getAllPressReleases,
        getPressRelease,
        updatePressRelease,
        deletePressRelease
    };
};