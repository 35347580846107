import React from 'react';
import locationIcon from '../../../Assets/locationIcon.svg';
import './ProfileViewComponent.css';
import isEditIcon from '../../../Assets/isEditIcon.svg';

const ProfileViewComponent = ({
  localization,
  name,
  age,
  imgProfile,
  seniority,
  especialitation,
  salary,
  visibility,
  surname,
  goProfileCardModule,
  notEditIcon,
  isCompany,
  zIndex,
  borderBlue,
  position,
  CompanySector,
  CompanySize,
  size,
  companyWorkArea,
}) => {
  const actualAge = age;

  function formatSalary(salary) {
    if (salary >= 1000000) {
      return Math.round(salary / 1000000) + 'M';
    } else if (salary >= 1000) {
      return Math.round(salary / 1000) + 'k';
    } else {
      return salary;
    }
  }

  return (
    <div
      className={`profileComponentMain ${borderBlue ? 'borderBlue' : ''}`}
      style={{ zIndex: zIndex }}
    >
      <div className="profileComponentHeader">
        <img src={locationIcon} alt="location" />
        <span>{localization}</span>
      </div>
      <div className="profileComponentBody">
        <div className="imgProfileVisibilityContainer">
          <img src={imgProfile} alt="profile" className="imgProfile" />
          {visibility ? (
            <img
              src={visibility}
              alt="visibility"
              className="imgProfileVisibility"
            />
          ) : null}
        </div>
        <span
          title={
            (name ? name.charAt(0).toUpperCase() + name.slice(1) : '') +
            ' ' +
            (surname
              ? surname.charAt(0).toUpperCase() + surname.slice(1)
              : '') +
            (actualAge !== undefined ? ', ' + actualAge : '')
          }
        >
          {(name ? name.charAt(0).toUpperCase() + name.slice(1) : '') +
            ' ' +
            (surname
              ? surname.charAt(0).toUpperCase() + surname.slice(1)
              : '') +
            (actualAge !== undefined ? ', ' + actualAge : '')}
        </span>
        {notEditIcon ? null : (
          <button
            onClick={goProfileCardModule}
            style={{ cursor: 'pointer', background: 'none', border: 'none' }}
          >
            <img src={isEditIcon} alt="edit" />
          </button>
        )}
      </div>
      {isCompany ? (
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="profile-card-company__sector w-100">
            <img src={CompanySector} alt="Company Sector" />
            <span>{companyWorkArea}</span>
          </div>
          <div className="profile-card-company__size w-100 justify-content-end">
            <div>
              <img src={CompanySize} alt="Company Sector" />
              <span>{size}</span>
            </div>
          </div>
        </div>
      ) : null}
      <div className="profileComponentFooterContainer">
        <div className="profileComponentFooter">
          <div className="d-flex justify-content-between">
            {isCompany ? (
              <>
                <div>
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: '#000',
                    }}
                  >
                    {position}
                  </span>
                </div>
                <div className="sen">{seniority}</div>
              </>
            ) : (
              <>
                <div className="esp">
                  {especialitation && especialitation.length > 0
                    ? especialitation[0]
                    : ''}
                </div>
                <div className="sen">{seniority}</div>
              </>
            )}
          </div>
          <div>
            {especialitation && especialitation.length > 1
              ? especialitation[1] + ', ' + especialitation[2]
              : ''}
          </div>
          <div className="d-flex justify-content-between">
            {salary === undefined ? null : <span>Expectativa salarial</span>}
            {salary === undefined ? null : (
              <div>
                {'$' +
                  ' ' +
                  formatSalary(salary[1]) +
                  ' - ' +
                  formatSalary(salary[2])}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileViewComponent;
