import React, { useState, useMemo, useEffect } from 'react';

export const RegisterDataContext = React.createContext();

export const RegisterDataProvider = ({ children }) => {
  const [registerData, setRegisterData] = useState(() => {
    const token = localStorage.getItem('tokenRegister');
    return token ? { token } : {};
  });

  const setRegisterDataAndStoreToken = (data) => {
    if (data && data.token) {
      localStorage.setItem('tokenRegister', data.token);
      setRegisterData(data);
    } else if (data && !data.token && registerData.token) {
      setRegisterData({ ...data, token: registerData.token });
    } else {
      setRegisterData({});
    }
  };

  const value = useMemo(
    () => [registerData, setRegisterDataAndStoreToken],
    [registerData]
  );

  useEffect(() => {
    console.log('registerData', registerData);
  }, [registerData]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'tokenRegister') {
        if (event.newValue) {
          setRegisterData((prevState) => ({
            ...prevState,
            token: event.newValue,
          }));
        } else {
          setRegisterData({});
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <RegisterDataContext.Provider value={value}>
      {children}
    </RegisterDataContext.Provider>
  );
};
