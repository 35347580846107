import { useContext, useCallback } from 'react';
import axios from 'axios';
import { UserContext } from '../../../contexts/UserContext';
import { RegisterDataContext } from '../../../contexts/RegisterDataContext';

const API_BASE_URL = 'https://api-stg.empleosmarketingdigital.com/v1';

const useEmployments = () => {
  const { userData } = useContext(UserContext);
  const { registerData } = useContext(RegisterDataContext);
  const token = userData.token || registerData.token;

  const createEmployment = useCallback(
    async (employmentData) => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/employments`,
          employmentData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error creating employment:', error);
        throw error;
      }
    },
    [token]
  );

  const getEmployment = useCallback(
    async (employmentId) => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/employments/${employmentId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error getting employment:', error);
        throw error;
      }
    },
    [token]
  );

  const updateEmployment = useCallback(
    async (employmentId, employmentData) => {
      try {
        const response = await axios.put(
          `${API_BASE_URL}/employments/${employmentId}`,
          employmentData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error updating employment:', error);
        throw error;
      }
    },
    [token]
  );

  const createEmploymentTool = useCallback(
    async (toolData) => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/employment-tools`,
          toolData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error creating employment tool:', error);
        throw error;
      }
    },
    [token]
  );

  const getEmploymentTool = useCallback(
    async (employmentId) => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/employment-tools/employment/${employmentId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error getting employment tool:', error);
        throw error;
      }
    },
    [token]
  );

  const updateEmploymentTool = useCallback(
    async (toolId, toolData, employmentId) => {
      try {
        const response = await axios.put(
          `${API_BASE_URL}/employment-tools/employment/${employmentId}/tool/${toolId}`,
          toolData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error updating employment tool:', error);
        throw error;
      }
    },
    [token]
  );

  const deleteEmploymentTool = useCallback(
    async (toolId, employmentId) => {
      try {
        const response = await axios.delete(
          `${API_BASE_URL}/employment-tools/employment/${employmentId}/tool/${toolId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error deleting employment tool:', error);
        throw error;
      }
    },
    [token]
  );

  const createbenefit = useCallback(
    async (benefitData) => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/benefits`,
          benefitData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error creating employment benefit:', error);
        throw error;
      }
    },
    [token]
  );

  const getbenefits = useCallback(
    async (benefitData) => {
      try {
        const response = await axios.get(`${API_BASE_URL}/benefits`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
      } catch (error) {
        console.error('Error getting employment benefits:', error);
        throw error;
      }
    },
    [token]
  );

  const getbenefit = useCallback(
    async (benefitId) => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/benefits/${benefitId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error getting employment benefit:', error);
        throw error;
      }
    },
    [token]
  );

  const updatebenefit = useCallback(
    async (benefitId, benefitData) => {
      try {
        const response = await axios.put(
          `${API_BASE_URL}/benefits/${benefitId}`,
          benefitData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error updating employment benefit:', error);
        throw error;
      }
    },
    [token]
  );

  const deletebenefit = useCallback(
    async (benefitId) => {
      try {
        const response = await axios.delete(
          `${API_BASE_URL}/benefits/${benefitId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error deleting employment benefit:', error);
        throw error;
      }
    },
    [token]
  );

  const createEmploymentLanguage = useCallback(
    async (languageData) => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/employment-languages`,
          languageData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error creating employment language:', error);
        throw error;
      }
    },
    [token]
  );

  const getEmploymentLanguages = useCallback(
    async (employmentId) => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/employment-languages/employment/${employmentId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error getting employment languages:', error);
        throw error;
      }
    },
    [token]
  );

  const updateEmploymentLanguage = useCallback(
    async (languageId, languageData, employmentId) => {
      try {
        const response = await axios.put(
          `${API_BASE_URL}/employment-languages/employment/${employmentId}/language/${languageId}`,
          languageData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error updating employment language:', error);
        throw error;
      }
    },
    [token]
  );

  const deleteEmploymentLanguage = useCallback(
    async (languageId, employmentId) => {
      try {
        const response = await axios.delete(
          `${API_BASE_URL}/employment-languages/employment/${employmentId}/language/${languageId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error deleting employment language:', error);
        throw error;
      }
    },
    [token]
  );

  const createEmploymentHardSkill = useCallback(
    async (hardSkillData) => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/employment-hard-skills`,
          hardSkillData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error creating employment hard skill:', error);
        throw error;
      }
    },
    [token]
  );

  const getEmploymentHardSkills = useCallback(
    async (employmentId) => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/employment-hard-skills/employment/${employmentId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error getting employment hard skills:', error);
        throw error;
      }
    },
    [token]
  );

  const updateEmploymentHardSkill = useCallback(
    async (hardSkillId, hardSkillData, employmentId) => {
      try {
        const response = await axios.put(
          `${API_BASE_URL}/employment-hard-skills/employment/${employmentId}/hard-skill/${hardSkillId}`,
          hardSkillData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error updating employment hard skill:', error);
        throw error;
      }
    },
    [token]
  );

  const deleteEmploymentHardSkill = useCallback(
    async (hardSkillId, employmentId) => {
      try {
        const response = await axios.delete(
          `${API_BASE_URL}/employment-hard-skills/employment/${employmentId}/hard-skill/${hardSkillId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error deleting employment hard skill:', error);
        throw error;
      }
    }
  );

  const createEmploymentBenefit = useCallback(
    async (benefitData) => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/employment-benefits`,
          benefitData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error creating employment benefit:', error);
        throw error;
      }
    },
    [token]
  );

  const getEmploymentBenefits = useCallback(
    async (employmentId) => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/employment-benefits/employment/${employmentId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error getting employment benefits:', error);
        throw error;
      }
    },
    [token]
  );

  const updateEmploymentBenefit = useCallback(
    async (benefitId, benefitData, employmentId) => {
      try {
        const response = await axios.put(
          `${API_BASE_URL}/employment-benefits/employment/${employmentId}/benefit/${benefitId}`,
          benefitData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error updating employment benefit:', error);
        throw error;
      }
    },
    [token]
  );

  const deleteEmploymentBenefit = useCallback(
    async (benefitId, employmentId) => {
      try {
        const response = await axios.delete(
          `${API_BASE_URL}/employment-benefits/employment/${employmentId}/benefit/${benefitId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error deleting employment benefit:', error);
        throw error;
      }
    },
    [token]
  );

  return {
    createEmployment,
    getEmployment,
    updateEmployment,
    createEmploymentTool,
    getEmploymentTool,
    updateEmploymentTool,
    deleteEmploymentTool,
    createbenefit,
    getbenefits,
    getbenefit,
    updatebenefit,
    deletebenefit,
    createEmploymentLanguage,
    getEmploymentLanguages,
    updateEmploymentLanguage,
    deleteEmploymentLanguage,
    createEmploymentHardSkill,
    getEmploymentHardSkills,
    updateEmploymentHardSkill,
    deleteEmploymentHardSkill,
    createEmploymentBenefit,
    getEmploymentBenefits,
    updateEmploymentBenefit,
    deleteEmploymentBenefit,
  };
};

export default useEmployments;
