import React from "react";
import ButtonComponent from "../../../../Shared/ButtonComponent";
import SearchComponent from "../../../../Shared/SearchComponent/SearchComponent";
import { Col, Row } from "react-bootstrap";

export const levels = [
    { id: 1, name: "Básico" },
    { id: 2, name: "Lectura" },
    { id: 3, name: "Conversacional" },
    { id: 4, name: "Nativo" }
];

const LanguageSelection = ({
    showLevels,
    selectedLanguages,
    currentLanguage,
    languages,
    inputText,
    onLanguageSelect,
    onLevelSelect,
    isLanguageDisabled,
    handleLanguageAndLevelSelection,
    prevLangs
}) => {

    if (showLevels) {
        return (
            <Row>
                <Col xs={12} className="mb-3">
                    <ButtonComponent
                        isColorBlack
                        text={inputText}
                        large
                        paddingY="15px"
                        isLeft
                        version="outline"
                        isEditIcon
                    />
                </Col>
                {levels.map((level) => (
                    <Col xs={6} key={`level-${level.id}`} className="mb-3">
                        <ButtonComponent
                            text={level.name}
                            onClick={() => onLevelSelect(level)}
                            large
                            isColorBlack
                            version="outline"
                            paddingY="15px"
                        />
                    </Col>
                ))}
            </Row>
        );
    } else {
        return (
            <Row>
                {selectedLanguages.length > 0 && (
                    <Col xs={12} className="mb-3">
                        <ButtonComponent
                            isColorBlack
                            text={inputText}
                            large
                            paddingY="15px"
                            isLeft
                            version="outline"
                            isEditIcon

                        />
                    </Col>
                )}
                {languages.map((language) => (
                    <Col xs={6} key={`language-${language.id}`} className="mb-3">
                        <ButtonComponent
                            text={language.name_es}
                            onClick={() => onLanguageSelect(language)}
                            large
                            isColorBlack
                            version={isLanguageDisabled(language.name_es) ? "grey" : "outline"}
                            paddingY="15px"
                        />
                    </Col>
                ))}
                <Col xs={12} className="mb-3">
                    <SearchComponent
                        options={languages.map((l) => l.name_es)}
                        placeholder="Buscar idioma"
                        addOption={true}
                        onOptionSelected={(language) => onLanguageSelect({ name_es: language })}
                        isLang={true}
                    />
                </Col>
                <Col xs={12} className="text-center mt-3">
                    <ButtonComponent
                        text="Continuar"
                        onClick={handleLanguageAndLevelSelection}
                        large
                        disabled={
                            prevLangs.length === selectedLanguages.length && prevLangs.every((lang, index) => lang.language.name_es === selectedLanguages[index].langname && lang.level === selectedLanguages[index].levelname)
                        }
                        version={selectedLanguages.length === 0 ? "grey" : "fill"}
                        isHover
                    />
                </Col>
            </Row>
        );
    }
};

export default LanguageSelection;