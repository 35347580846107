import React from "react";

export const WorkPreferencesContext = React.createContext();

export const WorkPreferencesProvider = ({ children }) => {
    const [workPreferences, setWorkPreferences] = React.useState(() => {
        const storedWorkPreferences = localStorage.getItem('workPreferences');
        return storedWorkPreferences ? JSON.parse(storedWorkPreferences) : {
            timezone: null,
            id: null,
            contract: "",
            duration: "",
            from_date: null,
            to_date: null,
            dedication: "",
            modality: "",
            payment: "",
            currency: "",
            min_salary: null,
            max_salary: null,
            talent_id: null,
            updated_at: null,
            created_at: null,
            talentId: null,
        };
    });

    const updateWorkPreferences = React.useCallback((newWorkPreferences) => {
        setWorkPreferences(newWorkPreferences);
        localStorage.setItem('workPreferences', JSON.stringify(newWorkPreferences));
    }, []);


    React.useEffect(() => {
        const handleStorageChange = () => {
            const storedWorkPreferences = localStorage.getItem('workPreferences');
            if (storedWorkPreferences) {
                const newWorkPreferences = JSON.parse(storedWorkPreferences);
                if (JSON.stringify(newWorkPreferences) !== JSON.stringify(workPreferences)) {
                    setWorkPreferences(newWorkPreferences);
                    console.log('workPreferences>>>>>>>>>>' + newWorkPreferences);
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        }
    }, [workPreferences]);

    const value = React.useMemo(() => ({ workPreferences, updateWorkPreferences }), [workPreferences, updateWorkPreferences]);


    return (
        <WorkPreferencesContext.Provider value={value}>
            {children}
        </WorkPreferencesContext.Provider>
    );
};

export const useWorkPreferences = () => {
    const context = React.useContext(WorkPreferencesContext);
    if (!context) {
        throw new Error("useWorkPreferences must be used within a WorkPreferencesProvider");
    }
    return context;
};