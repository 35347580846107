import React, { useState } from 'react';
import DefaultStep4CreateJob from './DefaultStep4CreateJob';
import Case1Step4CreateJob from './Case1Step4CreateJob';
import Case2Step4CreateJob from './Case2Step4CreateJob';
import Case3Step4CreateJob from './Case3Step4CreateJob';

const Step4CreateJob = ({
  responsabilities,
  salary,
  horarios,
  recommendations,
  softSkills,
  culture,
  isGoBack,
  handleSaveFormStep4,
}) => {
  const [internalSteps, setInternalSteps] = useState(0);
  const [internalResponsabilities, setInternalResponsabilities] =
    useState(responsabilities);
  const [internalSalary, setInternalSalary] = useState(salary);
  const [internalRecommendations, setInternalRecommendations] =
    useState(recommendations);
  const [internalSoftSkills, setInternalSoftSkills] = useState(softSkills);
  const [internalCulture, setInternalCulture] = useState(culture);
  const [internalHorarios, setInternalHorarios] = useState(horarios);

  const translatedSalary = (salary) => {
    // { currency,payment: PROJECT, MONTHLY, YEARLY,min_salary,max_salary}

    if (salary.currency === 'ARS') {
      return `ARS ${salary.min_salary} - ${salary.max_salary}`;
    }
  };

  const handleSaveCase1 = (responsabilities) => {
    console.log('responsabilities', responsabilities);
    setInternalResponsabilities(responsabilities);
  };
  const handleSaveCase2 = (salary) => {
    console.log('salary', salary);
    setInternalSalary(translatedSalary(salary));
  };

  const handleSaveCase3 = (schedules) => {
    console.log('recommendations', schedules);
    const schedulesText = schedules ? 'Si' : 'No';
    setInternalHorarios(schedulesText);
  };

  switch (internalSteps) {
    case 0:
      return (
        <DefaultStep4CreateJob
          culture={internalCulture}
          horarios={internalHorarios}
          recommendations={internalRecommendations}
          responsabilities={internalResponsabilities}
          salary={internalSalary}
          softSkills={internalSoftSkills}
          goToStep={(step) => {
            setInternalSteps(step);
          }}
          isGoBack={isGoBack}
          handleSaveFormStep4={() => {
            handleSaveFormStep4(
              internalResponsabilities,
              internalSalary,
              internalRecommendations,
              internalHorarios,
              internalSoftSkills,
              internalCulture
            );
          }}
        />
      );
    case 1:
      return (
        <Case1Step4CreateJob
          goBack={() => setInternalSteps(0)}
          responsabilities={internalResponsabilities}
          setResponsabilities={setInternalResponsabilities}
          save={handleSaveCase1}
        />
      );
    case 2:
      return (
        <Case2Step4CreateJob
          goBack={() => setInternalSteps(0)}
          salary={internalSalary}
          save={handleSaveCase2}
        />
      );
    case 3:
      return (
        <Case3Step4CreateJob
          goBack={() => setInternalSteps(0)}
          schedules={internalHorarios}
          save={handleSaveCase3}
        />
      );
    default:
      return <div>Step not found</div>;
  }
};

export default Step4CreateJob;
