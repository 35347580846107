import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import CompanyReusableComponent from '../../Components/CompanyReusable/CompanyReusableComponent';
import ButtonComponent from '../../../../Shared/ButtonComponent';

const DescriptionSection5 = ({
    setCurrentSection,
    handleSetStep,
    formDataStep5,
    handleInputChange,
    setFormDataStep5,
}) => {

    const [text, setText] = useState(formDataStep5.description);

    const handleTextChange = (event) => {
        const words = event.target.value.split(' ');
        if (words.length <= 50) {
            setText(event.target.value);
        } else {
            const newText = words.slice(0, 50).join(' ');
            setText(newText);
        }
    };



    const handleSaveDescription = () => {

        setFormDataStep5({
            ...formDataStep5,
            description: text
        });

        setCurrentSection("main");

    };


    return (
        <CompanyReusableComponent
            company="WhiteScreen"
            step={handleSetStep}
            title="Cantidad de empleados"
            goBack={() => setCurrentSection("main")}
        >
            <Row className="m-0">
                <Col xs={12} className="text-center py-5">
                    <span className="mb-2 color-black">Escribe el mail del admin de tu empresa. Le enviaremos un link de autorización.</span>
                </Col>
                <Col xs={12} className="gap-after">
                    <span className="bold">{`${text.split(' ').length}/50`}</span>
                    <textarea
                        className="text-area"
                        placeholder="Descripción de la empresa"
                        value={text}
                        onChange={handleTextChange}
                        style={{ width: "100%", height: "200px", borderRadius: "10px", border: "1px solid #E2E2E2", padding: "10px" }}
                    />
                </Col>
                <Col xs={12} className="text-center mt-5">
                    <ButtonComponent
                        text="Guardar"
                        onClick={handleSaveDescription}
                        disabled={text.split(' ').length < 50 && text.length < 1}
                        version={text.split(' ').length < 50 && text.length < 1 ? "grey" : "fill"}
                        isHover
                        large
                    />
                </Col>
            </Row>
        </CompanyReusableComponent>
    );
};


export default DescriptionSection5;