import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/talent-soft-skills';

export const useTalentSoftSkillsApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);

    const createTalentSoftSkillRelation = async (relationData) => {
        try {
            const response = await axios.post(`${apiBaseUrl}`, relationData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || registerData.token}`
                }
            });
            console.log('Talent-SoftSkill relation created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating Talent-SoftSkill relation:', error.response ? error.response.data : error);
        }
    };

    const fetchSoftSkillsByTalent = async (talentId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/talent/${talentId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || registerData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching soft skills by talent:', error);
            return [];
        }
    };

    const updateTalentSoftSkillRelation = async (talentId, softSkillId, updateData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/talent/${talentId}/soft-skill/${softSkillId}`, updateData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || registerData.token}`
                }
            });
            console.log('Talent-SoftSkill relation updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating Talent-SoftSkill relation:', error.response ? error.response.data : error);
        }
    };

    const deleteTalentSoftSkillRelation = async (talentId, softSkillId) => {
        try {
            await axios.delete(`${apiBaseUrl}/talent/${talentId}/soft-skill/${softSkillId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || registerData.token}`
                }
            });
            console.log('Talent-SoftSkill relation deleted');
        } catch (error) {
            toast.error('Error deleting Talent-SoftSkill relation:', error.response ? error.response.data : error);
        }
    };

    return {
        createTalentSoftSkillRelation,
        fetchSoftSkillsByTalent,
        updateTalentSoftSkillRelation,
        deleteTalentSoftSkillRelation,
    };
};
