import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/uploads';

export const useUploadsApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;

    const uploadFile = async (file, path) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${apiBaseUrl}/${path}`, formData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('File uploaded:', response.data);
            return response.data;
        } catch (error) {
            toast.error(`${error.response?.data}`);
            throw error;
        }
    };

    const uploadTalentProfileImage = (file) => uploadFile(file, 'talent-profile-image');
    const uploadCompanyProfileImage = (file) => uploadFile(file, 'company-profile-image');
    const uploadTalentProjectImage = (file) => uploadFile(file, 'talent-project-image');
    const uploadPressReleaseImage = (file) => uploadFile(file, 'press-release-image');
    const uploadCustomerImage = (file) => uploadFile(file, 'customer-image');
    const uploadDataBoxImage = (file) => uploadFile(file, 'data-box-image');
    const uploadVideo = (file) => uploadFile(file, 'video');

    return {
        uploadTalentProfileImage,
        uploadCompanyProfileImage,
        uploadTalentProjectImage,
        uploadPressReleaseImage,
        uploadCustomerImage,
        uploadDataBoxImage,
        uploadVideo
    };
};
