import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CompanyReusableComponent from '../../Components/CompanyReusable/CompanyReusableComponent';
import InputComponent from '../../../../Shared/InputComponent';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import Photo from '../../../../../Assets/Photo.svg';

const MainSection5 = ({
    setStep,
    handleSetStep,
    setCurrentSection,
    fileInputRef,
    handleImageUpload,
    handleClick,
    uploadedImage,
    imgUrl,
    formDataStep5,
    handleInputChange,
    handleSubmit,
    workAreaName,
    nameRef,

}) => {
    return (
        <CompanyReusableComponent
            company="BlueScreen"
            step={handleSetStep}
            title="¡Ya casi estás allí!"
            subtitle="Ayúdanos a personalizar tu perfil proporcionando algunos detalles"
            goBack={() => setStep(4)}
        >
            <Row>
                <Col xs={12} className="gap-after">
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleImageUpload}
                    />
                    <ButtonComponent
                        version={imgUrl ? "outline" : "grey"}
                        text="Cargar logo"
                        isLeft
                        large
                        paddingY="20px"
                        icon={uploadedImage || Photo}
                        isColorBlack={true}
                        isEditIcon={imgUrl !== ''}
                        onClick={handleClick}
                    />
                </Col>
                <Col xs={12} className="gap-after">
                    <InputComponent
                        type="text"
                        placeholder={"Nombre de la empresa"}
                        value={formDataStep5.name}
                        onChange={(e) => handleInputChange(e, 'name')}
                        externalValue={formDataStep5.name}
                        ref={nameRef}
                        isValidData={formDataStep5.name !== ""}
                    />
                </Col>

                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        version={formDataStep5.size ? "outline" : "grey"}
                        text={formDataStep5.size || "Cantidad de empleados"}
                        isLeft
                        large
                        isColorBlack={formDataStep5.size !== ""}
                        isEditIcon={formDataStep5.size !== ""}
                        onClick={() => setCurrentSection("employees")}
                    />
                </Col>
                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        version={formDataStep5.company_work_area_id ? "outline" : "grey"}
                        text={workAreaName || "Actividad en el sector"}
                        isLeft
                        large
                        isColorBlack={formDataStep5.company_work_area_id !== null}
                        isEditIcon={formDataStep5.company_work_area_id !== null}
                        onClick={() => setCurrentSection("sector")}
                    />
                </Col>
                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        version={formDataStep5.city ? "outline" : "grey"}
                        text={formDataStep5.city || "Ubicación de la empresa"}
                        isLeft
                        large
                        isColorBlack={formDataStep5.city !== ""}
                        isEditIcon={formDataStep5.city !== ""}
                        onClick={() => setCurrentSection("location")}
                    />
                </Col>
                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        version={formDataStep5.description ? "outline" : "grey"}
                        text={formDataStep5.description || "Describe tu empresa en 50 palabras"}
                        isLeft
                        large
                        isColorBlack={formDataStep5.description !== ""}
                        isEditIcon={formDataStep5.description !== ""}
                        onClick={() => setCurrentSection("description")}
                    />
                </Col>
                <Col xs={12} className="gap-after pb-5">
                    <ButtonComponent
                        text="Guardar"
                        version="fill"
                        large
                        isFontBold
                        isHover
                        onClick={handleSubmit}
                    />
                </Col>
            </Row>
        </CompanyReusableComponent>
    );

}


export default MainSection5;