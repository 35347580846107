export const fullScreenStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "white",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    paddingTop: "20px",
    overflowY: "auto",
    overflowX: "hidden",
};


export const contentStyle = {
    alignSelf: 'start',
    width: '100%',
};

export const textStyle = {
    margin: "20px 0",
    textAlign: "start",
    color: "black",
    fontWeight: "600",
};

export const listStyle = {
    listStyleType: "circle",
    paddingInlineStart: "20px",
};

export const logoStyle = {
    cursor: "pointer",
    marginBottom: "2rem"
};

export const containerStyle = {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "between",
    flexDirection: "column"
};

export const buttonStyle = {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    position: "absolute",
    top: "10px",
    left: "10px",
    zIndex: 1000,
    border: "none",
    cursor: "pointer"
};

export const pointsContainerStyle = {
    backgroundColor: "#FFC700",
    borderRadius: "10px",
    marginBottom: "20px",
    color: "white",
    fontWeight: "600",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.5rem",
};

export const linkStyle = {
    color: 'rgba(0, 62, 255, 0.25) ',
    textDecoration: 'underline',
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
};

export const cardSkillStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "10px",
    borderRadius: "10px",
    border: '1px solid #2859F1',
}

export const buttonStyleP = {
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    backgroundColor: "white",
    width: "100px",
    height: "100px",
    boxShadow: '0px 8px 24px -4px rgba(0, 62, 255, 0.15)',
    fontSize: "2rem",
    color: '#003EFF',
}

export const backButtonStyle = {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    position: 'absolute',
    top: '10px',
    left: '10px',
    zIndex: 1000,
    border: 'none',
    cursor: 'pointer',
};

export const backButtonIconStyle = {
    width: '20px'
};
