import axios from 'axios';
import { useContext } from 'react';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { UserContext } from '../contexts/UserContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/talent-educations';

export const useTalentEducationsApi = () => {
    const [registerData] = useContext(RegisterDataContext);
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;

    const createTalentEducation = async (educationData) => {
        try {
            const response = await axios.post(apiBaseUrl, educationData, {
                headers: {
                    'Authorization': `Bearer ${registerData.token || userData.token || token}`
                }
            });
            console.log('Talent-Education created:', response.data);
            return response.data;
        } catch (error) {
            const errorType = error.response?.statusText || 'Error';
            const errorMessage = error.response?.data || error.message;
            toast.error(`Error creating Talent-Education: ${errorType} - ${errorMessage}`);
        }
    };

    const fetchTalentEducations = async (talentId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}?talent_id=${talentId}`, {
                headers: {
                    'Authorization': `Bearer ${registerData.token || userData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching Talent-Educations:', error);
            return [];
        }
    };

    const updateTalentEducation = async (educationId, educationData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${educationId}`, educationData, {
                headers: {
                    'Authorization': `Bearer ${registerData.token || userData.token || token}`
                }
            });
            console.log('Talent-Education updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating Talent-Education:', error.response ? error.response.data : error);
        }
    };

    const deleteTalentEducation = async (educationId) => {
        try {
            await axios.delete(`${apiBaseUrl}/${educationId}`, {
                headers: {
                    'Authorization': `Bearer ${registerData.token || userData.token || token}`
                }
            });
            console.log('Talent-Education deleted');
        } catch (error) {
            toast.error('Error deleting Talent-Education:', error.response ? error.response.data : error);
        }
    };

    return {
        createTalentEducation,
        fetchTalentEducations,
        updateTalentEducation,
        deleteTalentEducation,
    };
};
