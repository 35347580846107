import React, { useState, useCallback } from 'react';
import { usePressReleasesApi } from '../../api/PressReleaseApi';
import { useUploadsApi } from '../../../../api/UploadsApi';
import { toast } from 'react-toastify';
import './PressRelease.css';
import CameraIcon from '../../../../Assets/CameraIcon.svg';

async function resizeImage(file) {
    const MAX_WIDTH = 800; 
    const MAX_HEIGHT = 600; 
    const MIME_TYPE = "image/jpeg";
    const QUALITY = 0.7; 

    const img = document.createElement('img');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const imgPromise = new Promise((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
    });

    const imgResult = await imgPromise;

    let width = imgResult.width;
    let height = imgResult.height;

    if (width > height) {
        if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
        }
    } else {
        if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
        }
    }

    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(imgResult, 0, 0, width, height);
    URL.revokeObjectURL(img.src);

    return new Promise((resolve, reject) => {
        canvas.toBlob(resolve, MIME_TYPE, QUALITY);
    });
}

const PressReleaseCreateModal = (
    { handleClose }
) => {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [imageURL, setImageURL] = useState('');
    const [isVisible, setIsVisible] = useState(true);
    const { createPressRelease } = usePressReleasesApi();
    const { uploadPressReleaseImage } = useUploadsApi();

    const handleFileChange = useCallback(async (e) => {
        const file = e.target.files[0];
        const resizedImage = await resizeImage(file);
        setImageFile(resizedImage);
        setImageURL(URL.createObjectURL(resizedImage));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!title || !text) {
            toast.error('Title and text are required');
            return;
        }

        let imageUrl = null;
        if (imageFile) {
            try {
                imageUrl = await uploadPressReleaseImage(imageFile);
                uploadPressReleaseImage(imageFile);

            } catch (error) {
                toast.error(`Error uploading image: ${error}`);
                return;
            }
        }

        try {
            await createPressRelease({ title, text, image: imageUrl.url });
            setIsVisible(false);
            window.location.reload();
        } catch (error) {
            toast.error(`Error creating press release: ${error}`);
            window.location.reload();
        }

    };


    const handleCloseForm = () => {
        handleClose();
    };


    if (!isVisible) return null;

    return (
        <div className="prcm-overlay">
            <div className="prcm-modal">
                <button className="prcm-close-btn" onClick={handleCloseForm}>X</button>
                <h2>Crear Nota de Prensa</h2>
                <form onSubmit={handleSubmit}>
                    <label className="prcm-label" >
                        Titulo:
                        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className="prcm-input" style={{ borderRadius: '10px', border: '1px solid rgba(0, 62, 255, 0.25)' }} />
                    </label>
                    <label className="prcm-label">
                        Texto:
                        <textarea value={text} onChange={(e) => setText(e.target.value)} className="prcm-textarea" style={{ borderRadius: '10px', border: '1px solid rgba(0, 62, 255, 0.25)' }} />
                    </label>
                    <label htmlFor="file-upload" className="prp-upload-label">
                        {imageURL ? <img src={imageURL} alt="Upload" className="prp-upload-image" /> : <img src={CameraIcon} alt="Upload" />}
                        <span>Cargar foto</span>
                    </label>
                    <input id="file-upload" type="file" onChange={handleFileChange} className="prp-file-input" />
                    <button type="submit" className="prcm-submit-btn">Create Press Release</button>
                </form>
            </div>
        </div>
    );
};

export default PressReleaseCreateModal;
