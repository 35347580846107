import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTalentProjectsApi } from '../../../../api/TalentProjectsApi';
import { UserContext } from '../../../../contexts/UserContext';
import NavBarComponentProfile from '../../../Shared/NavBarComponent/NavBarComponent';
import Pencil from '../../../../Assets/WhiteEditPen.svg';
import './ProjectDetails.css';
import { Col, Row } from 'react-bootstrap';
import ButtonComponent from '../../../Shared/ButtonComponent';
import { useToolsApi } from '../../../../api/ToolsApi';
import { useWorkAreaGet } from '../../../../api/WorkAreaApi';
import { toast } from 'react-toastify';
import AdmProjectsStep1 from './steps/AdmProjectsStep1';



const ProjectDetails = () => {
    const { projectId, talentId } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const { getTalentProjects } = useTalentProjectsApi();
    const { userData } = useContext(UserContext);
    const { fetchTools } = useToolsApi();
    const [tool, setTool] = useState([]);
    const [projectTransformedTools, setProjectTransformedTools] = useState('');
    const getWorkArea = useWorkAreaGet();
    const [workArea, setWorkArea] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [editProject, setEditProject] = useState(false);

    useEffect(() => {
        getTalentProjects(userData.talent.id)
            .then(projects => {
                setAllProjects(projects);
                const foundProject = projects.find(p => p.id.toString() === projectId && p.talent_id.toString() === talentId);
                if (foundProject) {
                    setProject(foundProject);
                } else {
                    throw new Error('Proyecto no encontrado');

                }
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error loading the project details:', error);
                setLoading(false);
            });

    }, [projectId, userData]);

    useEffect(() => {
        fetchTools().then((response) => {
            setTool(response);
        });
    }, []);

    useEffect(() => {
        if (project && tool.length > 0) {
            const projectToolIds = JSON.parse(project.tools);
            const toolNames = projectToolIds.map(id => {
                const foundTool = tool.find(t => t.id === id);
                return foundTool ? foundTool.name_es : null;
            }).filter(name => name != null);
            setProjectTransformedTools(toolNames.join(', '));
        }
    }, [project, tool]);

    useEffect(() => {
        const fetchWorkAreaData = async () => {
            const data = await getWorkArea(project?.work_area_id);
            setWorkArea(data);
        };

        if (project?.work_area_id) {
            fetchWorkAreaData();
        }
    }, [project]);

    useEffect(() => {
        console.log('All projects:', allProjects);
    }, [allProjects]);

    const goBack = () => {
        window.history.back();
    };

    const goNextProject = () => {
        if (allProjects.length === 0) {
            console.log("No hay proyectos disponibles.");
            return;
        }

        const currentIndex = allProjects.findIndex(project => project.id.toString() === projectId);

        let nextIndex = currentIndex + 1;

        if (nextIndex >= allProjects.length) {
            nextIndex = 0;
        }

        const nextProject = allProjects[nextIndex];

        window.location.href = `/profile/project/${nextProject.id}/${userData.talent.id}`;
    };

    const openEditProject = () => {
        setEditProject(true);
    };


    if (loading) {
        return <div>Cargando...</div>;
    }

    return (
        <>
            {editProject && project ? (
                <AdmProjectsStep1 handleBackStep={
                    () => {
                        setEditProject(false);
                    }
                } project={project} isEdit />
            ) : (
                <div>
                    {project ? (
                        <div className="mainScreen">
                            <NavBarComponentProfile imgProfile={userData.talent.profile_image} darkLogo={false} zindex='4' position={{ position: 'static' }} />
                            <div className='MainProjects__container'>
                                <div className='MainProjects__header'>
                                    <a href='/profile' className='MainProjects__back'>
                                        <span className='MainProjects__back'>Regresar</span>
                                    </a>
                                    <button className='MainProjects__editButton' onClick={openEditProject}>
                                        <img src={Pencil} alt='Editar' />
                                    </button>
                                    <img src={JSON.parse(project.images)[0]} alt={project.name} />
                                    <span className='MainProjects__title'>{project.name}</span>
                                </div>
                                <div className='MainProjects__info'>
                                    <div className='MainProjects__info__section'>
                                        <span className='MainProjects__info__title'>Cliente:</span>
                                        <span title={project.customer} className='MainProjects__info__text'>{project.customer}</span>
                                    </div>
                                    <div className='MainProjects__info__section'>
                                        <span className='MainProjects__info__title'>Categoria:</span>
                                        <span title={projectTransformedTools} className='MainProjects__info__text'>{projectTransformedTools}</span>
                                    </div>
                                    <div className='MainProjects__info__section'>
                                        <span className='MainProjects__info__title'>Cargo:</span>
                                        <span title={workArea.name_es} className='MainProjects__info__text'>{workArea.name_es}</span>
                                    </div>
                                    <div className='MainProjects__info__section'>
                                        <span className='MainProjects__info__title'>Duración:</span>
                                        <span title={project.duration} className='MainProjects__info__text'>{project.duration}</span>
                                    </div>
                                    <div className='MainProjects__info__section'>
                                        <span className='MainProjects__info__title'>Año:</span>
                                        <span title={project.start_date} className='MainProjects__info__text'>{project.start_date}</span>
                                    </div>
                                    <div className='MainProjects__info__section__images'>
                                        {JSON.parse(project.images).slice(1).map((image, index) => (
                                            <img src={image} alt={project.name} key={index} />
                                        ))}
                                    </div>
                                </div>
                                <Row className='w-100 mb-5' style={{ gap: '20px' }}>

                                    <ButtonComponent large isHover version='grey' text='Siguiente proyecto' onClick={goNextProject} />


                                    <ButtonComponent large isHover version='fill' text='Volver' onClick={goBack} />
                                </Row>
                            </div>

                        </div>
                    ) : (
                        <p>Proyecto no encontrado.</p>
                    )}
                </div>
            )}
        </>
    );
};

export default ProjectDetails;
