import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import White from '../../../Assets/White.png';
import Back from '../../../Assets/Back.svg';
import ButtonComponent from '../../Shared/ButtonComponent';
import InputComponent from '../../Shared/InputComponent';
import Loader from '../../../Assets/Loader.svg';
import { titleStyle, linkStyle, linkStep } from './talentoStyles';
import { BRicon, ARicon, USicon } from '../../Shared/iconsComponent';
import ProfilePers from './steps/ProfilePers';
import ProfilePreview from './steps/ProfilePreview';
import NotificationComponent from '../../Shared/NotificationComponent/NotificationComponent';
import infoNotification from '../../../Assets/infoNotification.svg';
import ProfilePersTwo from './steps/ProfilePersTwo';
import ProfilePersThree from './steps/ProfilePersThree';
import ProfilePersFour from './steps/ProfilePersFour';
import ProfilePersFive from './steps/ProfilePersFive';
import ProfilePersSix from './steps/ProfilePersSix';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { RegisterDataContext } from '../../../contexts/RegisterDataContext';
import { UserContext } from '../../../contexts/UserContext';
import ProfileCardModule from './savedModules/ProfileCardModule';
import LangModule from './savedModules/LangModuleFolder/LangModule';
import HardSkillsModule from './savedModules/HardSkillsFolder/HardSkillModule';
import SoftSkillModule from './savedModules/SoftSkillsFolder/SoftSkillModule';
import { toast } from 'react-toastify';
import HiringModule from './savedModules/HiringModuleFolder/HiringModule';
import { useMedia } from '../../../contexts/mediaContext';

const countryCodes = {
  AR: '+54',
  US: '+1',
  BR: '+55',
};

function RenderStep({
  step,
  setStep,
  refs,
  codeGenerationTime,
  phoneNumber,
  isDropdownValidated,
  pos,
  setPos,
  seniority,
  setSeniority,
  imgProfile,
  setImgProfile,
  especialitation,
  setEspecialitation,
  visibility,
  setVisibility,
  goBack,
  goNext,
  handleOptionChange,
  handleEmailSend,
  handleEmailReSend,
  handleVerificationCode,
  handleVerificationStep3,
  handleVerificationStep4,
  handlePhoneChange,
  handleTelSend,
  gotostep6Handler,
  name,
  age,
  surname,
  hiring,
  setHiring,
  setModHiring,
  modHiring,
  salary,
  setSalary,
  setLenguage,
  setHardSkills,
  setTechSkills,
  isLanguage,
  isHardSkills,
  isTechSkills,
  gobackStep,
  setAboutMe,
  setEducation,
  setCerts,
  setExps,
  setRefs,
  isAboutMe,
  isCerts,
  isEducation,
  isExps,
  isRefs,
  setDataSkills,
  isDataSkills,
  goBackStepAboutMe,
  gobackStepDataSkills,
  corporateCulture,
  setCorporateCulture,
  goBackCorporateCulture,
  setLangScreen,
  goProfileCardModule,
  goLangModule,
  goHardModule,
  goSoftSkillModule,
  showVerificationCode,
  loadEmailCode,
  setProfileData,
  goHiringModule,
  prevHiringData,
}) {
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const media = useMedia();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  switch (step) {
    case 2:
      return (
        <Row className="justify-content-center align-items-center flex-column">
          <Col className="text-center mb-4">
            <img src={White} alt="Logo" style={{ width: '150px' }} />
          </Col>
          <div
            style={{
              height: '40vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              paddingTop: '5rem',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={Loader} alt="Loader" className="Loader" />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
              <h1 style={titleStyle}>¡Gracias por registrarte!</h1>
              <span
                style={{
                  fontSize: '0.8rem',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                Has completado el primer paso para registrar tu perfil.
              </span>
            </div>
          </div>
          <div
            style={{
              position: 'fixed',
              bottom: '10px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <button onClick={() => setStep(3)} style={linkStep}>
              Haz click acá para ir de inmediato al step 3
            </button>
          </div>
        </Row>
      );
    case 6:
      return (
        <Container
          fluid
          className="d-flex flex-column justify-content-between h-100"
        >
          <Row className="justify-content-center align-items-center flex-column ">
            <Col className="text-center mb-4">
              <img src={White} alt="Logo" style={{ width: '150px' }} />
            </Col>
            <button
              style={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                position: 'absolute',
                top: '10px',
                left: '10px',
                zIndex: 1000,
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={goBack}
            >
              <img src={Back} alt="back" style={{ width: '20px' }} />
            </button>
            <ProfilePreview
              talent={1}
              location={pos}
              seniority={seniority}
              imgProfile={imgProfile}
              especialitation={especialitation}
              visibility={visibility}
              age={age}
              name={name}
              surname={surname}
              goProfileCardModule={goProfileCardModule}
            />
          </Row>
          <Row style={{ marginBottom: '1rem', marginTop: '1rem' }}>
            <NotificationComponent
              icon={infoNotification}
              text={{
                boldPart: '¡Bien hecho!',
                normalText:
                  'Has completado tus preferencias laborales con éxito. Continúa para definir tu perfil técnico.',
              }}
            />
            <ButtonComponent
              text="Confirmar"
              version="fill"
              large
              onClick={goNext}
              isHover
            />
          </Row>
        </Container>
      );
    case 8:
      return (
        <Container
          fluid
          className="d-flex flex-column justify-content-between h-100"
        >
          <Row className="justify-content-center align-items-center flex-column ">
            <Col className="text-center mb-4">
              <img src={White} alt="Logo" style={{ width: '150px' }} />
            </Col>
            <button
              style={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                position: 'absolute',
                top: '10px',
                left: '10px',
                zIndex: 1000,
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={goBack}
            >
              <img src={Back} alt="back" style={{ width: '20px' }} />
            </button>
            <ProfilePreview
              talent={2}
              location={pos}
              seniority={seniority}
              imgProfile={imgProfile}
              especialitation={especialitation}
              visibility={visibility}
              age={age}
              name={name}
              surname={surname}
              hiring={hiring}
              salary={salary}
              modHiring={modHiring}
              goProfileCardModule={goProfileCardModule}
              goHiringModule={goHiringModule}
            />
          </Row>
          <Row style={{ marginBottom: '1rem', marginTop: '1rem' }}>
            <NotificationComponent
              icon={infoNotification}
              text={{
                boldPart: '¡Bien hecho!',
                normalText:
                  'Has completado esta parte del registro con éxito. Continúa para definir tus preferencias laborales.',
              }}
            />
            <ButtonComponent
              text="Confirmar"
              version="fill"
              large
              onClick={goNext}
              isHover
            />
          </Row>
        </Container>
      );
    case 10:
      return (
        <Container
          fluid
          className="d-flex flex-column justify-content-between h-100"
        >
          <Row className="justify-content-center align-items-center flex-column ">
            <Col className="text-center mb-4">
              <img src={White} alt="Logo" style={{ width: '150px' }} />
            </Col>
            <button
              style={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                position: 'absolute',
                top: '10px',
                left: '10px',
                zIndex: 1000,
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={goBack}
            >
              <img src={Back} alt="back" style={{ width: '20px' }} />
            </button>
            <ProfilePreview
              talent={3}
              location={pos}
              seniority={seniority}
              imgProfile={imgProfile}
              especialitation={especialitation}
              visibility={visibility}
              age={age}
              name={name}
              surname={surname}
              hiring={hiring}
              salary={salary}
              modHiring={modHiring}
              langs={isLanguage}
              hardSkills={isHardSkills}
              techSkills={isTechSkills}
              back={goBack}
              goLangModule={goLangModule}
              goProfileCardModule={goProfileCardModule}
              goHardModule={goHardModule}
              goSoftSkillModule={goSoftSkillModule}
              goHiringModule={goHiringModule}
            />
          </Row>
          <Row style={{ marginBottom: '1rem', marginTop: '1rem' }}>
            <NotificationComponent
              icon={infoNotification}
              text={{
                boldPart: '¡Bien hecho!',
                normalText:
                  'Has completado tus preferencias laborales con éxito. Continúa para definir tu formación académica.',
              }}
            />
            <ButtonComponent
              text="Confirmar"
              version="fill"
              large
              onClick={goNext}
              isHover
            />
          </Row>
        </Container>
      );
    case 12:
      return (
        <Container
          fluid
          className="d-flex flex-column justify-content-between h-100"
        >
          <Row className="justify-content-center align-items-center flex-column ">
            <Col className="text-center mb-4">
              <img src={White} alt="Logo" style={{ width: '150px' }} />
            </Col>
            <button
              style={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                position: 'absolute',
                top: '10px',
                left: '10px',
                zIndex: 1000,
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={goBack}
            >
              <img src={Back} alt="back" style={{ width: '20px' }} />
            </button>
            <ProfilePreview
              talent={4}
              location={pos}
              seniority={seniority}
              imgProfile={imgProfile}
              especialitation={especialitation}
              visibility={visibility}
              age={age}
              name={name}
              surname={surname}
              hiring={hiring}
              salary={salary}
              modHiring={modHiring}
              langs={isLanguage}
              hardSkills={isHardSkills}
              techSkills={isTechSkills}
              back={gobackStep}
              aboutMe={isAboutMe}
              certs={isCerts}
              education={isEducation}
              exp={isExps}
              refs={isRefs}
              goLangModule={goLangModule}
              goProfileCardModule={goProfileCardModule}
              goHardModule={goHardModule}
              goSoftSkillModule={goSoftSkillModule}
              goHiringModule={goHiringModule}
            />
          </Row>
          <Row style={{ marginBottom: '1rem', marginTop: '1rem' }}>
            <NotificationComponent
              icon={infoNotification}
              text={{
                boldPart: '¡Bien hecho!',
                normalText:
                  'Has completado tus preferencias laborales con éxito. Continúa para definir tu formación académica.',
              }}
            />
            <ButtonComponent
              text="Confirmar"
              version="fill"
              large
              onClick={goNext}
              isHover
            />
          </Row>
        </Container>
      );
    case 13:
      return (
        <ProfilePersFive
          back={goBack}
          dataskills={setDataSkills}
          gotostep={goNext}
        />
      );
    case 14:
      return (
        <Container
          fluid
          className="d-flex flex-column justify-content-between h-100"
        >
          <Row className="justify-content-center align-items-center flex-column ">
            <Col className="text-center mb-4">
              <img src={White} alt="Logo" style={{ width: '150px' }} />
            </Col>
            <button
              style={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                position: 'absolute',
                top: '10px',
                left: '10px',
                zIndex: 1000,
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={goBack}
            >
              <img src={Back} alt="back" style={{ width: '20px' }} />
            </button>
            <ProfilePreview
              talent={5}
              location={pos}
              seniority={seniority}
              imgProfile={imgProfile}
              especialitation={especialitation}
              visibility={visibility}
              age={age}
              name={name}
              surname={surname}
              hiring={hiring}
              salary={salary}
              modHiring={modHiring}
              langs={isLanguage}
              hardSkills={isHardSkills}
              techSkills={isTechSkills}
              aboutMe={isAboutMe}
              certs={isCerts}
              education={isEducation}
              exp={isExps}
              refs={isRefs}
              dataSoftSkills={isDataSkills}
              goLangModule={goLangModule}
              goBackAboutMe={goBackStepAboutMe}
              goBackDataSoftSkills={gobackStepDataSkills}
              goProfileCardModule={goProfileCardModule}
              goHardModule={goHardModule}
              goSoftSkillModule={goSoftSkillModule}
              goHiringModule={goHiringModule}
            />
          </Row>
          <Row style={{ marginBottom: '1rem', marginTop: '1rem' }}>
            <NotificationComponent
              icon={infoNotification}
              text={{
                boldPart: '¡Bien hecho!',
                normalText:
                  'Has completado tus preferencias laborales con éxito. Continúa para definir tu formación académica.',
              }}
            />
            <ButtonComponent
              text="Confirmar"
              version="fill"
              large
              onClick={goNext}
              isHover
            />
          </Row>
        </Container>
      );
    case 15:
      return (
        <ProfilePersSix
          back={goBack}
          gotostep={goNext}
          corporateCulture={setCorporateCulture}
        />
      );
    case 16:
      return (
        <Container
          fluid
          className="d-flex flex-column justify-content-between h-100"
        >
          <Row className="justify-content-center align-items-center flex-column ">
            <Col className="text-center mb-4">
              <img src={White} alt="Logo" style={{ width: '150px' }} />
            </Col>
            <button
              style={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                position: 'absolute',
                top: '10px',
                left: '10px',
                zIndex: 1000,
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={goBack}
            >
              <img src={Back} alt="back" style={{ width: '20px' }} />
            </button>
            <ProfilePreview
              talent={6}
              location={pos}
              seniority={seniority}
              imgProfile={imgProfile}
              especialitation={especialitation}
              visibility={visibility}
              age={age}
              name={name}
              surname={surname}
              hiring={hiring}
              salary={salary}
              modHiring={modHiring}
              langs={isLanguage}
              hardSkills={isHardSkills}
              techSkills={isTechSkills}
              aboutMe={isAboutMe}
              certs={isCerts}
              education={isEducation}
              exp={isExps}
              refs={isRefs}
              dataSoftSkills={isDataSkills}
              goLangModule={goLangModule}
              goBackAboutMe={goBackStepAboutMe}
              goBackDataSoftSkills={gobackStepDataSkills}
              corporateCulture={corporateCulture}
              goBackCorporateCulture={goBackCorporateCulture}
              goProfileCardModule={goProfileCardModule}
              goHardModule={goHardModule}
              goSoftSkillModule={goSoftSkillModule}
              goHiringModule={goHiringModule}
            />
          </Row>
          <Row style={{ marginBottom: '1rem', marginTop: '1rem' }}>
            <NotificationComponent
              icon={infoNotification}
              text={{
                boldPart: '¡Bien hecho!',
                normalText:
                  'Has completado tus preferencias laborales con éxito. Continúa para definir tu formación académica.',
              }}
            />
            <ButtonComponent
              text="Confirmar"
              version="fill"
              large
              onClick={() => history.push('/profile')}
              isHover
            />
          </Row>
        </Container>
      );
    default:
      return (
        <>
          <button
            style={{
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
              position: 'absolute',
              top: media === 'Desk' ? '50px' : '10px',
              left: media === 'Desk' ? '90%' : '10px',
              zIndex: 1000,
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={goBack}
          >
            <img src={Back} alt="back" style={{ width: '20px' }} />
          </button>
          <Row className="justify-content-center align-items-center">
            <Col className="text-center">
              <div
                style={{
                  display: 'flex',
                  justifyContent: media === 'Desk' ? 'start' : 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <img src={White} alt="Logo" style={{ width: '150px' }} />
              </div>
              {step === 0 && <h1 style={titleStyle}>¡Estamos listos!</h1>}
              {(step === 4 || step === 1) && (
                <h1 style={titleStyle}>¡Vas muy bien!</h1>
              )}
              {step === 3 && (
                <h1 style={titleStyle}>¡Estamos listos para empezar!</h1>
              )}
              {step === 5 && <h1 style={titleStyle}>¡Ya casi estás allí!</h1>}
              {(step === 7 || step === 9) && (
                <h1 style={titleStyle}>¡Excelente!</h1>
              )}
              {step === 11 && <h1 style={titleStyle}>¡Bien hecho!</h1>}
            </Col>
          </Row>
          {step === 0 && (
            <span style={{ fontSize: '0.8rem', color: 'white' }}>
              Vamos a diseñar juntos el perfil que te abrirá puertas.
            </span>
          )}
          {(step === 1 || step === 4) && (
            <span style={{ fontSize: '0.8rem', color: 'white' }}>
              Es momento de verificar tu cuenta.
            </span>
          )}
          {step === 3 && (
            <span style={{ fontSize: '0.8rem', color: 'white' }}>
              Comencemos, necesitamos saber más sobre ti. ¡Serán solo un par de
              datos rápidos!
            </span>
          )}
          {step === 5 && (
            <span style={{ fontSize: '0.8rem', color: 'white' }}>
              Ayúdanos a personalizar tu perfil proporcionando algunos detalles
            </span>
          )}
          {step === 7 && (
            <span style={{ fontSize: '0.8rem', color: 'white' }}>
              Es el momento de conocer tus skills profesionales.
            </span>
          )}
          {step === 9 && (
            <span style={{ fontSize: '0.8rem', color: 'white' }}>
              Ahora, nos enfocaremos en detallar tus habilidades técnicas.
            </span>
          )}
          {step === 11 && (
            <span style={{ fontSize: '0.8rem', color: 'white' }}>
              has avanzado mucho en poco tiempo y estás cerca de completar el
              proceso.
            </span>
          )}
          <div
            style={{
              backgroundColor: 'white',
              position: 'fixed',
              bottom: media === 'Desk' ? '35%' : '0',
              left: media === 'Desk' ? '5%' : '0',
              right: media === 'Desk' ? '5%' : '0',
              padding: '20px',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              borderBottomLeftRadius: media === 'Desk' ? '20px' : '0',
              borderBottomRightRadius: media === 'Desk' ? '20px' : '0',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'start',
              minHeight:
                step === 9 ? '30%' : '40%' && media === 'Desk' ? '10%' : '40%',
              gap: '10px',
              paddingTop:
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 7 ||
                step === 9 ||
                step === 11
                  ? '2rem'
                  : '4rem' && media === 'Desk'
                  ? ' 1.5rem'
                  : '4rem',
            }}
          >
            {step === 0 && (
              <>
                <span
                  className="mb-2"
                  style={{ color: 'black', fontWeight: 'bold' }}
                >
                  Ingresa tu direccion de correo electronico
                </span>
                <InputComponent
                  ref={refs.emailInputRef}
                  type="email"
                  placeholder="Correo electrónico"
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                />
                <ButtonComponent
                  text="Enviar"
                  version={email.trim() && !loadEmailCode ? 'fill' : 'grey'}
                  large
                  onClick={handleEmailSend}
                  isFontBold
                  isHover
                  disabled={!email.trim() || loadEmailCode}
                />
              </>
            )}
            {step === 1 && (
              <>
                <InputComponent
                  ref={refs.verificationCodeRef}
                  type="code"
                  placeholder="Código de verificación"
                  codeGenerationTime={codeGenerationTime}
                  onChange={(e) => setCode(e.target.value)}
                />
                <ButtonComponent
                  text="Confirmar"
                  version={code.trim() ? 'fill' : 'grey'}
                  large
                  onClick={handleVerificationCode}
                  isFontBold
                  isHover
                  disabled={!code.trim()}
                />
                <button onClick={handleEmailReSend} style={linkStyle}>
                  No he recibido ningún código.
                </button>
              </>
            )}
            {step === 3 && (
              <Row>
                <Col xs={6}>
                  <InputComponent
                    type="text"
                    placeholder="Nombre"
                    ref={refs.nameRef}
                    autoComplete="given-name"
                  />
                </Col>
                <Col xs={6}>
                  <InputComponent
                    type="text"
                    placeholder="Apellido"
                    ref={refs.textRef}
                    autoComplete="family-name"
                  />
                </Col>
                <Col xs={12} className="gap-after">
                  <InputComponent
                    type="dropdown"
                    placeholder="Como te percibes"
                    isDropdown
                    options={[
                      'Masculino',
                      'Femenino',
                      'No Binario',
                      'Género fluido',
                      'Agénero(sin género)',
                      'Prefiero no decirlo',
                    ]}
                    onOptionChange={handleOptionChange}
                    isValidDrop={isDropdownValidated}
                    ref={refs.dropdownRef}
                  />
                </Col>
                <Col xs={12} className="gap-after">
                  <InputComponent
                    type="date"
                    placeholder="Fecha de nacimiento"
                    isCalendar
                    ref={refs.calendarRef}
                    autoComplete="bday"
                  />
                </Col>
                <Col xs={12} className="gap-after">
                  <InputComponent
                    type="password"
                    placeholder="Contraseña"
                    ref={refs.passwordRef}
                    autoComplete="new-password"
                  />
                </Col>
                <Col xs={12} className="gap-after">
                  <InputComponent
                    type="password"
                    placeholder="Repetir contraseña"
                    autoComplete="new-password"
                    ref={refs.confirmPasswordRef}
                  />
                </Col>
                <Col
                  xs={12}
                  className="gap-after"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <input
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={handleCheckboxChange}
                    />
                    <span
                      style={{
                        fontSize: '0.7rem',
                        color: '#8A8A9D',
                      }}
                    >
                      He leído y acepto los{' '}
                      <a href="ww.w.w">términos y condiciones *</a>
                    </span>
                  </div>
                </Col>

                <Col xs={12} className="gap-after">
                  <ButtonComponent
                    text="Confirmar"
                    version={isChecked ? 'fill' : 'grey'}
                    large
                    onClick={handleVerificationStep3}
                    isFontBold
                    disabled={!isChecked}
                    isHover
                  />
                </Col>
              </Row>
            )}
            {step === 4 && (
              <Row>
                <Col xs={12} className="gap-after">
                  <span className="mb-2" style={{ color: 'black' }}>
                    Ingresa el código que te enviamos a whatsapp
                  </span>
                </Col>
                <Col xs={3} className="gap-after">
                  <InputComponent
                    type="text"
                    placeholder="ARS"
                    isDropdown
                    options={['ARS', 'US', 'BR']}
                    iconOptions={{ icons: [ARicon, USicon, BRicon] }}
                    onOptionChange={handleOptionChange}
                    ref={refs.dropdownRef}
                  />
                </Col>
                <Col xs={9} className="gap-after d-flex">
                  <InputComponent
                    type="number"
                    externalValue={phoneNumber}
                    onChange={handlePhoneChange}
                    placeholder={`Teléfono (Obligatorio)`}
                    ref={refs.telRef}
                  />
                </Col>
                <Col xs={12} className="gap-after">
                  <InputComponent
                    type="code6"
                    placeholder="Código de verificación"
                    ref={refs.verificationCodeRefTel}
                    codeGenerationTime={codeGenerationTime}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Col>
                <Col xs={12} className="gap-after">
                  <ButtonComponent
                    text="Confirmar"
                    version={
                      !code.trim() || !handleTelSend || !phoneNumber.trim()
                        ? 'grey'
                        : 'fill'
                    }
                    large
                    disabled={
                      !code.trim() || !handleTelSend || !phoneNumber.trim()
                    }
                    onClick={handleVerificationStep4}
                    isFontBold
                    isHover
                  />
                </Col>
                <Col xs={12} className="gap-after pt-2">
                  <button
                    style={{ ...linkStyle, fontSize: '1rem' }}
                    onClick={handleTelSend}
                  >
                    No he recibido ningún código.
                  </button>
                </Col>
                <Col xs={12} className="gap-after">
                  <button
                    style={{ ...linkStyle, fontSize: '1rem' }}
                    onClick={() => setStep(5)}
                  >
                    Omitir este paso
                  </button>
                </Col>
              </Row>
            )}
            {step === 5 && (
              <ProfilePers
                gotostep={gotostep6Handler}
                location={setPos}
                seniority={setSeniority}
                photoProfile={setImgProfile}
                especialitation={setEspecialitation}
                visibility={setVisibility}
              />
            )}
            {step === 7 && (
              <ProfilePersTwo
                isHiring={setHiring}
                isModHiring={setModHiring}
                gotostep={goNext}
                isSalary={setSalary}
              />
            )}
            {step === 9 && (
              <ProfilePersThree
                setLangScreen={setLangScreen}
                isLanguage={setLenguage}
                isHardSkills={setHardSkills}
                isTechSkills={setTechSkills}
                gotostep={goNext}
              />
            )}
            {step === 11 && (
              <ProfilePersFour
                gotostep={goNext}
                isAboutMe={setAboutMe}
                isCerts={setCerts}
                isEducation={setEducation}
                isExps={setExps}
                isRefs={setRefs}
              />
            )}
            {step === 'profileCardModule' && (
              <ProfileCardModule goBack={goBack} profileData={setProfileData} />
            )}
            {step === 'langModule' && (
              <LangModule goBack={goBack} isLanguage={setLenguage} />
            )}
            {step === 'HardSkillsModule' && (
              <HardSkillsModule goBack={goBack} isHardSkills={setHardSkills} />
            )}
            {step === 'SoftSkillModule' && (
              <SoftSkillModule goBack={goBack} isTechSkills={setTechSkills} />
            )}
            {step === 'HiringModule' && (
              <HiringModule
                gotostep={goBack}
                isHiring={setHiring}
                isModHiring={setModHiring}
                isSalary={setSalary}
                previousData={prevHiringData}
              />
            )}
          </div>
        </>
      );
  }
}

const TalentoRegister = () => {
  const codeGenerationTime = new Date();
  const [step, setStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [isDropdownValidated, setIsDropdownValidated] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pos, setPos] = useState('');
  const [seniority, setSeniority] = useState('');
  const [imgProfile, setImgProfile] = useState();
  const [especialitation, setEspecialitation] = useState('');
  const [visibility, setVisibility] = useState('');
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [surname, setSurname] = useState('');
  const [hiring, setHiring] = useState('');
  const [salary, setSalary] = useState([]);
  const [modHiring, setModHiring] = useState('');
  const [isHardSkills, setHardSkills] = useState([]);
  const [isLanguage, setLenguage] = useState([]);
  const [isTechSkills, setTechSkills] = useState([]);
  const [isAboutMe, setAboutMe] = useState('');
  const [isCerts, setCerts] = useState({});
  const [isEducation, setEducation] = useState({});
  const [isExps, setExps] = useState({});
  const [isRefs, setRefs] = useState({});
  const [isDataSkills, setDataSkills] = useState([]);
  const [corporateCulture, setCorporateCulture] = useState([]);
  const [codeData, setCodeData] = useState({});
  const [emailUser, setEmailUser] = useState('');
  const [phoneUser, setPhoneUser] = useState('');
  const [registerData, setRegisterData] = useContext(RegisterDataContext);
  const [isStep6, setIsStep6] = useState(false);
  const [talentData, setTalentData] = useState({});
  const { updateUserData, userData } = useContext(UserContext);
  const [langScreen, setLangScreen] = useState(false);
  const [history, setHistory] = useState([]);
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [loadEmailCode, setLoadEmailCode] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [gender, setGender] = useState('');
  const [prevHiringData, setPrevHiringData] = useState({});

  useEffect(() => {
    setPrevHiringData({
      hiring: hiring,
      salary: salary,
      modHiring: modHiring,
    });
  }, [hiring, salary, modHiring]);

  useEffect(() => {
    console.log(prevHiringData);
  }, [prevHiringData]);

  const apiClient = axios.create({
    baseURL: 'https://api-stg.empleosmarketingdigital.com/v1/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${registerData.token}`,
    },
  });

  useEffect(() => {
    console.log(registerData);
  }, [registerData]);

  useEffect(() => {
    console.log(talentData);
  }, [talentData]);

  useEffect(() => {
    if (step !== 8) {
      return;
    }

    const translateValues = {
      contract: {
        'Relación de dependencia': 'IN_RELATION',
        'Freelance / Autónomo': 'FREELANCE',
        'Prácticas / Pasantía': 'PRACTICE',
      },
      duration: {
        'Puesto fijo': 'LONG_TERM',
        Proyecto: 'PROJECT',
        Temporal: 'PERIOD',
      },
      dedication: {
        'Full Time': 'FULL_TIME',
        'Part Time': 'PART_TIME',
        'Horas estipuladas': 'SPECIFIC_HOURS',
        Turnos: 'SHIFTS',
        Voluntariado: 'VOLUNTEERING',
      },
      modality: {
        Presencial: 'ON_SITE',
        Híbrido: 'HIBRYD',
        'Remoto global': 'GLOBAL_REMOTE',
        'Remoto nacional': 'LOCAL_REMOTE',
        'Horas estipuladas': 'TIMEZONE_REMOTE',
      },
      payment: {
        'Por proyecto': 'PROJECT',
        'Pago mensual': 'MONTHLY',
        'Pago anual': 'YEARLY',
        Indiferente: 'INDIFERENT',
      },
    };

    const fetchData = async () => {
      try {
        const hiringType = hiring[1];

        let fromDate = null;
        let toDate = null;

        if (hiringType.startsWith('Temporal')) {
          const datePattern =
            /(\d{2})\/(\d{2})\/(\d{2}) - (\d{2})\/(\d{2})\/(\d{2})/;
          const match = hiringType.match(datePattern);

          if (match) {
            fromDate = `20${match[3]}-${match[2]}-${match[1]}T00:00:00.000Z`;
            toDate = `20${match[6]}-${match[5]}-${match[4]}T00:00:00.000Z`;
          }
        }

        const response = await apiClient.post('/talent-work-preferences', {
          id: talentData.id,
          contract: translateValues.contract[hiring[0]],
          duration: translateValues.duration[hiring[1]],
          from_date: fromDate,
          to_date: toDate,
          dedication: translateValues.dedication[hiring[2]],
          modality: translateValues.modality[modHiring],
          payment: translateValues.payment[salary[0]],
          currency: salary[2],
          min_salary: '0',
          max_salary: salary[1],
        });
        console.log('Info', response.data);
        console.log(hiring);
      } catch (error) {
        toast.error(
          'Error al registrar usuario:',
          error.response.data.toString()
        );
      }
    };

    if (step === 8) {
      fetchData();
    }
  }, [step]);

  const refs = {
    emailInputRef: useRef(),
    verificationCodeRef: useRef(),
    dropdownRef: useRef(),
    textRef: useRef(),
    nameRef: useRef(),
    calendarRef: useRef(),
    passwordRef: useRef(),
    confirmPasswordRef: useRef(),
    telRef: useRef(),
    verificationCodeRefTel: useRef(),
  };

  const optionToCountryCode = {
    ARS: 'AR',
    US: 'US',
    BR: 'BR',
  };

  const handleOptionChange = (option) => {
    selectedOption === option
      ? setSelectedOption('')
      : setSelectedOption(option);
    if (option) {
      setIsDropdownValidated(true);
    }
    console.log('la opcion es: ', option);
    const countryCode = optionToCountryCode[option];
    const newPhonePrefix = countryCodes[countryCode] || '';
    setPhoneNumber(newPhonePrefix);
  };

  useEffect(() => {
    if (step === 2) {
      const timer = setTimeout(() => {
        setStep(3);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [step]);

  const goBack = useCallback(() => {
    if (
      step === 'profileCardModule' ||
      step === 'langModule' ||
      step === 'HardSkillsModule' ||
      step === 'SoftSkillModule' ||
      step === 'HiringModule'
    ) {
      const lastStep = history[history.length - 1];
      setStep(lastStep);
      setHistory((currentHistory) => currentHistory.slice(0, -1));
    } else if (step > 1) {
      setStep((currentStep) => currentStep - 1);
      setHistory((currentHistory) => currentHistory.slice(0, -1));
    } else {
      window.history.back();
    }
  }, [step, history]);

  const goProfileCardModule = useCallback(() => {
    setHistory((currentHistory) => [...currentHistory, step]);
    setStep('profileCardModule');
  }, [step]);

  const goLangModule = useCallback(() => {
    setHistory((currentHistory) => [...currentHistory, step]);
    setStep('langModule');
  }, [step]);

  const goHardModule = useCallback(() => {
    setHistory((currentHistory) => [...currentHistory, step]);
    setStep('HardSkillsModule');
  }, [step]);

  const goSoftSkillModule = useCallback(() => {
    setHistory((currentHistory) => [...currentHistory, step]);
    setStep('SoftSkillModule');
  }, [step]);

  const goHiringModule = useCallback(() => {
    setHistory((currentHistory) => [...currentHistory, step]);
    setStep('HiringModule');
  }, [step]);

  const goNext = useCallback(() => {
    if (
      step === 0 ||
      step === 1 ||
      step === 3 ||
      step === 4 ||
      step === 5 ||
      step === 6 ||
      step === 7 ||
      step === 8 ||
      step === 9 ||
      step === 10 ||
      step === 11 ||
      step === 12 ||
      step === 13 ||
      step === 14 ||
      step === 15 ||
      step === 16
    ) {
      setStep(step + 1);
    }
  }, [step]);

  const gobackStep = useCallback(() => {
    setLangScreen(true);
    setStep(9);
    console.log(langScreen);
  }, []);

  const gobackStepAboutMe = useCallback(() => {
    setStep(11);
  }, []);

  const goBackCorporateCulture = useCallback(() => {
    setStep(15);
  }, []);

  const validatePasswords = async () => {
    const password = refs.passwordRef.current.getValue();
    const confirmPassword = refs.confirmPasswordRef.current.getValue();
    const confirmPasswordValidation =
      await refs.confirmPasswordRef.current.validateExternal();

    if (password !== confirmPassword) {
      refs.confirmPasswordRef.current.setErrorFromOutside(
        'Las contraseñas no coinciden'
      );
      return false;
    } else if (!confirmPasswordValidation.isValid) {
      refs.confirmPasswordRef.current.setErrorFromOutside(
        'Este campo es requerido'
      );
      return false;
    }

    return true;
  };

  const handleEmailSend = async () => {
    const emailValidation = await refs.emailInputRef.current.validateExternal();
    if (emailValidation.isValid) {
      const email = refs.emailInputRef.current.getValue();
      setEmailUser(email);
      console.log(`el Mail es: ${email}`);
      setLoadEmailCode(true);
      try {
        const response = await apiClient.post('/auth/validate-email', {
          email,
        });
        console.log('Código de validación enviado:', response.data);
        setCodeData(response.data);
        setStep(1);
        setLoadEmailCode(false);
      } catch (error) {
        toast.error('Error en el registro: ' + error.response.data.toString());
        setLoadEmailCode(false);
      }
    } else {
      /* */
    }
  };

  const handleEmailReSend = async () => {
    try {
      const response = await apiClient.post('/auth/validate-email', {
        email: emailUser,
      });
      toast.success('Código de validación reenviado');
      setCodeData(response.data);
      setStep(1);
    } catch (error) {
      toast.error('Error en el registro: ' + error.response.data.toString());
    }
  };

  const validateCode = (code) => {
    console.log('el codigo es:', codeData.validation_code);
    return code === codeData.validation_code;
  };

  const handleVerificationCode = async () => {
    const verificationCodeValidation =
      await refs.verificationCodeRef.current.validateExternal();
    if (verificationCodeValidation.isValid) {
      const valueInput = refs.verificationCodeRef.current.getValue();
      if (!validateCode(valueInput)) {
        refs.verificationCodeRef.current.setErrorFromOutside(
          'El código ingresado no coincide o ha expirado. Solicita uno nuevo y asegúrate de usarlo pronto.'
        );
        return;
      }
      console.log(`el codigo es: ${valueInput}`);
      setStep(2);
    } else {
      /* */
    }
  };

  function calculateAge(birthDateString) {
    const birthDate = new Date(birthDateString);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  const handleVerificationStep3 = async () => {
    const textValidation = await refs.textRef.current.validateExternal();
    const dropdownValidation =
      await refs.dropdownRef.current.validateExternal();
    const nameValidation = await refs.nameRef.current.validateExternal();
    const calendarValidation =
      await refs.calendarRef.current.validateExternal();
    const passwordValidation =
      await refs.passwordRef.current.validateExternal();
    const confirmPasswordValidation =
      await refs.confirmPasswordRef.current.validateExternal();
    const passwordsMatch = validatePasswords();

    const passwordsMatchValue = await passwordsMatch;
    if (
      textValidation.isValid &&
      dropdownValidation.isValid &&
      nameValidation.isValid &&
      calendarValidation.isValid &&
      passwordValidation.isValid &&
      confirmPasswordValidation.isValid &&
      passwordsMatchValue
    ) {
      const textValue = refs.textRef.current.getValue();
      const dropdownValue = refs.dropdownRef.current.getValue();
      const nameValue = refs.nameRef.current.getValue();
      const selectedDate = refs.calendarRef.current.getValue();
      const passwordValue = refs.passwordRef.current.getValue();
      const confirmPasswordValue = refs.confirmPasswordRef.current.getValue();

      const age = calculateAge(selectedDate);
      setAge(age);
      setName(nameValue);
      setSurname(textValue);

      console.log(`el texto es: ${textValue}`);
      console.log(`el dropdown es: ${dropdownValue}`);
      console.log(`el nombre es: ${nameValue}`);
      console.log(`La edad de la persona es: ${age} años.`);
      console.log(`la contraseña es: ${passwordValue}`);
      console.log(`la confirmacion de contraseña es: ${confirmPasswordValue}`);

      const email = emailUser;
      const phone = phoneUser;

      try {
        const response = await apiClient.post('/auth/register', {
          f_name: nameValue,
          l_name: textValue,
          email: email,
          password: passwordValue,
          phone: phone,
          gender: dropdownValue,
          language: 'es',
          role: 'TALENT',
          birth_date: selectedDate,
        });

        console.log('Registro exitoso:', response.data);
        setRegisterData(response.data);
        setStep(4);
      } catch (error) {
        toast.error('Error en el registro: ' + error.response.data.toString());
      }
    } else {
      if (!textValidation.isValid) {
        refs.textRef.current.setErrorFromOutside('Este campo es requerido');
      }
    }
  };

  const [isCodeSent, setIsCodeSent] = useState(false);

  const handleVerificationStep4 = async () => {
    const telValue = refs.telRef.current.getValue();
    const telValidation = await refs.telRef.current.validateExternal();

    if (!isCodeSent) {
      // Si el código no ha sido enviado, validar el teléfono y enviar el código.
      if (telValidation.isValid) {
        if (telValue.length < 10) {
          refs.telRef.current.setErrorFromOutside(
            'El número de teléfono debe tener 10 dígitos.'
          );
          return;
        }

        try {
          const response = await apiClient.post('/auth/validate-phone', {
            phone: phoneNumber,
          });
          setPhoneUser(phoneNumber);
          setCodeData(response.data);
          setIsCodeSent(true);
          setShowVerificationCode(true);
          toast.success('Código de validación enviado');
        } catch (error) {
          toast.error(
            'Error al enviar correo para validación:',
            error.response.data.toString()
          );
        }
      } else {
        refs.telRef.current.setErrorFromOutside('Este campo es requerido');
      }
    } else {
      // Si el código ya fue enviado, proceder a la validación del código
      const verificationCodeValue =
        refs.verificationCodeRefTel.current.getValue();
      const verificationCodeValidation =
        await refs.verificationCodeRefTel.current.validateExternal();

      if (verificationCodeValidation.isValid) {
        if (!validateCode(verificationCodeValue)) {
          refs.verificationCodeRefTel.current.setErrorFromOutside(
            'El código ingresado no coincide o ha expirado. Solicita uno nuevo y asegúrate de usarlo pronto.'
          );
          return;
        }

        console.log(`El teléfono es: ${telValue}`);
        console.log(`El código de verificación es: ${verificationCodeValue}`);
        setStep(5); // Proceder al siguiente paso
      } else {
        refs.verificationCodeRefTel.current.setErrorFromOutside(
          'Este campo es requerido'
        );
      }
    }
  };

  const handleTelSend = async () => {
    const telValidation = await refs.telRef.current.validateExternal();
    setShowVerificationCode(false);
    if (telValidation.isValid) {
      const telValue = refs.telRef.current.getValue();
      if (telValue.length < 10) {
        refs.telRef.current.setErrorFromOutside(
          'El número de teléfono debe tener 10 dígitos.'
        );
        return;
      }
      try {
        const response = await apiClient.post('/auth/validate-phone', {
          phone: phoneNumber,
        });
        console.log('Código de validación enviado:', response.data);
        setPhoneUser(phoneNumber);
        setCodeData(response.data);
        setShowVerificationCode(true);
      } catch (error) {
        toast.error(
          'Error al enviar correo para validación:',
          error.response.data.toString()
        );
      }
    }
  };

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const gotostep6Handler = () => {
    setStep(6);
    setIsStep6(true);
  };

  useEffect(() => {
    console.log('profileData', profileData);
    setTalentData(profileData);
    console.log('userData', userData);
    setRegisterData({
      user: profileData,
    });
    setAge(calculateAge(profileData.birth_date));
    setName(profileData.f_name);
    setSurname(profileData.l_name);
    setEmailUser(profileData.email);
    setPhoneUser(profileData.phone);
    setGender(profileData.gender);
  }, [profileData]);

  useEffect(() => {
    if (!isStep6) {
      return;
    }

    const postData = async () => {
      try {
        const response = await apiClient.post('/talents', {
          profile_image: imgProfile,
          visibility: visibility,
          phone_contact: phoneUser !== '',
          email_contact: emailUser !== '',
          seniority: seniority,
          city: '',
          country: pos,
          coordinates: [-27.4610099, -59.000908],
          about_me: isAboutMe,
        });
        console.log('Info', response.data);
        setTalentData(response.data);

        updateUserData({
          user: registerData.user,
          talent: response.data,
        });
      } catch (error) {
        toast.error(
          'Error al registrar usuario:',
          error.response.data.toString()
        );
      }
    };
    postData();
  }, [isStep6]);

  const gobackStepDataSkills = () => {
    setStep(13);
  };

  useEffect(() => {
    if (step !== 12) {
      return;
    }
    console.log('educacion', isEducation);
  }, [step]);

  return (
    <Container
      fluid
      style={{
        height: 'auto',
        paddingTop: '50px',
        position: 'relative',
        minHeight: '100vh',
      }}
      className={
        step === 6 ||
        step === 8 ||
        step === 10 ||
        step === 12 ||
        step === 14 ||
        step === 16
          ? 'bgopacity'
          : ''
      }
    >
      <RenderStep
        step={step}
        setStep={setStep}
        refs={refs}
        codeGenerationTime={codeGenerationTime}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        isDropdownValidated={isDropdownValidated}
        setIsDropdownValidated={setIsDropdownValidated}
        pos={pos}
        setPos={setPos}
        seniority={seniority}
        setSeniority={setSeniority}
        imgProfile={imgProfile}
        setImgProfile={setImgProfile}
        especialitation={especialitation}
        setEspecialitation={setEspecialitation}
        visibility={visibility}
        setVisibility={setVisibility}
        goBack={goBack}
        goNext={goNext}
        handleOptionChange={handleOptionChange}
        handleEmailSend={handleEmailSend}
        handleEmailReSend={handleEmailReSend}
        handleVerificationCode={handleVerificationCode}
        handleVerificationStep3={handleVerificationStep3}
        handleVerificationStep4={handleVerificationStep4}
        handlePhoneChange={handlePhoneChange}
        gotostep6Handler={gotostep6Handler}
        handleTelSend={handleTelSend}
        showVerificationCode={showVerificationCode}
        name={name}
        age={age}
        surname={surname}
        setHiring={setHiring}
        hiring={hiring}
        salary={salary}
        setSalary={setSalary}
        modHiring={modHiring}
        setModHiring={setModHiring}
        isHardSkills={isHardSkills}
        isLanguage={isLanguage}
        isTechSkills={isTechSkills}
        setHardSkills={setHardSkills}
        setLenguage={setLenguage}
        setTechSkills={setTechSkills}
        gobackStep={gobackStep}
        isAboutMe={isAboutMe}
        isCerts={isCerts}
        isEducation={isEducation}
        isExps={isExps}
        isRefs={isRefs}
        setAboutMe={setAboutMe}
        setEducation={setEducation}
        setCerts={setCerts}
        setExps={setExps}
        setRefs={setRefs}
        setDataSkills={setDataSkills}
        isDataSkills={isDataSkills}
        goBackStepAboutMe={gobackStepAboutMe}
        gobackStepDataSkills={gobackStepDataSkills}
        setCorporateCulture={setCorporateCulture}
        corporateCulture={corporateCulture}
        goBackCorporateCulture={goBackCorporateCulture}
        setLangScreen={langScreen}
        goProfileCardModule={goProfileCardModule}
        goLangModule={goLangModule}
        goHardModule={goHardModule}
        goSoftSkillModule={goSoftSkillModule}
        loadEmailCode={loadEmailCode}
        setProfileData={setProfileData}
        goHiringModule={goHiringModule}
        prevHiringData={prevHiringData}
      />
    </Container>
  );
};

export default TalentoRegister;
