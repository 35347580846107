import React from 'react';
import CompanyReusableComponent from '../../../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import { Col, Form } from 'react-bootstrap';
import ButtonComponent from '../../../../../Shared/ButtonComponent';

const Case3Step0 = ({
  goBack,
  selectedModality,
  handleModalityChange,
  handleTimezoneChange,
  handleNextStep,
  timezone,
}) => {
  return (
    <CompanyReusableComponent goBack={goBack} company={'WhiteScreen'}>
      <Col xs={12} className="gap-after my-5">
        <span>Por favor, especifica modalidad de contratación.</span>
      </Col>
      <Col xs={12} className="gap-after" style={{ height: '100%' }}>
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            gap: '2rem',
            fontWeight: 'bold',
          }}
          className="gap-after"
        >
          <Form.Check
            type="radio"
            id="on-site"
            name="modality"
            label="Presencial"
            value="ON_SITE"
            checked={selectedModality === 'ON_SITE'}
            onChange={handleModalityChange}
          />
          <Form.Check
            type="radio"
            id="hibryd"
            name="modality"
            label="Híbrido"
            value="HIBRYD"
            checked={selectedModality === 'HIBRYD'}
            onChange={handleModalityChange}
          />
          <Form.Check
            type="radio"
            id="global-remote"
            name="modality"
            label="Remoto Global"
            value="GLOBAL_REMOTE"
            checked={selectedModality === 'GLOBAL_REMOTE'}
            onChange={handleModalityChange}
          />
          <Form.Check
            type="radio"
            id="local-remote"
            name="modality"
            label="Remoto Local"
            value="LOCAL_REMOTE"
            checked={selectedModality === 'LOCAL_REMOTE'}
            onChange={handleModalityChange}
          />
          <Form.Check
            type="radio"
            id="timezone-remote"
            name="modality"
            label="Remoto con Zona Horaria"
            value="TIMEZONE_REMOTE"
            checked={selectedModality === 'TIMEZONE_REMOTE'}
            onChange={handleModalityChange}
          />
          {selectedModality === 'TIMEZONE_REMOTE' && (
            <Form.Group>
              <Form.Label>Zona Horaria</Form.Label>
              <Form.Control
                type="text"
                value={timezone || ''}
                onChange={handleTimezoneChange}
              />
            </Form.Group>
          )}
        </Form>
      </Col>
      <Col xs={12} className="gap-after">
        <ButtonComponent
          text="Continuar"
          version={selectedModality ? 'fill' : 'grey'}
          large
          onClick={handleNextStep}
          disabled={!selectedModality}
          isHover
        />
      </Col>
    </CompanyReusableComponent>
  );
};

export default Case3Step0;
