import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ButtonComponent from '../../../Shared/ButtonComponent';

const ProfilePersSixRenderer = ({ handleConfirm }) => {
    return (
        <Container fluid>
            <Row>
                <Col xs={12} className="gap-after d-flex flex-column mb-5">
                    <span style={{ color: '#003EFF', fontSize: '1.5rem', fontWeight: 'bolder' }}>¡Buen trabajo, has llegado lejos!</span>
                    <span style={{ color: '#003eff' }}>El test EMD Cultura Empresarial, simplifica la búsqueda del match ideal según valores y aspiraciones.</span>
                </Col>
                <Col xs={12} className="gap-after text-left mt-5">
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>¿Cómo funciona?</span>
                </Col>
                <Col xs={12} className="gap-after d-flex flex-column text-left">
                    <span className="mb-2">EDM enfoca el examen de cultura empresarial para ir más allá de las habilidades técnicas y fortalecer la colaboración. Con esto, detectamos alineaciones culturales, fortaleciendo las relaciones laborales. Este enfoque va más allá de competencias, enfocándose en la armonía cultural de las dos partes. Buscamos consolidar lazos laborales donde todos prosperen y alcancen sus metas.</span>
                    <span className="mb-4">Se detallará las fases del proceso:</span>
                    <li className="mb-2">En un entorno laboral</li>
                    <li className="mb-2">Resolución de conflictos</li>
                    <li className="mb-2">Motivación y Trabajo en Equipo</li>
                    <li className="mb-2">Comunicación y liderazgo</li>
                </Col>
                <Col xs={12} className="gap-after d-flex flex-column text-left">
                    <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>Después de completar el test, no olvides que puedes revisar y editar si lo consideras necesario</span>
                </Col>
            </Row>
            <Row className="w-100">
                <Col xs={12} className="gap-after mb-3">
                    <ButtonComponent text="Confirmar" large onClick={handleConfirm} isHover />
                </Col>
            </Row>
        </Container>
    );
};

export default ProfilePersSixRenderer;
