import React, { useEffect } from "react";
import "./InfoStats.css";
import TalentIcon from "../../../../Assets/TalentIcon.svg";
import CompanyIcon from "../../../../Assets/CompanyIcon.svg";
import RecluterIcon from "../../../../Assets/RecluterIcon.svg";

const InfoStats = ({
    isTalent,
    isCompany,
    isRecluter,
    data
}) => {
    useEffect(() => {
        console.log('InfoStats', data);
    }, [data]);

    // Extrae la data necesaria del objeto data según el contexto
    const talentData = data?.users?.talents;
    const companyData = data?.users?.companies;
    const recruiterData = data?.users?.recruiters;

    switch (true) {
        case isTalent:
            return (
                <div className="box__info">
                    <div className="box__info__content">
                        <span className="title__box__info">Usuarios Talentos</span>
                        <span>{talentData || 0}</span>
                    </div>
                    <div className="box__info__icon">
                        <img src={TalentIcon} alt="Talent Icon" />
                    </div>
                </div>
            );
        case isCompany:
            return (
                <div className="box__info">
                    <div className="box__info__content">
                        <span className="title__box__info">Usuarios Empresas</span>
                        <div className="d-flex" style={{ gap: '10px' }}>
                            {Object.entries(companyData || {}).map(([key, value]) => (
                                <div key={key} className="d-flex" style={{ flexDirection: 'column-reverse' }}>
                                    <span style={{ fontSize: '10px' }}>{key}</span>
                                    <span>{value}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="box__info__icon">
                        <img src={CompanyIcon} alt="Company Icon" />
                    </div>
                </div>
            );
        case isRecluter:
            return (
                <div className="box__info">
                    <div className="box__info__content">
                        <span className="title__box__info">Reclutadores</span>
                        <span>{recruiterData || 0}</span>
                    </div>
                    <div className="box__info__icon">
                        <img src={RecluterIcon} alt="Recluter Icon" />
                    </div>
                </div>
            );
        default:
            return null;
    }
};

export default InfoStats;
