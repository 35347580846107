import React, { useState } from "react";
import './ProfileCardCompanyComponent.css';
import LocationIcon from "../../../../../Assets/locationIcon.svg";
import isEditIcon from "../../../../../Assets/isEditIcon.svg";
import CompanySector from "../../../../../Assets/CompanySector.svg";
import CompanySize from "../../../../../Assets/CompanySize.svg";
import { Collapse } from 'react-bootstrap';
import ReusableSlider from "../ReusableSlider/ReusableSlider";

const sliderConfig = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    cssEase: "linear",
    slidesToScroll: 3,
    centerMode: true,
};

const sliderData = [
    {
        name: 'Slide 1',
    },
    {
        name: 'Slide 2',
    },
    {
        name: 'Slide 3',
    },
    {
        name: 'Slide 1',
    },
    {
        name: 'Slide 2',
    },
    {
        name: 'Slide 3',
    }
];

const ProfileCardCompanyComponent = ({
    data = {
        name: "",
        logo: "",
        country: "",
        companyWorkArea: "",
        size: "",
        description: "",
    },
    setStep,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="profile-card-company">
            <div className="profile-card-company__locate">
                <img src={LocationIcon} alt="Location Icon" />
                <span>{data.country}</span>
            </div>
            <div className="profile-card-company__logo__name">
                <div style={{ display: "flex", alignItems: "center", gap: '2rem' }}>
                    <img src={data.logo} alt="Company Logo" />
                    <span>{data.name}</span>
                </div>
                <button
                    onClick={() => setStep('editProfile')}
                > <img src={isEditIcon} alt="Edit Icon" /></button>
            </div>
            <div className="profile-card-company__sector">
                <img src={CompanySector} alt="Company Sector" />
                <span>{data.companyWorkArea}</span>
            </div>
            <div className="profile-card-company__size">
                <div>
                    <img src={CompanySize} alt="Company Sector" />
                    <span>{data.size}</span>
                </div>
                <button className={
                    open ? "profile-card-company__size__button" : "profile-card-company__size__button--rotate"
                } onClick={() => setOpen(!open)} >{'>'}</button>
            </div>
            <Collapse in={open}>
                <div id="example-collapse-text" style={{
                    maxWidth: '100%',
                }}>
                    <ReusableSlider config={sliderConfig} data={sliderData} />

                    <div>
                        <span>Descripción:</span>{data.description}
                    </div>

                </div>
            </Collapse>
        </div>
    );
};

export default ProfileCardCompanyComponent;