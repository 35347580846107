import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Arrow from '../../Assets/Arrow.svg';
import {
  emailSchema,
  passwordSchema,
  codeSchema,
  codeSchemaSix,
  textSchema,
  dropdownSchema,
  dateSchema,
} from '../../validations/validations';
import {
  eyeIcon,
  eyeOffIcon,
  infoIcon,
  checkIcon,
  errorEyeIcon,
  errorOffEyeIcon,
  calendarIcon,
} from './iconsComponent';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './InputComponent.css';

const InputComponent = forwardRef(
  (
    {
      type,
      placeholder,
      onValidation,
      codeGenerationTime,
      isDropdown,
      isCalendar,
      options = [],
      onOptionChange,
      iconOptions,
      externalValue,
      onChange,
      readOnly,
      disabled,
      autoComplete,
      onBlur,
      isValidData,
      isBorderBlue,
      isMedium,
    },
    ref
  ) => {
    const [value, setValue] = useState(iconOptions ? options[0] : '');
    const [error, setError] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedIconIndex, setSelectedIconIndex] = useState(0);
    const [isCalendarValid, setIsCalendarValid] = useState(false);

    useEffect(() => {
      if (isCalendar && externalValue) {
        setSelectedDate(externalValue);
      } else if (!isCalendar && externalValue !== undefined) {
        setValue(externalValue);
      }
    }, [externalValue, isCalendar]);

    useEffect(() => {
      if (isValidData) {
        setIsValid(true);
      }
    }, [isValidData]);

    const handleChange = (event) => {
      setValue(event.target.value);
      onChange?.(event);
      setIsValid(false);
      setIsCalendarValid(false);
    };

    const getSchema = (type, isDropdown, isCalendar) => {
      if (isCalendar) return dateSchema;
      else if (isDropdown) return dropdownSchema;
      switch (type) {
        case 'email':
          return emailSchema;
        case 'password':
          return passwordSchema;
        case 'code':
          return codeSchema;
        case 'code6':
          return codeSchemaSix;
        case 'text':
          return textSchema;
        case 'number':
          return yup.number();
        default:
          return yup.string();
      }
    };

    const validate = useCallback(
      async (value) => {
        try {
          const schema = getSchema(type, isDropdown, isCalendar);
          const validationOptions = { context: { codeGenerationTime } };
          await schema.validate(value, validationOptions);
          setError('');
          setIsValid(true);
          if (isCalendar) setIsCalendarValid(true);
          return true;
        } catch (error) {
          setError(error.message);
          setIsValid(false);
          setIsCalendarValid(false);
          return false;
        }
      },
      [type, isDropdown, codeGenerationTime, isCalendar]
    );

    useImperativeHandle(
      ref,
      () => ({
        async validateExternal() {
          const isValid = isCalendar
            ? await validate(selectedDate)
            : await validate(value);
          onValidation?.(isValid, isCalendar ? selectedDate : value);
          setIsValid(isValid);
          if (isValid) setIsCalendarValid(true);
          return { isValid, value: isCalendar ? selectedDate : value };
        },
        setErrorFromOutside(errorMessage) {
          setError(errorMessage);
          setIsValid(false);
          setIsCalendarValid(false);
        },
        getValue() {
          return isCalendar ? selectedDate : value;
        },
        reset() {
          setValue('');
          setSelectedDate(null);
          setError('');
          setIsValid(false);
          setIsCalendarValid(false);
        },
      }),
      [validate, value, selectedDate, isCalendar, onValidation]
    );

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const passwordInputValue = value;

    let passwordIcon = null;
    if (!isValid && passwordInputValue !== '') {
      passwordIcon = showPassword ? eyeOffIcon : eyeIcon;
    }
    if (error) {
      passwordIcon = showPassword ? errorOffEyeIcon : errorEyeIcon;
    }

    const getStyles = () => {
      if (error) {
        return {
          borderColor: '#B91C1C',
          color: '#B91C1C',
          background: 'transparent',
        };
      }
      if (isValid) {
        return {
          borderColor: '#003EFF',
          backgroundColor: 'transparent',
        };
      }
      if (disabled) {
        return {
          borderColor: '#f1f1f1',
          color: '#f1f1f1',
          backgroundColor: 'transparent',
          cursor: 'not-allowed',
        };
      }
      return {
        borderColor: '#E2E2E2',
        color: '#000000',
        backgroundColor: 'transparent',
      };
    };

    const styles = {
      ...getStyles(),
      width: '100%',
      padding: '10px',
      borderRadius: '8px',
      borderWidth: '1px',
      borderStyle: 'solid',
      position: 'relative',
      ...(isBorderBlue ? { borderColor: '#003EFF' } : {}),
    };

    const iconStyle = {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      background: 'transparent',
      border: 'none',
      color: error ? '#B91C1C' : '',
    };

    const containerStyle = error
      ? {
          position: 'relative',
          marginBottom: '25px',
          transition: 'all 0.5s',
          width: '100%',
        }
      : {
          position: 'relative',
          width: '100%',
        };

    const errorStyle = {
      position: 'absolute',
      color: 'rgba(185, 28, 28, 0.25)',
      display: 'flex',
      marginTop: '4px',
      fontSize: '0.6rem',
      top: '100%',
      left: '0',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const getButtonStyles = () => {
      const baseStyle = {
        ...getStyles(),
        width: '100%',
        padding: '10px',
        borderRadius: '8px',
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: iconOptions ? '' : 'space-between',
        alignItems: 'center',
        position: 'relative',
      };
      return baseStyle;
    };

    const handleDropdownChange = (selectedOption, index) => {
      setValue(selectedOption);
      setSelectedIconIndex(index);
      setDropdownOpen(false);
      onOptionChange?.(selectedOption);
    };

    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        toggleDropdown();
      }
    };

    const chevronDownIcon = (
      <img src={Arrow} alt="arrow" style={{ transform: 'rotate(180deg)' }} />
    );
    const chevronUpIcon = <img src={Arrow} alt="arrow" />;
    const renderDropdown = () => {
      return (
        <>
          <div style={{ position: 'relative' }}>
            <button
              style={getButtonStyles()}
              onClick={toggleDropdown}
              onKeyDown={handleKeyDown}
            >
              {iconOptions && iconOptions.icons[selectedIconIndex] ? (
                <>
                  {React.cloneElement(iconOptions.icons[selectedIconIndex], {
                    style: { marginRight: '10px', minHeight: '20px' },
                  })}
                  <span className="optionW">{value}</span>
                </>
              ) : (
                <span style={{ color: value ? 'black' : '#e2e2e2' }}>
                  {value || placeholder}
                </span>
              )}
              <div
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
                className="chevron-drop"
              >
                {dropdownOpen ? chevronUpIcon : chevronDownIcon}
              </div>
            </button>
            {dropdownOpen && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  zIndex: 1000,
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {options.map((option, index) => (
                  <label
                    htmlFor={!iconOptions ? `dropdown-${option}` : undefined}
                    key={index}
                    style={{
                      padding: '10px 20px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => handleDropdownChange(option, index)}
                  >
                    {iconOptions ? (
                      <>
                        {iconOptions.icons[index] &&
                          React.cloneElement(iconOptions.icons[index], {
                            style: { marginRight: '10px', minHeight: '20px' },
                          })}
                        <span className="optionW">{option}</span>
                      </>
                    ) : (
                      <>
                        <input
                          type="radio"
                          name="dropdown"
                          value={option}
                          checked={value === option}
                          onChange={() => handleDropdownChange(option)}
                          style={{ marginRight: '10px' }}
                        />
                        {option}
                      </>
                    )}
                  </label>
                ))}
              </div>
            )}
          </div>
          {error && (
            <div style={errorStyle}>
              {infoIcon}
              <span>{error}</span>
            </div>
          )}
          {isValid && <div style={iconStyle}>{checkIcon}</div>}
        </>
      );
    };

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const years = [];

    for (let i = 1900; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }

    const getYear = (date) => {
      return date.getFullYear();
    };

    const getMonth = (date) => {
      return months[date.getMonth()];
    };

    const renderCalendar = () => {
      return (
        <>
          <div
            className={`react-datepicker-wrapper ${
              isCalendarValid ? 'valid-calendar' : ''
            }`}
          >
            <DatePicker
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                    className="yearSelect"
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <div
                    style={{
                      display: 'flex',
                      gap: '5px',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                      style={{
                        border: 'none',
                        background: 'none',
                        fontSize: '20px',
                        color: '#003EFF',
                      }}
                    >
                      {'<'}
                    </button>

                    <select
                      value={getMonth(date)}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                      className="monthSelect"
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                      style={{
                        border: 'none',
                        background: 'none',
                        fontSize: '20px',
                        color: '#003EFF',
                      }}
                    >
                      {'>'}
                    </button>
                  </div>
                </div>
              )}
              withPortal
              selected={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
                validate(date);
              }}
              placeholderText={placeholder}
              dateFormat="dd/MM/yyyy"
              showIcon={true}
              customInput={
                <input
                  type={
                    type === 'password' && !showPassword ? 'password' : 'text'
                  }
                  placeholder={placeholder}
                  value={value}
                  onChange={handleChange}
                  style={styles}
                  className="myInput"
                  ref={ref}
                  autoComplete={autoComplete}
                />
              }
              icon={calendarIcon}
              style={styles}
              showMonthDropdown
              className={
                error
                  ? 'myCalendar errorCalendar'
                  : isValid
                  ? 'myCalendar validCalendar'
                  : 'myCalendar'
              }
            />
          </div>
          {error && (
            <div style={errorStyle}>
              {infoIcon}
              <span>{error}</span>
            </div>
          )}
          {isValid && <div style={iconStyle}>{checkIcon}</div>}
        </>
      );
    };

    return (
      <div style={containerStyle}>
        {isCalendar ? (
          renderCalendar()
        ) : isDropdown ? (
          renderDropdown()
        ) : (
          <>
            <input
              type={
                type === 'number'
                  ? 'number'
                  : type === 'password' && !showPassword
                  ? 'password'
                  : 'text'
              }
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              style={styles}
              className="myInput"
              ref={ref}
              autoComplete={autoComplete}
              readOnly={readOnly}
              disabled={disabled}
              onBlur={onBlur}
            />
            {type === 'password' && (
              <button style={iconStyle} onClick={toggleShowPassword}>
                {passwordIcon}
              </button>
            )}
            {isValid && <div style={iconStyle}>{checkIcon}</div>}
            {error && (
              <div style={errorStyle}>
                {infoIcon}
                <span>{error}</span>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);

InputComponent.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onValidation: PropTypes.func,
  codeGenerationTime: PropTypes.instanceOf(Date),
  isDropdown: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  onOptionChange: PropTypes.func,
  isCalendar: PropTypes.bool,
  iconOptions: PropTypes.object,
  externalValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default InputComponent;
