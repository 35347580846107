import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { RegisterDataContext } from '../../../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/data-boxes';

export const useDataBoxesApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;

    // Create a data box
    const createDataBox = async (dataBoxData) => {
        try {
            const response = await axios.post(apiBaseUrl, dataBoxData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Data box created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating data box:', error.response ? error.response.data : error);
            throw error;
        }
    };

    // Retrieve all data boxes
    const getDataBoxes = async () => {
        try {
            const response = await axios.get(apiBaseUrl, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Data boxes retrieved:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error retrieving data boxes:', error);
            return [];
        }
    };

    // Retrieve a specific data box
    const getDataBox = async (dataBoxId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${dataBoxId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Data box retrieved:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error retrieving data box:', error);
            return null;
        }
    };

    // Update a data box
    const updateDataBox = async (dataBoxId, dataBoxData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${dataBoxId}`, dataBoxData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Data box updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating data box:', error.response ? error.response.data.toString() : error);
            throw error;
        }
    };

    // Delete a data box
    const deleteDataBox = async (dataBoxId) => {
        try {
            const response = await axios.delete(`${apiBaseUrl}/${dataBoxId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Data box deleted');
            return response.data;
        } catch (error) {
            toast.error('Error deleting data box:', error.response ? error.response.data : error);
            throw error;
        }
    };

    return {
        createDataBox,
        getDataBoxes,
        getDataBox,
        updateDataBox,
        deleteDataBox
    };
};
