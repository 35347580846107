import React, { useState, useEffect } from "react";
import "./PersonalInfoCardComponent.css";
import ButtonComponent from "../ButtonComponent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import isEditIcon from "../../../Assets/isEditIcon.svg";

const PersonalInfoCardComponent = ({
    aboutMe,
    education,
    certs,
    exp,
    refs,
    back,
    goBackAboutMe,
    notEditIcon,
}) => {
    const [activeSection, setActiveSection] = useState("");
    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 3,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };
    const sections = [
        { key: "aboutMe", label: "Acerca de mí", data: aboutMe, isString: true },
        { key: "education", label: "Educación", data: education },
        { key: "certs", label: "Certificación", data: certs },
        { key: "exp", label: "Experiencia", data: exp },
        { key: "refs", label: "Referencias", data: refs },
    ];

    useEffect(() => {
        const firstSectionWithData = sections.find((section) => section.data);
        if (firstSectionWithData && !activeSection) {
            setActiveSection(firstSectionWithData.key);
        }
    }, [activeSection, sections]);

    const formatDate = (date) => {
        const datePattern = /^\d{4}-\d{2}-\d{2}$/; // Regex pattern for "YYYY-MM-DD"

        if (!date) {
            return null;
        }
        if (datePattern.test(date)) {
            return date;
        }

        return new Intl.DateTimeFormat("es-AR", { dateStyle: "long" }).format(new Date(date));
    };


    const renderSectionData = () => {
        const section = sections.find((section) => section.key === activeSection);
        if (!section?.data) {
            return null;
        }

        if (section.isString) {
            return <p className="text-left">{section.data}</p>;
        } else if (Array.isArray(section.data)) {
            return (
                <ul>
                    {section.data.map((item) => (
                        <li key={item.id}>{renderItem(item)}</li>
                    ))}
                </ul>
            );
        } else if (typeof section.data === "object" && section.data !== null) {
            return renderItem(section.data);
        } else {
            return null;
        }
    };

    const renderItem = (item) => {
        switch (activeSection) {
            case "education":
                return (
                    <>
                        <div className="d-flex"><span className="font-weight-bold">Institución Educativa:</span><span className="ml-1">{item.institucion}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Título:</span><span className="ml-1">{item.titulo || item.degree}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Disciplina:</span><span className="ml-1">{item.disciplina || item.discipline}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Ciudad/Pais:</span><span className="ml-1">{item.pais || item.country}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Fecha de inicio:</span><span className="ml-1">{formatDate(item.fechaInicio) || item.start_date}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Fecha de fin:</span><span className="ml-1">{formatDate(item.fechaFin) || item.end_date}</span></div>
                        {item.titulosAdicionales && item.titulosAdicionales.length > 0 && (
                            <ul>
                                {item.titulosAdicionales.map((titulo, i) => (
                                    <li key={`educacion-${item.id}-tituloAdicional-${i}`}>{titulo}</li>
                                ))}
                            </ul>
                        )}
                    </>
                );
            case "certs":
                return (
                    <>
                        <div className="d-flex"><span className="font-weight-bold">Empresa Emisora:</span><span className="ml-1">{item.institucion || item.emitter}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Título:</span><span className="ml-1">{item.titulo || item.tittle}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Disciplina:</span><span className="ml-1">{item.disciplina || item.discipline}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Ciudad/Pais:</span><span className="ml-1">{item.pais || item.country}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">inicio:</span><span className="ml-1">{formatDate(item.fechaInicio) || item.start_date}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Finalización:</span><span className="ml-1">{formatDate(item.fechaFin) || item.end_date}</span></div>
                        {item.titulosAdicionales && item.titulosAdicionales.length > 0 && (
                            <ul>
                                {item.titulosAdicionales.map((titulo, i) => (
                                    <li key={`certificado-${item.id}-tituloAdicional-${i}`}>{titulo}</li>
                                ))}
                            </ul>
                        )}
                    </>
                );
            case "exp":
                return (
                    <>
                        <div className="d-flex"><span className="font-weight-bold">Cargo:</span><span className="ml-1">{item.disciplina}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Empresa:</span><span className="ml-1">{item.titulo || item.company}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Ubicación:</span><span className="ml-1">{item.pais || item.location}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Fecha de inicio:</span><span className="ml-1">{formatDate(item.fechaInicio) || item.start_date}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Fecha de fin:</span><span className="ml-1">{formatDate(item.fechaFin) || item.end_date}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Descripción del cargo:</span><span className="ml-1">{item.institucion || item.description}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Desafío:</span><span className="ml-1">{item.desafio || item.challenge}</span></div>
                    </>
                );
            case "refs":
                return (
                    <>
                        <div className="d-flex"><span className="font-weight-bold">Cargo:</span><span className="ml-1">{item.cargo}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Empresa:</span><span className="ml-1">{item.empresa}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Email:</span><span className="ml-1">{item.email}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Ubicación:</span><span className="ml-1">{item.ubicacion}</span></div>
                        <div className="d-flex"><span className="font-weight-bold">Teléfono:</span><span className="ml-1">{item.telefono}</span></div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="personal-info-card">
            <nav style={{ padding: '10px 25px 10px 10px' }}>
                <Slider {...sliderSettings}>
                    {sections.map(
                        ({ key, label, data }) =>
                            data && (
                                <ButtonComponent
                                    key={key}
                                    onClick={() => setActiveSection(key)}
                                    text={label}
                                    version={activeSection === key ? "fill" : "outline"}
                                    large
                                    paddingX={key === "aboutMe" ? "5px" : "10px"}
                                    paddingY={"10px"}

                                />
                            )
                    )}
                </Slider>
            </nav>
            {
                !notEditIcon && (
                    <button className="edit-button" onClick={back || goBackAboutMe}>
                        <img src={isEditIcon} alt="edit" />
                    </button>
                )
            }
            <div className="section-data">{renderSectionData()}</div>
        </div>
    );
};

export default PersonalInfoCardComponent;
