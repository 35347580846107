import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdmProjectsStep0 from './steps/AdmProjectsStep0';
import AdmProjectsStep1 from './steps/AdmProjectsStep1';

const AdmProjects = () => {
    const history = useHistory();
    const [step, setStep] = useState(0);
    const handleBack = () => {
        history.goBack();
    };
    const addProjects = () => {
        setStep(1);
    };

    const handleBackStep = () => {
        setStep(prevStep => Math.max(prevStep - 1, 0));
    };


    switch (step) {
        case 0:
            return <AdmProjectsStep0 handleBack={handleBack} addProjects={addProjects} />;
        case 1:
            return <AdmProjectsStep1 handleBackStep={handleBackStep} />;
        default:
            return <></>
    }
};

export default AdmProjects;
