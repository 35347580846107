import * as yup from 'yup';

const emailSchema = yup.string()
    .required('El correo electrónico es requerido.')
    .email('Dirección de correo electrónico inválida. Asegúrate de que incluye \'@\' y un dominio correcto.');
const passwordSchema = yup.string().min(8, 'La contraseña debe tener al menos 8 caracteres, incluyendo una letra mayúscula y un número.').matches(/[A-Z]/).matches(/\d/);
const codeSchema = yup.string()
    .required('El código es requerido.')
    .matches(/^\d{4}$/, 'El código debe tener 4 dígitos.')
    .test('is-expired', 'El código ha expirado.', function () {
        const { codeGenerationTime } = this.options.context;
        const now = new Date();
        const expirationTime = new Date(codeGenerationTime);
        expirationTime.setMinutes(expirationTime.getMinutes() + 5);
        return now <= expirationTime;
    });

const codeSchemaSix = yup.string()
    .required('El código es requerido.')
    .matches(/^\d{6}$/, 'El código debe tener 6 dígitos.')
    .test('is-expired', 'El código ha expirado.', function () {
        const { codeGenerationTime } = this.options.context;
        const now = new Date();
        const expirationTime = new Date(codeGenerationTime);
        expirationTime.setMinutes(expirationTime.getMinutes() + 5);
        return now <= expirationTime;
    });


const textSchema = yup.string().required('El campo es requerido.');
const dropdownSchema = yup.string().required('Debes seleccionar una opción.');
const dateSchema = yup.date()
    .nullable()
    .transform((value, originalValue) =>
        originalValue === '' ? null : value)
    .required('Debes seleccionar una fecha.');


export { emailSchema, passwordSchema, codeSchema, textSchema, dropdownSchema, dateSchema, codeSchemaSix };