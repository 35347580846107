import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CompanyReusableComponent from '../Components/CompanyReusable/CompanyReusableComponent';
import InputComponent from '../../../Shared/InputComponent';
import ButtonComponent from '../../../Shared/ButtonComponent';
import { ARicon, BRicon, USicon } from '../../../Shared/iconsComponent';
import RenderCompanyRole from './renderCompanyRole';

const renderStep3 = ({
  setStep,
  handleSetStep,
  formData,
  setFormData,
  handleNextStep,
  loading,
  terms,
  setTerms,
  selectedRole,
  handleRoleSelection,
  handleSaveRole,
  currentSectionStep3,
  setCurrentSectionStep3,
}) => {
  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    if (!/^\d+$/.test(phone)) return;
    setFormData({ ...formData, phone });
  };

  const sections = {
    main: (
      <CompanyReusableComponent
        company="BlueScreen"
        step={handleSetStep}
        title="¡Estamos listos para empezar!"
        subtitle="Por favor, proporciona unos pocos datos esenciales sobre ti y tu empresa."
        goBack={() => setStep(2)}
      >
        <form>
          <Row>
            <Col xs={12} className="gap-after">
              <InputComponent
                type="text"
                placeholder="Nombre"
                autoComplete="given-name"
                value={formData.f_name}
                onChange={(e) =>
                  setFormData({ ...formData, f_name: e.target.value })
                }
                externalValue={formData.f_name}
                isValidData={
                  formData.f_name !== '' &&
                  formData.f_name.length >= 2 &&
                  formData.f_name.length <= 50 &&
                  /^[a-zA-Z\s]*$/.test(formData.f_name)
                }
              />
            </Col>
            <Col xs={12} className="gap-after">
              <InputComponent
                type="text"
                placeholder="Apellido"
                autoComplete="family-name"
                value={formData.l_name}
                onChange={(e) =>
                  setFormData({ ...formData, l_name: e.target.value })
                }
                isValidData={formData.l_name !== ''}
                externalValue={formData.l_name}
              />
            </Col>
            <Col xs={12} className="gap-after">
              <ButtonComponent
                version={formData.company_role ? 'outline' : 'grey'}
                text={formData.company_role || 'Rol en la empresa'}
                isLeft
                large
                isColorBlack={formData.company_role !== ''}
                isEditIcon={formData.company_role !== ''}
                onClick={() => setCurrentSectionStep3('companyRole')}
              />
            </Col>
            <Col xs={3} className="gap-after">
              <InputComponent
                type="text"
                placeholder="ARS"
                isDropdown
                options={['ARS', 'US', 'BR']}
                iconOptions={{ icons: [ARicon, USicon, BRicon] }}
              />
            </Col>
            <Col xs={9} className="gap-after">
              <InputComponent
                type="tel"
                placeholder="Teléfono"
                value={formData.phone}
                onChange={handlePhoneChange}
                autoComplete="tel"
                externalValue={formData.phone}
                isValidData={formData.phone !== ''}
              />
            </Col>
            <Col xs={12} className="gap-after">
              <InputComponent
                type="password"
                placeholder="Contraseña"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                autoComplete="password"
                externalValue={formData.password}
                isValidData={formData.password !== ''}
              />
            </Col>
            <Col
              xs={12}
              className="gap-after d-flex justify-content-center align-items-center"
            >
              <div
                style={{ gap: '10px' }}
                className="d-flex align-items-center"
              >
                <input
                  type="checkbox"
                  id="flexCheckDefault"
                  checked={terms}
                  onChange={() => setTerms(!terms)}
                />
                <span style={{ fontSize: '0.7rem', color: '#8A8A9D' }}>
                  He leído y acepto los{' '}
                  <a href="ww.w.w">términos y condiciones *</a>
                </span>
              </div>
            </Col>
            <Col xs={12} className="gap-after pb-5">
              <ButtonComponent
                text="Continuar"
                version="fill"
                large
                isFontBold
                isHover
                onClick={(e) => {
                  e.preventDefault();
                  setTimeout(handleNextStep, 100);
                }}
                disabled={loading}
              />
            </Col>
          </Row>
        </form>
      </CompanyReusableComponent>
    ),
    companyRole: (
      <RenderCompanyRole
        handleRoleSelection={handleRoleSelection}
        handleSaveRole={handleSaveRole}
        handleSetStep={handleSetStep}
        selectedRole={selectedRole}
        setCurrentSectionStep3={setCurrentSectionStep3}
      />
    ),
  };

  return sections[currentSectionStep3];
};

export default renderStep3;
