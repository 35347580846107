import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/soft-skills';

export const useSoftSkillsApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);

    const createSoftSkill = async (softSkillData) => {
        try {
            const response = await axios.get(`${apiBaseUrl}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            return response.data.map(skill => ({
                id: skill.id,
                title: skill.name_es,
                description: skill.desc_es
            }));
        } catch (error) {
            toast.error('Error fetching SoftSkills:', error.response ? error.response.data : error);
            return [];
        }
    };

    const fetchSoftSkills = async (queryParams = {}) => {
        try {
            const response = await axios.get(apiBaseUrl, {
                params: queryParams,
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching SoftSkills:', error);
            return [];
        }
    };

    const fetchSoftSkill = async (softSkillId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${softSkillId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching SoftSkill:', error);
            return null;
        }
    };

    const updateSoftSkill = async (softSkillId, softSkillData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${softSkillId}`, softSkillData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('SoftSkill updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating SoftSkill:', error.response ? error.response.data : error);
        }
    };

    const deleteSoftSkill = async (softSkillId) => {
        try {
            await axios.delete(`${apiBaseUrl}/${softSkillId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('SoftSkill deleted');
        } catch (error) {
            toast.error('Error deleting SoftSkill:', error.response ? error.response.data : error);
        }
    };

    return {
        createSoftSkill,
        fetchSoftSkills,
        fetchSoftSkill,
        updateSoftSkill,
        deleteSoftSkill,
    };
};
