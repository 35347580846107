import React, { useState, useEffect } from 'react';
import CompanyReusableComponent from '../../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import { Col, Form } from 'react-bootstrap';
import PortfolioNotification from '../../../../../Assets/PortfolioNotification.svg';
import ButtonComponent from '../../../../Shared/ButtonComponent';

const Case1CreateJob = ({ goBack, setVacancyData, vacancyData, save }) => {
  const [selectedVacancy, setSelectedVacancy] = useState('');

  useEffect(() => {
    if (vacancyData) {
      setSelectedVacancy(vacancyData.vacancies);
    }
  }, [vacancyData]);

  const handleVacancyChange = (e) => {
    const value = e.target.value;
    setSelectedVacancy(value);
    setVacancyData({ vacancies: value });
  };

  const handleSave = () => {
    save(selectedVacancy);
    goBack();
  };

  return (
    <CompanyReusableComponent goBack={goBack} company={'WhiteScreen'}>
      <Col xs={12} className="gap-after my-5">
        <img src={PortfolioNotification} alt="PortfolioNotification" />
        <span style={{ fontWeight: 'bold', textAlign: 'start' }}>
          Por favor, especifica cantidad de vacantes.
        </span>
      </Col>
      <Col xs={12} className="gap-after" style={{ height: '100%' }}>
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            gap: '1.5rem',
            fontWeight: 'bold',
          }}
          className="gap-after"
        >
          <Form.Check
            type="radio"
            id="vacancy-1-2"
            name="vacancy"
            label="De 1 a 2 Personas"
            value="BETWEEN_1_2"
            checked={selectedVacancy === 'BETWEEN_1_2'}
            onChange={handleVacancyChange}
          />
          <Form.Check
            type="radio"
            id="vacancy-3-5"
            name="vacancy"
            label="De 3 a 5 Personas"
            value="BETWEEN_3_5"
            checked={selectedVacancy === 'BETWEEN_3_5'}
            onChange={handleVacancyChange}
          />
          <Form.Check
            type="radio"
            id="vacancy-6-plus"
            name="vacancy"
            label="De 6 o más Personas"
            value="MORE_THAN_6"
            checked={selectedVacancy === 'MORE_THAN_6'}
            onChange={handleVacancyChange}
          />
        </Form>
      </Col>

      <Col xs={12} className="gap-after">
        <ButtonComponent
          text="Guardar"
          version={selectedVacancy ? 'fill' : 'grey'}
          large
          onClick={handleSave}
          disabled={!selectedVacancy}
        />
      </Col>
    </CompanyReusableComponent>
  );
};

export default Case1CreateJob;
