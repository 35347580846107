import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CompanyReusableComponent from '../../Components/CompanyReusable/CompanyReusableComponent';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import { toast } from 'react-toastify';
import SearchComponent from '../../../../Shared/SearchComponent/SearchComponent';

const SectorSection5 = ({ setCurrentSection, handleSetStep, handleSaveSector, selectedWorkArea, workAreas, handleSelectWorkArea, handleAddWorkArea, handleOptionSelected }) => {


    return (
        <CompanyReusableComponent
            company="WhiteScreen"
            step={handleSetStep}
            title="Actividad en el sector"
            goBack={() => setCurrentSection("main")}
        >
            <Row className="m-0">
                <Col xs={12} className="gap-after my-5">
                    <span style={{ textAlign: 'start' }}>
                        ¿Cuál es su área de especialización? Seleccionar 1 opción.
                    </span>
                </Col>

                {workAreas.map((area) => (
                    <Col xs={12} className={`gap-after ${area.id === workAreas[workAreas.length - 1].id ? 'pb-5' : ''}`} key={`area-${area.id}`}>
                        <ButtonComponent text={area.name_es} version={
                            selectedWorkArea === area.id ? "fill" : "outline"
                        } onClick={() => handleSelectWorkArea(area.id)} large isLeft isColorBlack />
                    </Col>
                ))}

                <Col xs={12} className="gap-after pb-5">
                    <SearchComponent
                        addOption={handleAddWorkArea}
                        options={workAreas.map((area) => area.name_es)}
                        isCompanyWorkArea
                        onOptionSelected={handleOptionSelected}
                        placeholder={'¿no encuentras la tuya? Sumala.'}
                        filterStrings={true}
                    />
                </Col>


                <Col xs={12} className="gap-after pb-5">
                    <ButtonComponent
                        text="Guardar"
                        version={selectedWorkArea === null ? "grey" : "fill"}
                        large
                        onClick={() => {
                            if (selectedWorkArea === null) {
                                toast.error('Selecciona una opción');
                                return;
                            }
                            handleSaveSector(selectedWorkArea);
                        }}
                    />
                </Col>

            </Row>
        </CompanyReusableComponent>
    )
}

export default SectorSection5;
