import React, { useCallback } from 'react';
import { fullScreenStyles } from '../../steps/ProfilePersStyles';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import { Row, Col, Container } from 'react-bootstrap';
import StepperComponent from '../../../../Shared/StepperComponent/StepperComponent';
import BlueLogo from '../../../../../Assets/BlueLogo.svg';
import PortfolioNotification from '../../../../../Assets/PortfolioNotification.svg';
import SalaryNotification from '../../../../../Assets/SalaryNotification.svg';
import { BRicon, ARicon, USicon } from '../../../../Shared/iconsComponent'
import InputComponent from '../../../../Shared/InputComponent';
import InfoIcon from '../../../../../Assets/InfoIcon.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { remoteModHiringOptions, modHiringOptions, selectableOptions, salaryOptions } from './HiringConfig';

const HiringWindow = ({
    step,
    setStep,
    isHiring,
    isModHiring,
    isSalary,
    onClose,
}) => {
    const [isButtonDisabled, setIsButtonDisabled] = React.useState([true, true, true]);
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [selectedRemoteOption, setSelectedRemoteOption] = React.useState(null);
    const [showRemoteOptions, setShowRemoteOptions] = React.useState(false);
    const dropdownRef = React.useRef();
    const salaryRef = React.useRef(true);
    const [salarySelectedOption, setSalarySelectedOption] = React.useState('ARS');
    const [salaryOptionsFlag, setSalaryOptionsFlag] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [showDatePicker, setShowDatePicker] = React.useState(false);
    const [minSalary, setMinSalary] = React.useState('');
    const [maxSalary, setMaxSalary] = React.useState('');



    const handleMinSalaryChange = (e) => {
        setMinSalary(e.target.value);
    };

    const handleMaxSalaryChange = (e) => {
        setMaxSalary(e.target.value);
    };

    const handleStartDateChange = date => {
        setStartDate(date);
        handleHiringWithDates();
    };

    const handleEndDateChange = date => {
        setEndDate(date);
        handleHiringWithDates();
    };


    const formatDate = (date) => {
        if (!date) return '';

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().substr(-2);

        return `${day}/${month}/${year}`;
    };


    const handleHiringWithDates = () => {
        if (selectedOption && selectedOption.title === 'Temporal' && startDate && endDate) {
            const hiringInfo = `${selectedOption.title} ${formatDate(startDate)} - ${formatDate(endDate)}`;
            console.log('Hiring Option Selected:', hiringInfo);
            if (typeof isHiring === 'function') {
                isHiring(hiringInfo);
            }
        }
    };


    const handleRadioChange = useCallback((option, stepIndex) => {
        if (stepIndex === 1) {
            setSelectedOption(option);
            setShowRemoteOptions(option.title === 'Remoto');
            setIsButtonDisabled(isButtonDisabled.map((item, index) =>
                index !== step - 1 ? item : option.title === 'Remoto'
            ));

            if (option.title === 'Temporal') {
                setShowDatePicker(true);
            } else {
                setShowDatePicker(false);
                setStartDate(null);
                setEndDate(null);
            }

            if (option.title === 'Presencial' || option.title === 'Híbrido') {
                if (typeof isModHiring === 'function') {
                    isModHiring(option.title);
                }
            }

            if (selectedOption && selectedOption.title === 'Temporal' && startDate && endDate) {
                const hiringInfo = `${selectedOption.title} ${formatDate(startDate)} - ${formatDate(endDate)}`;
                console.log('Hiring Option Selected:', hiringInfo);
                if (typeof isHiring === 'function') {
                    isHiring(hiringInfo);
                }
            }
            if (option.title !== 'Temporal') {
                if (typeof isHiring === 'function') {
                    isHiring(option.title);
                }
            }
            console.log('Hiring Option SelecteeeSd:', option.title);
        } else if (stepIndex === 2) {
            setSelectedRemoteOption(option);
            setIsButtonDisabled(isButtonDisabled.map((item, index) =>
                index !== step - 1 ? item : false
            ));
            if (typeof isModHiring === 'function') {
                isModHiring(option.title);
            }
        } else if (stepIndex === 3) {
            setIsButtonDisabled(isButtonDisabled.map((item, index) =>
                index !== step - 1 ? item : false
            ));
        } else if (stepIndex === 4) {
            setSalaryOptionsFlag(option.title);
            setIsButtonDisabled(isButtonDisabled.map((item, index) =>
                index !== step - 1 ? item : false
            ));
            if (typeof isSalary === 'function') {
                isSalary([
                    option.title,
                    minSalary,
                    maxSalary,
                    salarySelectedOption
                ]);
            }
        }
    }, [isButtonDisabled, step, isModHiring, isHiring, selectedOption, startDate, endDate, minSalary, maxSalary, salarySelectedOption, setShowRemoteOptions, setShowDatePicker, setStartDate, setEndDate, setSelectedRemoteOption, setSalaryOptionsFlag, isSalary]);

    const handleOptionChange = (option) => {
        salarySelectedOption === option
            ? setSalarySelectedOption("")
            : setSalarySelectedOption(option);
        console.log("la opcion es: ", option);
    };
    let placeholderText;
    if (salarySelectedOption === "ARS") {
        placeholderText = "Pesos Argentinos";
    } else if (salarySelectedOption === "US") {
        placeholderText = "Dólares Estadounidenses";
    } else {
        placeholderText = "Reales Brasileños";
    }

    const handleSaveClick = () => {
        const selectedSalaryOption = salaryOptions.find(option => option.title === salaryOptionsFlag);

        const salary = [
            selectedSalaryOption ? selectedSalaryOption.title : "",
            minSalary,
            maxSalary,
            salarySelectedOption
        ];
        console.log("Salario seleccionado: ", salary);

        if (typeof onClose === 'function') {
            onClose();
        }
    };


    React.useEffect(() => {
        if (salaryOptionsFlag && minSalary && maxSalary && salarySelectedOption) {
            isSalary([
                salaryOptionsFlag,
                minSalary,
                maxSalary,
                salarySelectedOption
            ]);
        }
    }, [salaryOptionsFlag, minSalary, maxSalary, salarySelectedOption]);


    const renderRemoteOptions = () => {
        if (!showRemoteOptions) return null;

        return remoteModHiringOptions.map((option, index) => (
            <Col xs={11} key={index} className="mb-5">
                <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        className="form-check-input"
                        type="radio"
                        name="remote-options"
                        id={`remote-option-${index}`}
                        onChange={() => handleRadioChange(option, 2)}
                        checked={selectedRemoteOption === option}
                    />
                    <label className="form-check-label" htmlFor={`remote-option-${index}`} style={{ marginLeft: '1rem' }}>
                        <div style={{ fontWeight: 'bold', textAlign: 'start' }}>
                            {option.title}
                        </div>
                        <div style={{ textAlign: 'start' }}>
                            {option.description}
                        </div>
                    </label>
                </div>
            </Col>
        ));
    };


    const renderOptions = (options, stepIndex) => options.map((option, index) => (
        <Col xs={11} key={index} className="mb-5">
            <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    className="form-check-input"
                    type="radio"
                    name={`options-${stepIndex}`}
                    id={`option-${stepIndex}-${index}`}
                    onChange={() => handleRadioChange(option, stepIndex)}
                    checked={selectedOption === option}
                />
                <label className="form-check-label" htmlFor={`option-${stepIndex}-${index}`}>
                    <div style={{ fontWeight: 'bold', textAlign: 'start' }}>
                        {option.title}
                    </div>
                    <div style={{ textAlign: 'start' }}>
                        {option.description}
                    </div>
                </label>
            </div>
        </Col>
    ));

    const renderHiringOptions = () => selectableOptions.map((options, index) => (
        <React.Fragment key={index}>
            {step === index + 1 && (
                <>
                    {renderOptions(options, 1)}
                    {showDatePicker && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleStartDateChange}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Fecha de inicio"
                                className='DatePicker-date'
                            />
                            <DatePicker
                                selected={endDate}
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="Fecha de fin"
                                className='DatePicker-date'
                            />
                        </div>
                    )}

                    <Col xs={12} className="gap-after">
                        <ButtonComponent
                            text="Guardar"
                            onClick={() => {
                                if (step === 3) {
                                    onClose();
                                } else {
                                    setStep(step + 1);
                                    if (step === 2 && selectedOption.title === 'Temporal') {
                                        setShowDatePicker(false);
                                    }
                                }
                            }}
                            large
                            disabled={isButtonDisabled[index]}
                            isHover
                            version={isButtonDisabled[index] ? 'grey' : 'fill'}
                        />
                    </Col>
                </>
            )}
        </React.Fragment>
    ));


    const renderSalaryOptions = () => salaryOptions.map((option, index) => (
        <Col xs={11} key={index} className="mb-5">
            <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    className="form-check-input"
                    type="radio"
                    name="salary-options"
                    id={`salary-option-${index}`}
                    onChange={() => handleRadioChange(option, 4)}
                    checked={salaryOptionsFlag === option.title}
                />
                <label className="form-check-label" htmlFor={`salary-option-${index}`} style={{ marginLeft: '1rem' }}>
                    <div style={{ fontWeight: 'bold', textAlign: 'start' }}>
                        {option.title}
                    </div>
                    <div style={{ textAlign: 'start' }}>
                        {option.description}
                    </div>
                </label>
            </div>
        </Col>
    ));

    return (
        <div style={fullScreenStyles}>
            <img
                src={BlueLogo}
                alt="BlueLogo"
                style={{ cursor: 'pointer', marginBottom: '2rem' }}
            />
            {isHiring && (
                <Container fluid className="h-100 d-flex align-items-center justify-content-between flex-column">
                    <Row className="justify-content-center ">
                        <StepperComponent
                            className="mb-5"
                            steps={3}
                            currentStep={step}
                            subtitle="Por favor, especifica tipo de empleo deseado"
                            borderless
                        />
                        {renderHiringOptions()}
                    </Row>
                </Container>
            )}
            {isModHiring && (
                <Container fluid className="h-100 d-flex align-items-center justify-content-between flex-column">
                    <Row className="justify-content-center ">
                        <Col xs={12} className="gap-after mb-5">
                            <img src={PortfolioNotification} alt="PortfolioNotification" />
                            <span style={{ fontWeight: 'bold', textAlign: 'start' }}>
                                Por favor, especifica modalidad laboral.
                            </span>
                        </Col>
                        {renderOptions(modHiringOptions, 1)}
                        {renderRemoteOptions()}

                        <Col xs={12} className="gap-after">
                            <ButtonComponent
                                text="Guardar"
                                onClick={() => onClose()}
                                large
                                disabled={isButtonDisabled[0]}
                                isHover
                                version={isButtonDisabled[0] ? 'grey' : 'fill'}
                            />
                        </Col>
                    </Row>
                </Container>
            )}
            {isSalary && (
                <Container fluid className="h-100 d-flex align-items-center justify-content-between flex-column">
                    <Row className="justify-content-center ">
                        <Col xs={12} className="gap-after mb-5">
                            <img src={SalaryNotification} alt="SalaryNotification" />
                            <span style={{ fontWeight: 'bold', textAlign: 'start' }}>
                                Indicanos la moneda y rango salarial deseado.
                            </span>
                        </Col>
                        <Col xs={6} className="gap-after">
                            <InputComponent
                                type="text"
                                placeholder="ARS"
                                isDropdown
                                options={["ARS", "US", "BR"]}
                                iconOptions={{ icons: [ARicon, USicon, BRicon] }}
                                onOptionChange={handleOptionChange}
                                ref={dropdownRef}
                            />
                        </Col>
                        <Col xs={12} className="gap-after">
                            {renderSalaryOptions()}
                        </Col>
                        <Col xs={12} className="gap-after text-start" style={{ textAlign: 'start' }}>
                            <span style={{ fontWeight: 'bold' }}>
                                Rango
                            </span>
                        </Col>
                        <Col xs={12} className="gap-after d-flex align-items-center">
                            <InputComponent type="number" placeholder="Mínimo" ref={salaryRef} onChange={handleMinSalaryChange} />
                            <span style={{ margin: '0 1rem', color: '#cfcccc' }}> a </span>
                            <InputComponent type="number" placeholder="Máximo" ref={salaryRef} onChange={handleMaxSalaryChange} />
                        </Col>
                        <Col xs={12} className="gap-after">
                            <ButtonComponent
                                text="Siempre es una buena idea indicar el salario que deseas recibir para poder aparecer en mejores ofertas de empleos."
                                version="outline"
                                large
                                icon={InfoIcon}
                                isLeft
                                paddingY={'15px'}
                                disabled
                                isColorBlack
                            />
                        </Col>
                        <Col xs={12} className="gap-after">
                            <ButtonComponent text="Guardar" onClick={handleSaveClick} large disabled={!minSalary || !maxSalary || isButtonDisabled[0]} isHover version={!minSalary || !maxSalary || isButtonDisabled[0] ? 'grey' : 'fill'} />
                        </Col>
                    </Row>
                </Container>
            )}
        </div>

    );
};

export default HiringWindow;