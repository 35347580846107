import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ButtonComponent from '../../../Shared/ButtonComponent';
import Photo from '../../../../Assets/Photo.svg';
import BlueLogo from '../../../../Assets/BlueLogo.svg';
import Human from '../../../../Assets/Human.png';
import {
  fullScreenStyles,
  contentStyle,
  textStyle,
  listStyle,
} from './ProfilePersStyles';
import GreenPoint from '../../../../Assets/GreenPoint.svg';
import YellowPoint from '../../../../Assets/YellowPoint.svg';
import BluePoint from '../../../../Assets/BluePoint.svg';
import RedPoint from '../../../../Assets/RedPoint.svg';
import InfoIcon from '../../../../Assets/InfoIcon.svg';
import Whatsapp from '../../../../Assets/Whatsapp.svg';
import Email from '../../../../Assets/Email.svg';
import MapComponent from '../../../Shared/MapsComponent';
import { RegisterDataContext } from '../../../../contexts/RegisterDataContext';
import axios from 'axios';
import { useWorkAreas } from '../../../../api/WorkAreaApi';
import { usePositionsApi } from '../../../../api/PositionsApi';
import { toast } from 'react-toastify';
import locateIcon from '../../../../Assets/locateIcon.svg';
import SearchComponent from '../../../Shared/SearchComponent/SearchComponent';
import SearchContainer from '../../../Shared/SearchContainer/SearchContainer';

const options = [
  {
    title: 'Búsqueda activa',
    description:
      'Aparecerás en las búsquedas relacionadas a tu perfil sin importar el % de match.',
    icon: GreenPoint,
  },
  {
    title: 'Escuchando ofertas',
    description: 'Aparecerás en las búsquedas con mayor de 50% de match.',
    icon: YellowPoint,
  },
  {
    title: 'Gran Match',
    description:
      'Solo aparecerás en ofertas de trabajo con más de 85% de match.',
    icon: BluePoint,
  },
  {
    title: 'Sin interés',
    description: 'No aparecerás en las búsquedas',
    icon: RedPoint,
  },
];

const radioOptions = [
  {
    title: 'Practicante',
    description:
      'Estoy en una etapa de aprendizaje, ganando experiencia mientras todavía estoy estudiando o por graduarme.',
  },
  {
    title: 'Junior',
    description:
      'Estoy en las primeras etapas de mi carrera, con algo de experiencia y conocimiento, aún desarrollando habilidades bajo supervisión.',
  },
  {
    title: 'Semi-Senior',
    description:
      'Tengo experiencia, aún en desarrollo para avanzar al nivel senior, trabajo con cierto grado de independencia.',
  },
  {
    title: 'Senior',
    description:
      'Tengo alta experiencia y competencia, puedo tener responsabilidades de liderazgo, trabajo de manera independiente.',
  },
  {
    title: 'Manager',
    description:
      'Superviso equipos o departamentos, me responsabilizo de alcanzar ciertos objetivos o metas.',
  },
  {
    title: 'Director',
    description:
      'Tengo responsabilidades de supervisión a gran escala, me encargo de tomar decisiones importantes.',
  },
  {
    title: 'Consultor',
    description:
      'Soy experto en un área específica para proporcionar asesoramiento, soluciones a problemas, para mejorar el rendimiento de una organización.',
  },
];

const FullScreenPhoto = ({
  isLocalitation,
  isSeniorityOption,
  isAreaTrabajoOptions,
  onClose,
  onPhotoTaken,
  logoOnly,
  isVisibilityLaboral,
  setIsVisibilityLaboral,
  isAreaTrabajo,
  isSeniority,
  isUbicacion,
  updateVisibilityOption,
  updateVisibilityOptionIcon,
}) => {
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [currentPosition, setCurrentPosition] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [registerData] = useContext(RegisterDataContext);
  const [workAreas, setWorkAreas] = useState([]);
  const workAreasData = useWorkAreas({ isNameEs: true });
  const [dataFiltered, setDataFiltered] = useState([]);
  const [isFilteredValue, setIsFilteredValue] = useState(false);
  const [firstWorkArea, setFirstWorkArea] = useState([]);
  const [selectedWorkAreas, setSelectedWorkAreas] = useState([]);
  const { fetchPositions } = usePositionsApi();
  const [isFirstTitle, setIsFirstTitle] = useState(false);
  const [isFirstTitleOptions, setIsFirstTitleOptions] = useState([]);
  const [isFirstOptionSelected, setIsFirstOptionSelected] = useState([]);
  const [selectedButtonsWorkArea, setSelectedButtonsWorkArea] = useState([]);
  const [selectedButtonsFirstTitle, setSelectedButtonsFirstTitle] = useState(
    []
  );
  const [selectedOption, setSelectedOption] = useState('');
  const [isContactMethod, setIsContactMethod] = useState(false);

  const handleOptionSelect = (option) => {
    const newSelection = option.title;
    console.log('Opción seleccionada en SearchContainer:', newSelection);

    setSelectedOption((prevOptions) => {
      const optionsSet = new Set(prevOptions);
      optionsSet.add(newSelection);

      const updatedOptions = Array.from(optionsSet);

      updateVisibilityOption(updatedOptions.join(', '));
      return updatedOptions;
    });
  };

  const handleContactMethodSelect = (option) => {
    const newSelection = option;
    console.log('Opción seleccionada en ContactMethod:', newSelection);

    setSelectedOption((prevOptions) => {
      const optionsSet = new Set(prevOptions);
      if (optionsSet.has(newSelection)) {
        optionsSet.delete(newSelection);
      } else {
        optionsSet.delete('Whatsapp');
        optionsSet.delete('Email');
        optionsSet.delete('Whatsapp, Email');
        optionsSet.add(newSelection);
      }

      const updatedOptions = Array.from(optionsSet);

      updateVisibilityOption(updatedOptions.join(', '));
      return updatedOptions;
    });
  };

  const isAnyOptionSelected = selectedOption.length > 0;

  const getButtonVersion = (method) => {
    return selectedOption.includes(method) ? 'fill' : 'grey';
  };

  const checkToast = () => {
    toast.success('Foto subida correctamente');
  };

  const apiBaseUrl =
    'https://api-stg.empleosmarketingdigital.com/v1/expertise-areas';
  const authHeaders = (token) => ({
    Authorization: `Bearer ${token}`,
  });

  const apiClient = axios.create({
    baseURL: 'https://api-stg.empleosmarketingdigital.com/v1/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${registerData.token}`,
    },
  });

  const handleCapture = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error('No file selected.');
      return;
    }

    if (!file.type.startsWith('image/')) {
      toast.error('El archivo seleccionado no es una imagen.');
      return;
    }

    const resizedImage = await resizeImage(file);

    const formData = new FormData();
    formData.append('file', resizedImage);

    try {
      const response = await apiClient.post(
        '/uploads/talent-profile-image',
        formData
      );
      const photoURL = response.data.url;
      onPhotoTaken(photoURL);
      checkToast();
      onClose();
    } catch (error) {
      const errorType = error.response?.statusText || 'Error';
      const errorMessage = error.response?.data || error.message;
      toast.error(`Error uploading photo: ${errorType} - ${errorMessage}`);
    }
  };

  async function resizeImage(file) {
    const MAX_WIDTH = 800; // Ancho máximo para la imagen
    const MAX_HEIGHT = 600; // Alto máximo para la imagen
    const MIME_TYPE = 'image/jpeg'; // Tipo de imagen de salida
    const QUALITY = 0.7; // Calidad de la imagen de salida

    const img = document.createElement('img');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const imgPromise = new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });

    const imgResult = await imgPromise;

    let width = imgResult.width;
    let height = imgResult.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }

    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(imgResult, 0, 0, width, height);
    URL.revokeObjectURL(img.src);

    return new Promise((resolve, reject) => {
      canvas.toBlob(resolve, MIME_TYPE, QUALITY);
    });
  }

  const handleOptionSelectIcon = (selectedIcon) => {
    console.log('Icono seleccionado:', selectedIcon);
    if (selectedIcon) {
      updateVisibilityOptionIcon(selectedIcon);
    } else {
      console.log('No hay icono seleccionado');
    }
  };

  const handleButtonClickWorkArea = async (workArea) => {
    if (!workArea.id) {
      toast.error('ID de área de trabajo no proporcionado');
      return;
    }
    const filterValue = workArea.id;
    try {
      const response = await axios.get(
        `${apiBaseUrl}?filter_by=work_area_id&filter_value=${filterValue}`,
        {
          headers: authHeaders(registerData.token),
        }
      );
      setDataFiltered(response.data);
      setSelectedButtonsWorkArea([workArea.name]);
      setFirstWorkArea([workArea.name]);
    } catch (error) {
      toast.error(
        'Error:',
        error.response ? error.response.data.toString() : error
      );
    }
  };

  const handleAddAndClose = (selectedOption) => {
    if (selectedOption) {
      console.log('Option passed to handleAddAndClose:', selectedOption);
      setSelectedButtons([selectedOption]);
      handleFirstTitleClick(selectedOption);
      onClose();
    }
  };

  const handleSelectArea = (areaName) => {
    if (selectedButtons.includes(areaName)) {
      setSelectedButtons(selectedButtons.filter((name) => name !== areaName));
    } else if (selectedButtons.length < 3) {
      setSelectedButtons([...selectedButtons, areaName]);
      setIsSaveEnabled(true);
      setSelectedWorkAreas([...firstWorkArea, ...selectedButtons, areaName]);
    }
  };

  const handleRadioChange = (title) => {
    console.log(title);
    setSelectedTitle(title);
    isSeniorityOption(title);
  };

  const gosetIsFilteredValue = () => {
    setIsFilteredValue(true);
  };

  async function getPlaceName(lat, lng) {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAvB4rWV__iIfDH8UuHjRvPu4GTTaA85bI`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        return data.results[0].formatted_address;
      } else {
        toast.error('No se encontraron resultados para la ubicación');
        return 'Ubicación desconocida';
      }
    } catch (error) {
      toast.error('Error al obtener el nombre del lugar:', error);
      return 'Error al obtener la ubicación';
    }
  }

  const fetchCurrentLocationClick = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const posString = `Latitud: ${pos.lat}, Longitud: ${pos.lng}`;
        console.log('Ubicación actual Click:', posString);

        getPlaceName(pos.lat, pos.lng).then((placeName) => {
          console.log(`La ubicación actual es en ${placeName}`);
          isLocalitation(placeName);
        });

        setCurrentPosition(pos);
        setIsLoaded(true);
      });
    } else {
      toast.error('Geolocation is not supported by this browser.');
      setIsLoaded(true);
    }
  }, [isLocalitation]);

  const fetchCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const posString = `Latitud: ${pos.lat}, Longitud: ${pos.lng}`;
        console.log('Ubicación actual:', posString);

        setIsLoaded(true);
      });
    } else {
      toast.error('Geolocation is not supported by this browser.');
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isUbicacion) {
      fetchCurrentLocationClick();
    }
  }, [fetchCurrentLocationClick, isUbicacion]);

  const handleFirstTitleClick = useCallback(
    (title) => {
      setIsFirstOptionSelected(title);
      setIsSaveEnabled(true);
      isAreaTrabajoOptions([title, ...selectedWorkAreas]);
      setSelectedButtonsFirstTitle([title]);
      console.log(title);
    },
    [selectedWorkAreas, isAreaTrabajoOptions]
  );

  const handleSelectFilter = (filter) => {
    const filteredData = workAreas.filter((area) =>
      area.name.toLowerCase().includes(filter.toLowerCase())
    );
    setDataFiltered(filteredData);
  };

  useEffect(() => {
    fetchCurrentLocation();
  }, [fetchCurrentLocation]);

  useEffect(() => {
    if (isAreaTrabajo) {
      workAreasData().then((data) => {
        setWorkAreas(data);
      });
    }
  }, [isAreaTrabajo]);

  useEffect(() => {
    if (isFirstTitle) {
      fetchPositions().then((data) => {
        setIsFirstTitleOptions(data);
      });
    }
  }, [isFirstTitle]);

  if (logoOnly) {
    return (
      <div style={fullScreenStyles}>
        <img src={BlueLogo} alt="BlueLogo" />
        {isVisibilityLaboral && (
          <Container
            fluid
            className="h-100 d-flex align-items-center justify-content-between flex-column"
          >
            <div className="pt-5">
              <SearchContainer
                options={options}
                onOptionSelect={handleOptionSelect}
                onOptionSelectIcon={handleOptionSelectIcon}
              />
              <div
                className="pt-3"
                style={{
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    width: '12px',
                    height: '5px',
                    borderRadius: '30%',
                    backgroundColor: '#003EFF',
                  }}
                ></div>
                <div
                  style={{
                    width: '5px',
                    height: '5px',
                    borderRadius: '50%',
                    backgroundColor: '#003EFF40',
                  }}
                ></div>
              </div>
            </div>
            <div style={{ width: '80%', marginBottom: '2rem' }}>
              <ButtonComponent
                text={'Siguiente'}
                version={selectedOption ? 'fill' : 'grey'}
                onClick={() => {
                  setIsContactMethod(true);
                  setIsVisibilityLaboral(false);
                }}
                large
              />
            </div>
          </Container>
        )}
        {isContactMethod && (
          <Container
            fluid
            className="h-100 d-flex align-items-center justify-content-between flex-column"
          >
            <Row className="justify-content-center ">
              <Col xs={10} className="gap-after mb-2 pt-4">
                <span>
                  Ahora cuentanos por cuál medio te gustaría que te contacten
                  las empresas
                </span>
              </Col>
              <Col xs={12} className="gap-after mb-2">
                <span style={{ color: '#E2E2E2' }}>Modalidad de contacto</span>
              </Col>
              <Col xs={12} className="gap-after mb-2">
                <ButtonComponent
                  text="a través de Whatsapp"
                  version={getButtonVersion('Whatsapp')}
                  large
                  icon={Whatsapp}
                  paddingY={'15px'}
                  style={{
                    color:
                      getButtonVersion('Whatsapp') === 'fill'
                        ? 'white'
                        : 'black',
                  }}
                  onClick={() => {
                    handleContactMethodSelect('Whatsapp');
                  }}
                />
              </Col>
              <Col xs={12} className="gap-after mb-2">
                <ButtonComponent
                  text="a través del Correo electrónico"
                  version={getButtonVersion('Email')}
                  large
                  icon={Email}
                  paddingY={'15px'}
                  style={{
                    color:
                      getButtonVersion('Email') === 'fill' ? 'white' : 'black',
                  }}
                  onClick={() => {
                    handleContactMethodSelect('Email');
                  }}
                />
              </Col>
              <Col xs={12} className="gap-after mb-2">
                <ButtonComponent
                  text="Ambos métodos de contacto"
                  version={getButtonVersion('Ambos métodos de contacto')}
                  large
                  paddingY={'15px'}
                  onClick={() => {
                    handleContactMethodSelect('Whatsapp, Email');
                  }}
                />
              </Col>
              <div
                className="pt-3"
                style={{
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    width: '5px',
                    height: '5px',
                    borderRadius: '50%',
                    backgroundColor: '#003EFF40',
                  }}
                ></div>
                <div
                  style={{
                    width: '12px',
                    height: '5px',
                    borderRadius: '30%',
                    backgroundColor: '#003EFF',
                  }}
                ></div>
              </div>
            </Row>

            <div
              style={{
                width: '80%',
                marginBottom: '2rem',
                gap: '10px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ButtonComponent
                text="Continuar"
                onClick={() => {
                  onClose();
                }}
                large
                version={isAnyOptionSelected ? 'fill' : 'grey'}
                isHover={isAnyOptionSelected}
                disabled={!isAnyOptionSelected}
              />
              <ButtonComponent
                text="Regresar"
                onClick={() => {
                  setIsVisibilityLaboral(true);
                  setIsContactMethod(false);
                }}
                large
                version="grey"
                isHover
              />
            </div>
          </Container>
        )}
        {!isFilteredValue && isFirstTitle && (
          <Container
            fluid
            className="h-100 d-flex align-items-center justify-content-between flex-column"
          >
            <Row className="justify-content-start">
              <Col xs={12} className="gap-after mb-2">
                <span>
                  ¿Cómo te gustaría que se vea la primera línea de tu perfil?
                  Sería tu profesión u oficio.
                </span>
              </Col>
              {isFirstTitleOptions.map((workArea, index) => (
                <Col key={index} xs={6} className="gap-after mb-2">
                  <ButtonComponent
                    text={workArea.name_es}
                    version={
                      isFirstOptionSelected === workArea.name_es
                        ? 'fill'
                        : 'outline'
                    }
                    large
                    paddingY={'15px'}
                    height={'100%'}
                    onClick={() => handleFirstTitleClick(workArea.name_es)}
                  />
                </Col>
              ))}
              <Col xs={12} className="gap-after mb-2">
                <ButtonComponent
                  text="Aunque el área de especialización está en espera de aprobación, podrás hacer uso de ella hasta que el equipo confirme su conformidad con nuestros criterios."
                  version="outline"
                  large
                  icon={InfoIcon}
                  isLeft
                  paddingY={'15px'}
                  disabled
                />
              </Col>
            </Row>
            <div style={{ width: '80%', marginBottom: '2rem' }}>
              <ButtonComponent
                text="Guardar"
                onClick={() => {
                  console.log(selectedButtons);
                  onClose();
                }}
                large
                version={selectedButtonsFirstTitle.length > 0 ? 'fill' : 'grey'}
                disabled={selectedButtonsFirstTitle.length === 0}
                isHover={selectedButtonsFirstTitle.length > 0}
              />
            </div>
          </Container>
        )}
        {isFilteredValue && (
          <Container
            fluid
            className="h-100 d-flex align-items-center justify-content-between flex-column"
          >
            <Row className="justify-content-start">
              <Col xs={12} className="gap-after mb-2">
                <span>
                  ¿Cuál es su área de especialización? Puede seleccionar hasta 3
                  ítems.
                </span>
              </Col>
              {dataFiltered.map((workArea, index) => (
                <Col key={index} xs={6} className="gap-after mb-2">
                  <ButtonComponent
                    text={workArea.name_es}
                    version={
                      selectedButtons.includes(workArea.name_es)
                        ? 'fill'
                        : 'outline'
                    }
                    large
                    paddingY={'15px'}
                    height={'100%'}
                    onClick={() => handleSelectArea(workArea.name_es)}
                  />
                </Col>
              ))}
              <Col xs={12} className="gap-after mb-2">
                <ButtonComponent
                  text="Aunque el área de especialización está en espera de aprobación, podrás hacer uso de ella hasta que el equipo confirme su conformidad con nuestros criterios."
                  version="outline"
                  large
                  icon={InfoIcon}
                  isLeft
                  paddingY={'15px'}
                  disabled
                />
              </Col>
            </Row>
            <div style={{ width: '80%', marginBottom: '2rem' }}>
              <ButtonComponent
                text="Guardar"
                onClick={() => {
                  console.log(selectedButtons);
                  setIsFirstTitle(true);
                  setIsFilteredValue(false);
                }}
                large
                disabled={!isSaveEnabled || selectedButtons.length === 0}
                version={selectedButtons.length > 0 ? 'fill' : 'grey'}
                isHover={selectedButtons.length > 0}
              />
            </div>
          </Container>
        )}
        {isAreaTrabajo && !isFilteredValue && !isFirstTitle && (
          <Container
            fluid
            className="h-100 d-flex align-items-center justify-content-between flex-column"
          >
            <Row className="justify-content-start">
              <Col xs={12} className="gap-after mb-2">
                <span>¿Cuál es tu área de especialización y trabajo?</span>
              </Col>
              {workAreas.map((workArea, index) => (
                <Col key={index} xs={6} className="gap-after mb-2">
                  <ButtonComponent
                    text={workArea.name}
                    version={
                      selectedButtonsWorkArea.includes(workArea.name)
                        ? 'fill'
                        : 'outline'
                    }
                    large
                    paddingY={'15px'}
                    height={'100%'}
                    onClick={() => handleButtonClickWorkArea(workArea)}
                  />
                </Col>
              ))}
              <Col xs={12} className="gap-after mb-2">
                <ButtonComponent
                  text="Aunque el área de especialización está en espera de aprobación, podrás hacer uso de ella hasta que el equipo confirme su conformidad con nuestros criterios."
                  version="outline"
                  large
                  icon={InfoIcon}
                  isLeft
                  paddingY={'15px'}
                  disabled
                />
              </Col>
              <Col xs={12} className="gap-after mb-2">
                <SearchComponent
                  placeholder="Buscar área de trabajo"
                  options={workAreas.map((area) => area.name)}
                  onOptionSelected={handleSelectFilter}
                  addOption
                  isWorkArea
                  selectAndCloseOnAdd
                  onAddAndClose={handleAddAndClose}
                />
              </Col>
            </Row>
            <div style={{ width: '80%', marginBottom: '2rem' }}>
              <ButtonComponent
                text="Guardar"
                onClick={gosetIsFilteredValue}
                large
                version={selectedButtonsWorkArea.length > 0 ? 'fill' : 'grey'}
                disabled={selectedButtonsWorkArea.length === 0}
                isHover={selectedButtonsWorkArea.length > 0}
              />
            </div>
          </Container>
        )}
        {isSeniority && (
          <Container
            fluid
            className="h-100 d-flex align-items-center justify-content-around flex-column"
          >
            <Row>
              {radioOptions.map((option, index) => (
                <Col xs={12} key={index} className="mb-2">
                  <div
                    className="form-check"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="options"
                      id={`radio-${index}`}
                      onChange={() => handleRadioChange(option.title)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`radio-${index}`}
                      style={{ marginLeft: '1rem' }}
                    >
                      <div style={{ fontWeight: 'bold', textAlign: 'start' }}>
                        {option.title}
                      </div>
                      <div style={{ textAlign: 'start' }}>
                        {option.description}
                      </div>
                    </label>
                  </div>
                </Col>
              ))}
            </Row>
            <ButtonComponent
              disabled={!selectedTitle}
              text="Continuar"
              onClick={onClose}
              isHover
              large
            />
          </Container>
        )}
        {isUbicacion && (
          <Container
            fluid
            className="h-100 d-flex align-items-center justify-content-around flex-column"
          >
            <MapComponent
              isLoaded={isLoaded}
              currentPosition={currentPosition}
            />
            <Row>
              <Col xs={12} className="gap-after mb-2">
                <SearchComponent
                  placeholder="Buscar ubicación"
                  options={[
                    'Buenos Aires, Argentina',
                    'Córdoba, Argentina',
                    'Rosario, Argentina',
                  ]}
                  onOptionSelected={(option) => {
                    isLocalitation(option);
                  }}
                />
              </Col>
              <Col xs={12} className="gap-after mb-2">
                <ButtonComponent
                  text="Mi Ubicación actual"
                  version="outline"
                  icon={locateIcon}
                  large
                  onClick={fetchCurrentLocationClick}
                  style={{
                    border: 'none',
                    color: 'rgba(0, 62, 255, 0.25)',
                  }}
                />
              </Col>
            </Row>
            <ButtonComponent
              text="Guardar"
              onClick={onClose}
              large
              version={'fill'}
              isHover
            />
          </Container>
        )}
      </div>
    );
  }

  return (
    <div style={fullScreenStyles}>
      <Container fluid>
        <img
          src={BlueLogo}
          alt="BlueLogo"
          style={{ cursor: 'pointer', marginBottom: '2rem' }}
        />
        <Row className="justify-content-center m-0">
          <img
            src={Human}
            alt="Profile"
            style={{ width: '200px', height: '200px', borderRadius: '15px' }}
          />
          <Col xs={9} style={contentStyle} className="gap-after">
            <p style={textStyle}>Consejos para tu foto de perfil...</p>
            <ol style={listStyle}>
              <li style={{ textAlign: 'start' }}>
                Utiliza iluminación natural suave, evitando sombras en el
                rostro.
              </li>
              <li style={{ textAlign: 'start' }}>
                Asegura un lente limpio para imágenes nítidas.
              </li>
              <li style={{ textAlign: 'start' }}>
                Posiciona la cámara a la altura de los ojos o un poco arriba.
              </li>
              <li style={{ textAlign: 'start' }}>
                Elige un fondo neutral sin distracciones.
              </li>
              <li style={{ textAlign: 'start' }}>
                Viste de forma profesional según el puesto deseado.
              </li>
            </ol>
          </Col>
          <input
            type="file"
            accept="image/*"
            capture="environment"
            style={{ display: 'none' }}
            onChange={handleCapture}
            id="photo-capture-input"
          />
          <Col xs={10} className="gap-after">
            <ButtonComponent
              text="Cargar"
              version="fill"
              large
              onClick={() =>
                document.getElementById('photo-capture-input').click()
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default function ProfilePers({
  gotostep,
  location,
  seniority,
  photoProfile,
  especialitation,
  visibility,
}) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [photoSrc, setPhotoSrc] = useState(Photo);
  const [buttonVersion, setButtonVersion] = useState('grey');
  const [buttonText, setButtonText] = useState('Cargar foto');
  const [isSecondButtonEnabled, setIsSecondButtonEnabled] = useState(false);
  const [isLogoOnly, setIsLogoOnly] = useState(false);
  const [isVisibilityLaboral, setIsVisibilityLaboral] = useState(false);
  const [isAreaTrabajo, setIsAreaTrabajo] = useState(false);
  const [isSeniority, setIsSeniority] = useState(false);
  const [isUbicacion, setIsUbicacion] = useState(false);
  const [visibilityButtonVersion, setVisibilityButtonVersion] =
    useState('grey');
  const [visibilityButtonText, setVisibilityButtonText] = useState(
    'Visibilidad laboral'
  );
  const [isThirdButtonEnabled, setIsThirdButtonEnabled] = useState(false);
  const [isFourthButtonEnabled, setIsFourthButtonEnabled] = useState(false);
  const [isAreaTrabajoText, setIsAreaTrabajoText] = useState(
    'Área de trabajo y especialización'
  );
  const [workButtonVersion, setWorkButtonVersion] = useState('grey');
  const [isFifthButtonEnabled, setIsFifthButtonEnabled] = useState(false);
  const [isSeniorityText, setIsSeniorityText] = useState(
    '¿Podrías indicar tu seniority?'
  );
  const [seniorityButtonVersion, setSeniorityButtonVersion] = useState('grey');
  const [isLocalitationsVersion, setIsLocalitationsVersion] = useState('grey');
  const [isLocalitationsText, setIsLocalitationsText] = useState('Ubicación');
  const [isSexthButtonEnabled, setIsSexthButtonEnabled] = useState(false);

  const isLocalitation = (pos) => {
    setIsSexthButtonEnabled(true);
    setIsLocalitationsVersion('outline');
    setIsLocalitationsText(pos);
    location(pos);
  };

  const isSeniorityOption = (selectedTitle) => {
    setIsFifthButtonEnabled(true);
    setSeniorityButtonVersion('outline');
    setIsSeniorityText(selectedTitle);
    seniority(selectedTitle);
  };

  const isAreaTrabajoOptions = (selectedButtons) => {
    setIsFourthButtonEnabled(true);
    setWorkButtonVersion('outline');
    setIsAreaTrabajoText(selectedButtons.join(' | '));
    especialitation(selectedButtons);
    console.log(selectedButtons);
  };

  const updateVisibilityOption = (option) => {
    setVisibilityButtonText(option);
    setVisibilityButtonVersion('outline');
    setIsThirdButtonEnabled(true);
  };

  const updateVisibilityOptionIconHandle = (selectedIcon) => {
    console.log('SADSADSAD' + selectedIcon);
    visibility(selectedIcon);
  };

  const handlePhotoTaken = (photoURL) => {
    setPhotoSrc(photoURL);
    setButtonVersion('outline');
    setButtonText('Foto cargada');
    setIsSecondButtonEnabled(true);
    photoProfile(photoURL);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    setIsLogoOnly(false);
  };

  const handleVisibilityLaboralClick = () => {
    setIsFullScreen(true);
    setIsLogoOnly(true);
    setIsVisibilityLaboral(true);
    setIsAreaTrabajo(false);
    setIsSeniority(false);
    setIsUbicacion(false);
  };

  const handleAreaTrabajoClick = () => {
    setIsFullScreen(true);
    setIsLogoOnly(true);
    setIsVisibilityLaboral(false);
    setIsAreaTrabajo(true);
    setIsSeniority(false);
    setIsUbicacion(false);
  };

  const handleSeniorityClick = () => {
    setIsFullScreen(true);
    setIsLogoOnly(true);
    setIsVisibilityLaboral(false);
    setIsAreaTrabajo(false);
    setIsSeniority(true);
    setIsUbicacion(false);
  };

  const handleUbicacionClick = () => {
    setIsFullScreen(true);
    setIsLogoOnly(true);
    setIsVisibilityLaboral(false);
    setIsAreaTrabajo(false);
    setIsSeniority(false);
    setIsUbicacion(true);
  };

  const handleVerificationStep5 = () => {
    console.log('handleVerificationStep5');
    gotostep(6);
  };

  if (isFullScreen) {
    return (
      <FullScreenPhoto
        isLocalitation={isLocalitation}
        onClose={toggleFullScreen}
        isSeniorityOption={isSeniorityOption}
        isAreaTrabajoOptions={isAreaTrabajoOptions}
        updateVisibilityOption={updateVisibilityOption}
        onPhotoTaken={handlePhotoTaken}
        updateVisibilityOptionIcon={updateVisibilityOptionIconHandle}
        logoOnly={isLogoOnly}
        isVisibilityLaboral={isVisibilityLaboral}
        isAreaTrabajo={isAreaTrabajo}
        isSeniority={isSeniority}
        isUbicacion={isUbicacion}
        setIsVisibilityLaboral={setIsVisibilityLaboral}
      />
    );
  }

  return (
    <Row>
      <Col xs={12} className="gap-after">
        <ButtonComponent
          version={buttonVersion}
          text={buttonText}
          isLeft
          large
          paddingY={'20px'}
          icon={photoSrc !== Photo ? photoSrc : Photo}
          onClick={toggleFullScreen}
          disabled={false}
          isColorBlack={isSecondButtonEnabled}
          isEditIcon={buttonText !== 'Cargar foto'}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <ButtonComponent
          version={visibilityButtonVersion}
          text={visibilityButtonText}
          isLeft
          large
          onClick={handleVisibilityLaboralClick}
          disabled={!isSecondButtonEnabled}
          isColorBlack={isThirdButtonEnabled}
          isEditIcon={visibilityButtonText !== 'Visibilidad laboral'}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <ButtonComponent
          version={workButtonVersion}
          text={isAreaTrabajoText}
          isLeft
          large
          onClick={handleAreaTrabajoClick}
          disabled={!isThirdButtonEnabled}
          isColorBlack={isFourthButtonEnabled}
          isEditIcon={isAreaTrabajoText !== 'Área de trabajo y especialización'}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <ButtonComponent
          version={seniorityButtonVersion}
          text={isSeniorityText}
          isLeft
          large
          onClick={handleSeniorityClick}
          disabled={!isFourthButtonEnabled}
          isColorBlack={isFifthButtonEnabled}
          isEditIcon={isSeniorityText !== '¿Podrías indicar tu seniority?'}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <ButtonComponent
          version={isLocalitationsVersion}
          text={isLocalitationsText}
          isLeft
          large
          onClick={handleUbicacionClick}
          disabled={!isFifthButtonEnabled}
          isColorBlack={isSexthButtonEnabled}
          isEditIcon={isLocalitationsText !== 'Ubicación'}
        />
      </Col>
      <Col xs={12} className="gap-after">
        <ButtonComponent
          text="Confirmar"
          version={isSexthButtonEnabled ? 'fill' : 'grey'}
          large
          onClick={handleVerificationStep5}
          isFontBold
          isHover
          disabled={
            !isSecondButtonEnabled ||
            !isThirdButtonEnabled ||
            !isFourthButtonEnabled ||
            !isFifthButtonEnabled
          }
        />
      </Col>
    </Row>
  );
}
