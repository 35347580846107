import axios from 'axios';
import { useContext } from 'react';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { UserContext } from '../contexts/UserContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/work-areas';

const authHeaders = (token) => ({
  Authorization: `Bearer ${token}`,
});

export const useCreateWorkArea = () => {
  const [registerData] = useContext(RegisterDataContext);

  const createWorkArea = async (data) => {
    try {
      const response = await axios.post(apiBaseUrl, data, {
        headers: authHeaders(registerData.token),
      });
      return response.data;
    } catch (error) {
      toast.error(
        'Error creating work area:',
        error.response ? error.response.data : error
      );
      throw error;
    }
  };

  return createWorkArea;
};

export const useWorkAreas = ({ isNameEs = false } = {}) => {
  const [registerData] = useContext(RegisterDataContext);
  const { userData } = useContext(UserContext);
  const token = localStorage.getItem('tokenRegister') || registerData.token;

  const fetchWorkAreas = async () => {
    try {
      const response = await axios.get(apiBaseUrl, {
        headers: authHeaders(registerData.token || token || userData.token),
      });
      return response.data
        .filter((workArea) => workArea.name_es != null)
        .map((workArea) => ({
          id: workArea.id,
          name: isNameEs ? workArea.name_es : workArea.name,
        }));
    } catch (error) {
      toast.error(
        'Error fetching work areas:',
        error.response ? error.response.data : error
      );
      return [];
    }
  };

  return fetchWorkAreas;
};

export const useWorkAreaGet = () => {
  const [registerData] = useContext(RegisterDataContext);
  const { userData } = useContext(UserContext);
  const token = localStorage.getItem('tokenRegister') || registerData.token;

  const getWorkArea = async (workAreaId) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/${workAreaId}`, {
        headers: {
          Authorization: `Bearer ${
            token || registerData.token || userData.token
          }`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(
        'Error getting specific work area:',
        error.response ? error.response.data : error
      );
      return null;
    }
  };

  return getWorkArea;
};

export const updateWorkArea = (workAreaId, data, token) => {
  try {
    const response = axios.put(`${apiBaseUrl}/${workAreaId}`, data, {
      headers: authHeaders(token),
    });
    return response.data;
  } catch (error) {
    toast.error(
      'Error updating work area:',
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const deleteWorkArea = (workAreaId, token) => {
  try {
    axios.delete(`${apiBaseUrl}/${workAreaId}`, {
      headers: authHeaders(token),
    });
  } catch (error) {
    toast.error(
      'Error deleting work area:',
      error.response ? error.response.data : error
    );
    throw error;
  }
};
