import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const SiteStatsSpinner = ({ data }) => {
    if (!data?.employments) {
        return <div style={{ width: 200, height: 200 }}>
            <CircularProgressbarWithChildren value={0} strokeWidth={8} styles={buildStyles({ pathColor: 'white' })}>
                <div style={{ position: 'absolute', top: '40%', left: '25%', zIndex: 10 }}>
                    <span style={{ display: 'block', textAlign: 'center' }}>Cargando...</span>
                </div>
            </CircularProgressbarWithChildren>
        </div>;
    }

    const { freemium, premium, diamond } = data.employments;
    const total = 100;
    const radius = 90;
    const viewBoxSize = 200;
    const strokeWidth = 10;
    const center = viewBoxSize / 2;
    const gapDegrees = 10;

    const describeArc = (x, y, radius, startAngle, endAngle) => {
        const start = polarToCartesian(x, y, radius, endAngle);
        const end = polarToCartesian(x, y, radius, startAngle);
        const largeArcFlag = endAngle - startAngle >= 180 ? "1" : "0";
        return [
            "M", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");
    };

    const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    };

    const startFreemium = gapDegrees / 2;
    const endFreemium = startFreemium + (freemium.percentage / total) * 360 - gapDegrees;
    const startPremium = endFreemium + gapDegrees;
    const endPremium = startPremium + (premium.percentage / total) * 360 - gapDegrees;
    const startDiamond = endPremium + gapDegrees;
    const endDiamond = startDiamond + (diamond.percentage / total) * 360 - gapDegrees;
    const innerCircleRadius = 70;
    return (
        <div style={{ width: 200, height: 200, position: 'relative' }}>
            <CircularProgressbarWithChildren
                value={100}
                strokeWidth={strokeWidth}
                styles={buildStyles({
                    strokeLinecap: 'round',
                    pathColor: 'white',
                })}
            >
                <svg style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }} viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
                    <path d={describeArc(center, center, radius, startFreemium, endFreemium)} fill="none" stroke="#003EFF" strokeWidth={strokeWidth} strokeLinecap="round" />
                    <path d={describeArc(center, center, radius, startPremium, endPremium)} fill="none" stroke="#FFC700" strokeWidth={strokeWidth} strokeLinecap="round" />
                    <path d={describeArc(center, center, radius, startDiamond, endDiamond)} fill="none" stroke="#7B61FF" strokeWidth={strokeWidth} strokeLinecap="round" />
                </svg>

                <svg style={{ width: '190px', height: '190px', position: 'absolute', left: '2.5%' }} viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
                    <circle cx={center} cy={center} r={innerCircleRadius} fill="transparent" stroke="rgba(0, 62, 255, 0.25)" strokeWidth="2" />
                    <text x={center} y={center} textAnchor="middle" fill="blue" fontSize="40" fontWeight="bold" fontFamily="Arial, sans-serif">{data?.employments?.total}</text>
                    <text x={center} y={center + 30} textAnchor="middle" fill="blue" fontSize="12" fontFamily="Arial, sans-serif">Avisos Publicados</text>
                </svg>




            </CircularProgressbarWithChildren>
        </div>
    );
};

export default SiteStatsSpinner;