import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/talent-projects';

export const useTalentProjectsApi = () => {
    const [registerData] = useContext(RegisterDataContext);
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;

    // Function to create a talent project
    const createTalentProject = async (projectData) => {
        try {
            const response = await axios.post(apiBaseUrl, projectData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || token || registerData.token}`
                }
            });
            console.log('Talent project created:', response.data);
            return response.data;
        } catch (error) {
            const errorType = error.response?.statusText || 'Error';
            const errorMessage = error.response?.data || error.message;
            toast.error(`Error creating talent project: ${errorType} - ${errorMessage}`);
        }
    };

    // Function to get all projects for a talent
    const getTalentProjects = async () => {
        try {
            const response = await axios.get(apiBaseUrl, {
                headers: {
                    'Authorization': `Bearer ${userData.token || token || registerData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching talent projects:', error);
            return [];
        }
    };

    // Function to update a specific talent project
    const updateTalentProject = async (projectId, updateData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${projectId}`, updateData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || token || registerData.token}`
                }
            });
            console.log('Talent project updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating talent project:', error.response ? error.response.data : error);
            throw error;
        }
    };

    // Function to delete a specific talent project
    const deleteTalentProject = async (projectId) => {
        try {
            await axios.delete(`${apiBaseUrl}/${projectId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || token || registerData.token}`
                }
            });
            console.log('Talent project deleted');
        } catch ({ response, message }) {
            const serverMessage = response?.data?.message || 'Internal Server Error';
            const errorMessage = `Error deleting talent project: ${serverMessage || message}`;
            toast.error(errorMessage);
        }
    };

    return {
        createTalentProject,
        getTalentProjects,
        updateTalentProject,
        deleteTalentProject
    };
};
