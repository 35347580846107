export const selectableOptions = [
    [
        { title: 'Relación de dependencia' },
        { title: 'Freelance / Autónomo' },
        { title: 'Prácticas / Pasantía' },
    ],
    [{ title: 'Puesto fijo' }, { title: 'Proyecto' }, { title: 'Temporal' }],
    [
        { title: 'Full Time' },
        { title: 'Part Time' },
        { title: 'Horas estipuladas' },
        { title: 'Turnos' },
        { title: 'Voluntariado' },
    ],
];

export const modHiringOptions = [
    {
        title: 'Presencial',
        description: 'Deseo trabajar en las instalaciones de la empresa.',
    },
    {
        title: 'Híbrido',
        description: 'Deseo trabajar tanto en la empresa como en mi casa.',
    },
    {
        title: 'Remoto',
        description: 'Me siento más cómodo trabajando fuera de la oficina.',
    },
];

export const remoteModHiringOptions = [
    {
        title: 'Remoto global',
        description: 'Quiero trabajar desde cualquier parte del mundo.',
    },
    {
        title: 'Remoto nacional',
        description: 'Quiero trabajar desde cualquier lugar del país.',
    },
    {
        title: 'Horas estipuladas',
        description: 'Deseo trabajar una cantidad de horas específicas por día.',
    },
    {
        title: 'Huso horario específico',
        description: 'Trabajar remoto dentro de un husos horario determinado',
    },
];

export const salaryOptions = [
    {
        title: 'Por proyecto',
        description: 'Se remunera al finalizar cada proyecto asignado.',
    },
    {
        title: 'Pago mensual',
        description: 'Se remunera el salario al finalizar cada mes.',
    },
    {
        title: 'Pago anual',
        description: 'Este es el presupuesto anual para este proyecto.',
    },
    {
        title: 'Indiferente',
    },
];

export const formatDate = (date) => {
    if (!date) return '';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().substr(-2);
    return `${day}/${month}/${year}`;
};