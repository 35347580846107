import React, { useState, useCallback, useContext, useEffect } from 'react';
import Step1CreateJob from './steps/Step1CreateJob';
import useEmployments from '../api/Employments';
import { toast } from 'react-toastify';
import CompanyPreviewProfile from './steps/CompanyPreviewProfile';
import infoNotification from '../../../Assets/infoNotification.svg';
import { UserContext } from '../../../contexts/UserContext';
import { usePositionsApi } from '../../../api/PositionsApi';
import { useAuthApi } from '../../Register/Empresa/Api/CompanyApi';
import CompanySector from '../../../Assets/CompanySector.svg';
import CompanySize from '../../../Assets/CompanySize.svg';
import { sizeMappingReverse } from '../../Register/Empresa/Steps/ProfileCompany';
import Step2CreateJob from './steps/step2CreateJob/Step2CreateJob';
import Step3CreateJob, {
  skillsLevels,
} from './steps/step3CreateJob/Step3CreateJob';
import { levels } from './steps/step3CreateJob/Case1Step3CreateJob';
import Step4CreateJob from './steps/step4CreateJob/Step4CreateJob';

/** @type {EmploymentData} */

const initialEmploymentData = {
  position_id: 0,
  seniority: '',
  work_area_id: 0,
  city: '',
  country: '',
  coordinates: [''],
  contract: '',
  duration: '',
  from_date: '',
  to_date: '',
  dedication: '',
  modality: '',
  timezone: 0,
  work_days: {
    on_site: [''],
    remote: [''],
    from_hour: '',
    to_hour: '',
  },
  education_level: '',
  require_certification: true,
  require_education: true,
  responsabilities: '',
  payment: '',
  currency: '',
  min_salary: 0,
  max_salary: 0,
  require_recommendations: true,
};

export default function CreateJob({ id }) {
  const [step, setStep] = useState(1);
  const [position, setPosition] = useState('');
  const [workArea, setWorkArea] = useState('');
  const [employmentData, setEmploymentData] = useState(initialEmploymentData);
  const {
    createEmployment,
    updateEmployment,
    createEmploymentLanguage,
    createEmploymentTool,
    createEmploymentBenefit,
  } = useEmployments();
  const { userData } = useContext(UserContext);
  const { fetchPosition } = usePositionsApi();
  const { getCompanyWorkArea } = useAuthApi();
  const {
    getEmploymentLanguages,
    getEmploymentTool,
    getEmploymentHardSkills,
    createEmploymentHardSkill,
  } = useEmployments();
  const [employmentId, setEmploymentId] = useState(id);
  const [hiringData, setHiringData] = useState([]);
  const [companyLangDataTranslate, setCompanyLangDataTranslate] = useState([]);
  const [companyHardSkillsDataTranslate, setCompanyHardSkillsDataTranslate] =
    useState([]);

  const [companyToolsDataTranslate, setCompanyToolsDataTranslate] = useState(
    []
  );
  const [companyLangData, setCompanyLangData] = useState([]);
  const [hardSkillsData, setHardSkillsData] = useState([]);
  const [benefitsData, setBenefitsData] = useState([]);
  const [companyToolsData, setCompanyToolsData] = useState([]);

  const handleSaveFormStep1 = useCallback(
    async (
      title,
      selectedExpertiseId,
      seniority,
      ubication,
      expertiseSpecialization
    ) => {
      try {
        const newEmployment = await createEmployment({
          position_id: title,
          seniority: seniority,
          work_area_id: selectedExpertiseId,
          city: ubication.city,
          country: ubication.country,
          coordinates: ubication.coordinates,
        });
        setEmploymentData(newEmployment);
        setEmploymentId(newEmployment.id);
        setStep(2);
        console.log('Employment created', newEmployment);
        console.log(
          `position_id: ${title}, seniority: ${seniority}, work_area_id: ${selectedExpertiseId}, city: ${ubication.city}, country: ${ubication.country}, coordinates: ${ubication.coordinates}, expertiseSpecialization: ${expertiseSpecialization}`
        );
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
      console.log(
        `Title: ${title}, workAreaId: ${selectedExpertiseId}, seniority: ${seniority}, ubication: ${
          ubication.city
        } - ${ubication.country} - ${
          ubication.coordinates
        }, expertiseSpecialization: ${expertiseSpecialization.map(
          (specialization) => specialization.id
        )}`
      );
    },
    [createEmployment]
  );

  const handleUpdateFormStep1 = useCallback(
    async (title, selectedExpertiseId, seniority, ubication) => {
      try {
        const updatedEmployment = await updateEmployment(employmentData.id, {
          position_id: title,
          seniority: seniority,
          work_area_id: selectedExpertiseId,
          city: ubication.city,
          country: ubication.country,
          coordinates: ubication.coordinates,
        });
        setEmploymentData(updatedEmployment);
        setStep(2);
        console.log('Employment updated', updatedEmployment);
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
    },
    [updateEmployment, employmentData.id]
  );

  const handleSaveFormStep2 = async (
    vacancyData,
    contratData,
    modalityData
  ) => {
    try {
      const newEmployment = await updateEmployment(employmentId, {
        vacancies: vacancyData,
        contract: contratData.contract,
        duration: contratData.duration,
        dedication: contratData.dedication,
        modality: modalityData.modality,
        from_date: contratData.from_date,
        to_date: contratData.to_date,
        work_days: {
          on_site: modalityData.work_days.onsite,
          remote: modalityData.work_days.remote,
          from_hour: modalityData.work_days.from_hour,
          to_hour: modalityData.work_days.to_hour,
        },
      });
      setEmploymentData(newEmployment);
      console.log('Employment updated', newEmployment);
      setStep(4);
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleSaveFormStep3 = async (
    langsData,
    educationData,
    toolsData,
    hardSkillsData,
    benefitsData
  ) => {
    const promises = [];

    console.log('benefitsData', benefitsData);

    if (Array.isArray(langsData)) {
      for (const tool of langsData) {
        const { langid, levelid } = tool;

        const promise = createEmploymentLanguage({
          employment_id: employmentId,
          language_id: langid,
          level: levelid,
        })
          .then(() => {
            console.log('Lenguaje creado correctamente');
          })
          .catch((error) => {
            toast.error(`${error}, langid: ${langid}, levelid: ${levelid}`);
          });

        promises.push(promise);
      }
    } else {
      console.error('toolsData is not an array');
    }

    if (educationData) {
      const promise = updateEmployment(employmentId, {
        education_level: educationData.education_level,
        require_certification: educationData.require_certification,
        require_education: educationData.require_education,
      })
        .then(() => {
          console.log('Educación actualizada correctamente');
        })
        .catch((error) => {
          toast.error(`Error: ${error.message}`);
        });

      promises.push(promise);
    } else {
      console.error('educationData is not an object');
    }

    if (Array.isArray(toolsData)) {
      for (const tool of toolsData) {
        const promise = createEmploymentTool({
          employment_id: employmentId,
          tool_id: tool.id,
          level: tool.levelId,
        })
          .then(() => {
            console.log('Herramienta creada correctamente');
          })
          .catch((error) => {
            toast.error(
              `${error}, toolid: ${tool.id}, levelid: ${tool.levelId}`
            );
          });

        promises.push(promise);
      }
    } else {
      toast.error('toolsData is not an array');
    }

    if (Array.isArray(benefitsData)) {
      for (const benefit of benefitsData) {
        const promise = createEmploymentBenefit({
          employment_id: employmentId,
          benefit_id: benefit.id,
        })
          .then(() => {
            console.log('Beneficio creado correctamente');
          })
          .catch((error) => {
            toast.error(`${error}, benefitid: ${benefit.id}`);
          });

        promises.push(promise);
      }
    } else {
      console.error('benefitsData is not an array');
    }

    if (Array.isArray(hardSkillsData)) {
      for (const skill of hardSkillsData) {
        const promise = createEmploymentHardSkill({
          employment_id: employmentId,
          hard_skill_id: skill.id,
        })
          .then(() => {
            console.log('Habilidad técnica creada correctamente');
          })
          .catch((error) => {
            toast.error(`${error}, skillid: ${skill.id}`);
          });

        promises.push(promise);
      }
    } else {
      console.error('hardSkillsData is not an array');
    }

    try {
      await Promise.all(promises);
      setStep(6);
    } catch (error) {
      console.error('Error processing data:', error);
    }
  };

  const handleSaveFormStep4 = async (
    responsabilities,
    salary,
    recommendations,
    softSkills,
    culture
  ) => {
    console.log('responsabilities', responsabilities);
    console.log('salary', salary);
    console.log('recommendations', recommendations);
    console.log('softSkills', softSkills);
    console.log('culture', culture);
  };

  const handleUpdateFormStep2 = async (contratData, modalityData) => {
    try {
      const updatedEmployment = await updateEmployment(employmentId, {
        contract: contratData.contract,
        duration: contratData.duration,
        dedication: contratData.dedication,
        modality: modalityData.modality,
        from_date: contratData.from_date,
        to_date: contratData.to_date,
        work_days: {
          on_site: modalityData.work_days.onsite,
          remote: modalityData.work_days.remote,
          from_hour: modalityData.work_days.from_hour,
          to_hour: modalityData.work_days.to_hour,
        },
      });
      setEmploymentData(updatedEmployment);
      console.log('Employment updated', updatedEmployment);
      setStep(4);
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const TranslateData = useCallback((data) => {
    const translations = {
      BETWEEN_1_2: 'De 1 a 2 Personas',
      BETWEEN_3_5: 'De 3 a 5 Personas',
      MORE_THAN_6: 'De 6 o más Personas',
      IN_RELATION: 'Relación de Dependencia',
      FREELANCE: 'Freelance / Autónomo',
      PRACTICE: 'Prácticas / Pasantías',
      LONG_TERM: 'Largo Plazo',
      PROJECT: 'Proyecto',
      PERIOD: 'Período',
      FULL_TIME: 'Tiempo Completo',
      PART_TIME: 'Medio Tiempo',
      SPECIFIC_HOURS: 'Horas Específicas',
      SHIFTS: 'Turnos',
      VOLUNTEERING: 'Voluntariado',
      TIMEZONE_REMOTE: 'Remoto con Zona Horaria',
      TIMEZONE_ONSITE: 'Presencial con Zona Horaria',
      ON_SITE: 'Presencial',
      HIBRYD: 'Híbrido',
      GLOBAL_REMOTE: 'Remoto Global',
      LOCAL_REMOTE: 'Remoto Local',
      FRIDAY: 'Viernes',
      WEDNESDAY: 'Miércoles',
      THURSDAY: 'Jueves',
      MONDAY: 'Lunes',
      TUESDAY: 'Martes',
      SATURDAY: 'Sábado',
      SUNDAY: 'Domingo',
    };
    return translations[data] || data;
  }, []);

  const handleUpdateFormStep3 = async (
    langsData,
    educationData,
    toolsData,
    hardSkillsData,
    benefitsData
  ) => {
    console.log('langsData', langsData);
    console.log('educationData', educationData);
    console.log('toolsData', toolsData);
    console.log('hardSkillsData', hardSkillsData);
    console.log('benefitsData', benefitsData);
  };

  useEffect(() => {
    if (employmentData.position_id) {
      fetchPosition(employmentData.position_id).then((position) => {
        setPosition(position.name_es);
      });
    }
    if (userData.company?.company_work_area_id) {
      getCompanyWorkArea(userData.company?.company_work_area_id).then(
        (workArea) => {
          setWorkArea(workArea.name_es);
        }
      );
    }
    if (employmentId) {
      getEmploymentLanguages(employmentId).then((languages) => {
        setCompanyLangData(languages);
        const levelMap = levels.reduce((acc, level) => {
          acc[level.id] = level.name;
          return acc;
        }, {});

        const translatedLanguages = languages
          .map((lang) => {
            const languageName = lang.language.name_es;
            const levelName = levelMap[lang.level];
            return `${languageName} / ${levelName}`;
          })
          .join(', ');
        console.log('translatedLanguages', translatedLanguages);
        setCompanyLangDataTranslate(translatedLanguages);
      });

      getEmploymentHardSkills(employmentId).then((hardSkills) => {
        setHardSkillsData(hardSkills);
        const translatedHardSkills = hardSkills
          .map((skill) => skill.hard_skill.name_es)
          .join(', ');
        console.log('translatedHardSkills', translatedHardSkills);
        setCompanyHardSkillsDataTranslate(translatedHardSkills);
      });

      getEmploymentTool(employmentId).then((tools) => {
        setCompanyToolsData(tools);
        const levelMap = skillsLevels.reduce((acc, level) => {
          acc[level.id] = level.name;
          return acc;
        }, {});

        const translatedTools = tools
          .map((tool) => {
            const toolName = tool.tool.name_es;
            const levelName = levelMap[tool.level];
            return `${toolName} / ${levelName}`;
          })
          .join(', ');

        console.log('translatedTools', translatedTools);
        setCompanyToolsDataTranslate(translatedTools);
      });
    }
  }, []);

  useEffect(() => {
    const hiring = [
      TranslateData(employmentData.contract),
      TranslateData(employmentData.vacancies),
      TranslateData(employmentData.dedication),
    ];
    if (
      employmentData.contract &&
      employmentData.vacancies &&
      employmentData.dedication
    ) {
      setHiringData(hiring);
    }
  }, [TranslateData, employmentData]);

  switch (step) {
    case 1:
      return <Step1CreateJob handleSaveForm={handleSaveFormStep1} />;
    case 2:
      return (
        <CompanyPreviewProfile
          isCompany
          CurrentStep={1}
          imgProfile={userData?.company?.profile_image}
          name={userData?.company?.name}
          location={employmentData.city}
          seniority={employmentData.seniority}
          infoNotification={infoNotification}
          boldPart="¡Bien hecho!"
          normalText="Es momento de determinar las capacidades técnicas fundamentales para el rol."
          position={position}
          companyWorkArea={workArea}
          CompanySector={CompanySector}
          CompanySize={CompanySize}
          size={sizeMappingReverse[userData?.company?.size]}
          continueStep={() => setStep(3)}
          goProfileCardModule={() => setStep('EditCase1')}
          goBackStep={() => setStep(1)}
        />
      );
    case 3:
      return (
        <Step2CreateJob
          CurrentStep={1}
          isGoBack={() => setStep(2)}
          handleSaveFormStep2={handleSaveFormStep2}
        />
      );
    case 4:
      return (
        <CompanyPreviewProfile
          isCompany
          CurrentStep={2}
          imgProfile={userData?.company?.profile_image}
          name={userData?.company?.name}
          location={employmentData.city}
          seniority={employmentData.seniority}
          infoNotification={infoNotification}
          boldPart="¡Bien hecho!"
          normalText="Es momento de determinar las capacidades técnicas fundamentales para el rol."
          position={position}
          companyWorkArea={workArea}
          CompanySector={CompanySector}
          CompanySize={CompanySize}
          size={sizeMappingReverse[userData?.company?.size]}
          continueStep={() => setStep(5)}
          goProfileCardModule={() => setStep('EditCase1')}
          goBackStep={() => setStep(1)}
          hiring={hiringData}
          modHiring={TranslateData(employmentData.modality)}
          borderBlue
          goHiringModule={() => setStep('EditCase2')}
        />
      );
    case 5:
      return (
        <Step3CreateJob
          handleSaveFormStep3={handleSaveFormStep3}
          isGoBack={() => setStep(4)}
        />
      );
    case 6:
      return (
        <CompanyPreviewProfile
          isCompany
          CurrentStep={3}
          imgProfile={userData?.company?.profile_image}
          name={userData?.company?.name}
          location={employmentData.city}
          seniority={employmentData.seniority}
          infoNotification={infoNotification}
          boldPart="¡Bien hecho!"
          normalText="Es momento de determinar las capacidades técnicas fundamentales para el rol."
          position={position}
          companyWorkArea={workArea}
          CompanySector={CompanySector}
          CompanySize={CompanySize}
          size={sizeMappingReverse[userData?.company?.size]}
          continueStep={() => setStep(7)}
          goProfileCardModule={() => setStep('EditCase1')}
          goBackStep={() => setStep(1)}
          hiring={hiringData}
          modHiring={TranslateData(employmentData.modality)}
          borderBlue
          goHiringModule={() => setStep('EditCase2')}
          langs={companyLangDataTranslate}
          hardSkills={companyHardSkillsDataTranslate}
          techSkills={companyToolsDataTranslate}
        />
      );
    case 7:
      return (
        <Step4CreateJob
          handleSaveFormStep4={handleSaveFormStep4}
          isGoBack={() => setStep(6)}
        />
      );
    case 'EditCase1':
      return (
        <Step1CreateJob
          handleSaveForm={handleUpdateFormStep1}
          title={employmentData.position_id}
          selectedExpertiseId={employmentData.work_area_id}
          isGoBack={
            employmentData.position_id ? () => setStep(2) : () => setStep(1)
          }
          seniority={employmentData.seniority}
          ubication={{
            city: employmentData.city,
            country: employmentData.country,
            coordinates: employmentData.coordinates,
          }}
        />
      );
    case 'EditCase2':
      return (
        <Step2CreateJob
          CurrentStep={1}
          isGoBack={() => setStep(4)}
          handleSaveFormStep2={handleUpdateFormStep2}
          contrat={{
            contract: employmentData.contract,
            duration: employmentData.duration,
            dedication: employmentData.dedication,
            from_date: employmentData.from_date,
            to_date: employmentData.to_date,
          }}
          modality={{
            modality: employmentData.modality,
            timezone: employmentData.timezone,
            work_days: {
              onsite: employmentData.work_days.on_site,
              remote: employmentData.work_days.remote,
              from_hour: employmentData.work_days.from_hour,
              to_hour: employmentData.work_days.to_hour,
            },
          }}
          vacancy={employmentData.vacancies}
        />
      );
    case 'EditCase3':
      return (
        <Step3CreateJob
          handleSaveFormStep3={handleUpdateFormStep3}
          isGoBack={() => setStep(6)}
          langs={companyLangData}
          education={
            employmentData.education_level
              ? {
                  education_level: employmentData.education_level,
                  require_certification: employmentData.require_certification,
                  require_education: employmentData.require_education,
                }
              : null
          }
          tools={companyToolsData}
          hardSkills={hardSkillsData}
          benefits={benefitsData}
        />
      );
    default:
      return null;
  }
}
