import React from "react";
import { toast } from 'react-toastify';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Guardar el error y la información del error en el estado
        this.setState({ error, errorInfo });

        // Mostrar el error en la consola y con un toast
        toast.error("Error caught by Error Boundary.");
        console.error("Error:", error);
        console.error("ErrorInfo:", errorInfo);
    }

    handleReload = () => {
        // Recargar manualmente la página
        window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            return (
                <div style={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#000',
                    color: '#721c24',
                    fontSize: 'large',
                    fontWeight: 'bold',
                    border: '1px solid #f5c6cb',
                    boxSizing: 'border-box',
                    position: 'relative'
                }}>
                    <h1>Something went wrong.</h1>
                    <button onClick={this.handleReload} style={{
                        marginTop: '20px',
                        padding: '10px 20px',
                        backgroundColor: '#721c24',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}>
                        Reload Page
                    </button>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
