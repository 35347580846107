import React, { useState, useMemo, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DropdownComponent from "../../../Shared/DropdownComponent";
import {
    fullScreenStyles,
    pointsContainerStyle,
    logoStyle,
    linkStyle,
    cardSkillStyle,
    buttonStyleP
} from "./ProfilePersStyles";
import BlueLogo from "../../../../Assets/BlueLogo.svg";
import BackBlue from "../../../../Assets/BackBlue.svg";
import ButtonComponent from "../../../Shared/ButtonComponent";
import InfoIcon from "../../../../Assets/InfoIcon.svg";
import EditIconBlack from "../../../../Assets/EditIconBlack.svg";
import './ProfilePers.css';
import { useSoftSkillsApi } from '../../../../api/SoftSkillsApi';
import { useTalentSoftSkillsApi } from '../../../../api/TalentSoftSkills';
import { UserContext } from '../../../../contexts/UserContext';
import { toast } from 'react-toastify';



const ProfilePersFive = ({ back, dataskills, gotostep }) => {
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [points, setPoints] = useState(100);
    const [skillPoints, setSkillPoints] = useState(0);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [hasInteracted, setHasInteracted] = useState(false);
    const [hasConfirmed, setHasConfirmed] = useState(false);
    const { createSoftSkill } = useSoftSkillsApi();
    const [softSkills, setSoftSkills] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { createTalentSoftSkillRelation } = useTalentSoftSkillsApi();
    const { userData } = useContext(UserContext);



    useEffect(() => {
        const fetchSoftSkills = async () => {
            setIsLoading(true);
            const skills = await createSoftSkill();
            setSoftSkills(skills);
            setIsLoading(false);
        };
        fetchSoftSkills();
    }, []);


    const handleConfirm = () => {
        setHasConfirmed(true);
    };

    const availableSkills = useMemo(() => {
        if (isLoading) {
            return [];
        }

        return softSkills.filter(
            (skill) => !selectedSkills.some((selected) => selected.title === skill.title)
        );
    }, [selectedSkills, isLoading]);

    const handleSelectSkill = (skillTitle) => {
        const skill = softSkills.find((s) => s.title === skillTitle);
        const existingSkill = selectedSkills.find((s) => s.title === skillTitle);
        setSelectedSkill(skill);
        setSkillPoints(existingSkill ? existingSkill.points : 0);
    };

    const handleIncrease = () => {
        if (skillPoints < 10 && points > 0) { // Cambio para asegurar que no se incrementen los puntos si no hay puntos disponibles
            setSkillPoints(skillPoints + 1);
            setPoints(points - 1); // Disminuye los puntos globales al incrementar skillPoints
        }
    };

    const handleDecrease = () => {
        if (skillPoints > 0) {
            setSkillPoints(skillPoints - 1);
            setPoints(points + 1); // Incrementa los puntos globales al disminuir skillPoints
        }
    };

    const handleContinue = () => {
        if (selectedSkill && skillPoints > 0) {
            const newPoints =
                points -
                skillPoints +
                (selectedSkills.find((s) => s.title === selectedSkill.title)?.points ||
                    0);
            setPoints(newPoints);

            const newSelection = { title: selectedSkill.title, points: skillPoints };
            const otherSkills = selectedSkills.filter(
                (s) => s.title !== selectedSkill.title
            );
            setSelectedSkills([...otherSkills, newSelection]);

            setSelectedSkill(null);
            setSkillPoints(0);
            setHasInteracted(true);
        }
    };

    const handleSave = async () => {
        for (const skill of selectedSkills) {
            const relationData = {
                talent_id: userData.talent.id,
                soft_skill_id: softSkills.find((s) => s.title === skill.title).id,
                score: skill.points,
            };
            try {
                await createTalentSoftSkillRelation(relationData);
            } catch (error) {
                toast.error('Error creating Talent-SoftSkill relation:', error.response ? error.response.data : error);
            }
        }


        dataskills(selectedSkills);

        console.log(selectedSkills);
        gotostep(14);
    };

    const handleBack = () => {
        if (selectedSkills.length > 0) {
            const lastSelectedSkill = selectedSkills[selectedSkills.length - 1];
            const updatedSkills = selectedSkills.slice(0, -1);

            setSelectedSkills(updatedSkills);
            setSelectedSkill(
                softSkills.find((skill) => skill.title === lastSelectedSkill.title)
            );
            setSkillPoints(lastSelectedSkill.points);
            setPoints(points + lastSelectedSkill.points);
        }
    };

    const handleEditSkill = (skillTitle) => {
        const skillToEdit = softSkills.find(skill => skill.title === skillTitle);
        const selectedSkill = selectedSkills.find(skill => skill.title === skillTitle);
        if (!skillToEdit || !selectedSkill) return;
        setSelectedSkill(skillToEdit);
        setSkillPoints(selectedSkill.points);
    };

    const renderHasConfirmed = () => {
        if (!hasConfirmed) {
            return (
                <div style={fullScreenStyles}>
                    <img src={BlueLogo} alt="BlueLogo" style={logoStyle} />
                    <Container
                        fluid
                        className="h-100 d-flex align-items-center justify-content-between flex-column"
                    >
                        <button
                            style={{
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "rgba(255, 255, 255, 0.15)",
                                position: "absolute",
                                top: "10px",
                                left: "10px",
                                zIndex: 1000,
                                border: "none",
                                cursor: "pointer",
                            }}
                            onClick={back}
                        >
                            <img src={BackBlue} alt="back" style={{ width: "20px" }} />
                        </button>
                        <Row>
                            <Col xs={12} className="gap-after d-flex flex-column mb-5">
                                <span style={{ color: '#003EFF', fontSize: '1.5rem', fontWeight: 'bolder' }}>¡Buen trabajo llegando hasta este punto!</span>
                                <span style={{ color: '#003eff' }}>EMD Habilidades Blandas te ayudará a descubrir la oportunidad que mejor se adapte a ti.</span>
                            </Col>
                            <Col xs={12} className="gap-after text-left mt-5">
                                <span style={{
                                    fontWeight: "bold",
                                    fontSize: "1.2rem",
                                }}>¿Cómo funciona?</span>
                            </Col>
                            <Col xs={12} className="gap-after d-flex flex-column text-left">
                                <span className="mb-2">Comenzarás con un crédito de 100 puntos, libre para distribuir entre las habilidades blandas. Cada habilidad puede recibir un puntaje entre 1 y 10.</span>
                                <span className="mb-2">Tienes total libertad para seleccionar las que prefieras. Sin embargo, hay un límite: el total de puntos distribuidos entre todas las habilidades no puede exceder los 100 puntos asignados inicialmente.</span>
                                <span>Además, la plataforma te ofrece flexibilidad, permitiéndote modificar los puntajes de tus habilidades según lo necesites, sin límite en la cantidad de ajustes que puedes realizar.</span>
                            </Col>
                        </Row>
                        <Row className="w-100 mb-3">
                            <Col xs={12} className="gap-after">
                                <ButtonComponent text="Confirmar" large version="fill" paddingY={'11px'} isHover onClick={handleConfirm} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        } else {
            return (
                <div style={fullScreenStyles}>
                    <img src={BlueLogo} alt="BlueLogo" style={logoStyle} />
                    <Container
                        fluid
                        className="h-100 d-flex align-items-center justify-content-between flex-column"
                    >
                        <button
                            style={{
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "rgba(255, 255, 255, 0.15)",
                                position: "absolute",
                                top: "10px",
                                left: "10px",
                                zIndex: 1000,
                                border: "none",
                                cursor: "pointer",
                            }}
                            onClick={back}
                        >
                            <img src={BackBlue} alt
                                ="back" style={{ width: "20px" }} />
                        </button>
                        <Row>
                            {!selectedSkill && (
                                <>
                                    <Col xs={12} className="gap-after text-left font-weight-bold">
                                        <span >¿Cómo funciona?</span>
                                    </Col>
                                    <Col xs={12} className="gap-after text-left">
                                        <span>Comenzarás con un crédito de 100 puntos, libre para distribuir entre las habilidades blandas. Cada habilidad puede recibir un puntaje entre 1 y 10.</span>
                                    </Col>
                                </>
                            )}
                            <Col xs={12} className="gap-after">
                                <div style={pointsContainerStyle}><span style={{
                                    fontWeight: "600",
                                    fontSize: "2rem",
                                }} >{points}</span><span style={{
                                    fontWeight: "500",
                                }}>PUNTOS</span></div>
                            </Col>
                            {(selectedSkills.length === 0) && (
                                <Col xs={12} className="gap-after">
                                    <DropdownComponent
                                        isBordered={true}
                                        placeholder="Selecciona una habilidad blanda"
                                        options={availableSkills}
                                        onOptionSelect={handleSelectSkill}
                                        selectedOption={selectedSkill}
                                        style={{ width: "100%" }}
                                        paddingY={'20px'}
                                    />
                                </Col>
                            )}
                            {!selectedSkill && selectedSkills.length > 0 && (
                                <>
                                    <Col xs={12} className="gap-after">
                                        <DropdownComponent
                                            isBordered={true}
                                            placeholder="Selecciona una habilidad blanda"
                                            options={availableSkills}
                                            onOptionSelect={handleSelectSkill}
                                            selectedOption={selectedSkill}
                                            style={{ width: "100%" }}
                                            paddingY={'20px'}
                                        />
                                    </Col>
                                    {selectedSkills.map((skill) => (
                                        <Col xs={12} key={skill.title} className="gap-after">
                                            <div style={cardSkillStyle}>
                                                <div className="d-flex justify-content-between">
                                                    <span className="font-weight-bold">{skill.points}</span>
                                                    <button
                                                        style={{
                                                            border: "none",
                                                            backgroundColor: "transparent",
                                                        }}
                                                        onClick={() => handleEditSkill(skill.title)}
                                                    ><img src={EditIconBlack} alt="BackBlue" style={{ width: "20px", height: "20px" }} /></button>
                                                </div>
                                                <span className="text-left">{skill.title}</span>
                                            </div>
                                        </Col>
                                    ))}
                                </>
                            )}
                            {
                                !selectedSkill && selectedSkills.length > 0 && (
                                    <Col xs={12} className="gap-after">
                                        <ButtonComponent text="EDM habilidades blandas esta incompleto; Para aprovechar al máximo la plataforma sería recomendable que lo completes." version="outline" large icon={InfoIcon} isLeft paddingY={'20px'} isColorBlack />
                                    </Col>
                                )
                            }
                            {selectedSkill && (
                                <>
                                    <Col xs={12} className="gap-after">
                                        <p>
                                            Selecciona un puntaje entre 1 y 10 para {selectedSkill.title}
                                        </p>
                                    </Col>
                                    <Col xs={12} className="gap-after">
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1.5rem" }}>
                                            <button className="active" onClick={handleDecrease} style={buttonStyleP}>-</button>
                                            <span style={
                                                {
                                                    fontWeight: "600",
                                                    fontSize: "2rem",
                                                }
                                            }>{skillPoints}</span>
                                            <button className="active" onClick={handleIncrease} style={buttonStyleP}>+</button>
                                        </div>
                                    </Col>
                                    <Col xs={12} className="gap-after" style={{ textAlign: "left", fontWeight: 'bold', fontSize: '1.7rem' }}>
                                        <span className="">{selectedSkill.title}</span>
                                    </Col>
                                    <Col xs={12} className="gap-after" style={{ textAlign: "left" }}>
                                        <p>{selectedSkill.description}</p>
                                    </Col>
                                </>
                            )}
                            {selectedSkill ? (
                                <>
                                    <Col xs={12} className="gap-after">
                                        <ButtonComponent onClick={handleContinue} text="Continuar" large version="fill" isHover paddingY={'11px'} />
                                    </Col>
                                    <Col xs={12} className="gap-after" >
                                        <button onClick={handleBack} style={{
                                            ...linkStyle,
                                            padding: "10px",
                                        }}> Regresar</button>
                                    </Col>
                                </>
                            ) : (
                                hasInteracted && (
                                    <>
                                        <Col xs={12} className="gap-after">
                                            <ButtonComponent onClick={handleSave} text="Guardar" large version="fill" paddingY={'11px'} isHover />
                                        </Col>
                                        <Col xs={12} className="gap-after">
                                            <button onClick={handleBack} style={{
                                                ...linkStyle,
                                                padding: "10px",
                                            }}> Regresar</button>
                                        </Col>
                                    </>
                                )
                            )}
                        </Row>
                    </Container>
                </div >
            )
        }
    }

    return (
        <>
            {renderHasConfirmed()}
        </>
    );
};

export default ProfilePersFive;
