import React, { useEffect } from 'react';
import './CardInfoComponent.css';
import hiringImg from '../../../Assets/hiringImg.svg';
import hiringImg2 from '../../../Assets/hiringImg2.svg';
import hiringImg3 from '../../../Assets/hiringImg3.svg';
import hiringImg4 from '../../../Assets/hiringImg4.svg';
import BluePecil from '../../../Assets/isEditIcon.svg';
import Pencil from '../../../Assets/Pencil.svg';

const CardInfoComponent = ({
  hiring,
  modHiring,
  borderBlue,
  goHiringModule,
  zIndex,
}) => {
  useEffect(() => {
    console.log(hiring, modHiring);
  }, [hiring, modHiring]);
  return (
    <div className="d-flex w-100 flex-column" style={{ zIndex: zIndex }}>
      <div className="w-100 d-flex justify-content-between pb-3 px-4">
        <span className={`${borderBlue ? 'text-light' : 'text-light'}`}>
          Contratación
        </span>
        <button
          className="p-0"
          style={{ border: 'none', background: 'none' }}
          onClick={goHiringModule}
        >
          <img src={borderBlue ? BluePecil : Pencil} alt="edit" />
        </button>
      </div>
      <div
        className={`cardInfoComponentMain ${borderBlue ? 'borderBlue' : ''}`}
      >
        {hiring === undefined ? null : (
          <div
            className="d-flex flex-column align-items-center"
            title={hiring[0]}
          >
            <img src={hiringImg2} alt="hiring" />
            <span style={{ cursor: 'pointer' }}>{hiring[0]}</span>
          </div>
        )}
        <hr className="hr-styled" />
        {hiring === undefined ? null : (
          <div
            className="d-flex flex-column align-items-center"
            title={hiring[1]}
          >
            <img src={hiringImg3} alt="hiring" />
            <span style={{ cursor: 'pointer' }}>{hiring[1]}</span>
          </div>
        )}
        <hr className="hr-styled" />
        {hiring === undefined ? null : (
          <div
            className="d-flex flex-column align-items-center"
            title={hiring[2]}
          >
            <img src={hiringImg4} alt="hiring" />
            <span style={{ cursor: 'pointer' }}>{hiring[2]}</span>
          </div>
        )}
        <hr className="hr-styled" />
        {modHiring === undefined ? null : (
          <div
            className="d-flex flex-column align-items-center"
            title={modHiring}
          >
            <img src={hiringImg} alt="hiring" />
            <span style={{ cursor: 'pointer' }}>{modHiring}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardInfoComponent;
