import React from 'react';
import { useHistory } from 'react-router-dom';
import CompanyReusableComponent from '../../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import { Col, Row } from 'react-bootstrap';
import ReusableButton from '../../../components/ReusableButtonsComponent';
import ButtonComponent from '../../../../Shared/ButtonComponent';

const DefaultStep3CreateJob = ({
  isGoBack,
  langs,
  education,
  tools,
  hardSkills,
  benefits,
  goToStep,
  handleSaveFormStep3,
}) => {
  const history = useHistory();
  const handleGoBack = isGoBack || history.goBack;

  return (
    <CompanyReusableComponent
      goBack={handleGoBack}
      subtitle="Tienes la oportunidad de subir un anuncios gratis. Para más, considera nuestra versión premium."
      title="Crea tu propuesta de empleo"
      company="BlueScreen"
    >
      <Row className="w-100">
        <ReusableButton
          text={langs || 'Idiomas requeridos'}
          version={langs ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(1)}
          isColorBlack={!!langs && langs !== 'Idiomas requeridos'}
        />
        <ReusableButton
          text={education || 'Formación'}
          version={education ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(2)}
          isColorBlack={!!education && education !== 'Formación'}
        />
        <ReusableButton
          text={tools || 'Aptitudes técnicas'}
          version={tools ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(3)}
          isColorBlack={!!tools && tools !== 'Aptitudes técnicas'}
        />
        <ReusableButton
          text={hardSkills || 'Skills técnicos'}
          version={hardSkills ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(4)}
          isColorBlack={!!hardSkills && hardSkills !== 'Skills técnicos'}
        />
        <ReusableButton
          text={benefits || ' ¿Qué benficios brinda la empresa?'}
          version={benefits ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(5)}
          isColorBlack={
            !!benefits && benefits !== ' ¿Qué benficios brinda la empresa?'
          }
        />
        <Col xs={12} className="gap-after">
          <ButtonComponent
            text="Confirmar"
            version={
              langs && education && tools && hardSkills && benefits
                ? 'fill'
                : 'grey'
            }
            large
            onClick={handleSaveFormStep3}
            disabled={
              !langs || !education || !tools || !hardSkills || !benefits
            }
          />
        </Col>
      </Row>
    </CompanyReusableComponent>
  );
};

export default DefaultStep3CreateJob;
