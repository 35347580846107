import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import CompanyReusableComponent from '../Components/CompanyReusable/CompanyReusableComponent';
import { useAuthApi } from '../Api/CompanyApi';
import { UserContext } from '../../../../contexts/UserContext';
import ButtonComponent from '../../../Shared/ButtonComponent';
import NotificationIcon from '../../../../Assets/InfoIcon.svg';
import { Col } from 'react-bootstrap';
import ProfileCardCompanyComponent from '../Components/ProfileCardCompanyComponent/ProfileCardCompanyComponent';
import RenderStep5 from './renderStep5';
import { toast } from 'react-toastify';
import { useUploadsApi } from '../../../../api/UploadsApi';
import infoNotification from '../../../../Assets/infoNotification.svg';
import PropTypes from 'prop-types';
import JobCard from '../Components/JobCard/JobCard';
import CreateJob from '../../../Empresa/CreateJob/CreateJob';

const sizeMapping = {
  Microempresa: 'MICRO',
  'Pequeña empresa': 'SMALL',
  'Mediana empresa': 'MEDIUM',
  'Gran empresa': 'BIG',
};

export const sizeMappingReverse = {
  MICRO: 'Microempresa',
  SMALL: 'Pequeña empresa',
  MEDIUM: 'Mediana empresa',
  BIG: 'Gran empresa',
};

const descriptions = {
  Microempresa: 'Descripción para Microempresa',
  'Pequeña empresa': 'Descripción para Pequeña empresa',
  'Mediana empresa': 'Descripción para Mediana empresa',
  'Gran empresa': 'Descripción para Gran empresa',
};

const useCompanyProfile = (
  companyId,
  history,
  getCompanyFullProfile,
  userData,
  setCompanyData
) => {
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    if (!isFetched) {
      const id = companyId || userData.company?.id || userData.companyID;
      if (id === undefined) {
        history.goBack();
      } else {
        getCompanyFullProfile(id).then((response) => {
          setCompanyData(response);
          setIsFetched(true);
        });
      }
    }
  }, [companyId, history, getCompanyFullProfile, userData, isFetched]);
};

const ProfileCompany = ({ companyId }) => {
  ProfileCompany.propTypes = {
    companyId: PropTypes.string,
  };
  const history = useHistory();
  const {
    getCompanyFullProfile,
    updateCompany,
    getCompanyWorkAreas,
    createCompanyWorkArea,
    getCompanyWorkArea,
  } = useAuthApi();
  const { uploadCompanyProfileImage } = useUploadsApi();
  const { userData, updateUserData } = useContext(UserContext);
  const [step, setStep] = useState('main');
  const [currentSection, setCurrentSection] = useState('main');
  const fileInputRef = useRef(null);
  const [imgUrl, setImgUrl] = useState(userData.company?.profile_image);
  const [formDataStep5, setFormDataStep5] = useState({
    name: userData.company?.name,
    size: userData.company?.size,
    company_work_area_id: userData.company?.company_work_area_id,
    city: userData.company?.city,
    country: userData.company?.country,
    coordinates: userData.company?.coordinates,
    is_full_remote: userData.company?.is_full_remote,
    description: userData.company?.description,
    profile_image: userData.company?.profile_image,
  });
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState('');
  const [selectedWorkArea, setSelectedWorkArea] = useState(null);
  const [workAreas, setWorkAreas] = useState([]);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const nameRef = useRef();
  const [workAreaName, setWorkAreaName] = useState('');
  const [companyWorkAreaName, setCompanyWorkAreaName] = useState('');
  const [companyData, setCompanyData] = useState({});
  const [idEmployment, setIdEmployment] = useState(null);

  useEffect(() => {
    if (step === 'isEditProfile') {
      getCompanyWorkAreas()
        .then((response) => {
          setWorkAreas(response);
        })
        .catch((error) => {
          toast.error(`Error: ${error.response}`);
        });
    }
  }, [step, getCompanyWorkAreas]);

  const handleSelectWorkArea = (id) => {
    setSelectedWorkArea(id);
  };

  const effectiveCompanyId = companyId || userData.companyID;

  useCompanyProfile(
    effectiveCompanyId,
    history,
    getCompanyFullProfile,
    userData,
    setCompanyData
  );

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleInputChange = async (e, field) => {
    const { value } = e.target;
    setFormDataStep5((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleSubmit = async () => {
    try {
      const translatedFormData = {
        ...formDataStep5,
        size: sizeMapping[formDataStep5.size],
        is_full_remote: formDataStep5.isRemote,
      };
      const response = await updateCompany(
        userData.company.id,
        translatedFormData
      );
      updateUserData({
        ...userData,
        company: response,
      });

      setCompanyData(response);

      toast.success('Perfil actualizado correctamente');
      setStep('main');
    } catch (error) {
      console.error('Error creating company', error);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await uploadCompanyProfileImage(file);

        const imgUrl = response?.url;
        if (!imgUrl) {
          console.error('Unexpected response structure:', response);
          toast.error('Error: Unexpected response structure');
          return;
        }

        setImgUrl(imgUrl);
        setFormDataStep5((prevData) => ({
          ...prevData,
          profile_image: imgUrl,
        }));

        const reader = new FileReader();
        reader.onloadend = () => {
          setUploadedImage(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error('Error uploading image', error);
        toast.error('Error uploading image');
      }
    }
  };

  const handleSelectEmployees = (emp) => {
    setSelectedEmployees(emp);
  };

  const handleSaveEmployees = () => {
    setFormDataStep5((prevData) => ({ ...prevData, size: selectedEmployees }));
    setCurrentSection('main');
  };

  const handleSaveSector = (id) => {
    setFormDataStep5((prevData) => ({ ...prevData, company_work_area_id: id }));
    setCurrentSection('main');
  };

  const handleAddWorkArea = async (option) => {
    try {
      const newWorkArea = await createCompanyWorkArea({ name_es: option });
      setWorkAreas((prevWorkAreas) => [...prevWorkAreas, newWorkArea]);
      handleSelectWorkArea(newWorkArea.id);
      setFormDataStep5((prevData) => ({
        ...prevData,
        company_work_area_id: newWorkArea.id,
      }));
    } catch (error) {
      toast.error(`Error: ${error.response.data}`);
    }
  };

  const handleOptionSelected = async (option) => {
    const selectedArea = workAreas.find((area) => area.name_es === option);
    if (selectedArea) {
      handleSelectWorkArea(selectedArea.id);
    } else {
      await handleAddWorkArea(option);
    }
  };

  const getPlaceName = useCallback(async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=0b3d9c`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        setFormDataStep5((prevData) => ({
          ...prevData,
          city: data.results[0].address_components[2].long_name,
          country: data.results[0].address_components[5].long_name,
        }));
        return data.results[0].formatted_address;
      } else {
        setFormDataStep5((prevData) => ({
          ...prevData,
          city: 'Desconocido',
          country: 'Desconocido',
        }));
        toast.error('No se encontraron resultados para la ubicación');
        return 'Ubicación desconocida';
      }
    } catch (error) {
      toast.error('Error al obtener el nombre del lugar:', error);
      return 'Error al obtener la ubicación';
    }
  }, []);

  const fetchCurrentLocation = useCallback(
    (logPosition = false, getPlace = false) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            if (logPosition)
              console.log(
                `Ubicación actual ${getPlace ? 'Click:' : ':'} Latitud: ${
                  pos.lat
                }, Longitud: ${pos.lng}`
              );
            if (getPlace) {
              const placeName = await getPlaceName(pos.lat, pos.lng);
              console.log(`La ubicación actual es en ${placeName}`);
            }
            setCurrentPosition(pos);
            setFormDataStep5((prevData) => ({
              ...prevData,
              coordinates: [pos.lat, pos.lng],
            }));
            setIsLoaded(true);
          },
          () => {
            toast.error('Geolocation is not supported by this browser.');
            setIsLoaded(true);
          }
        );
      } else {
        toast.error('Geolocation is not supported by this browser.');
        setIsLoaded(true);
      }
    },
    [getPlaceName]
  );

  const handleOptionSelectedLocation = useCallback((option) => {
    console.log('Option selected:', option);

    setFormDataStep5((prevData) => ({
      ...prevData,
      city: option,
      country: option,
    }));
  }, []);

  const handleSaveLocation = () => {
    console.log('Guardando ubicación:', formDataStep5);
    setCurrentSection('main');
  };

  const isRemoteChange = (e) => {
    setFormDataStep5((prevData) => ({
      ...prevData,
      isRemote: e.target.checked,
    }));
  };

  const handleFindCompanyWorkArea = useCallback(
    async (id) => {
      try {
        const response = await getCompanyWorkArea(id);
        setWorkAreaName(response.name_es.toString());
      } catch (error) {
        toast.error(`Error: ${error.response.data}`);
      }
    },
    [getCompanyWorkArea, setWorkAreaName]
  );

  const handleGoEdit = (id) => {
    console.log('Edit job:', id);
    setIdEmployment(id.id);
    setStep('editEmployments');
  };

  useEffect(() => {
    if (selectedWorkArea !== null) {
      handleFindCompanyWorkArea(selectedWorkArea);
    }
  }, [selectedWorkArea, handleFindCompanyWorkArea]);

  const mockData = {
    handleSetStep: () => setStep('main'),
    setCurrentSection: setCurrentSection,
    currentSection: currentSection,
    setStep: () => setStep('main'),
    handleClick: handleClick,
    imgUrl: imgUrl,
    handleInputChange: handleInputChange,
    handleSubmit: handleSubmit,
    fileInputRef: fileInputRef,
    uploadedImage: uploadedImage,
    setUploadedImage: setUploadedImage,
    setImgUrl: setImgUrl,
    handleImageUpload: handleImageUpload,
    selectedEmployees: selectedEmployees,
    handleSelectEmployees: handleSelectEmployees,
    handleSaveEmployees: handleSaveEmployees,
    descriptions: descriptions,
    formDataStep5: formDataStep5,
    handleSaveSector: handleSaveSector,
    selectedWorkArea: selectedWorkArea,
    workAreas: workAreas,
    handleAddWorkArea: handleAddWorkArea,
    handleSelectWorkArea: handleSelectWorkArea,
    workAreaName: workAreaName,
    handleOptionSelected: handleOptionSelected,
    currentPosition: currentPosition,
    fetchCurrentLocation: fetchCurrentLocation,
    isLoaded: isLoaded,
    handleOptionSelectedLocation: handleOptionSelectedLocation,
    handleSaveLocation: handleSaveLocation,
    isRemoteChange: isRemoteChange,
    nameRef: nameRef,
    setFormDataStep5: setFormDataStep5,
  };

  useEffect(() => {
    getCompanyWorkArea(userData.company?.company_work_area_id)
      .then((response) => {
        setCompanyWorkAreaName(response.name_es);
      })
      .catch((error) => {
        toast.error(`Error: ${error.response}`);
      });
  }, [userData.company?.company_work_area_id]);

  useEffect(() => {
    console.log('Company data:');
  }, []);

  switch (step) {
    case 'main': {
      return (
        <CompanyReusableComponent
          company="ProfileScreen"
          goBack={history.goBack}
          imgProfile={userData.company?.profile_image}
        >
          <div className="d-flex flex-column pt-3" style={{ gap: '10px' }}>
            <Col xs={12} className="gap-after">
              <ButtonComponent
                text={'Comienza a publicar empleos'}
                onClick={() => history.push('/register/empresa/empleos')}
                version="grey"
                large
                isLeft
                icon={NotificationIcon}
                paddingY={'10px'}
              />
            </Col>
            <Col xs={12} className="gap-after">
              <ProfileCardCompanyComponent
                data={{
                  name: userData.company?.name,
                  logo: userData.company?.profile_image,
                  country: userData.company?.country,
                  companyWorkArea: companyWorkAreaName,
                  size: sizeMappingReverse[userData.company?.size],
                  description: userData.company?.description,
                }}
                setStep={setStep}
              />
            </Col>
            <Col xs={12} className="gap-after text-left">
              <span className="h5 w-100">Empleos publicados:</span>
            </Col>
            {companyData?.employments?.length > 0 ? (
              <div style={{ position: 'relative' }}>
                <Col
                  xs={12}
                  className="gap-after"
                  style={{
                    position: 'absolute',
                    overflowY: 'scroll',
                    maxHeight: '400px',
                  }}
                >
                  {companyData.employments.map((employment) => (
                    <JobCard
                      key={employment.id}
                      job={employment}
                      goEdit={handleGoEdit}
                    />
                  ))}
                  {companyData?.employments?.length > 0 ? (
                    <Col xs={12} className="gap-after">
                      <ButtonComponent
                        text={'Crea una oferta'}
                        version="grey"
                        large
                        paddingY={'10px'}
                        onClick={() =>
                          (window.location.href = '/company-profile/empleos')
                        }
                        isHover
                      />
                    </Col>
                  ) : null}
                </Col>
              </div>
            ) : (
              <Col key="no-employments" xs={12} className="gap-after">
                <ButtonComponent
                  text="¡Todo listo! Encuentra talento, publica tu primera oferta laboral aquí."
                  version="outline"
                  large
                  icon={infoNotification}
                  isLeft
                  paddingY={'20px'}
                  paddingX={'20px'}
                  isColorBlack
                />
              </Col>
            )}
            {companyData?.employments?.length > 0 ? null : (
              <Col xs={12} className="gap-after">
                <ButtonComponent
                  text={'Crea una oferta'}
                  version="grey"
                  large
                  paddingY={'10px'}
                  onClick={() =>
                    (window.location.href = '/company-profile/empleos')
                  }
                  isHover
                />
              </Col>
            )}
          </div>
        </CompanyReusableComponent>
      );
    }

    case 'editProfile': {
      return <RenderStep5 {...mockData} />;
    }

    case 'editEmployments': {
      return <CreateJob id={idEmployment} />;
    }
    default: {
      return null;
    }
  }
};

export default ProfileCompany;
