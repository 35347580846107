import React from "react";
import { Row, Col } from "react-bootstrap";
import CompanyReusableComponent from "../Components/CompanyReusable/CompanyReusableComponent";
import ButtonComponent from "../../../Shared/ButtonComponent";
import PortfolioNotification from "../../../../Assets/PortfolioNotification.svg";


const RenderCompanyRole = ({
    handleSetStep,
    selectedRole,
    handleRoleSelection,
    handleSaveRole,
    setCurrentSectionStep3,
}) => (
    <CompanyReusableComponent
        company="WhiteScreen"
        step={handleSetStep}
        title="Selecciona tu rol en la empresa"
        goBack={() => setCurrentSectionStep3("main")}
    >
        <Row className="m-0">
            <Col xs={12} className="gap-after my-5">
                <img src={PortfolioNotification} alt="PortfolioNotification" />
                <span style={{ fontWeight: 'bold', textAlign: 'start' }}>
                    ¿Cuál es tu rol dentro de la empresa?
                </span>
            </Col>
            {["CEO / Owner", "Director", "Gerente de área", "Supervisor / Responsable", "Ejecutivo / Analista", "Representante Junior.", "Secretario / Asistente", "Soporte Técnico", "Practicante / Trainee", "Recursos Humanos", "Adminsitración", "Otro"].map((role) => (
                <Col xs={12} className="gap-after d-flex" key={role}>
                    <label style={{ cursor: "pointer" }}>
                        <input
                            type="radio"
                            name="companyRole"
                            value={role}
                            checked={selectedRole === role}
                            onChange={() => handleRoleSelection(role)}
                            style={{ marginRight: "10px" }}
                        />
                        {role}
                    </label>
                </Col>
            ))}
            <Col xs={12} className="gap-after pb-5">
                <ButtonComponent
                    text="Guardar"
                    version="fill"
                    large
                    isFontBold
                    isHover
                    onClick={handleSaveRole}
                    disabled={!selectedRole}
                />
            </Col>
        </Row>
    </CompanyReusableComponent>
);

export default RenderCompanyRole;