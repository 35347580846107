import axios from 'axios';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/languages';

// POST - Crear un nuevo idioma
export const useCreateLang = () => {
  const [registerData] = useContext(RegisterDataContext);
  const { userData } = useContext(UserContext);
  const token = localStorage.getItem('tokenRegister') || registerData.token;

  const createLanguage = async (newLanguage) => {
    try {
      const response = await axios.post(
        apiBaseUrl,
        { name: newLanguage },
        {
          headers: {
            Authorization: `Bearer ${
              registerData.token || userData.token || token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      toast.error(
        'Error creating language:',
        error.response ? error.response.data : error
      );
    }
  };

  return createLanguage;
};

// GET - Obtener la lista de idiomas
export const useFetchLanguages = () => {
  const [registerData] = useContext(RegisterDataContext);
  const { userData } = useContext(UserContext);
  const token = localStorage.getItem('tokenRegister') || registerData.token;

  const fetchLanguages = async () => {
    try {
      const response = await axios.get(apiBaseUrl, {
        headers: {
          Authorization: `Bearer ${
            registerData.token || userData.token || token
          }`,
        },
      });
      return response.data; // Devuelve el array completo de idiomas
    } catch (error) {
      toast.error('Error fetching languages:', error);
      return [];
    }
  };

  return fetchLanguages;
};

// GET - Obtener un idioma por ID

export const useFetchLanguageById = () => {
  const [registerData] = useContext(RegisterDataContext);
  const { userData } = useContext(UserContext);
  const token = localStorage.getItem('tokenRegister') || registerData.token;

  const fetchLanguageById = async (id) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${
            registerData.token || userData.token || token
          }`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error('Error fetching language by ID:', error);
    }
  };

  return fetchLanguageById;
};
