import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { useAuthApi } from './Api/CompanyApi';
import { toast } from 'react-toastify';
import RenderStep0 from './Steps/renderStep0';
import renderStep1 from './Steps/renderStep1';
import renderStep2 from './Steps/renderStep2';
import renderStep3 from './Steps/renderStep3';
import renderStep4 from './Steps/renderStep4';
import RenderStep5 from './Steps/renderStep5';
import renderCompanyRegistered from './Steps/renderCompanyRegistered';
import renderCompanyRegisteredLoading from './Steps/renderCompanyRegisteredLoading';
import { useUploadsApi } from '../../..//api/UploadsApi';
import ProfileCompany from './Steps/ProfileCompany';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';

const Empresa = () => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadCompany, setLoadCompany] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [codeResponse, setCodeResponse] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [formData, setFormData] = useState({
    f_name: '',
    l_name: '',
    email: '',
    password: '',
    company_role: '',
    phone: '',
  });
  const [terms, setTerms] = useState(false);
  const [adminEmail, setAdminEmail] = useState('');

  const { registerUser, validateEmail, requestCollaboration } = useAuthApi();

  useEffect(() => {
    const timer = setTimeout(() => setLoadCompany(true), 1200);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (step === 2) {
      const timer = setTimeout(() => setStep(3), 2000);
      return () => clearTimeout(timer);
    }
  }, [step]);

  const handleNextStep = useCallback(async () => {
    setLoading(true);
    try {
      switch (step) {
        case 0:
          await handleEmailValidation();
          break;
        case 1:
          handleCodeValidation();
          break;
        case 3:
          await handleUserRegistration();
          break;
        case 'companyRegistered':
          await handleRequestCollaboration();
          break;
        default:
          setStep((prev) => prev + 1);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [step, email, formData, code, terms]);

  const handleEmailValidation = async () => {
    const response = await validateEmail(email);
    if (response) {
      setCodeResponse(response.validation_code);
    }
    setStep(1);
    setCode('');
  };

  const handleRequestCollaboration = async () => {
    await requestCollaboration(adminEmail);
    setStep('companyRegisteredLoading');
  };

  const handleCodeValidation = () => {
    if (code === codeResponse) {
      setStep(2);
    } else {
      throw new Error('Código incorrecto. Por favor, intenta de nuevo.');
    }
  };

  const handleUserRegistration = async () => {
    if (!terms) {
      toast.error('Los términos y condiciones no han sido aceptados');
      setLoading(false);
      return;
    }

    if (!selectedRole) {
      toast.error('El rol en la empresa no ha sido seleccionado');
      setLoading(false);
      return;
    }

    if (!formData.f_name || !formData.l_name) {
      toast.error('El nombre y apellido son obligatorios');
      setLoading(false);
      return;
    }

    if (!formData.phone) {
      toast.error('El teléfono es obligatorio');
      setLoading(false);
      return;
    }

    if (formData.phone.length < 10) {
      toast.error('El teléfono debe tener al menos 10 dígitos');
      setLoading(false);
      return;
    }

    await registerUser({
      ...formData,
      email,
      role: 'COMPANY',
      language: 'es',
    });
    setStep(4);
  };

  const handleSetStep = useCallback((newStep) => {
    if (newStep === 1) {
      setCode('');
    }
    setStep(newStep);
  }, []);

  const handleRoleSelection = (role) => {
    setSelectedRole(role);
  };

  const handleSaveRole = () => {
    setFormData((prevData) => ({
      ...prevData,
      company_role: selectedRole,
    }));
    setCurrentSectionStep3('main');
  };

  const [currentSection, setCurrentSection] = useState('main');
  const [uploadedImage, setUploadedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [imgUrl, setImgUrl] = useState('');
  const [formDataStep5, setFormDataStep5] = useState({
    name: '',
    size: '',
    profile_image: null,
    company_work_area_id: null,
    city: '',
    country: '',
    coordinates: [''],
    is_full_remote: true,
    description: '',
  });
  const { uploadCompanyProfileImage } = useUploadsApi();
  const { createCompany } = useAuthApi();
  const nameRef = useRef();
  const [companyId, setCompanyId] = useState(null);
  const { updateUserData } = useContext(UserContext);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await uploadCompanyProfileImage(file);
        console.log('API response:', response);

        const imgUrl = response?.url;
        if (!imgUrl) {
          console.error('Unexpected response structure:', response);
          toast.error('Error: Unexpected response structure');
          return;
        }

        setImgUrl(imgUrl);
        setFormDataStep5((prevData) => ({
          ...prevData,
          profile_image: imgUrl,
        }));

        const reader = new FileReader();
        reader.onloadend = () => {
          setUploadedImage(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error('Error uploading image', error);
        toast.error('Error uploading image');
      }
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleInputChange = async (e, field) => {
    const { value } = e.target;
    setFormDataStep5((prevData) => ({ ...prevData, [field]: value }));
  };

  useEffect(() => {
    if (formDataStep5.name) {
      nameRef.current.validateExternal();
    }
  }, [formDataStep5.name]);

  const sizeMapping = {
    Microempresa: 'MICRO',
    'Pequeña empresa': 'SMALL',
    'Mediana empresa': 'MEDIUM',
    'Gran empresa': 'BIG',
  };

  const history = useHistory();

  const handleSubmit = async () => {
    try {
      const translatedFormData = {
        ...formDataStep5,
        size: sizeMapping[formDataStep5.size],
        is_full_remote: formDataStep5.isRemote,
        profile_image: imgUrl,
      };
      const response = await createCompany(translatedFormData);
      setCompanyId(response.id);
      if (response?.id) {
        updateUserData({
          companyID: response.id,
          company: response,
        });
      }

      history.push('/company-profile');
      console.log('Company data:', translatedFormData);
    } catch (error) {
      console.error('Error creating company', error);
    }
  };

  // employees section

  const [selectedEmployees, setSelectedEmployees] = useState('');

  const handleSelectEmployees = (emp) => {
    setSelectedEmployees(emp);
  };

  const handleSaveEmployees = () => {
    setFormDataStep5((prevData) => ({ ...prevData, size: selectedEmployees }));
    setCurrentSection('main');
  };

  const descriptions = {
    Microempresa: 'Descripción para Microempresa',
    'Pequeña empresa': 'Descripción para Pequeña empresa',
    'Mediana empresa': 'Descripción para Mediana empresa',
    'Gran empresa': 'Descripción para Gran empresa',
  };

  // sector section

  const { getCompanyWorkAreas, createCompanyWorkArea, getCompanyWorkArea } =
    useAuthApi();
  const [workAreas, setWorkAreas] = useState([]);
  const [selectedWorkArea, setSelectedWorkArea] = useState(null);
  const [workAreaName, setWorkAreaName] = useState('');

  useEffect(() => {
    if (step === 5) {
      getCompanyWorkAreas()
        .then((response) => {
          setWorkAreas(response);
        })
        .catch((error) => {
          toast.error(`Error: ${error.response}`);
        });
    }
  }, [step]);

  const handleSelectWorkArea = (id) => {
    setSelectedWorkArea(id);
  };
  const handleOptionSelected = async (option) => {
    const selectedArea = workAreas.find((area) => area.name_es === option);
    if (selectedArea) {
      handleSelectWorkArea(selectedArea.id);
    } else {
      await handleAddWorkArea(option);
    }
  };
  const handleFindCompanyWorkArea = async (id) => {
    try {
      const response = await getCompanyWorkArea(id);
      setWorkAreaName(response.name_es.toString());
    } catch (error) {
      toast.error(`Error: ${error.response.data}`);
    }
  };

  const handleAddWorkArea = async (option) => {
    try {
      const newWorkArea = await createCompanyWorkArea({ name_es: option });
      setWorkAreas((prevWorkAreas) => [...prevWorkAreas, newWorkArea]);
      handleSelectWorkArea(newWorkArea.id);
      setFormDataStep5((prevData) => ({
        ...prevData,
        company_work_area_id: newWorkArea.id,
      }));
      console.log(newWorkArea);
    } catch (error) {
      toast.error(`Error: ${error.response.data}`);
    }
  };

  const handleSaveSector = (id) => {
    setFormDataStep5((prevData) => ({ ...prevData, company_work_area_id: id }));
    setCurrentSection('main');
  };

  useEffect(() => {
    if (selectedWorkArea !== null) {
      handleFindCompanyWorkArea(selectedWorkArea);
    }
  }, [selectedWorkArea]);

  // location section

  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);

  const fetchCurrentLocation = useCallback(
    (logPosition = false, getPlace = false) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            if (logPosition)
              console.log(
                `Ubicación actual ${getPlace ? 'Click:' : ':'} Latitud: ${
                  pos.lat
                }, Longitud: ${pos.lng}`
              );
            if (getPlace) {
              const placeName = await getPlaceName(pos.lat, pos.lng);
              console.log(`La ubicación actual es en ${placeName}`);
            }
            setCurrentPosition(pos);
            setFormDataStep5((prevData) => ({
              ...prevData,
              coordinates: [pos.lat, pos.lng],
            }));
            setIsLoaded(true);
          },
          () => {
            toast.error('Geolocation is not supported by this browser.');
            setIsLoaded(true);
          }
        );
      } else {
        toast.error('Geolocation is not supported by this browser.');
        setIsLoaded(true);
      }
    },
    []
  );

  const getPlaceName = useCallback(async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=0b3d9c`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        setFormDataStep5((prevData) => ({
          ...prevData,
          city: data.results[0].address_components[2].long_name,
          country: data.results[0].address_components[5].long_name,
        }));
        return data.results[0].formatted_address;
      } else {
        setFormDataStep5((prevData) => ({
          ...prevData,
          city: 'Desconocido',
          country: 'Desconocido',
        }));
        toast.error('No se encontraron resultados para la ubicación');
        return 'Ubicación desconocida';
      }
    } catch (error) {
      toast.error('Error al obtener el nombre del lugar:', error);
      return 'Error al obtener la ubicación';
    }
  }, []);

  useEffect(() => {
    fetchCurrentLocation();
  }, [fetchCurrentLocation]);

  const isRemoteChange = (e) => {
    setFormDataStep5((prevData) => ({
      ...prevData,
      isRemote: e.target.checked,
    }));
  };

  const handleOptionSelectedLocation = useCallback(
    (option) => {
      console.log('Option selected:', option);

      setFormDataStep5((prevData) => ({
        ...prevData,
        city: option,
        country: option,
      }));
    },
    [formDataStep5]
  );

  const handleSaveLocation = () => {
    console.log('Guardando ubicación:', formDataStep5);
    setCurrentSection('main');
  };

  const [currentSectionStep3, setCurrentSectionStep3] = useState('main');

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return RenderStep0({
          email,
          setEmail,
          handleNextStep,
          handleSetStep,
          loading,
        });
      case 1:
        return renderStep1({
          code,
          setCode,
          handleNextStep,
          handleSetStep,
          loading,
          setStep,
        });
      case 2:
        return renderStep2({ setStep, handleSetStep });
      case 3:
        return renderStep3({
          setStep,
          handleSetStep,
          formData,
          setFormData,
          handleNextStep,
          loading,
          terms,
          setTerms,
          handleRoleSelection,
          selectedRole,
          handleSaveRole,
          currentSectionStep3,
          setCurrentSectionStep3,
        });
      case 4:
        return renderStep4({ setStep, handleSetStep });
      case 'companyRegistered':
        return renderCompanyRegistered({
          setStep,
          handleSetStep,
          adminEmail,
          setAdminEmail,
          handleNextStep,
          loading,
        });
      case 'companyRegisteredLoading':
        return renderCompanyRegisteredLoading({
          setStep,
          handleSetStep,
          loadCompany,
        });
      case 5:
        return RenderStep5({
          handleClick,
          imgUrl,
          formDataStep5,
          handleInputChange,
          handleSubmit,
          fileInputRef,
          uploadedImage,
          setUploadedImage,
          setImgUrl,
          handleImageUpload,
          selectedEmployees,
          handleSelectEmployees,
          handleSaveEmployees,
          descriptions,
          currentSection,
          handleSetStep,
          setCurrentSection,
          setStep,
          handleSaveSector,
          selectedWorkArea,
          workAreas,
          handleAddWorkArea,
          handleSelectWorkArea,
          workAreaName,
          handleOptionSelected,
          currentPosition,
          fetchCurrentLocation,
          isLoaded,
          handleOptionSelectedLocation,
          handleSaveLocation,
          isRemoteChange,
          nameRef,
          setFormDataStep5,
        });
      case 6:
        return ProfileCompany({
          companyId: companyId,
        });
      default:
        return null;
    }
  };

  return <>{renderStepContent()}</>;
};

export default Empresa;
