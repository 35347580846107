import React from "react";
import { Row, Col } from "react-bootstrap";
import CompanyReusableComponent from "../Components/CompanyReusable/CompanyReusableComponent";
import InputComponent from "../../../Shared/InputComponent";
import ButtonComponent from "../../../Shared/ButtonComponent";
import { linkStyle } from "../../Talento/talentoStyles";

const renderStep1 = (
    { code, setCode, handleNextStep, handleSetStep, loading, setStep }
) => (
    <CompanyReusableComponent
        company="BlueScreen"
        step={handleSetStep}
        title="¡Vamos muy bien!"
        subtitle="Ha llegado el momento de validar el email asociado a tu empresa."
        goBack={() => setStep(0)}
    >
        <Row>
            <Col xs={12} className="text-center">
                <span className="mb-2 color-black font-weight-bold">Ingresa el código que te enviamos.</span>
            </Col>
            <Col xs={12} className="gap-after">
                <InputComponent
                    type="number"
                    placeholder="Código de verificación"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    externalValue={code}
                />
            </Col>
            <Col xs={12} className="gap-after">
                <ButtonComponent
                    text="Enviar"
                    version="fill"
                    large
                    isFontBold
                    isHover
                    onClick={handleNextStep}
                    disabled={loading}
                />
            </Col>
            <Col xs={12} className="gap-after pb-5">
                <a href="/" style={linkStyle}>
                    No he recibido ningún código.
                </a>
            </Col>
        </Row>
    </CompanyReusableComponent>
);

export default renderStep1;