import React from "react";
import { CommonPage } from "../../components/CommonPage/CommonPage";
import FilterSelectAdmin from "../../components/FilterSelectAdmin/FilterSelectAdmin";


const ABMUsers = () => {
    return (
        <CommonPage maintitle="Super Admin / ABM usuarios">
            <div style={{ height: '800px' }} >
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <FilterSelectAdmin title="Filtrar por:" options={['Nombre', 'Apellido', 'Email']} selected="Nombre" setSelected={() => { }} />
                    <FilterSelectAdmin title="Mostrar" options={['Nombre', 'Apellido', 'Email']} selected="Nombre" setSelected={() => { }} />
                    <FilterSelectAdmin title="Buscar" options={['Nombre', 'Apellido', 'Email']} selected="Nombre" setSelected={() => { }} />
                </div>
            </div>
        </CommonPage>
    );
}

export default ABMUsers;