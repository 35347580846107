import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import SearchComponent from '../../../../Shared/SearchComponent/SearchComponent';
import BlueLogo from '../../../../../Assets/BlueLogo.svg';
import { useHardSkillsApi } from '../../../../../api/HardSkills.api';
import { useTalentHardSkillsApi } from '../../../../../api/TalentHardSkillsApi';
import { UserContext } from '../../../../../contexts/UserContext';
import { toast } from 'react-toastify';
import BackBlue from '../../../../../Assets/BackBlue.svg';
import {
  fullScreenStyles,
  backButtonStyle,
  backButtonIconStyle,
} from '../../steps/ProfilePersStyles';

const MaxSkills = 5;

const HardSkillsModule = ({
  goBack,
  isHardSkills,
  hardSkillsIdData,
  prevHards,
}) => {
  const [selectedHardSkills, setSelectedHardSkills] = useState(() => {
    return prevHards.map((hard) => hard.hard_skill.name_es);
  });
  const [hardSkillsIds, setHardSkillsIds] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);
  const [showHardSkillsScreen, setShowHardSkillsScreen] = useState(true);
  const { fetchHardSkills } = useHardSkillsApi();
  const { createTalentHardSkillRelation, deleteTalentHardSkillRelation } =
    useTalentHardSkillsApi();
  const { userData } = useContext(UserContext);

  useEffect(() => {
    const fetchHardSkillsData = async () => {
      const data = await fetchHardSkills();
      if (data) setHardSkills(data);
      setShowHardSkillsScreen(true);
      console.log('data', data);
    };
    if (showHardSkillsScreen) {
      fetchHardSkillsData();
    }
    console.log('prevHards', prevHards);
  }, [showHardSkillsScreen]);

  const handleHardSkillSelect = useCallback(
    (skill) => {
      setSelectedHardSkills((prev) => {
        const index = prev.indexOf(skill.name_es);
        if (index === -1) {
          return prev.length < MaxSkills ? [...prev, skill.name_es] : prev;
        } else {
          return prev.filter((s) => s !== skill.name_es);
        }
      });
      if (typeof hardSkillsIdData === 'function') {
        hardSkillsIdData([...hardSkillsIds, skill.id]);
      }
    },
    [hardSkillsIds, hardSkillsIdData]
  );

  const handleHardSkillsSelection = useCallback(async () => {
    console.log(selectedHardSkills.join(', '));
    isHardSkills(selectedHardSkills.join(', '));

    if (userData.talent) {
      const prevSelectedIds = new Set(
        prevHards.map((item) => item.hard_skill.id)
      );
      const currentSelectedIds = new Set(hardSkillsIds);

      const toDelete = Array.from(prevSelectedIds).filter(
        (id) => !currentSelectedIds.has(id)
      );
      const toCreate = Array.from(currentSelectedIds).filter(
        (id) => !prevSelectedIds.has(id)
      );

      const deletePromises = toDelete.map((hardSkillId) => {
        const skillName = hardSkills.find(
          (skill) => skill.id === hardSkillId
        ).name_es;
        return deleteTalentHardSkillRelation(userData.talent.id, hardSkillId)
          .then(() => toast.success(`Habilidad eliminada: ${skillName}`))
          .catch((error) =>
            toast.error(`Error eliminando habilidad: ${skillName}`)
          );
      });

      const createPromises = toCreate.map(async (hardSkillId) => {
        const skillName = hardSkills.find(
          (skill) => skill.id === hardSkillId
        ).name_es;
        try {
          await createTalentHardSkillRelation(userData.talent.id, hardSkillId);
          return toast.success(`Habilidad agregada: ${skillName}`);
        } catch (error) {
          return toast.error(
            `Error agregando habilidad: ${skillName}, ${error}`
          );
        }
      });

      Promise.all([...createPromises, ...deletePromises])
        .then(() => {
          setTimeout(goBack, 2000);
        })
        .catch(() => {
          setTimeout(goBack, 2000);
        });
    }
  }, [
    selectedHardSkills,
    hardSkillsIds,
    prevHards,
    hardSkills,
    userData,
    deleteTalentHardSkillRelation,
    createTalentHardSkillRelation,
    goBack,
  ]);

  useEffect(() => {
    setHardSkillsIds(
      hardSkills
        .filter((skill) => selectedHardSkills.includes(skill.name_es))
        .map((skill) => skill.id)
    );
  }, [selectedHardSkills, hardSkills]);

  return (
    <div style={fullScreenStyles}>
      <img
        src={BlueLogo}
        alt="BlueLogo"
        style={{ cursor: 'pointer', marginBottom: '2rem' }}
      />
      <Container
        fluid
        className="h-100 d-flex align-items-center justify-content-between flex-column"
      >
        <button style={backButtonStyle} onClick={goBack}>
          <img src={BackBlue} alt="back" style={backButtonIconStyle} />
        </button>
        {showHardSkillsScreen && (
          <Row>
            <Col xs={12} className="mb-3">
              <span className="text-center">
                Indica tus cinco habilidades duras más fuertes.
              </span>
            </Col>
            <Col xs={12} className="mb-3">
              <ButtonComponent
                isColorBlack
                text={selectedHardSkills.join(', ') || 'Selecciona hasta cinco'}
                large
                paddingY="15px"
                isLeft
                version="outline"
                isEditIcon
              />
            </Col>
            {hardSkills.map((skill) => (
              <Col xs={6} key={skill.id} className="mb-3">
                <ButtonComponent
                  text={skill.name_es}
                  onClick={() => handleHardSkillSelect(skill)}
                  version={
                    selectedHardSkills.includes(skill.name_es)
                      ? 'fill'
                      : 'outline'
                  }
                  large
                  isColorBlack
                  paddingY="15px"
                  disabled={
                    selectedHardSkills.length === MaxSkills &&
                    !selectedHardSkills.includes(skill.name_es)
                  }
                />
              </Col>
            ))}
            <Col xs={12} className="mb-3">
              <SearchComponent
                options={hardSkills.map((s) => s.name_es)}
                placeholder="Buscar herramienta técnicas"
                addOption={true}
                onOptionSelected={handleHardSkillSelect}
                isWorkArea={true}
              />
            </Col>
            <Col xs={12} className="text-center mt-3">
              <ButtonComponent
                text="Guardar"
                onClick={handleHardSkillsSelection}
                large
                version={selectedHardSkills.length === 0 ? 'outline' : 'fill'}
                isHover
              />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default HardSkillsModule;
