import React, { useState, useEffect, useCallback, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ButtonComponent from "../../../../Shared/ButtonComponent";
import SearchComponent from "../../../../Shared/SearchComponent/SearchComponent";
import BlueLogo from "../../../../../Assets/BlueLogo.svg";
import { useToolsApi } from "../../../../../api/ToolsApi";
import { fullScreenStyles, backButtonStyle, backButtonIconStyle } from "../../steps/ProfilePersStyles";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTalentToolsApi } from "../../../../../api/TalentToolsApi";
import { toast } from "react-toastify";
import BackBlue from "../../../../../Assets/BackBlue.svg";

const SoftSkillLevels = ["Básico", "Intermedio", "Avanzado"];

const SoftSkillModule = ({ goBack, isTechSkills, techLevelIdData, prevSoftSkills }) => {
    const [showTechSkillsScreen, setShowTechSkillsScreen] = useState(true);
    const [showSoftSkillLevels, setShowSoftSkillLevels] = useState(false);
    const [currentSoftSkill, setCurrentSoftSkill] = useState("");
    const [selectedSoftSkills, setSelectedSoftSkills] = useState([]);
    const [softSkillInputText, setSoftSkillInputText] = useState("");
    const [techId, setTechId] = useState();
    const [techLevelId, setTechLevelId] = useState([]);
    const [softSkills, setSoftSkills] = useState([]);
    const { fetchTools } = useToolsApi();
    const { createTalentToolRelation, updateTalentToolRelation, deleteTalentToolRelation } = useTalentToolsApi();
    const { userData } = useContext(UserContext);

    const handleSoftSkillSelect = useCallback((skill) => {
        const skillIndex = selectedSoftSkills.findIndex(s => s.skillName === skill.name_es);
        if (skillIndex > -1) {
            // Si la habilidad ya está seleccionada, la eliminamos
            const newSelectedSkills = selectedSoftSkills.filter((_, index) => index !== skillIndex);
            setSelectedSoftSkills(newSelectedSkills);

            // Actualizar el texto que se muestra en el botón
            const newSoftSkillInputText = newSelectedSkills.map(s => `${s.skillName} / ${s.level}`).join(", ");
            setSoftSkillInputText(newSoftSkillInputText);

            // Opcional: si se utiliza un estado para IDs de habilidades, también actualizarlo
            setTechLevelId(prev => prev.filter(id => id.tool_id !== skill.id));
        } else {
            // Si no está seleccionada, procedemos a seleccionarla
            setCurrentSoftSkill(skill.name_es);
            setShowSoftSkillLevels(true);
            setTechId(skill.id);
        }
    }, [selectedSoftSkills, setShowSoftSkillLevels, setCurrentSoftSkill, setTechId]);


    const handleSoftSkillLevelSelect = useCallback((level) => {
        const newSelection = { skillName: currentSoftSkill, level };
        setSelectedSoftSkills(prev => [...prev, newSelection]);
        setSoftSkillInputText(prev => `${prev}${prev ? ", " : ""}${currentSoftSkill} / ${level}`);
        setCurrentSoftSkill("");
        setShowSoftSkillLevels(false);

        const newTechLevelId = { tool_id: techId, level };
        setTechLevelId(prev => [...prev, newTechLevelId]);
        console.log([...techLevelId, newTechLevelId]);
        if (typeof techLevelIdData === "function") {
            techLevelIdData([...techLevelId, newTechLevelId]);
        }
    }, [currentSoftSkill, techId]);


    const handleSoftSkillsAndLevelSelection = useCallback(async () => {
        console.log(selectedSoftSkills.map(s => `${s.skillName} / ${s.level}`).join(", "));

        if (typeof isTechSkills === "function") {
            isTechSkills(selectedSoftSkills.map(s => `${s.skillName} / ${s.level}`).join(", "));
        }
        const softSkillLevels = {
            "Básico": 1,
            "Intermedio": 2,
            "Avanzado": 3
        };

        const prevSoftSkillsMap = new Map(prevSoftSkills.map(s => [s.tool.id, s.level]));
        const operations = [];

        // Eliminar habilidades que ya no están seleccionadas
        const skillsToDelete = prevSoftSkills.filter(s => !selectedSoftSkills.find(ss => ss.skillName === s.tool.name_es));
        skillsToDelete.forEach(skill => {
            const promise = deleteTalentToolRelation(userData.talent.id, skill.tool_id)
                .then(() => `Eliminada: ${skill.tool.name_es}`);
            operations.push(promise);
        });

        // Crear y actualizar habilidades
        selectedSoftSkills.forEach(skill => {
            const toolId = softSkills.find(s => s.name_es === skill.skillName)?.id;
            const newLevel = softSkillLevels[skill.level];
            if (prevSoftSkillsMap.has(toolId) && prevSoftSkillsMap.get(toolId) !== newLevel) {
                // Actualizar habilidad si el nivel ha cambiado
                const promise = updateTalentToolRelation(userData.talent.id, toolId, { level: newLevel })
                    .then(() => `Actualizada: ${skill.skillName} a ${skill.level}`);
                operations.push(promise);
            } else if (!prevSoftSkillsMap.has(toolId)) {
                // Crear relación si la habilidad es nueva
                const promise = createTalentToolRelation(userData.talent.id, toolId, newLevel)
                    .then(() => `Creada: ${skill.skillName} a ${skill.level}`);
                operations.push(promise);
            }
        });

        Promise.all(operations)
            .then(results => {
                results.forEach(result => toast.success(result));
                setTimeout(goBack, 2000);
            })
            .catch(error => {
                toast.error(`Error: ${error.message}`);
                setTimeout(goBack, 2000);
            });
    }, [selectedSoftSkills, goBack, isTechSkills, prevSoftSkills, userData, softSkills]);


    useEffect(() => {
        const fetchExpertiseAreasData = async () => {
            const data = await fetchTools();
            setSoftSkills(data || []);
        };
        setShowTechSkillsScreen(true);
        fetchExpertiseAreasData();
        console.log("prevSoftSkills", prevSoftSkills);

        // Inicializar con habilidades previas
        if (prevSoftSkills && prevSoftSkills.length > 0) {
            const initialSoftSkills = prevSoftSkills.map(skill => ({
                skillName: skill.tool.name_es,
                level: SoftSkillLevels[skill.level - 1] // Ajusta el índice para obtener el nombre del nivel
            }));

            setSelectedSoftSkills(initialSoftSkills);

            const initialInputText = initialSoftSkills.map(skill => `${skill.skillName} / ${skill.level}`).join(", ");
            setSoftSkillInputText(initialInputText);
        }
    }, []);



    return (
        <div style={fullScreenStyles}>
            <img
                src={BlueLogo}
                alt="BlueLogo"
                style={{ cursor: "pointer", marginBottom: "2rem" }}
            />
            <Container
                fluid
                className="h-100 d-flex align-items-center justify-content-between flex-column"
            >
                <button style={backButtonStyle} onClick={goBack}>
                    <img src={BackBlue} alt="back" style={backButtonIconStyle} />
                </button>
                {showTechSkillsScreen && !showSoftSkillLevels && (
                    <Row>
                        <Col xs={12} className="mb-3">
                            <span className="text-center">
                                Indica hasta cinco Herramientas técnicas que mejor manejas.{" "}
                            </span>
                        </Col>
                        <Col xs={12} className="mb-3">
                            <ButtonComponent
                                isColorBlack
                                text={softSkillInputText || "Seleccion de un skill técnico"}
                                large
                                paddingY="15px"
                                isLeft
                                version="outline"
                                isEditIcon
                            />
                        </Col>
                        {Array.isArray(softSkills) && softSkills.map((skill) => (
                            <Col xs={6} key={skill.id} className="mb-3">
                                <ButtonComponent
                                    text={skill.name_es}
                                    onClick={() => handleSoftSkillSelect(skill)}
                                    large
                                    isColorBlack
                                    version={
                                        selectedSoftSkills.find(s => s.skillName === skill.name_es)
                                            ? "fill"
                                            : "outline"
                                    }
                                    paddingY="15px"
                                />
                            </Col>
                        ))}
                        <Col xs={12} className="mb-3">
                            <SearchComponent
                                options={softSkills.map((s) => s.name_es)}
                                placeholder="Buscar herramienta técnicas"
                                addOption={true}
                                onOptionSelected={handleSoftSkillSelect}
                                isWorkArea={true}
                            />
                        </Col>
                        <Col xs={12}>
                            <ButtonComponent
                                text="Continuar"
                                onClick={handleSoftSkillsAndLevelSelection}
                                large
                                isColorBlack
                                version={selectedSoftSkills.length === 0 ? "grey" : "fill"}
                                paddingY="15px"
                                disabled={selectedSoftSkills.length === 0}
                                isHover
                            />
                        </Col>
                    </Row>
                )}
                {showTechSkillsScreen && showSoftSkillLevels && (
                    <Row>
                        <Col xs={12} className="mb-3">
                            <span className="text-center">
                                Indica hasta cinco Herramientas técnicas que mejor manejas.
                            </span>
                        </Col>
                        <Col xs={12} className="mb-3">
                            <ButtonComponent
                                isColorBlack
                                text={currentSoftSkill}
                                large
                                paddingY="15px"
                                isLeft
                                version="outline"
                                isEditIcon
                            />
                        </Col>
                        {SoftSkillLevels.map((level, index) => (
                            <React.Fragment key={index}>
                                {index === 0 && (
                                    <Col xs={12} className="mb-3 text-left">
                                        <span>Selecciona tu nivel</span>
                                    </Col>
                                )}
                                {index === 1 && (
                                    <Col xs={12} className="mb-3 text-left">
                                        <span>Capaz de realizar tareas simples y rutinarias.</span>
                                    </Col>
                                )}
                                {index === 2 && (
                                    <Col xs={12} className="mb-3 text-left">
                                        <span>
                                            Utiliza características avanzadas y personaliza la
                                            interfaz.
                                        </span>
                                    </Col>
                                )}
                                <Col xs={12} className="mb-3">
                                    <ButtonComponent
                                        text={level}
                                        onClick={() => handleSoftSkillLevelSelect(level)}
                                        large
                                        isColorBlack
                                        version="outline"
                                        paddingY="15px"
                                    />
                                </Col>
                                {index === 2 && (
                                    <Col xs={12} className="mb-3 text-left">
                                        <span>
                                            Domina el software completamente, incluyendo programación
                                            y personalización a nivel de sistema.
                                        </span>
                                    </Col>
                                )}
                            </React.Fragment>
                        ))}
                        <Col xs={12}>
                            <ButtonComponent
                                text="Guardar"
                                onClick={handleSoftSkillsAndLevelSelection}
                                large
                                isColorBlack
                                version={selectedSoftSkills.length === 0 ? "grey" : "fill"}
                                paddingY="15px"
                                isHover
                            />
                        </Col>
                    </Row>
                )}
            </Container>


        </div>
    );
}

export default SoftSkillModule;