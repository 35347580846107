import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TrashIcon from '../../../../Assets/RedTrash.svg';
import VisibleIcon from '../../../../Assets/VisibleIcon.svg';
import eyeIcon from '../../../../Assets/EyeIcon.svg';


const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    pagination: false,
};


const ClientBox = ({ dataBox, deleteCustomer, handleClickVisible, onClick }) => {
    if (!dataBox) {
        return null;
    }


    return (
        <div style={{
            width: 'calc(100vw - 350px)'
        }}>
            <span style={{
                textAlign: 'start',
                width: '100%',
                display: 'block',
                fontSize: '36px',

            }}>Nuestros clientes</span>
            <Slider {...settings}>
                {dataBox.map((data) => (
                    <div key={data.id}>
                        <span
                            style={{
                                width: '100%',
                                display: 'flex',
                            }}
                            className="pb-2">{data.name}</span>
                        <div className={data.visible ? "data-box__item" : "data-box__item data-box__item--hidden"} onClick={() => onClick(data)}>
                            <div className="data-box__item__image" style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}>
                                <img style={{
                                    width: '100%',
                                    height: '100%'

                                }} src={data.image} alt="icon" className="img-fluid" />
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }} className="py-2">
                            <span>Acciones</span>
                            <button className="prp-action-visible" onClick={() => handleClickVisible(data.id)}>
                                <img src={data.visible ? eyeIcon : VisibleIcon} alt="Visible" />
                            </button>
                            <button className="prp-action-delete" onClick={() => deleteCustomer(data.id)}>
                                <img src={TrashIcon} alt="Delete" />
                            </button>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ClientBox;