import { useCallback, useEffect, useState } from 'react';
import { useHardSkillsApi } from '../../../../../api/HardSkills.api';

import {
  backButtonIconStyle,
  backButtonStyle,
  fullScreenStyles,
} from '../../../../Register/Talento/steps/ProfilePersStyles';
import { Col, Container, Row } from 'react-bootstrap';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import SearchComponent from '../../../../Shared/SearchComponent/SearchComponent';
import BlueLogo from '../../../../../Assets/BlueLogo.svg';
import BackBlue from '../../../../../Assets/BackBlue.svg';

const MAX_SKILLS = 5;

const Case3Step3CreateJob = ({ goBack, prevHards = [], save }) => {
  const [selectedHardSkills, setSelectedHardSkills] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);
  const { fetchHardSkills } = useHardSkillsApi();

  useEffect(() => {
    const fetchHardSkillsData = async () => {
      const data = await fetchHardSkills();
      if (data) setHardSkills(data);
    };

    fetchHardSkillsData();
  }, []);

  useEffect(() => {
    if (prevHards.length) {
      setSelectedHardSkills(prevHards);
    }
  }, [prevHards]);

  const handleHardSkillSelect = useCallback(
    (skill) => {
      if (!selectedHardSkills.some((hs) => hs.name === skill.name_es)) {
        setSelectedHardSkills((prev) =>
          prev.length < MAX_SKILLS
            ? [
                ...prev,
                { name: skill.name_es, level: 'Avanzado', id: skill.id },
              ]
            : prev
        );
      }
    },
    [selectedHardSkills]
  );

  const handleHardSkillsSelection = useCallback(() => {
    save(selectedHardSkills.map(({ name, id }) => ({ name, id })));
    goBack();
  }, [goBack, save, selectedHardSkills]);

  return (
    <div style={fullScreenStyles}>
      <img
        src={BlueLogo}
        alt="BlueLogo"
        style={{ cursor: 'pointer', marginBottom: '2rem' }}
      />
      <Container
        fluid
        className="h-100 d-flex align-items-center justify-content-between flex-column"
      >
        <button style={backButtonStyle} onClick={goBack}>
          <img src={BackBlue} alt="back" style={backButtonIconStyle} />
        </button>
        <Row>
          <Col xs={12} className="mb-3">
            <span className="text-center">
              Indica tus cinco habilidades duras más fuertes.
            </span>
          </Col>
          <Col xs={12} className="mb-3">
            <ButtonComponent
              isColorBlack
              text={
                selectedHardSkills.map((hs) => `${hs.name}`).join(', ') ||
                'Selecciona hasta cinco'
              }
              large
              paddingY="15px"
              isLeft
              version="outline"
              isEditIcon
            />
          </Col>
          {hardSkills.map((skill) => (
            <Col xs={6} key={skill.id} className="mb-3">
              <ButtonComponent
                text={skill.name_es}
                onClick={() => handleHardSkillSelect(skill)}
                version={
                  selectedHardSkills.some((hs) => hs.name === skill.name_es)
                    ? 'fill'
                    : 'outline'
                }
                large
                isColorBlack
                height={'100%'}
                paddingY="15px"
                disabled={
                  selectedHardSkills.length === MAX_SKILLS &&
                  !selectedHardSkills.some((hs) => hs.name === skill.name_es)
                }
              />
            </Col>
          ))}
          <Col xs={12} className="mb-3">
            <SearchComponent
              options={hardSkills.map((s) => s.name_es)}
              placeholder="Buscar herramienta técnicas"
              addOption={true}
              onOptionSelected={handleHardSkillSelect}
              isWorkArea={true}
            />
          </Col>
          <Col xs={12} className="text-center mt-3">
            <ButtonComponent
              text="Guardar"
              onClick={handleHardSkillsSelection}
              large
              version={selectedHardSkills.length === 0 ? 'outline' : 'fill'}
              isHover
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Case3Step3CreateJob;
