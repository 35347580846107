import React, { useEffect, useState } from "react";
import ButtonComponent from "../../../../../Shared/ButtonComponent";
import InterfaceAdmProject from "../InterfaceAdmProject";
import { Col } from "react-bootstrap";
import { useToolsApi } from "../../../../../../api/ToolsApi";


const UsedToolsProject = ({ handleBackStep, dataUsedTools, nextStep }) => {
    const [selectedUsedTool, setSelectedUsedTool] = useState([]);
    const [tool, setTool] = useState([]);
    const { fetchTools } = useToolsApi();

    const handleSave = () => {
        console.log(selectedUsedTool.map((tool) => tool.id));
        if (dataUsedTools) {
            dataUsedTools(selectedUsedTool.map((tool) => tool.id));
        }
        nextStep();
    };

    const handleSelectUsedTool = (tool) => {
        setSelectedUsedTool([...selectedUsedTool, tool]);
    };

    useEffect(() => {
        fetchTools().then((response) => {
            setTool(response);
        });
    }, []);

    return (
        <InterfaceAdmProject
            title="Por favor, identifica los tres herramientas o software que hayas utilizado en el proyecto."
            textLastButton="Guardar"
            versionLastButton="grey"
            actionLastButton={handleSave}
            flexEndLastButton={false}
            handleBack={handleBackStep}
        >
            {
                tool.map((tool) => (
                    <Col xs={6} className="gap-after" key={tool.id}>
                        <ButtonComponent
                            text={tool.name_es}
                            onClick={() => handleSelectUsedTool(tool)}
                            large
                            isColorBlack
                            version="outline"
                            paddingY="15px"
                            disabled={
                                selectedUsedTool.some((t) => t.id === tool.id) ||
                                selectedUsedTool.length === 5
                            }
                        />
                    </Col>
                ))
            }
        </InterfaceAdmProject>
    );

}

export default UsedToolsProject;