import React, { useState, useEffect, useRef } from 'react';
import { useCreateLang } from '../../../api/LangApi';
import { useCreateExpertiseArea } from '../../../api/ExpertiseAreaApi';
import { useToolsApi } from '../../../api/ToolsApi';
import { useAuthApi } from '../../Register/Empresa/Api/CompanyApi';
import './SeachComponent.css';
import { usePositionsApi } from '../../../api/PositionsApi';

function SearchComponent({
  options,
  placeholder,
  addOption,
  onOptionSelected,
  isLang,
  isWorkArea,
  isExpertise,
  isCompanyWorkArea,
  filterStrings,
  isPositions,
  selectAndCloseOnAdd,
  onAddAndClose,
}) {
  const createLanguage = useCreateLang();
  const createExpertiseArea = useCreateExpertiseArea();
  const { createPosition } = usePositionsApi();
  const { createTool } = useToolsApi();
  const { createCompanyWorkArea } = useAuthApi();
  const [isVisible, setIsVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const wrapperRef = useRef(null);

  useEffect(() => {
    let filtered = options;
    if (filterStrings) {
      filtered = options.filter((option) => typeof option === 'string');
    }

    setFilteredOptions(
      filtered.filter(
        (option) =>
          typeof option === 'string' &&
          option.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  }, [inputValue, options, filterStrings]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleAddLanguage = async () => {
    const newOption = inputValue;

    if (isLang) {
      await createLanguage(newOption);
    }

    if (isWorkArea) {
      await createTool({ name: newOption });
    }

    if (isExpertise) {
      await createExpertiseArea({ name: newOption });
    }

    if (isCompanyWorkArea) {
      await createCompanyWorkArea({ name: newOption });
    }

    if (isPositions) {
      await createPosition({ name: newOption });
    }

    setFilteredOptions((prevOptions) => [...prevOptions, newOption]);
    setInputValue('');
    setModalIsOpen(false);
    setIsVisible(false);

    if (selectAndCloseOnAdd) {
      onOptionSelected(newOption);
      if (onAddAndClose) {
        onAddAndClose(newOption);
      }
    }
  };

  return (
    <div ref={wrapperRef} className="search-component-dropdown">
      <input
        type="text"
        className="search-component-input"
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onClick={() => setIsVisible(!isVisible)}
      />
      {isVisible && (
        <ul
          className="search-component-content"
          style={{ display: isVisible ? 'block' : 'none' }}
        >
          {filteredOptions.map((option) => (
            <li key={option} className="search-component-item">
              <button
                onClick={() => {
                  setInputValue(option);
                  setIsVisible(false);
                  onOptionSelected(option);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setInputValue(option);
                    setIsVisible(false);
                    onOptionSelected(option);
                  }
                }}
                style={{
                  width: '100%',
                  backgroundColor: 'transparent',
                  border: 'none',
                }}
              >
                {option}
              </button>
            </li>
          ))}
          {addOption && (
            <button
              className="search-component-add-btn"
              onClick={() => setModalIsOpen(true)}
            >
              Agregar
            </button>
          )}
        </ul>
      )}

      {modalIsOpen && (
        <div className="search-component-modal">
          <div className="search-component-modal-content">
            <button
              className="search-component-close"
              onClick={() => setModalIsOpen(false)}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setModalIsOpen(false);
                }
              }}
            >
              &times;
            </button>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="search-component-modal-input"
            />
            <button
              onClick={handleAddLanguage}
              className="search-component-modal-add-btn"
            >
              Añadir Opción
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchComponent;
