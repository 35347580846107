import React from "react";
import { Container, Row } from "react-bootstrap";
import {
  AiFillFacebook,
  AiFillTwitterCircle,
  AiFillInstagram,
  AiFillYoutube
} from "react-icons/ai";
import Blue from "../Assets/Blue.svg";

function Footer() {
  const style = {
    FooterImg: {
      width: "100px",
    },
  };
  return (
    <Container fluid className="footer" >
      <Container style={{
        borderTop: "2px solid #003EFF",
        paddingTop: "20px",
      }}>
        <Row style={{
          justifyContent: "space-between",
        }}>
          <div style={style.FooterImg}>
            <img src={Blue} alt="about" className="img-fluid" />
          </div>
          <div className="footer-body">
            <ul className="footer-icons">
              <li className="social-icons">
                <a
                  href=""
                  style={{ color: "#003EFF" }}
                >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href=""
                  style={{ color: "#003EFF" }}
                >
                  <AiFillTwitterCircle />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href=""
                  style={{ color: "#003EFF" }}
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href=""
                  style={{ color: "#003EFF" }}
                >
                  <AiFillYoutube />
                </a>
              </li>
            </ul>
          </div>
        </Row>
      </Container>
    </Container>
  );
}

export default Footer;
