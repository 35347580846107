import React, { useEffect } from "react";
import StepperComponent from "../../../Shared/StepperComponent/StepperComponent";
import { Container } from "react-bootstrap";
import ProfileViewComponent from "../../../Shared/ProfileViewComponent/ProfileViewComponent";
import CardInfoComponent from "../../../Shared/CardInfoComponent/CardInfoComponent";
import SkillComponent from "../../../Shared/SkillComponent/SkillComponent";
import PersonalInfoCardComponent from "../../../Shared/PersonalInfoCardComponent/PersonalInfoCardComponent";
import DataSoftSkillsComponent from "../../../Shared/DataSoftSkillsComponent/DataSoftSkillsComponent";
import { useProfileData } from "../../../../contexts/profileContext";

const ProfilePreview = ({
    location,
    name,
    age,
    imgProfile,
    seniority,
    especialitation,
    salary,
    talents,
    hiring,
    langs,
    hardSkills,
    techSkills,
    aboutMe,
    education,
    certs,
    exp,
    refs,
    talent,
    visibility,
    surname,
    modHiring,
    back,
    dataSoftSkills,
    goLangModule,
    goBackAboutMe,
    goBackDataSoftSkills,
    corporateCulture,
    goBackCorporateCulture,
    goProfileCardModule,
    goHardModule,
    goSoftSkillModule,
    goHiringModule,
}) => {
    const { updateProfileData } = useProfileData();
    const [inicialize, setInicialize] = React.useState(false);

    useEffect(() => {
        if (!inicialize) {
            updateProfileData({
                location,
                name,
                age,
                imgProfile,
                seniority,
                especialitation,
                salary,
                talents,
                hiring,
                langs,
                hardSkills,
                techSkills,
                aboutMe,
                education,
                certs,
                exp,
                refs,
                talent,
                visibility,
                surname,
                modHiring,
                dataSoftSkills,
                corporateCulture,
            });
            setInicialize(true);
        }
    }, [
        updateProfileData,
        inicialize,
        location,
        name,
        age,
        imgProfile,
        seniority,
        especialitation,
        salary,
        talents,
        hiring,
        langs,
        hardSkills,
        techSkills,
        aboutMe,
        education,
        certs,
        exp,
        refs,
        talent,
        visibility,
        surname,
        modHiring,
        dataSoftSkills,
        corporateCulture,
    ]);


    return (
        <Container
            fluid
            className="d-flex flex-column align-items-center"
            style={{ gap: "1rem" }}
        >
            <StepperComponent
                steps={6}
                currentStep={talent}
                title="Preferencias laborales"
                subtitle="Pasos a completar"
            />
            <ProfileViewComponent
                age={age}
                especialitation={especialitation}
                imgProfile={imgProfile}
                localization={location}
                name={name}
                salary={salary}
                seniority={seniority}
                visibility={visibility}
                surname={surname}
                goProfileCardModule={goProfileCardModule}
            />
            {hiring && (
                <CardInfoComponent hiring={hiring} modHiring={modHiring} goHiringModule={goHiringModule} />
            )}
            {langs && (
                <SkillComponent softSkills={techSkills} languages={langs} technicalSkills={hardSkills} back={back} goLangModule={goLangModule} goHardModule={goHardModule} goSoftSkillModule={goSoftSkillModule} />
            )}
            {aboutMe && (
                <PersonalInfoCardComponent aboutMe={aboutMe} education={education} certs={certs} exp={exp} refs={refs} back={back} goBackAboutMe={goBackAboutMe} />
            )}
            {dataSoftSkills && (
                <DataSoftSkillsComponent dataSoftSkills={dataSoftSkills} goBack={back} goBackDataSoftSkills={goBackDataSoftSkills} img={'softSkills'} text={'Habilidades blandas'} />
            )}
            {corporateCulture && (
                <DataSoftSkillsComponent dataSoftSkills={corporateCulture} goBack={back} goBackDataSoftSkills={goBackCorporateCulture} text={'Cultura empresarial'} />
            )}
        </Container>
    );
};

export default ProfilePreview;
