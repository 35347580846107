import React, { useCallback, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import CompanyReusableComponent from '../../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import ButtonComponent from '../../../../Shared/ButtonComponent';

const Case2Step3CreateJob = ({ goBack, save, education, setEducation }) => {
  const [internalEducation, setInternalEducation] = useState(education || {});

  const handleEducationLevelChange = (e) => {
    setInternalEducation({
      ...internalEducation,
      education_level: e.target.value,
    });
  };

  const handleRequireCertificationChange = (e) => {
    setInternalEducation({
      ...internalEducation,
      require_certification: e.target.checked,
    });
  };

  const handleRequireEducationChange = (e) => {
    setInternalEducation({
      ...internalEducation,
      require_education: e.target.checked,
    });
  };

  const handleSave = useCallback(() => {
    save(internalEducation);
    goBack();
  }, [goBack, internalEducation, save]);

  return (
    <CompanyReusableComponent goBack={goBack} company={'WhiteScreen'}>
      <Col xs={12} className="gap-after my-5">
        <span>Por favor, especifica que estudios son requeridos.</span>
      </Col>
      <Col
        xs={12}
        className="gap-after mt-3"
        style={{
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <span
          style={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            textAlign: 'left',
            display: 'block',
          }}
          className="pb-4"
        >
          Nivel de estudios
        </span>
        <Form.Check type="radio" name="education_level">
          <Form.Check.Input
            type="radio"
            value="NONE"
            checked={internalEducation.education_level === 'NONE'}
            onChange={handleEducationLevelChange}
          />
          <Form.Check.Label>
            <strong>No es necesario previo aprendizaje</strong>
            <div>Las tareas no requieren estudios previos</div>
          </Form.Check.Label>
        </Form.Check>
        <Form.Check type="radio" name="education_level">
          <Form.Check.Input
            type="radio"
            value="STUDENT"
            checked={internalEducation.education_level === 'STUDENT'}
            onChange={handleEducationLevelChange}
          />
          <Form.Check.Label>
            <strong>Estudiante</strong>
            <div>Actualmente está estudiando acerca del puesto</div>
          </Form.Check.Label>
        </Form.Check>
        <Form.Check type="radio" name="education_level">
          <Form.Check.Input
            type="radio"
            value="PRE_DEGREE"
            checked={internalEducation.education_level === 'PRE_DEGREE'}
            onChange={handleEducationLevelChange}
          />
          <Form.Check.Label>
            <strong>Grado</strong>
            <div>Título obtenido tras dos años de estudios universitarios</div>
          </Form.Check.Label>
        </Form.Check>
        <Form.Check type="radio" name="education_level">
          <Form.Check.Input
            type="radio"
            value="DEGREE"
            checked={internalEducation.education_level === 'DEGREE'}
            onChange={handleEducationLevelChange}
          />
          <Form.Check.Label>
            <strong>Licenciatura</strong>
            <div>
              Título universitario obtenido tras aproximadamente cuatro años de
              estudios universitarios
            </div>
          </Form.Check.Label>
        </Form.Check>
        <Form.Check type="radio" name="education_level">
          <Form.Check.Input
            type="radio"
            value="MASTER_DEGREE"
            checked={internalEducation.education_level === 'MASTER_DEGREE'}
            onChange={handleEducationLevelChange}
          />
          <Form.Check.Label>
            <strong>Maestría</strong>
            <div>
              Título de postgrado que se obtiene tras uno o dos años de estudios
              adicionales después de la licenciatura
            </div>
          </Form.Check.Label>
        </Form.Check>
        <Form.Check type="radio" name="education_level">
          <Form.Check.Input
            type="radio"
            value="DOCTORATE"
            checked={internalEducation.education_level === 'DOCTORATE'}
            onChange={handleEducationLevelChange}
          />
          <Form.Check.Label>
            <strong>Doctorado</strong>
            <div>
              El grado académico más elevado, usualmente obtenido tras años de
              investigación después de la maestría
            </div>
          </Form.Check.Label>
        </Form.Check>
      </Col>
      <Col xs={12} className="gap-after mt-3">
        <span
          style={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            textAlign: 'left',
            display: 'block',
          }}
          className="pb-4"
        >
          Certificación / Diplomatura
        </span>
      </Col>
      <Col xs={12} className="gap-after mt-3">
        <Form.Check>
          <Form.Check.Input
            type="checkbox"
            checked={!!internalEducation.require_certification}
            onChange={handleRequireCertificationChange}
          />
          <Form.Check.Label style={{ textAlign: 'left' }}>
            Dispongan de certificaciones pertinentes en relación a lo detallado
            en esta oferta.
          </Form.Check.Label>
        </Form.Check>
      </Col>
      <Col xs={12} className="gap-after mt-3 d-flex">
        <Form.Check>
          <Form.Check.Input
            type="checkbox"
            checked={!!internalEducation.require_education}
            onChange={handleRequireEducationChange}
          />
          <Form.Check.Label>
            Requisito obligatorio para el candidato.
          </Form.Check.Label>
        </Form.Check>
      </Col>
      <Col xs={12} className="gap-after mt-5 d-flex">
        <ButtonComponent
          text="Guardar"
          onClick={handleSave}
          large
          isHover
          version={internalEducation.education_level ? 'fill' : 'grey'}
          disabled={!internalEducation.education_level}
        />
      </Col>
    </CompanyReusableComponent>
  );
};

export default Case2Step3CreateJob;
