import React, { useState, useEffect, useContext } from "react";
import "./AdmStyles.css";
import ButtonComponent from "../../../../Shared/ButtonComponent";
import { Col } from "react-bootstrap";
import Lock from "../../../../../Assets/Lock.svg";
import InterfaceAdmProject from "./InterfaceAdmProject";
import ProjectBoxComponent from "../../../../Shared/ProjectBoxComponent/ProjectBoxComponent";
import { RegisterDataContext } from "../../../../../contexts/RegisterDataContext";
import { UserContext } from "../../../../../contexts/UserContext";
import { useTalentProjectsApi } from "../../../../../api/TalentProjectsApi";
import { toast } from "react-toastify";

const AdmProjectsStep0 = ({ prevProjects, addProjects, handleBack }) => {
    const [talentProjects, setTalentProjects] = useState([]);
    const { getTalentProjects } = useTalentProjectsApi();
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);


    useEffect(() => {
        const token = localStorage.getItem('tokenRegister') || registerData.token;
        if (userData.token || registerData.token || token) {
            getTalentProjects(userData.talent.id)
                .then((projects) => {
                    console.log('Projects:', projects);
                    setTalentProjects(projects);
                })
                .catch((error) => toast.error('Error fetching talent projects:', error));
        }

    }, [userData, registerData]);


    return (
        <InterfaceAdmProject
            handleBack={handleBack}
            title="Crea y edita todos tus proyectos"
            subtitle={talentProjects.length < 4 ? `Publica un máximo de ${4 - talentProjects.length} proyectos *` : 'Ya no puedes agregar más proyectos'}
            textLastButton="Button"
            versionLastButton="outline"
            actionLastButton={prevProjects}
            icon={Lock}
            disabledLastButton={true}
            flexEndLastButton={true}
            lastInfo={true}
            alignItems={false}
        >
            {
                talentProjects.length > 0 ? (
                    <Col xs={12} className="gap-after">
                        <ProjectBoxComponent deleteProject hiddenHeader projects={talentProjects} />
                    </Col>
                ) : (
                    null
                )
            }
            <Col xs={12} className="gap-after">
                <ButtonComponent
                    text={
                        talentProjects.length === 0 ? '+ Agregar proyecto' : talentProjects.length < 4 ? `Agregar proyecto (${talentProjects.length})` : 'No hay espacios disponibles'
                    }
                    large
                    version="outline"
                    isFontBold
                    isFontBlue
                    onClick={addProjects}
                    className="button-outline-custom"
                    disabled={talentProjects.length >= 4}
                />
            </Col>
            <Col xs={12} className="gap-after">
                <ButtonComponent
                    text="Guardar cambios"
                    large
                    version="grey"
                    paddingY="11px"
                    isHover
                    onClick={prevProjects}
                    className="button-grey-custom"
                />
            </Col>
        </InterfaceAdmProject>
    );
};


export default AdmProjectsStep0;
