import React from "react";
import "./ProjectBoxComponent.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTalentProjectsApi } from "../../../api/TalentProjectsApi";
import { toast } from "react-toastify";
import TrashIcon from "../../../Assets/TrashIcon.svg";


const sliderConfig = {
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: false,
    arrows: false,
    customPaging: function (i) {
        return (
            <button style={{
                width: '5px',
                height: '5px',
                borderRadius: '50%',
                backgroundColor: 'rgba(0, 62, 255, 0.25)',
                border: 'none',
                display: 'inline-block',
                margin: '0 2px'
            }}></button>
        );
    },
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                variableWidth: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false,
                centerMode: false,
            },
        },
    ],
};

const procesedImage = (images) => {
    const urls = JSON.parse(images);
    const url = urls[0];
    return url;
};


const ProjectBoxComponent = ({ projects, hiddenHeader, deleteProject }) => {


    const { deleteTalentProject } = useTalentProjectsApi();

    const handleDeleteProject = (id) => {
        deleteTalentProject(id).then(() => {
            console.log('Project deleted:', id);
        }).catch((error) => {
            toast.error('Error deleting project:', error.toString());
        });
    };

    if (projects.length > 0) {
        return (
            <div className="projectBox">
                {
                    !hiddenHeader && (
                        <div className="projectBoxTitle pb-3">
                            <span>Proyectos</span>
                            <a href="/profile/adm-projects">
                                <button>
                                    <span>Crear</span><span>+</span>
                                </button>
                            </a>
                        </div>
                    )
                }
                <Slider {...sliderConfig}>
                    {projects.map((project) => (
                        <div key={project.id} >
                            {deleteProject && (
                                <div className="delete_container">
                                    <button className="deleteProject" onClick={() => handleDeleteProject(project.id)}>
                                        <img src={TrashIcon} alt="delete" />
                                    </button>
                                </div>
                            )}
                            <Link to={`/profile/project/${project.id}/${project.talentId}`} className="projectMain">
                                <img src={procesedImage(project.images)} alt="project" />
                                <span title={project.name}>{project.name}</span>
                            </Link>
                        </div>
                    ))}
                </Slider>

            </div>
        );
    } else {
        return (
            <div className="projectBox">
                <div className="projectBoxTitle pb-3">
                    <span>Proyectos</span>
                    <a href="/profile/adm-projects">
                        <button>
                            <span>Crear</span><span>+</span>
                        </button>
                    </a>
                </div>
                <button className="boxProjects">Carga tu primer proyecto</button>
            </div>
        );
    }
};

export default ProjectBoxComponent;