/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import CompanyReusableComponent from "../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ButtonComponent from "../../../Shared/ButtonComponent";
import PropTypes from "prop-types";
import { usePositionsApi } from "../../../../api/PositionsApi";
import SearchComponent from "../../../Shared/SearchComponent/SearchComponent";
import { useAuthApi } from "../../../Register/Empresa/Api/CompanyApi";
import { useWorkAreaGet, useWorkAreas } from "../../../../api/WorkAreaApi";
import MapComponent from "../../../Shared/MapsComponent";
import locateIcon from '../../../../Assets/locateIcon.svg';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const seniorityOptions = [
    { title: "Practicante", subtitle: "El talento se encuentra en una etapa de aprendizaje, ganando experiencia mientras todavía está estudiando o por graduarse." },
    { title: "Junior", subtitle: "El talento se encuentra en las primeras etapas de la carrera, con algo de experiencia y conocimiento, aún desarrollando habilidades bajo supervisión." },
    { title: "Semi-Senior", subtitle: "El talento tiene experiencia, aún en desarrollo para avanzar al nivel senior, trabaja con cierto grado de independencia." },
    { title: "Senior", subtitle: "El talento es un perfil con alta experiencia y competencia, puede tener responsabilidades de liderazgo, trabaja de manera independiente." },
    { title: "Manager", subtitle: "Supervisa equipos o departamentos, se responsabiliza de alcanzar ciertos objetivos o metas." },
    { title: "Director / Director General", subtitle: "A cargo de departamento o con serias responsabilidades de supervisión a gran escala, decisor." },
    { title: "Consultor", subtitle: "Experto en un área específica para proporcionar asesoramientos, soluciones o problemas, para mejorar el rendimiento de una organización." }
];

const Step1CreateJob = ({ isGoBack, title, workAreaAndSpecialty, seniority, ubication, handleSaveForm, workAreaId, expertiseSpecialization }) => {
    const [internalSteps, setInternalSteps] = useState(0);
    const [positions, setPositions] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [localTitle, setLocalTitle] = useState({
        name_es: title?.name_es || '',
        id: title?.id || '',
    });
    const [expertiseAreas, setExpertiseAreas] = useState([]);
    const [selectedExpertise, setSelectedExpertise] = useState(null);
    const [localExpertise, setLocalExpertise] = useState(workAreaAndSpecialty);
    const [selectedExpertiseId, setSelectedExpertiseId] = useState(workAreaId);
    const [expertiseAreasSpecialization, setExpertiseAreasSpecialization] = useState([]);
    const [selectedExpertiseSpecialization, setSelectedExpertiseSpecialization] = useState([
        {
            id: expertiseSpecialization?.id || '',
            name_es: expertiseSpecialization?.name_es || '',
            work_area_id: expertiseSpecialization?.work_area_id || ''
        }
    ]);
    const [selectedOptionSeniority, setSelectedOptionSeniority] = useState('');
    const [selectedSeniority, setSelectedSeniority] = useState(seniority);
    const [currentPosition, setCurrentPosition] = useState({});
    const [isLoaded, setIsLoaded] = useState(true);
    const [localUbication, setLocalUbication] = useState({
        city: ubication?.city || '',
        country: ubication?.country || '',
        coordinates: ubication?.coordinates || [],
    });


    const { fetchPositions, fetchPosition } = usePositionsApi();
    const { getIdListEmploymentExpertiseArea } = useAuthApi();
    const fetchWorkAreas = useWorkAreas({ isNameEs: true });
    const getWorkArea = useWorkAreaGet();
    const getPlaceName = useCallback(async (lat, lng) => {
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${'AIzaSyAvB4rWV__iIfDH8UuHjRvPu4GTTaA85bI'}`
            );
            const data = await response.json();
            if (data.results && data.results.length > 0) {
                const addressComponents = data.results[0].address_components;
                let city = "";
                let country = "";

                addressComponents.forEach(component => {
                    if (component.types.includes("locality")) {
                        city = component.long_name;
                    }
                    if (component.types.includes("country")) {
                        country = component.long_name;
                    }
                });

                return { city, country, coordinates: [lat, lng] };
            } else {
                toast.error("No se encontraron resultados para la ubicación");
                return { city: "Desconocido", country: "Desconocido", coordinates: [] };
            }
        } catch (error) {
            toast.error("Error al obtener el nombre del lugar:", error);
            return { city: "", country: "", coordinates: [] };
        }
    }, []);


    const fetchCurrentLocation = useCallback(
        (logPosition = false, getPlace = false) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        if (logPosition)
                            console.log(
                                `Ubicación actual ${getPlace ? "Click:" : ":"} Latitud: ${pos.lat
                                }, Longitud: ${pos.lng}`
                            );
                        setCurrentPosition(pos);
                        if (getPlace) {
                            const placeData = await getPlaceName(pos.lat, pos.lng);
                            console.log(`La ubicación actual es en ${placeData.city}, ${placeData.country}`);
                            setLocalUbication(placeData);
                        } else {
                            setLocalUbication(prev => ({
                                ...prev,
                                coordinates: [pos.lat, pos.lng]
                            }));
                        }
                        setIsLoaded(true);
                    },
                    () => {
                        toast.error("Geolocation is not supported by this browser.");
                        setIsLoaded(true);
                    }
                );
            } else {
                toast.error("Geolocation is not supported by this browser.");
                setIsLoaded(true);
            }
        },
        [getPlaceName]
    );


    const memoizedFetchPositions = useCallback(fetchPositions, []);
    const memoizedFetchWorkAreas = useCallback(fetchWorkAreas, []);
    const memoizedGetIdListEmploymentExpertiseArea = useCallback(() => getIdListEmploymentExpertiseArea(selectedExpertiseId), [selectedExpertiseId]);

    const history = useHistory();

    const handleSelectedPosition = useCallback((position) => setSelectedPosition(position), []);

    const handleSelectedExpertise = useCallback((expertise) => setSelectedExpertise(expertise), []);

    const handleSelectedExpertiseSpecialization = (expertise) => {
        setSelectedExpertiseSpecialization((prevSelected) => {
            console.log("Selected Specializations:", selectedExpertiseSpecialization);
            if (prevSelected.includes(expertise)) {
                return prevSelected.filter(spec => spec !== expertise);
            } else if (prevSelected.length < 3) {
                return [...prevSelected, expertise];
            } else {
                return prevSelected;
            }
        });
    };

    const handleSaveSelectedPosition = useCallback(() => {
        if (selectedPosition?.name_es && selectedPosition?.id) {
            setLocalTitle({
                name_es: selectedPosition.name_es,
                id: selectedPosition.id
            });
            setInternalSteps(0);
        }
    }, [selectedPosition]);

    const handleSaveSelectedExpertise = useCallback(() => {
        if (selectedExpertise) {
            setLocalExpertise(selectedExpertise.name_es);
            setSelectedExpertiseId(
                selectedExpertise.id
            );
            setInternalSteps('AreaSpecializationScreen');
        }
    }, [selectedExpertise]);

    const handleSenioritySelection = (event) => setSelectedOptionSeniority(event.target.value);

    const handleOptionSelectedLocation = (option) => console.log(option);

    const isRemoteChange = (event) => {
        if (event.target.checked) {
            console.log("Todo nuestro equipo opera de forma remota.");
        }
    };

    const handleSaveWorkAreaAndSpecialty = useCallback(() => {
        if (selectedExpertiseSpecialization?.id && selectedExpertiseSpecialization?.name_es && selectedExpertiseSpecialization?.work_area_id) {
            setSelectedExpertiseSpecialization(prevSelected => prevSelected);
        }

        if (selectedExpertiseSpecialization.length > 0) {
            setLocalExpertise(`${selectedExpertise.name} - ${selectedExpertiseSpecialization.map(spec => spec.name_es).join(', ')}`);
            setInternalSteps(0);
        }

    }, [selectedExpertise, selectedExpertiseSpecialization]);

    const handleSaveSeniority = useCallback(() => {
        if (selectedOptionSeniority) {
            setSelectedSeniority(selectedOptionSeniority);
            setInternalSteps(0);
        }
    }, [selectedOptionSeniority]);

    const handleSaveLocation = useCallback(() => {
        if (currentPosition.lat && currentPosition.lng) {
            setInternalSteps(0);
        }
    }, [currentPosition]);

    useEffect(() => {
        if (internalSteps === 1) memoizedFetchPositions().then(setPositions);
        if (internalSteps === 2) {
            setSelectedExpertise(null);
            memoizedFetchWorkAreas().then(setExpertiseAreas);
        }
        if (internalSteps === 'AreaSpecializationScreen') {
            setSelectedExpertiseSpecialization([]);
            memoizedGetIdListEmploymentExpertiseArea().then(setExpertiseAreasSpecialization);
        }
    }, [internalSteps, memoizedFetchPositions, memoizedFetchWorkAreas, memoizedGetIdListEmploymentExpertiseArea]);

    useEffect(() => {
        console.log("Title:", title);
        if (title) {
            fetchPosition(title).then((position) => {
                setLocalTitle({
                    name_es: position.name_es,
                    id: position.id
                });
                setSelectedPosition(position);
            });
        }
        console.log("WorkAreaId:", workAreaId);
        if (workAreaId) {
            getWorkArea(workAreaId).then((workArea) => {
                setLocalExpertise(workArea.name_es);
                setSelectedExpertise(workArea);
            });
        }
    }, [title, workAreaId]);

    const renderButtons = (label, step, defaultLabel) => (
        <Col xs={12} className="gap-after">
            <ButtonComponent
                text={label || defaultLabel}
                version={label ? "outline" : "grey"}
                isLeft
                large
                onClick={() => setInternalSteps(step)}
                isColorBlack={!!label && label !== defaultLabel}
            />
        </Col>
    );

    const renderSelectionButtons = (items, selectedItem, onClickHandler, isMultiSelect = false) => (
        items.map(item => (
            <Col key={item.id} xs={6} className="gap-after">
                <ButtonComponent
                    text={item.name || item.name_es}
                    version={isMultiSelect ? (selectedItem.includes(item) ? "fill" : "outline") : (selectedItem && selectedItem.id === item.id ? "fill" : "outline")}
                    large
                    onClick={() => onClickHandler(item)}
                    paddingX="35px"
                    style={{ height: "100%" }}
                    isColorBlack
                />
            </Col>
        ))
    );

    const renderStep0 = ({ isGoBack }) => (
        <CompanyReusableComponent
            goBack={
                isGoBack
                    ? () => isGoBack()
                    : () => history.goBack()
            }
            subtitle="Tienes la oportunidad de subir un anuncios gratis. Para más, considera nuestra versión premium."
            title="Crea tu propuesta de empleo"
            company="BlueScreen"
        >
            <Row className="w-100">
                {renderButtons(localTitle.name_es, 1, "Título a cargo")}
                {renderButtons(localExpertise, 2, "Área de trabajo y especialización")}
                {renderButtons(selectedSeniority, 3, "Seniority")}
                {renderButtons(localUbication.city, 4, "Ubicación del empleo")}
                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        text="Confirmar"
                        version={localTitle.name_es && localExpertise && selectedSeniority && localUbication ? "fill" : "grey"}
                        large
                        onClick={
                            () => handleSaveForm(localTitle.id, selectedExpertiseId, selectedSeniority, localUbication, selectedExpertiseSpecialization)
                        }
                        disabled={!localTitle || !localExpertise || !selectedSeniority}
                        isHover
                    />
                </Col>
            </Row>
        </CompanyReusableComponent>
    );

    const renderStep1 = () => (
        <CompanyReusableComponent
            goBack={() => setInternalSteps(0)}
            subtitle="Título a cargo"
            company="WhiteScreen"
        >
            <Row className="w-100 pt-3">
                <Col xs={12} className="gap-after">
                    <span className="mb-2 color-black">
                        ¿Cuál es el título del cargo que estás ofertando?
                    </span>
                </Col>
                {renderSelectionButtons(positions, selectedPosition, handleSelectedPosition)}
                <Col xs={12} className="gap-after">
                    <SearchComponent
                        placeholder="Buscar cargo"
                        options={positions.map(position => position.name_es)}
                        onOptionSelected={(option) => {
                            const newOption = positions.find(pos => pos.name_es === option);
                            if (newOption) {
                                handleSelectedPosition(newOption);
                            } else {
                                const createdOption = { id: option, name_es: option };
                                setPositions(prev => [...prev, createdOption]);
                                handleSelectedPosition(createdOption);
                            }
                        }}
                        isPositions
                        addOption
                    />
                </Col>
                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        text="Continuar"
                        version={selectedPosition ? "fill" : "grey"}
                        large
                        onClick={handleSaveSelectedPosition}
                        isHover
                    />
                </Col>
            </Row>
        </CompanyReusableComponent>
    );

    const renderStep2 = () => (
        <CompanyReusableComponent
            goBack={() => setInternalSteps(0)}
            subtitle="Área de trabajo y especialización"
            company="WhiteScreen"
        >
            <Row className="w-100 pt-3">
                <Col xs={12} className="gap-after">
                    <span className="mb-2 color-black">
                        ¿Cuál es el título del cargo que estás ofertando?
                    </span>
                </Col>
                {renderSelectionButtons(expertiseAreas, selectedExpertise, handleSelectedExpertise)}
                <Col xs={12} className="gap-after">
                    <SearchComponent
                        placeholder="Buscar cargo"
                        options={expertiseAreas.map(expertise => expertise.name_es)}
                        onOptionSelected={(option) => {
                            const newOption = expertiseAreas.find(exp => exp.name_es === option);
                            if (newOption) {
                                handleSelectedExpertise(newOption);
                            } else {
                                const createdOption = { id: option, name_es: option };
                                setExpertiseAreas(prev => [...prev, createdOption]);
                                handleSelectedExpertise(createdOption);
                            }
                        }}
                        isExpertise
                        addOption
                        filterStrings={expertiseAreas.map(expertise => expertise.name_es)}
                    />
                </Col>
                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        text="Continuar"
                        version={selectedExpertise ? "fill" : "grey"}
                        large
                        onClick={handleSaveSelectedExpertise}
                        isHover
                    />
                </Col>
            </Row>
        </CompanyReusableComponent>
    );

    const renderStep3 = () => (
        <CompanyReusableComponent
            goBack={() => setInternalSteps(0)}
            subtitle="Seniority"
            company="WhiteScreen"
        >
            <Row className="w-100 pt-3">
                <Col xs={12} className="gap-after">
                    <span className="mb-2 color-black">
                        Por favor, tu nivel de experiencia profesional
                    </span>
                </Col>
                {seniorityOptions.map(option => (
                    <Col xs={12} className="gap-after text-left" key={option.title}>
                        <label>
                            <input
                                type="radio"
                                name="seniority"
                                value={option.title}
                                checked={selectedOptionSeniority === option.title}
                                onChange={handleSenioritySelection}
                                className="mr-2"
                            />
                            <strong>{option.title}</strong>:<br /> {option.subtitle}
                        </label>
                    </Col>
                ))}
                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        text="Continuar"
                        version="fill"
                        large
                        isHover
                        onClick={handleSaveSeniority}
                    />
                </Col>
            </Row>
        </CompanyReusableComponent>
    );

    const renderStep4 = () => (
        <CompanyReusableComponent
            company="WhiteScreen"
            title="Ubicación de la empresa"
            goBack={() => setInternalSteps(0)}
        >
            <Row className="m-0">
                <Col xs={12} className="gap-after py-2">
                    Agregar ubication
                </Col>

                <Col xs={12} className="gap-after d-flex justify-content-center">
                    <MapComponent isLoaded={isLoaded} currentPosition={currentPosition} />
                </Col>

                <Col xs={12} className="gap-after d-flex">
                    <input type="checkbox" id="isRemote" name="isRemote" value="isRemote" style={{ marginRight: "10px" }} onChange={isRemoteChange} />
                    <label htmlFor="isRemote" className="form-label mb-0">Todo nuestro equipo opera de forma remota.</label>
                </Col>

                <Col xs={12} className="gap-after py-2">
                    <SearchComponent
                        placeholder={'Ingresa dirección o punto de referencia'}
                        options={['Calle 1 # 1-1', 'Calle 2 # 2-2', 'Calle 3 # 3-3']}
                        onOptionSelected={handleOptionSelectedLocation}
                    />
                </Col>

                <Col xs={12} className="gap-after py-4">
                    <ButtonComponent
                        text="Mi Ubicación actual"
                        version="outline"
                        icon={locateIcon}
                        large
                        onClick={() => fetchCurrentLocation(true, true)}
                        style={{
                            border: 'none',
                            color: 'rgba(0, 62, 255, 0.25)'
                        }}
                    />
                </Col>

                <Col xs={12} className="gap-after pb-5">
                    <ButtonComponent text="Guardar" version={
                        'fill'
                    } large onClick={handleSaveLocation} />
                </Col>
            </Row>
        </CompanyReusableComponent>
    );

    const areaSpecializationScreen = () => (
        <CompanyReusableComponent
            goBack={() => setInternalSteps(2)}
            subtitle="Área de trabajo y especialización"
            company="WhiteScreen"
        >
            <Row className="w-100 pt-3">
                <Col xs={12} className="gap-after">
                    <ButtonComponent text={selectedExpertise.name} version="outline" large isColorBlack isLeft />
                </Col>
                <Col xs={12} className="gap-after">
                    <span className="mb-2 color-black">
                        ¿Cuál es su área de especialización? Puede seleccionar hasta 3 ítems.
                    </span>
                </Col>
                {renderSelectionButtons(expertiseAreasSpecialization, selectedExpertiseSpecialization, handleSelectedExpertiseSpecialization, true)}
                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        text="Continuar"
                        version={selectedExpertiseSpecialization.length > 0 ? "fill" : "grey"}
                        large
                        onClick={handleSaveWorkAreaAndSpecialty}
                        isHover
                    />
                </Col>
            </Row>
        </CompanyReusableComponent>
    );

    const renderStep = () => {
        switch (internalSteps) {
            case 0:
                return renderStep0({ isGoBack });
            case 1:
                return renderStep1();
            case 2:
                return renderStep2();
            case 'AreaSpecializationScreen':
                return areaSpecializationScreen();
            case 3:
                return renderStep3();
            case 4:
                return renderStep4();
            default:
                return null;
        }
    };

    return renderStep();
};

Step1CreateJob.propTypes = {
    title: PropTypes.string,
    workAreaAndSpecialty: PropTypes.string,
    seniority: PropTypes.string,
    ubication: PropTypes.string,
};

export default Step1CreateJob;
