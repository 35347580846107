import React, { useState, useContext, useEffect, useCallback } from 'react';
import NavBarComponentProfile from '../Shared/NavBarComponent/NavBarComponent';
import { useProfileData } from '../../contexts/profileContext';
import ProfileViewComponent from '../Shared/ProfileViewComponent/ProfileViewComponent';
import { Container } from 'react-bootstrap';
import CardInfoComponent from '../Shared/CardInfoComponent/CardInfoComponent';
import SkillComponent from '../Shared/SkillComponent/SkillComponent';
import ProjectBoxComponent from '../Shared/ProjectBoxComponent/ProjectBoxComponent';
import PersonalInfoCardComponent from '../Shared/PersonalInfoCardComponent/PersonalInfoCardComponent';
import DataSoftSkillsComponent from '../Shared/DataSoftSkillsComponent/DataSoftSkillsComponent';
import ButtonComponent from '../Shared/ButtonComponent';
import NotificationComponent from '../Shared/NotificationComponent/NotificationComponent';
import NotificationIcon from '../../Assets/YellowNotification.svg';
import { UserContext } from '../../contexts/UserContext';
import { TalentLangContext } from '../../contexts/talentLangContext';
import { WorkPreferencesContext } from '../../contexts/workPreferencesContext';
import './MainScreen.css';
import { useTalentLangsApi } from '../../api/TalentLangsApi';
import { useTalentEducationsApi } from '../../api/TalentEducationsApi';
import { useTalentCertificationsApi } from '../../api/TalentCertificationsApi';
import { useTalentWorkExperiencesApi } from '../../api/TalentWorkExperiences';
import { useTalentHardSkillsApi } from '../../api/TalentHardSkillsApi';
import { useTalentToolsApi } from '../../api/TalentToolsApi';
import { RegisterDataContext } from '../../contexts/RegisterDataContext';
import { useTalentsApi } from '../../api/TalentApi';
import LangModule from '../Register/Talento/savedModules/LangModuleFolder/LangModule';
import HardSkillsModule from '../Register/Talento/savedModules/HardSkillsFolder/HardSkillModule';
import SoftSkillModule from '../Register/Talento/savedModules/SoftSkillsFolder/SoftSkillModule';
import ProfileCardModule from '../Register/Talento/savedModules/ProfileCardModule';
import HiringModule from '../Register/Talento/savedModules/HiringModuleFolder/HiringModule';
import { useTalentProjectsApi } from '../../api/TalentProjectsApi';
import { toast } from 'react-toastify';

const reverseTranslateValues = {
  contract: {
    IN_RELATION: 'Relación de dependencia',
    FREELANCE: 'Freelance / Autónomo',
    PRACTICE: 'Prácticas / Pasantía',
  },
  duration: {
    LONG_TERM: 'Puesto fijo',
    PROJECT: 'Proyecto',
    PERIOD: 'Temporal',
  },
  dedication: {
    FULL_TIME: 'Full Time',
    PART_TIME: 'Part Time',
    SPECIFIC_HOURS: 'Horas estipuladas',
    SHIFTS: 'Turnos',
    VOLUNTEERING: 'Voluntariado',
  },
  modality: {
    ON_SITE: 'Presencial',
    HIBRYD: 'Híbrido',
    GLOBAL_REMOTE: 'Remoto global',
    LOCAL_REMOTE: 'Remoto nacional',
    TIMEZONE_REMOTE: 'Horas estipuladas',
  },
  payment: {
    PROJECT: 'Por proyecto',
    MONTHLY: 'Pago mensual',
    YEARLY: 'Pago anual',
    INDIFERENT: 'Indiferente',
  },
};

const languageLevels = ['Básico', 'Lectura', 'Conversacional', 'Nativo'];

const softSkillLevels = ['Básico', 'Intermedio', 'Avanzado'];

const MainScreen = () => {
  const { profileData } = useProfileData();
  const { userData } = useContext(UserContext);
  const [registerData] = useContext(RegisterDataContext);
  const [showNotification, setShowNotification] = useState(() => {
    const alreadyShown = sessionStorage.getItem('notificationShown') === 'true';
    return !alreadyShown;
  });
  const { talentLangs } = useContext(TalentLangContext);
  const { workPreferences } = useContext(WorkPreferencesContext);
  const { fetchTalentLanguages, talentWorkPreferencesGet } =
    useTalentLangsApi();
  const { updateTalentLangs } = useContext(TalentLangContext);
  const { updateWorkPreferences } = useContext(WorkPreferencesContext);
  const { fetchTalentEducations } = useTalentEducationsApi();
  const { fetchTalentCertifications } = useTalentCertificationsApi();
  const { fetchTalentWorkExperiences } = useTalentWorkExperiencesApi();
  const { fetchHardSkillsByTalent } = useTalentHardSkillsApi();
  const { fetchToolsByTalent } = useTalentToolsApi();
  const { fetchTalent, fetchFullProfile } = useTalentsApi();
  const [tools, setTools] = useState([]);
  const [langsData, setLangsData] = useState([]);
  const [techSkillsData, setTechSkillsData] = useState([]);
  const [dataEducations, setDataEducations] = useState([]);
  const [dataCertifications, setDataCertifications] = useState([]);
  const [dataWorkExperiences, setDataWorkExperiences] = useState([]);
  const [step, setStep] = useState(0);
  const [history, setHistory] = useState([]);
  const [dataLanguage, setLenguage] = useState([]);
  const [dataHardSkills, setHardSkills] = useState([]);
  const [dataTechSkills, setTechSkills] = useState([]);
  const [ProfileDataModule, setProfileDataModule] = useState([]);
  const [prevHiringData, setPrevHiringData] = useState({});
  const { getTalentProjects } = useTalentProjectsApi();
  const [talentProjects, setTalentProjects] = useState([]);
  const [dataFullProfile, setDataFullProfile] = useState({});
  const [talentData, setTalentData] = useState({});

  const handleNotificationClose = () => {
    setShowNotification(false);
    sessionStorage.setItem('notificationShown', 'true');
  };

  const transformLangsData = (data) => {
    return data
      .map(
        (item) => `${item.language.name_es} / ${languageLevels[item.level - 1]}`
      )
      .join(', ');
  };

  const transformSoftSkillsData = (data) => {
    return data
      .map(
        (item) => `${item.tool.name_es} / ${softSkillLevels[item.level - 1]}`
      )
      .join(', ');
  };

  const calculateAge = (birthDate) => {
    let parsedDate;
    if (birthDate.includes('T')) {
      parsedDate = new Date(birthDate);
    } else {
      parsedDate = new Date(birthDate + 'T00:00:00Z');
    }
    const difference = new Date().getTime() - parsedDate.getTime();
    const ageDate = new Date(difference);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const transformTechSkillsData = (data) => {
    return data.map((item) => item.hard_skill.name_es).join(', ');
  };

  const langs = Array.isArray(talentLangs)
    ? talentLangs
        .map(
          (lang) => `${lang.language.name_es} / ${languageLevels[lang.level]}`
        )
        .join(', ')
    : '';
  const techSkills = Array.isArray(profileData.techSkills)
    ? profileData.techSkills
        .map((skill) => `${skill.name_es} / ${softSkillLevels[skill.level]}`)
        .join(', ')
    : '';
  const combinedData = userData?.talent
    ? {
        ...userData.user,
        ...userData.talent,
        imgProfile:
          userData.talent.profile_image || talentData.profile_image || '',
        visibility: userData.talent.visibility,
        age: calculateAge(userData.user.birth_date),
        localization: userData.talent.country || talentData.country,
        name: userData.user.f_name,
        surname: userData.user.l_name,
        languages: langs,
        salary: {
          1: workPreferences.max_salary || profileData.salary[2],
          2: workPreferences.currency || profileData.salary[3],
        },
        hiring: {
          0:
            reverseTranslateValues.contract[workPreferences.contract] ||
            reverseTranslateValues.contract[profileData.hiring[0]],
          1:
            reverseTranslateValues.duration[workPreferences.duration] ||
            reverseTranslateValues.duration[profileData.hiring[1]] ||
            (workPreferences.from_date && workPreferences.to_date
              ? 'Temporal ' +
                workPreferences.from_date +
                '-' +
                workPreferences.to_date
              : null),
          2:
            reverseTranslateValues.dedication[workPreferences.dedication] ||
            reverseTranslateValues.dedication[profileData.hiring[2]],
          3:
            reverseTranslateValues.modality[workPreferences.modality] ||
            reverseTranslateValues.modality[profileData.modHiring],
          4:
            reverseTranslateValues.payment[workPreferences.payment] ||
            profileData.hiring[3],
        },
        modHiring: reverseTranslateValues.modality[workPreferences.modality],
        techSkills: techSkills,
      }
    : {};

  const defaultData = {
    languages: transformLangsData(langsData) || '',
    technicalSkills: transformTechSkillsData(techSkillsData) || '',
    softSkills: transformSoftSkillsData(tools) || '',
  };

  useEffect(() => {
    setPrevHiringData({
      hiring: Object.values({
        0:
          reverseTranslateValues.contract[workPreferences.contract] ||
          reverseTranslateValues.contract[profileData.hiring[0]],
        1:
          reverseTranslateValues.dedication[workPreferences.dedication] ||
          reverseTranslateValues.dedication[profileData.hiring[2]],
        2:
          reverseTranslateValues.modality[workPreferences.modality] ||
          reverseTranslateValues.modality[profileData.modHiring],
      }),
      modHiring: combinedData.modHiring,
      salary: {
        0:
          reverseTranslateValues.payment[workPreferences.payment] ||
          profileData.hiring[3],
        1: null,
        2: workPreferences.max_salary || profileData.salary[2],
        3: workPreferences.currency || profileData.salary[3],
      },
    });
  }, [userData, workPreferences, profileData, combinedData.modHiring]);

  const personalInfoData = {
    education: dataEducations || [],
    certs: dataCertifications || [],
    exp: dataWorkExperiences || [],
    refs: [],
  };

  const skillComponentProps = {
    ...defaultData,
  };

  useEffect(() => {
    const token = localStorage.getItem('tokenRegister') || registerData.token;
    if (userData.token || registerData.token || token) {
      if (!userData?.talent?.id) {
        return toast.error('No talent id found');
      }
      fetchTalentLanguages(userData?.talent?.id)
        .then((talentLangs) => {
          updateTalentLangs(talentLangs);
          setLangsData(talentLangs);
        })
        .catch((error) =>
          toast.error('Error fetching talent languages:', error)
        );
      talentWorkPreferencesGet(userData.talent.id)
        .then((talentWorkPreferences) => {
          updateWorkPreferences(talentWorkPreferences);
        })
        .catch((error) =>
          toast.error('Error fetching work preferences:', error)
        );
      fetchTalentEducations(userData.talent.id)
        .then((talentEducations) => {
          setDataEducations(talentEducations);
        })
        .catch((error) =>
          toast.error('Error fetching talent educations:', error)
        );
      fetchTalentCertifications(userData.talent.id)
        .then((talentCertifications) => {
          setDataCertifications(talentCertifications);
        })
        .catch((error) =>
          toast.error('Error fetching talent certifications:', error)
        );
      fetchTalentWorkExperiences(userData.talent.id)
        .then((talentWorkExperiences) => {
          setDataWorkExperiences(talentWorkExperiences);
        })
        .catch((error) =>
          toast.error('Error fetching talent work experiences:', error)
        );
      fetchHardSkillsByTalent(userData.talent.id)
        .then((hardSkills) => {
          setTechSkillsData(hardSkills);
        })
        .catch((error) =>
          toast.error('Error fetching talent hard skills:', error)
        );
      fetchToolsByTalent(userData.talent.id)
        .then((tools) => {
          setTools(tools);
        })
        .catch((error) => toast.error('Error fetching talent tools:', error));
      fetchTalent(userData.talent.id)
        .then((talent) => {
          setTalentData(talent);
        })
        .catch((error) => toast.error('Error fetching talent:', error));
      getTalentProjects(userData.talent.id)
        .then((projects) => {
          setTalentProjects(projects);
        })
        .catch((error) =>
          toast.error('Error fetching talent projects:', error)
        );
      fetchFullProfile(userData.talent.id)
        .then((fullProfile) => {
          setDataFullProfile(fullProfile);
        })
        .catch((error) => toast.error('Error fetching full profile:', error));
    } else {
      toast.error('No token found');
    }
  }, [registerData.token, userData.token]);

  const goBack = useCallback(() => {
    if (
      step === 'profileCardModule' ||
      step === 'langModule' ||
      step === 'HardSkillsModule' ||
      step === 'SoftSkillModule' ||
      step === 'HiringModule'
    ) {
      const lastStep = history[history.length - 1];
      setStep(lastStep);
      setHistory((currentHistory) => currentHistory.slice(0, -1));
    } else if (step > 1) {
      setStep((currentStep) => currentStep - 1);
      setHistory((currentHistory) => currentHistory.slice(0, -1));
    } else {
      window.history.back();
    }
    window.location.reload();
  }, [step, history]);

  switch (step) {
    case 0:
      return (
        <>
          {showNotification && <div className="notification-overlay"></div>}
          <div className="mainScreen">
            <NavBarComponentProfile
              admin={dataFullProfile}
              imgProfile={userData?.talent?.profile_image}
              darkLogo={false}
              zindex="4"
              position={{ position: showNotification ? 'relative' : 'static' }}
            />{' '}
            <div className="upper-space"></div>
            <div className="content-area">
              <Container fluid className="d-flex flex-column content-container">
                <ProfileViewComponent
                  {...(combinedData || ProfileDataModule)}
                  goProfileCardModule={() => {
                    setStep('profileCardModule');
                    setHistory([...history, step]);
                  }}
                />
                <CardInfoComponent
                  {...combinedData}
                  borderBlue
                  goHiringModule={() => {
                    setStep('HiringModule');
                    setHistory([...history, step]);
                  }}
                />
                <ProjectBoxComponent projects={talentProjects} />
                {showNotification && (
                  <NotificationComponent
                    text={{
                      boldPart: '',
                      normalText:
                        'Es el momento de comenzar. Por favor, empieza a cargar tus proyectos en la plataforma.',
                    }}
                    icon={NotificationIcon}
                    onClose={handleNotificationClose}
                    zindex="3"
                    isMainScreen
                  />
                )}
                <ButtonComponent
                  isHover
                  version="fill"
                  text={'Administrar proyectos'}
                  large
                  href={'/profile/adm-projects'}
                  zindex="3"
                />
                <SkillComponent
                  languages={dataLanguage}
                  technicalSkills={dataHardSkills}
                  softSkills={dataTechSkills}
                  {...skillComponentProps}
                  borderBlue
                  goLangModule={() => {
                    setStep('langModule');
                    setHistory([...history, step]);
                  }}
                  goHardModule={() => {
                    setStep('HardSkillsModule');
                    setHistory([...history, step]);
                  }}
                  goSoftSkillModule={() => {
                    setStep('SoftSkillModule');
                    setHistory([...history, step]);
                  }}
                />
                <PersonalInfoCardComponent {...personalInfoData} notEditIcon />
                <DataSoftSkillsComponent
                  dataSoftSkills={profileData.dataSoftSkills}
                  img={'softSkills'}
                  text={'Habilidades blandas'}
                  notEditIcon
                />
                <DataSoftSkillsComponent
                  dataSoftSkills={profileData.corporateCulture}
                  text={'Cultura empresarial'}
                  notEditIcon
                />
              </Container>
            </div>
          </div>
        </>
      );
    case 'langModule':
      return (
        <LangModule
          prevLangs={langsData}
          goBack={goBack}
          isLanguage={setLenguage}
        />
      );
    case 'HardSkillsModule':
      return (
        <HardSkillsModule
          goBack={goBack}
          isHardSkills={setHardSkills}
          prevHards={techSkillsData}
        />
      );
    case 'SoftSkillModule':
      return (
        <SoftSkillModule
          goBack={goBack}
          isTechSkills={setTechSkills}
          prevSoftSkills={tools}
        />
      );
    case 'profileCardModule':
      return (
        <ProfileCardModule goBack={goBack} profileData={setProfileDataModule} />
      );
    case 'HiringModule':
      return (
        <HiringModule
          isMainScreen
          gotostep={goBack}
          previousData={prevHiringData}
        />
      );
  }
};

export default MainScreen;
