import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { RegisterDataContext } from '../../../contexts/RegisterDataContext';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/dashboard/stats';

export const useDashboardApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;

    // Function to get dashboard statistics within a specific date range
    const getDashboardStats = async (dateFrom, dateTo) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/from/${dateFrom}/to/${dateTo}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Dashboard stats retrieved:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching dashboard stats:', error.response ? error.response.data : error);
            return null;
        }
    };

    return {
        getDashboardStats
    };
};
