import React, { useState, useRef, useEffect } from 'react';

const AutocompleteSelect = ({ datos, onSeleccionar, placeholder, reset }) => {
  const [filtro, setFiltro] = useState('');
  const [mostrarDropdown, setMostrarDropdown] = useState(false);
  const [seleccionado, setSeleccionado] = useState(false);
  const dropdownRef = useRef(null);

  const datosSeguros = datos || [];

  const datosFiltrados = datosSeguros.filter((dato) => 
    dato.name_es && dato.name_es.toLowerCase().includes(filtro.toLowerCase())
  );

  useEffect(() => {
    if (!seleccionado) {
      setMostrarDropdown(datosFiltrados.length > 0 && filtro !== '');
    }
  }, [filtro, datosFiltrados.length, seleccionado]);

  const seleccionarItem = (dato) => {
    console.log(`Seleccionado: ${dato.name_es} con ID: ${dato.id}`);
    onSeleccionar(dato);
    setFiltro(dato.name_es);
    setMostrarDropdown(false);
    setSeleccionado(true);
  };

  const resetear = () => {
    setFiltro('');
    setMostrarDropdown(false);
    setSeleccionado(false);
  };

  useEffect(() => {
    if (reset) {
      resetear();
    }
  }, [reset]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMostrarDropdown(false);
        setSeleccionado(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('focusin', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('focusin', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef}>
      <input
        type="text"
        placeholder={placeholder}
        value={filtro}
        onChange={(e) => {
          setFiltro(e.target.value);
          setSeleccionado(false);
        }}
        onFocus={() => {
          setMostrarDropdown(true);
          setSeleccionado(false);
        }}
        autoComplete="off"
        className="myInput"
        style={{
          width: '100%',
          padding: '10px',
          borderRadius: '8px',
          border: '1px solid rgb(226, 226, 226)',
        }}
      />
      {mostrarDropdown && (
        <ul
          style={{
            listStyle: 'none',
            margin: 0,
            padding: 0,
            position: 'absolute',
            backgroundColor: 'white',
            width: '100%',
            zIndex: 1000,
          }}
        >
          {datosFiltrados.map((dato) => (
            <li
              key={dato.id}
              onClick={() => seleccionarItem(dato)}
              style={{
                padding: '10px',
                cursor: 'pointer',
                borderBottom: '1px solid #ccc',
              }}
            >
              {dato.name_es}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteSelect;
