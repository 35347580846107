import React from "react";
import './SiteStats.css';

const SiteStatsElement = ({ data }) => {
    if (!data?.employments) {
        return null;
    }
    const { freemium, premium, diamond } = data.employments;

    return (
        <div className="site__stats__element">
            <span className="site__stats__element__title">Estadísticas del sitio</span>

            {/* Bloque para Freemium */}
            <div className="site__stats__element__content">
                <div className="d-flex align-items-center justify-content-between" style={{ gap: '10px' }}>
                    <div className="site__stats__element__content__item"></div>
                    <span className="site__stats__element__content__item__title">Freemium</span>
                </div>
                <span className="site__stats__element__content__item__amount">({freemium.percentage.toFixed(2)}%)</span>
            </div>

            {/* Bloque para Premium */}
            <div className="site__stats__element__content">
                <div className="d-flex align-items-center justify-content-between" style={{ gap: '10px' }}>
                    <div className="site__stats__element__content__item_prem"></div>
                    <span className="site__stats__element__content__item__title">Premium</span>
                </div>
                <span className="site__stats__element__content__item__amount_prem">({premium.percentage.toFixed(2)}%)</span>
            </div>

            {/* Bloque para Diamond */}
            <div className="site__stats__element__content w-100">
                <div className="d-flex align-items-center justify-content-between" style={{ gap: '10px' }}>
                    <div className="site__stats__element__content__item_diam"></div>
                    <span className="site__stats__element__content__item__title">Diamond</span>
                </div>
                <span className="site__stats__element__content__item__amount_diam">({diamond.percentage.toFixed(2)}%)</span>
            </div>
        </div>
    );
};

export default SiteStatsElement;
