import React from "react";
import { Col } from "react-bootstrap";
import ButtonComponent from "../../Shared/ButtonComponent";
import propTypes from "prop-types";

const ReusableButton = ({ text, defaultText, version, isLeft, large, onClick, isColorBlack, disabled, isHover }) => {
    return (
        <Col xs={12} className="gap-after">
            <ButtonComponent
                text={text || defaultText}
                version={version || "grey"}
                isLeft={isLeft}
                large={large}
                onClick={onClick}
                isColorBlack={isColorBlack}
                disabled={disabled}
                isHover={isHover}
            />
        </Col>
    );
};

ReusableButton.propTypes = {
    text: propTypes.string,
    defaultText: propTypes.string,
    version: propTypes.string,
    isLeft: propTypes.bool,
    large: propTypes.bool,
    onClick: propTypes.func,
    isColorBlack: propTypes.bool,
    disabled: propTypes.bool,
    isHover: propTypes.bool
};

export default ReusableButton;
