import React, { useContext, createContext, useState, useEffect, useMemo } from "react";

const MediaContext = createContext();

export const useMedia = () => {
    return useContext(MediaContext);
}

export const MediaProvider = ({ children }) => {
    const [media, setMedia] = useState(window.innerWidth <= 1200 ? 'Mobile' : 'Desk');

    useEffect(() => {
        const handleResize = () => {
            setMedia(window.innerWidth <= 1200 ? 'Mobile' : 'Desk');
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return useMemo(() => (
        <MediaContext.Provider value={media}>
            {children}
        </MediaContext.Provider>
    ), [media, children]);
}