import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '500px',
    height: '500px'
};

const MapComponent = ({ currentPosition }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyAvB4rWV__iIfDH8UuHjRvPu4GTTaA85bI"
            onLoad={() => setIsLoaded(true)}
        >
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={currentPosition}
                    zoom={10}
                >
                    <Marker position={currentPosition} />
                </GoogleMap>
            ) : (
                <div>Loading...</div>
            )}
        </LoadScript>
    );
};

export default MapComponent;
