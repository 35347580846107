import React, { useState, useCallback, useEffect } from "react";
import ButtonComponent from "../../../Shared/ButtonComponent";
import CameraIcon from '../../../../Assets/CameraIcon.svg';
import { toast } from "react-toastify";
import { useUploadsApi } from '../../../../api/UploadsApi';
import { useVideosApi } from "../../api/VideoApi";
import VideoBox from "../../components/VideoBox/VideoBox";
import './VideoHomePage.css';

const InputField = React.memo(({ label, value, onChange, id }) => (
    <div className="d-flex w-100 align-items-center" style={{ gap: '40px' }}>
        <span className="prp-field-label" style={{ wordWrap: 'break-word', whiteSpace: 'nowrap' }}>{label}</span>
        <input type="text" id={id} className="prp-title-input" value={value || ''} onChange={onChange} />
    </div>
));

const labels = {
    title_es: "Título del video en español",
    title_en: "Título del video en inglés",
    title_pt: "Título del video en portugués",
};

const VideoHomePage = () => {
    const { createVideo, deleteVideo, getVideos, updateVideo } = useVideosApi();
    const { uploadVideo } = useUploadsApi(); // Asegúrate de tener una API que maneje la carga de videos
    const [videoFile, setVideoFile] = useState(null);
    const [videoURL, setVideoURL] = useState('');
    const [copy, setClient] = useState({
        id: null,
        title_es: "",
        title_en: "",
        title_pt: "",
        video: "",
        visible: true
    });
    const [allDataBoxes, setAllDataBoxes] = useState([]);

    useEffect(() => {
        const fetchDataCustomers = async () => {
            try {
                const dataCustomers = await getVideos();
                setAllDataBoxes(dataCustomers);
            } catch (error) {
                toast.error(`Error fetching data customers: ${error}`);
            }
        };
        fetchDataCustomers();
    }, []);

    const handleInputChange = useCallback((field) => (e) => {
        setClient((prevCopy) => ({ ...prevCopy, [field]: e.target.value }));
    }, []);
    const handleSave = useCallback(async () => {
        let videoUrl = null;

        if (videoFile) {
            try {
                const uploadResult = await uploadVideo(videoFile);
                videoUrl = uploadResult.url;
            } catch (error) {
                toast.error(`Error uploading video: ${error}`);
                return;
            }
        }

        const dataCustomersData = {
            ...copy,
            video: videoUrl || copy.video,
        };

        if (copy.id) {
            try {
                await updateVideo(copy.id, dataCustomersData);
                toast.success('Data box updated');
                window.location.reload();
            } catch (error) {
                toast.error(`Error updating data box: ${error.toString()}`);
            }
        } else {
            try {
                await createVideo(dataCustomersData);
                toast.success('Data box created');
                window.location.reload();
            } catch (error) {
                toast.error(`Error creating data box: ${error}`);
            }
        }
    }, [copy, videoFile]);

    const handleDataBoxClick = useCallback((data) => {
        setClient({
            id: data.id,
            name: data.name,
            video: data.video,
            visible: data.visible,
        });
        setVideoURL(data.video);
        setVideoFile(null);
    }, []);

    const handleFileChange = useCallback(event => {
        setVideoFile(event.target.files[0]);
        setVideoURL(URL.createObjectURL(event.target.files[0]));
    }, []);

    const handleClickVisible = useCallback((id) => {
        setAllDataBoxes(prevDataBoxes => {
            const newDataBoxes = prevDataBoxes.map(data =>
                data.id === id ? { ...data, visible: !data.visible } : data
            );

            const updatedClient = newDataBoxes.find(data => data.id === id);

            if (updatedClient) {
                updateVideo(id, { visible: updatedClient.visible })
                    .then(() => {
                        toast.success('Visibilidad actualizada');
                    })
                    .catch(error => {
                        toast.error(`Error al actualizar la visibilidad: ${error}`);
                        setAllDataBoxes(prevDataBoxes);
                    });
            }

            return newDataBoxes;
        });
    }, []);

    const handleClickDelete = useCallback((id) => {
        deleteVideo(id)
            .then(() => {
                toast.success('Cliente eliminado');
                setAllDataBoxes(prevDataBoxes => prevDataBoxes.filter(data => data.id !== id));
            })
            .catch(error => {
                toast.error(`Error al eliminar el cliente: ${error}`);
            });
    }, []);

    return (
        <div className="main-dashboard">
            <div className="main-dashboard__header">
                <span className="main-dashboard__title"><button style={{
                    background: 'none',
                    border: 'none',
                    color: 'white',
                }}
                    onClick={
                        () => {
                            window.history.back();
                        }
                    }
                >Super Admin Home </button> / Galeria de videos</span>
            </div>
            <div className="main-dashboard__content pl-4 pr-4 pb-4" style={{ gap: '40px', alignItems: 'flex-start', justifyContent: 'flex-start', height: 'auto', overflowY: 'scroll' }}>
                <div className="d-flex w-100 justify-content-between pt-3">
                    <h2 className="pt-4">Agregar Video</h2>
                </div>
                <label htmlFor="file-upload" className="prp-upload-label">
                    {videoURL ? <video src={videoURL} alt="Upload video" className="prp-upload-video" controls /> : <img src={CameraIcon} alt="Upload" />}
                    <span>Cargar video</span>
                </label>
                <input id="file-upload" type="file" accept="video/*" onChange={handleFileChange} className="prp-file-input" />
                {Object.keys(labels).map((label) => (
                    <InputField key={label} label={labels[label]} value={copy[label]} onChange={handleInputChange(label)} id={label} />
                ))}
                <ButtonComponent text="Guardar" isHover onClick={handleSave} />
                <VideoBox dataBox={allDataBoxes} onClick={handleDataBoxClick} deleteCustomer={handleClickDelete} handleClickVisible={handleClickVisible} />
            </div>
        </div>
    );
}

export default VideoHomePage;
