import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Empresas from "../../Assets/Aliadas.svg";
import Ofertas1 from "../../Assets/Ofertas-1.svg";
import Ofertas from "../../Assets/Ofertas.svg";
import PuestosTrabajo from "../../Assets/Puestos-Trabajo.svg";
import { useDataBoxesApi } from "../admin/api/DataBoxesApi";

const styles = {
  boxIcon: {
    fontSize: '4.5em',
    margin: '15px',
    padding: '10px',
    borderRadius: '8px',
    background: '#fff',
    boxShadow: '0px 8px 24px -4px rgba(0, 62, 255, 0.2)',
    verticalAlign: 'middle',
    textAlign: 'center',
    overflow: 'hidden',
    transition: 'all 0.4s ease 0s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  paragraphStyle: {
    fontSize: '14px'
  }
};

export function BoxIcon({ image, title, description, padding, isCustomer, isAbout }) {
  return (
    <div style={{ ...styles.boxIcon }}>
      <img src={image} alt="icon" className="img-fluid" style={
        {
          padding: padding || '0',
          width: isAbout ? '50px' : isCustomer ? '150px' : '',
          height: isAbout ? '50px' : isCustomer ? '150px' : '',
        }
      } />
      {title && <h3>{title}</h3>}
      {description && <p style={styles.paragraphStyle}>{description}</p>}
    </div>
  );
}




function Home2() {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      }
    ]
  };

  const { getDataBoxes } = useDataBoxesApi();
  const [dataBoxes, setDataBoxes] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    getDataBoxes()
      .then((response) => {
        setDataBoxes(response);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <Container id="about" style={{ height: '6rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Container style={{
        marginBottom: '-6rem',
        zIndex: 1,
        position: 'absolute',

      }}>
        <Slider {...settings}>
          {dataBoxes.filter(dataBox => dataBox.visible).map((dataBox) => (
            <BoxIcon
              key={dataBox.id}
              image={dataBox.image}
              title={dataBox.amount}
              description={dataBox.title_es}
              padding='10px'
              isAbout
            />
          ))}
        </Slider>
      </Container>
    </Container>
  );
}

export default Home2
