import React, { useState } from 'react';
import './SearchContainer.css';

const SearchContainer = ({ options, onOptionSelect, onOptionSelectIcon }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (onOptionSelect) {
      onOptionSelect(option);
    }
    if (onOptionSelectIcon) {
      onOptionSelectIcon(option.icon);
    }
  };

  return (
    <div className="search-container">
      {options.map((option) => (
        <div
          key={option.title}
          className={`search-option ${
            selectedOption?.title === option.title ? 'selected' : ''
          }`}
          onClick={() => handleOptionClick(option)}
        >
          <img
            src={option.icon}
            alt={`${option.title} icon`}
            className="option-icon"
          />
          <div className="option-content">
            <div className="option-title">{option.title}</div>
            <div className="option-description">{option.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SearchContainer;
