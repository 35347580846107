import axios from 'axios';

const API_URL = 'https://api-stg.empleosmarketingdigital.com/v1';

export const login = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}/auth/login`, { email, password });
        return {
            success: true,
            data: response.data,
        };
    } catch (error) {

        let errMsg = 'Ocurrió un error desconocido.';
        if (error.response) {
            if (error.response.status === 400) {
                errMsg = 'Entrada inválida. Por favor verifica tus datos.';
            } else if (error.response.status === 500) {
                errMsg = 'Error interno del servidor.';
            }
        }
        return {
            success: false,
            message: errMsg,
        };
    }
};
