import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/tools';

export const useToolsApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;



    const createTool = async (toolData) => {
        try {
            const response = await axios.post(apiBaseUrl, toolData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Tool created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating tool:', error.response ? error.response.data : error);
        }
    };

    const fetchTools = async (queryParams = {}) => {
        try {
            const response = await axios.get(apiBaseUrl, {
                params: queryParams,
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching tools:', error);
            return [];
        }
    };

    const fetchTool = async (toolId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${toolId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching tool:', error);
            return null;
        }
    };

    const updateTool = async (toolId, toolData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${toolId}`, toolData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Tool updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating tool:', error.response ? error.response.data : error);
        }
    };

    const deleteTool = async (toolId) => {
        try {
            await axios.delete(`${apiBaseUrl}/${toolId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Tool deleted');
        } catch (error) {
            toast.error('Error deleting tool:', error.response ? error.response.data : error);
        }
    };

    return {
        createTool,
        fetchTools,
        fetchTool,
        updateTool,
        deleteTool,
    };
};
