import React, { useState, useCallback, useEffect } from "react";
import ButtonComponent from "../../../Shared/ButtonComponent";
import { useHomeApi } from "../../api/HomeDataApi";
import TrashIcon from '../../../../Assets/RedTrash.svg';
import { toast } from "react-toastify";
import VisibleIcon from '../../../../Assets/VisibleIcon.svg';
import eyeIcon from '../../../../Assets/EyeIcon.svg';

const InputField = React.memo(({ label, value, onChange, id, isTextArea }) => (
    <div className="d-flex w-100 align-items-center" style={{ gap: '40px' }}>
        <span className="prp-field-label" style={{ wordWrap: 'break-word', whiteSpace: 'nowrap' }}>{label}</span>
        {isTextArea ? (
            <textarea style={{
                height: '130px',
                width: '100%',
                border: '1px solid rgba(0, 62, 255, 0.25)',
                borderRadius: '10px',
            }} id={id} className="prp-textarea-input" value={value || ''} onChange={onChange} />
        ) : (
            <input type="text" id={id} className="prp-title-input" value={value || ''} onChange={onChange} />
        )}
    </div>
));

const ModUsHomePage = () => {
    const { setHomeData, getHomeData } = useHomeApi();
    const [copy, setCopy] = useState({
        id: null,
        about_us_title_es: "",
        about_us_desc_es: "",
        about_us_title_en: "",
        about_us_desc_en: "",
        about_us_title_pt: "",
        about_us_desc_pt: "",
    });

    const handleInputChange = useCallback((field) => (e) => {
        setCopy(prevCopy => ({ ...prevCopy, [field]: e.target.value }));
    }, []);

    const handleSave = useCallback(async () => {
        try {
            const response = await setHomeData(copy);
            if (response) {
                toast.success('Datos guardados correctamente');
            }
        } catch (error) {
            toast.error(`Error saving data: ${error}`);
        }
    }, [copy, setHomeData]);

    const handleDelete = useCallback(async () => {
        try {
            const response = await setHomeData({
                about_us_title_es: "",
                about_us_desc_es: "",
                about_us_title_en: "",
                about_us_desc_en: "",
                about_us_title_pt: "",
                about_us_desc_pt: "",
            });
            if (response) {
                setCopy({
                    id: null,
                    about_us_title_es: "",
                    about_us_desc_es: "",
                    about_us_title_en: "",
                    about_us_desc_en: "",
                    about_us_title_pt: "",
                    about_us_desc_pt: "",
                });
                toast.success('Datos eliminados correctamente');
            }
        } catch (error) {
            toast.error(`Error deleting data: ${error}`);
        }
    }, [copy, setHomeData]);

    const fields = [
        { lang: "es", title: "Título en español", desc: "Descripción en español" },
        { lang: "en", title: "Título en inglés", desc: "Descripción en inglés" },
        { lang: "pt", title: "Título en portugués", desc: "Descripción en portugués" }
    ];

    useEffect(() => {
        getHomeData().then((data) => {
            if (data) {
                setCopy({
                    id: data.id,
                    about_us_title_es: data.about_us_title_es || "",
                    about_us_desc_es: data.about_us_desc_es || "",
                    about_us_title_en: data.about_us_title_en || "",
                    about_us_desc_en: data.about_us_desc_en || "",
                    about_us_title_pt: data.about_us_title_pt || "",
                    about_us_desc_pt: data.about_us_desc_pt || "",
                });
            }
        });
    }, []);

    return (
        <div className="main-dashboard">
            <div className="main-dashboard__header">
                <span className="main-dashboard__title"><button style={{
                    background: 'none',
                    border: 'none',
                    color: 'white',
                }}
                    onClick={
                        () => {
                            window.history.back();
                        }
                    }
                >Super Admin Home </button> / Acerca de nosotros</span>
            </div>
            <div className="main-dashboard__content pl-4 pr-4 pb-4" style={{ gap: '40px', alignItems: 'flex-start', justifyContent: 'flex-start', height: 'auto', overflowY: 'scroll' }}>
                <div className="d-flex w-100 justify-content-between">
                    <h2 className="pt-4">Modificar</h2>
                    <div className="pt-3 prp-actions-container">
                        <button className="prp-action-visible" onClick={() => setCopy(prevCopy => ({ ...prevCopy, visible: !prevCopy.visible }))}>
                            <img src={
                                copy.visible ? VisibleIcon : eyeIcon
                            } alt="Visible" />
                        </button>
                        <button className="prp-action-delete" onClick={handleDelete}>
                            <img src={TrashIcon} alt="Delete" />
                        </button>
                    </div>
                </div>
                {fields.map(({ lang, title, desc }) => (
                    <React.Fragment key={lang}>
                        <InputField label={title} value={copy[`about_us_title_${lang}`]} onChange={handleInputChange(`about_us_title_${lang}`)} id={`about_us_title_${lang}`} />
                        <InputField label={desc} value={copy[`about_us_desc_${lang}`]} onChange={handleInputChange(`about_us_desc_${lang}`)} id={`about_us_desc_${lang}`} isTextArea={true} />
                    </React.Fragment>
                ))}
                <ButtonComponent text="Guardar" isHover onClick={handleSave} />
            </div>
        </div>
    );
}

export default ModUsHomePage;
