import React from "react";
import HomeList from "../../components/HomeList/HomeList";

const HomePage = () => {

    return (
        <div className="main-dashboard">
            <div className="main-dashboard__header">
                <span className="main-dashboard__title">Super Admin / Home</span>
            </div>
            <div className="main-dashboard__content pl-4 pr-4">
                <HomeList />
            </div>
        </div>
    );
}

export default HomePage;