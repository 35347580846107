import React from 'react';
import { useHistory } from 'react-router-dom';
import CompanyReusableComponent from '../../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import { Col, Row } from 'react-bootstrap';
import ReusableButton from '../../../components/ReusableButtonsComponent';
import ButtonComponent from '../../../../Shared/ButtonComponent';

const DefaultStep4CreateJob = ({
  isGoBack,
  responsabilities,
  salary,
  recommendations,
  horarios,
  softSkills,
  culture,
  handleSaveFormStep4,
  goToStep,
}) => {
  const history = useHistory();
  const handleGoBack = isGoBack || history.goBack;

  return (
    <CompanyReusableComponent
      goBack={handleGoBack}
      subtitle="Estás progresando de manera impecable."
      title="Magnífico"
      company="BlueScreen"
    >
      <Row className="w-100">
        <ReusableButton
          text={responsabilities || 'Responsabilidades'}
          version={responsabilities ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(1)}
          isColorBlack={
            !!responsabilities && responsabilities !== 'Responsabilidades'
          }
        />
        <ReusableButton
          text={salary || 'Salario'}
          version={salary ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(2)}
          isColorBlack={!!salary && salary !== 'Salario'}
        />
        <ReusableButton
          text={horarios || 'Horarios'}
          version={horarios ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(3)}
          isColorBlack={!!horarios && horarios !== 'Horarios'}
        />
        <ReusableButton
          text={recommendations || 'Recomendaciones'}
          version={recommendations ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(4)}
          isColorBlack={
            !!recommendations && recommendations !== 'Recomendaciones'
          }
        />
        <ReusableButton
          text={softSkills || 'Habilidades blandas'}
          version={softSkills ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(5)}
          isColorBlack={!!softSkills && softSkills !== 'Habilidades blandas'}
        />
        <ReusableButton
          text={culture || 'Cultura empresarial'}
          version={culture ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(6)}
          isColorBlack={!!culture && culture !== 'Cultura empresarial'}
        />
        <Col xs={12} className="gap-after">
          <ButtonComponent
            text="Confirmar"
            version={
              responsabilities &&
              salary &&
              horarios &&
              recommendations &&
              softSkills &&
              culture
                ? 'fill'
                : 'grey'
            }
            large
            onClick={handleSaveFormStep4}
            disabled={
              !responsabilities ||
              !salary ||
              !horarios ||
              !recommendations ||
              !softSkills ||
              !culture
            }
          />
        </Col>
      </Row>
    </CompanyReusableComponent>
  );
};

export default DefaultStep4CreateJob;
