import React, { useState } from 'react';
import CompanyReusableComponent from '../../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import { Form, Button } from 'react-bootstrap';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import NotificationComponent from '../../../../Shared/NotificationComponent/NotificationComponent';
import Clipboard from '../../../../../Assets/Clipboard.svg';

const ParameterButton = ({ onClick, children }) => (
  <Button
    variant="link"
    onClick={onClick}
    style={{ cursor: 'pointer', textDecoration: 'underline', color: 'black' }}
  >
    {children}
  </Button>
);

const Case1Step4CreateJob = ({
  goBack,
  responsabilities,
  setResponsabilities,
  save,
}) => {
  const [text, setText] = useState(responsabilities || '');

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value.length <= 50) {
      setText(value);
      setResponsabilities(value);
    }
  };

  const handleSave = () => {
    save(text);
    goBack();
  };

  const addParameterToText = (parameter) => {
    const newText = `${text} [${parameter}]`.trim();
    if (newText.length <= 50) {
      setText(newText);
      setResponsabilities(newText);
    }
  };

  return (
    <CompanyReusableComponent goBack={goBack} company={'WhiteScreen'}>
      <div className="p-4">
        <h6>
          Ahora necesitamos que describas las tareas y responsabilidades
          principales del puesto.
        </h6>
        <Form>
          <Form.Group controlId="responsabilitiesTextarea">
            <div className="text-right">
              <small>{text.length} / 50</small>
            </div>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Describe aquí..."
              value={text}
              onChange={handleInputChange}
              maxLength={50}
              style={{
                borderRadius: '10px',
              }}
            />
            <Form.Text className="text-muted text-left">
              Recuerda no superar las 50 palabras. *
            </Form.Text>
          </Form.Group>
        </Form>
        <div className="mt-4">
          <p>Clic para agregar un parámetro al texto.</p>
          <div className="text-muted">
            <ParameterButton onClick={() => addParameterToText('Cargo')}>
              [Cargo]
            </ParameterButton>
            <ParameterButton onClick={() => addParameterToText('Idioma')}>
              [Idioma]
            </ParameterButton>
            <ParameterButton onClick={() => addParameterToText('Formación')}>
              [Formación]
            </ParameterButton>
            <ParameterButton
              onClick={() => addParameterToText('Skills técnicos')}
            >
              [Skills técnicos]
            </ParameterButton>
            <ParameterButton onClick={() => addParameterToText('Estudios')}>
              [Estudios]
            </ParameterButton>
          </div>
        </div>

        <div className="mt-4 p-3">
          <NotificationComponent
            icon={Clipboard}
            text={{
              boldPart: 'Ej:',
              normalText:
                ' "Se busca [Cargo] para [Re ], asegurar la [Meta o resultado esperado]. Experiencia en [Área específica]. son esenciales."',
            }}
          />
          <small></small>
        </div>
        <ButtonComponent
          text={'Guardar'}
          large
          isHover
          onClick={handleSave}
          version={text.length > 50 || text.length === 0 ? 'grey' : 'fill'}
          disabled={text.length > 50 || text.length === 0}
        />
      </div>
    </CompanyReusableComponent>
  );
};

export default Case1Step4CreateJob;
