import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import White from '../../Assets/White.png';
import Back from '../../Assets/Back.svg';
import ButtonComponent from '../Shared/ButtonComponent';


const messages = ['Bienvenido', 'Welcome', 'Bienvenue', 'Willkommen'];


const loginButtonStyle = {
    display: 'inline-block',
    margin: '5px',
    padding: '10px 20px',
    backgroundColor: 'transparent',
    color: 'rgba(40, 89, 241, 0.50)',
    textDecoration: 'underline',
    fontWeight: 'bold',
    border: 'none',
};

const RegisterScreen = () => {
    const baseUrl = window.location.origin;
    const [messageIndex, setMessageIndex] = useState(0);
    const [fade, setFade] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setFade(false);
            setTimeout(() => {
                setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
                setFade(true);
            }, 500);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, [messageIndex]);


    const goBack = useCallback(() => window.history.back(), []);

    return (
        <Container fluid style={{ height: 'auto', paddingTop: '50px', position: 'relative', minHeight: '100vh' }}>
            <button style={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                position: 'absolute',
                top: '10px',
                left: '10px',
                zIndex: 1000,
                border: 'none',
                cursor: 'pointer',
            }}
                onClick={goBack}
            >
                <img src={Back} alt="back" style={{ width: '20px' }} />
            </button>
            <Row className="justify-content-center align-items-center">
                <Col className="text-center">
                    <img src={White} alt="Logo" style={{ width: '150px' }} />
                    <h1 style={{ marginTop: '60px', color: 'white', transition: 'opacity 0.5s', opacity: fade ? 1 : 0 }}>
                        {messages[messageIndex]}
                    </h1>
                </Col>
            </Row>
            <div style={{
                backgroundColor: 'white',
                position: 'fixed',
                bottom: '0',
                left: '0',
                right: '0',
                padding: '20px',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                gap: '10px',
            }}>
                <span className='mb-2' style={{ color: 'black', fontWeight: 'bold' }}>Registrarte como</span>
                <ButtonComponent text="Talento" version="grey" large href={`${baseUrl}/register/talento`} isHover isFontBold />
                <ButtonComponent text="Empresa" version="grey" large href={`${baseUrl}/register/empresa`} isHover isFontBold />
                <ButtonComponent text="Reclutador" version="grey" large href={`${baseUrl}/register/reclutador`} isHover isFontBold />
                <a style={{ ...loginButtonStyle, width: '100%' }} href={`${baseUrl}/login`}>Iniciar sesión</a>
            </div>
        </Container>
    );
};

export default RegisterScreen;
