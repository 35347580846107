import React, { useState, useRef, useEffect, useCallback, useMemo, useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import ButtonComponent from "../../../Shared/ButtonComponent";
import StepperComponent from "../../../Shared/StepperComponent/StepperComponent";
import { fullScreenStyles } from "./ProfilePersStyles";
import NotificationComponent from "../../../Shared/NotificationComponent/NotificationComponent";
import Clipboard from "../../../../Assets/Clipboard.svg";
import BlueLogo from "../../../../Assets/BlueLogo.svg";
import InputComponent from "../../../Shared/InputComponent";
import { BRicon, ARicon, USicon } from "../../../Shared/iconsComponent";
import './ProfilePers.css'
import { useTalentEducationsApi } from "../../../../api/TalentEducationsApi";
import { UserContext } from "../../../../contexts/UserContext";
import { formatearFechaArgentina } from "../../../../functions/calculateDate";
import { useTalentCertificationsApi } from "../../../../api/TalentCertificationsApi";
import { useTalentWorkExperiencesApi } from "../../../../api/TalentWorkExperiences";
import DatePicker from "react-datepicker";
import { useTalentsApi } from "../../../../api/TalentApi";
import { usePositionsApi } from "../../../../api/PositionsApi";
import AutocompleteSelect from "../../../Shared/AutocompleteSelect/AutocompleteSelect";

const ProfilePersFour = ({
    isAboutMe,
    isEducation,
    isCerts,
    isExps,
    isRefs,
    gotostep,
}) => {
    const [showScreen, setShowScreen] = useState("");
    const [isAboutMeState, setIsAboutMeState] = useState('');
    const [educationData, setEducationData] = useState({});
    const [certsData, setCertsData] = useState({});
    const [expsData, setExpsData] = useState({});
    const [refsData, setRefsData] = useState({});
    const [currentStep, setCurrentStep] = useState(0);
    const talentEducationsApi = useTalentEducationsApi();
    const { createTalentCertification } = useTalentCertificationsApi();
    const { createTalentWorkExperience } = useTalentWorkExperiencesApi();
    const { userData } = React.useContext(UserContext);

    useEffect(() => {
        let step = 0;
        if (isAboutMeState) step++;
        if (Object.keys(educationData).length > 0) step++;
        if (Object.keys(certsData).length > 0) step++;
        if (Object.keys(expsData).length > 0) step++;
        if (Object.keys(refsData).length > 0) step++;
        setCurrentStep(step);
    }, [isAboutMeState, educationData, certsData, expsData, refsData]);


    const handleShowScreen = (screenName) => {
        setShowScreen(screenName);
    };

    const handleClose = () => {
        setShowScreen("");
    };

    const handleConfirmation = async () => {
        if (typeof isAboutMe === 'function') {
            isAboutMe(isAboutMeState);
        }
        if (typeof isEducation === 'function') {
            isEducation(educationData);
        }
        if (typeof isCerts === 'function') {
            isCerts(certsData);
        }
        if (typeof isExps === 'function') {
            isExps(expsData);
        }
        if (typeof isRefs === 'function') {
            isRefs(refsData);
        }
        if (userData.talent) {
            for (const education of educationData) {
                 await talentEducationsApi.createTalentEducation(
                    {
                        talent_id: userData.talent.id,
                        institution: education.institucion,
                        degree: education.titulo,
                        discipline: education.disciplina,
                        country: education.pais,
                        start_date: education.fechaInicio,
                        end_date: education.fechaFin,
                        progress: education.progress,

                    }
                );
            }
            for (const certification of certsData) {
                await createTalentCertification(
                    {
                        talent_id: userData.talent.id,
                        emitter: certification.institucion,
                        tittle: certification.titulo,
                        discipline: certification.disciplina,
                        country: certification.pais,
                        start_date: certification.fechaInicio,
                        end_date: certification.fechaFin,
                        progress: certification.progress,
                    }
                );
            }
            for (const experience of expsData) {
                 await createTalentWorkExperience(
                    {
                        talent_id: userData.talent.id,
                        challenge: experience.institucion,
                        company: experience.titulo,
                        description: experience.disciplina,
                        location: experience.pais,
                        start_date: experience.fechaInicio,
                        end_date: experience.fechaFin,
                        in_progress: experience.progress,
                        position_id: experience.position_id,
                    }
                );
            }
        }
        gotostep(12);
    };

    const renderScreen = () => {
        switch (showScreen) {
            case "aboutMe":
                return <AboutMeScreen onClose={handleClose} isAboutMe={setIsAboutMeState} />;
            case "education":
                return <EducationScreen onClose={handleClose} isEducation={setEducationData} />;
            case "certs":
                return <CertsScreen onClose={handleClose} isCerts={setCertsData} />;
            case "exps":
                return <ExpsScreen onClose={handleClose} isExps={setExpsData} />;
            case "refs":
                return <RefsScreen onClose={handleClose} isRefs={setRefsData} />;
            default:
                return null;
        }
    };

    function formatEducationData(educationData) {
        if (!Array.isArray(educationData)) {
            console.error("Expected an array, but got:", typeof dataArray, educationData);
            return "";
        }
        return educationData.map(data => {
          const fechaInicio = new Date(data.fechaInicio).toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
          });
          
          let fechaFin = '';
          if (data.progress) {
            fechaFin = 'En progreso';
          } else {
            fechaFin = new Date(data.fechaFin).toLocaleDateString('es-ES', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit'
            });
          }
      
          return [
            data.institucion,
            data.titulo,
            data.disciplina,
            data.pais,
            `${fechaInicio} a ${fechaFin}`
          ]
          .filter(item => item)
          .join(', ');
        }).join('; ');
      }

      function formatPositionData(positionData) {

        if (!Array.isArray(positionData)) {
          console.error("Expected an array, but got:", typeof positionData, positionData);
          return "";
        }

        return positionData.map(data => {
          const fechaInicio = data.fechaInicioString 
            ? data.fechaInicioString 
            : new Date(data.fechaInicio).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
              });
          
          let fechaFin = '';
          if (data.progress) {
            fechaFin = 'En progreso';
          } else {
            fechaFin = data.fechaFinString 
              ? data.fechaFinString 
              : new Date(data.fechaFin).toLocaleDateString('es-ES', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit'
                });
          }
      
          return [
            data.institucion,
            data.titulo,
            data.disciplina,
            data.pais,
            `${fechaInicio} a ${fechaFin}`
          ]
          .filter(item => item) 
          .join(', ');
        }).join('; '); 
      }

      function formatContactData(contactData) {
        // Si contactData es un array, formatear cada objeto en el array
        if (Array.isArray(contactData)) {
          return contactData.map(data => formatSingleContact(data)).join('; ');
        } 
        
        // Si contactData es un objeto, formatear directamente
        if (typeof contactData === 'object') {
          return formatSingleContact(contactData);
        }
      
        // Si no es ni un objeto ni un array, mostrar un error y devolver una cadena vacía
        console.error("Expected an object or an array, but got:", typeof contactData, contactData);
        return "";
      }
      
      function formatSingleContact(data) {
        let result = [
          data.cargo,
          data.empresa,
          data.ubicacion,
          data.telefono,
          data.email
        ].filter(item => item) 
         .join(', ');
      
        if (data.titulosAdicionales && data.titulosAdicionales.length > 0) {
          result += ', ' + data.titulosAdicionales.join(', ');
        }
      
        return result;
      }

    return (
        <div>
            {showScreen ? (
                renderScreen()
            ) : (
                <Row>
                    <Col xs={12} className="gap-after">
                        <StepperComponent
                            steps={5}
                            currentStep={currentStep}
                            title="Aspectos esenciales"
                            subtitle="Estado de tu perfil"
                        />
                    </Col>
                    <Col xs={12} className="gap-after">
                        <ButtonComponent
                            text={isAboutMeState || 'Sobre mí'}
                            onClick={() => handleShowScreen("aboutMe")}
                            large
                            isLeft
                            version={isAboutMeState ? "outline" : "grey"}
                            disabled={false}
                            isColorBlack={!!isAboutMeState}
                            isEditIcon={!!isAboutMeState}
                        />
                    </Col>
                    <Col xs={12} className="gap-after">
                        <ButtonComponent
                            text={formatEducationData(educationData) || 'Educación'}
                            large
                            isLeft
                            onClick={() => handleShowScreen("education")}
                            version={Object.keys(educationData).length > 0 ? "outline" : "grey"}
                            disabled={!isAboutMeState}
                            isColorBlack={Object.keys(educationData).length > 0}
                            isEditIcon={Object.keys(educationData).length !== 0}
                        />
                    </Col>
                    <Col xs={12} className="gap-after">
                        <ButtonComponent
                            text={ formatEducationData(certsData) ||"Certificaciones"}
                            large
                            isLeft
                            onClick={() => handleShowScreen("certs")}
                            version={Object.keys(certsData).length > 0 ? "outline" : "grey"}
                            disabled={Object.keys(educationData).length === 0}
                            isColorBlack={Object.keys(certsData).length > 0}
                            isEditIcon={Object.keys(certsData).length !== 0}
                        />
                    </Col>
                    <Col xs={12} className="gap-after">
                        <ButtonComponent
                            text={formatPositionData(expsData) ||"Experiencia"}
                            large
                            isLeft
                            onClick={() => handleShowScreen("exps")}
                            version={Object.keys(expsData).length > 0 ? "outline" : "grey"}
                            disabled={Object.keys(certsData).length === 0}
                            isColorBlack={Object.keys(expsData).length > 0}
                            isEditIcon={Object.keys(expsData).length !== 0}
                        />
                    </Col>
                    <Col xs={12} className="gap-after">
                        <ButtonComponent
                            text={ formatContactData(refsData) || "Referencias"}
                            large
                            isLeft
                            onClick={() => handleShowScreen("refs")}
                            version={Object.keys(refsData).length > 0 ? "outline" : "grey"}
                            disabled={Object.keys(expsData).length === 0}
                            isColorBlack={Object.keys(refsData).length > 0}
                            isEditIcon={Object.keys(refsData).length !== 0}
                        />
                    </Col>
                    <Col xs={12} className="gap-after">
                        <ButtonComponent
                            text="Confirmar"
                            onClick={handleConfirmation}
                            large
                            disabled={
                                !isAboutMeState ||
                                Object.keys(educationData).length === 0 ||
                                Object.keys(certsData).length === 0 ||
                                Object.keys(expsData).length === 0 ||
                                Object.keys(refsData).length === 0
                            }
                            version={!isAboutMeState ||
                                Object.keys(educationData).length === 0 ||
                                Object.keys(certsData).length === 0 ||
                                Object.keys(expsData).length === 0 ||
                                Object.keys(refsData).length === 0 ? "grey" : "fill"}
                            isHover
                        />
                    </Col>
                </Row>
            )}
        </div>
    );
};

const AboutMeScreen = ({ onClose, isAboutMe }) => {
    const [text, setText] = useState('');
    const updateTalent = useTalentsApi();
    const { userData } = useContext(UserContext);

    const handleTextChange = (event) => {
        const words = event.target.value.split(' ');
        if (words.length <= 50) {
            setText(event.target.value);
        }
    };

    const handleSave = () => {
        if (typeof isAboutMe === 'function') {
            isAboutMe(text);
        }
        const talentId = userData.talent.id;

        updateTalent.updateTalent(talentId, { about_me: text });

        onClose();
    };

    return (
        <div style={fullScreenStyles}>
            <img
                src={BlueLogo}
                alt="BlueLogo"
                style={{ cursor: "pointer", marginBottom: "2rem" }}
            />
            <Container
                fluid
                className="h-100 d-flex align-items-center justify-content-between flex-column"
            >
                <Row>
                    <Col xs={12} className="gap-after">
                        <span>Por favor, proporciona una descripción
                            de tus intereses profesionales.</span>
                    </Col>
                    <Col xs={12} className="gap-after d-flex justify-content-between align-items-end flex-column">
                        <span className="bold">{`${text.split(' ').length}/50`}</span>
                        <textarea
                            style={{ width: "100%", height: "200px", borderRadius: "10px", border: "1px solid #E2E2E2", padding: "10px" }}
                            placeholder="Paragraph"
                            value={text}
                            onChange={handleTextChange}
                            className="text-area"
                        />
                    </Col>
                    <Col xs={12} className="gap-after d-flex  align-items-start">
                        <span style={{ color: 'rgba(0, 0, 0, 0.50)', fontSize: '0.8rem' }}>Recuerda no superar lase 50 palabras. * </span>
                    </Col>
                    <Col xs={12} className="gap-after d-flex justify-content-between align-items-start flex-column mb-5">
                        <span>Sugerencias:</span>
                        <NotificationComponent icon={Clipboard} text={{ boldPart: "Ej:", normalText: "“Me apasiona el diseño gráfico y la ilustración, siempre buscando innovar y crear conceptos visuales impactantes.”" }} />
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col xs={12} className="gap-after mb-4">
                        <ButtonComponent text="Guardar" onClick={handleSave} large disabled={text.trim() === ''} isHover version={text.trim() === '' ? "grey" : "fill"} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const GenericScreen = ({ onClose, onSave, headerText, addButtonText, isProgressCheckable, isExpsScreen, isSkipButton }) => {
    const [items, setItems] = useState([]);
    const institucionRef = useRef();
    const tituloRef = useRef();
    const disciplinaRef = useRef();
    const paisRef = useRef();
    const [isChecked, setIsChecked] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [fields, setFields] = useState(isExpsScreen ? {
        position_id: '',
        titulo: '',
        disciplina: '',
        pais: '',
        fechaInicio: '',
        fechaFin: '',
        progress: isChecked,
    } : {
        institucion: '',
        titulo: '',
        disciplina: '',
        pais: '',
        fechaInicio: '',
        fechaFin: '',
        progress: isChecked,
    });
    const { fetchPositions } = usePositionsApi();
    const [positions, setPositions] = useState([]);
    const [resetA, setResetA] = useState(false);

    const allFieldsFilled = useMemo(() => {
        const fieldsFilled = Object.entries(fields).every(([key, value]) => {
            if (isProgressCheckable && isChecked && key === 'fechaFin') {
                return true;
            }
            return value !== '' && value !== null;
        });
        const datesFilled = startDate !== null && (!isProgressCheckable || endDate !== null || isChecked);
        return fieldsFilled && datesFilled;
    }, [fields, startDate, endDate, isChecked, isProgressCheckable]);

    const addItem = useCallback(() => {
        const disciplinaValue = isExpsScreen ? disciplinaRef.current.value : disciplinaRef.current.getValue();
        const newItem = isExpsScreen ? {
            position_id: fields.position_id,
            institucion: fields.titulo,
            titulo: fields.disciplina,
            disciplina: disciplinaValue,
            pais: fields.pais,
            fechaInicio: startDate,
            fechaFin: isChecked ? null : endDate,
            progress: isChecked,
            fechaInicioString: formatearFechaArgentina(startDate),
            fechaFinString: isChecked ? null : formatearFechaArgentina(endDate),
        } : {
            institucion: institucionRef.current.getValue(),
            titulo: tituloRef.current.getValue(),
            disciplina: disciplinaValue,
            pais: paisRef.current.getValue(),
            fechaInicio: startDate,
            fechaFin: isChecked ? null : endDate,
            progress: isChecked,
            fechaInicioString: formatearFechaArgentina(startDate),
            fechaFinString: isChecked ? null : formatearFechaArgentina(endDate),
        };

        setItems([...items, newItem]);

        if (isExpsScreen) {
            setResetA(true);
        } else {
            institucionRef.current.reset();
        }
        tituloRef.current.reset();
        if (isExpsScreen) {
            if (disciplinaRef.current) disciplinaRef.current.value = '';
        } else {
            disciplinaRef.current?.reset();
        }
        paisRef.current.reset();
        setStartDate(null);
        setEndDate(null);
        setIsChecked(false);
        setFields(isExpsScreen ? {
            position_id: '',
            titulo: '',
            disciplina: '',
            pais: '',
            fechaInicio: '',
            fechaFin: '',
            progress: false,
        } : {
            institucion: '',
            titulo: '',
            disciplina: '',
            pais: '',
            fechaInicio: '',
            fechaFin: '',
            progress: false,
        });

    }, [isChecked, startDate, endDate, items, isExpsScreen]);

    const handleSave = async () => {
        if (typeof onSave === 'function') {
            onSave([...items, fields]);
            onClose();
        }
    };

    useEffect(() => {
        fetchPositions().then((response) => {
            setPositions(response);
        });
    }, []);

    const manejarSeleccionado = (item) => {
        setFields({ ...fields, position_id: item.id });
    };


    const arrayMonths = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];



    return (
        <div style={fullScreenStyles}>
            <img src={BlueLogo} alt="BlueLogo" style={{ cursor: "pointer", marginBottom: "2rem" }} />
            <Container fluid className="h-100 d-flex align-items-center justify-content-between flex-column">
                <Row>
                    <Col xs={12} className="gap-after d-flex justify-content-between align-items-start flex-column">
                        <span>{headerText}</span>
                        <span style={{ color: 'rgba(0, 0, 0, 0.50)', fontSize: '0.8rem' }}>* El asterisco indica que es obligatorio</span>
                    </Col>
                    {items.map((item, index) => (
                        <Col key={index} xs={12} className="gap-after">
                            <ButtonComponent
                                text={`${item.institucion}, ${item.titulo}, ${item.disciplina}, ${item.pais}, ${item.fechaInicioString}, ${item.fechaFinString === null ? 'En progreso' : item.fechaFinString
                                    }`}
                                onClick={() => { }}
                                large
                                isLeft
                                version="outline"
                            />
                        </Col>
                    ))}
                    <Col xs={12} className="gap-after">
                        {
                            isExpsScreen ? (
                                <AutocompleteSelect datos={positions} onSeleccionar={manejarSeleccionado} placeholder='Cargo (Area de especialización)' reset={resetA} />
                            ) : (
                                <InputComponent ref={institucionRef} label="Institución Educativa" type="text" placeholder={"Institución Educativa"} onChange={e => setFields({ ...fields, institucion: e.target.value })} />
                            )
                        }
                    </Col>
                    <Col xs={12} className="gap-after">
                        <InputComponent ref={tituloRef} label="Título" type="text" placeholder={
                            isExpsScreen ? "Nombre de la empresa" : "Título"
                        } onChange={e => setFields({ ...fields, titulo: e.target.value })} />
                    </Col>
                    <Col xs={12} className="gap-after">
                        {
                            isExpsScreen ? (
                                <textarea
                                    style={{ width: "100%", height: "200px", borderRadius: "10px", border: "1px solid #E2E2E2", padding: "10px" }}
                                    placeholder="Descripción del cargo (máximo 20 palabras)"
                                    className="text-area"
                                    onChange={e => setFields({ ...fields, disciplina: e.target.value })}
                                    ref={disciplinaRef}

                                />
                            ) : (
                                <InputComponent ref={disciplinaRef} label="Disciplina" type="text" placeholder="Disciplina" onChange={e => setFields({ ...fields, disciplina: e.target.value })} />
                            )
                        }
                    </Col>
                    {
                        isExpsScreen && (
                            <Col xs={12} className="gap-after">
                                <textarea style={{ width: "100%", height: "100px", borderRadius: "10px", border: "1px solid #E2E2E2", padding: "10px" }}
                                    placeholder="Cuenta un desafío que merece ser compartido (Maximo 50 palabras)"
                                    className="text-area"
                                />
                            </Col>
                        )
                    }
                    <Col xs={12} className="gap-after">
                        <InputComponent ref={paisRef} label="País de Emisión" type="text" placeholder={
                            isExpsScreen ? "Ubicación" : "País de Emisión"
                        } onChange={e => setFields({ ...fields, pais: e.target.value })} />
                    </Col>
                    <Col xs={6} className="gap-after">
                        <DatePicker
                            selected={startDate}
                            onChange={date => {
                                setStartDate(date);
                                setFields({ ...fields, fechaInicio: date });
                            }}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="Fecha de inicio"
                            className='DatePicker-date'
                            renderCustomHeader={
                                ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                                    <div style={{ margin: 10, display: 'flex', justifyContent: 'center', gap: '5px' }}>
                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} style={{
                                            border: 'none',
                                            background: 'none',
                                            color: 'black',
                                            fontWeight: 'bold',
                                        }}>{'<'}</button>
                                        <select
                                            value={date.getFullYear()}
                                            onChange={({ target: { value } }) => changeYear(value)}
                                        >
                                            {Array.from({ length: new Date().getFullYear() - 1970 + 1 }, (_, i) => i + 1970).map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            value={arrayMonths[date.getMonth()]}
                                            onChange={({ target: { value } }) => changeMonth(arrayMonths.indexOf(value))}
                                        >
                                            {arrayMonths.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <button style={{
                                            border: 'none',
                                            background: 'none',
                                            color: 'black',
                                            fontWeight: 'bold',
                                        }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{'>'}</button>
                                    </div>
                                )
                            }
                        />
                    </Col>
                    <Col xs={6} className="gap-after">
                        <DatePicker
                            selected={endDate}
                            onChange={date => {
                                setEndDate(date);
                                setFields({ ...fields, fechaFin: date });
                            }}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="Fecha de finalización"
                            className='DatePicker-date'
                            disabled={isChecked}
                            renderCustomHeader={
                                ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                                    <div style={{ margin: 10, display: 'flex', justifyContent: 'center', gap: '5px' }}>
                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} style={{
                                            border: 'none',
                                            background: 'none',
                                            color: 'black',
                                            fontWeight: 'bold',
                                        }}>{'<'}</button>
                                        <select
                                            value={date.getFullYear()}
                                            onChange={({ target: { value } }) => changeYear(value)}
                                        >
                                            {Array.from({ length: new Date().getFullYear() - 1970 + 1 }, (_, i) => i + 1970).map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            value={arrayMonths[date.getMonth()]}
                                            onChange={({ target: { value } }) => changeMonth(arrayMonths.indexOf(value))}
                                        >
                                            {arrayMonths.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <button style={{
                                            border: 'none',
                                            background: 'none',
                                            color: 'black',
                                            fontWeight: 'bold',
                                        }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{'>'}</button>
                                    </div>
                                )
                            }
                        />
                    </Col>
                    <Col xs={12} className="gap-after d-flex mt-5 justify-content-center">
                        <button
                            onClick={addItem}
                            disabled={!allFieldsFilled}
                            style={{ color: 'rgba(0, 62, 255, 0.25)', border: 'none', background: 'none' }}
                        >
                            + {addButtonText}
                        </button>
                    </Col>
                    {isProgressCheckable && (
                        <Col xs={12} className="gap-after col-11 d-flex justify-content-end" style={{ color: 'rgb(226, 226, 226)' }}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={() => {
                                        setIsChecked(!isChecked);
                                        if (!isChecked) {
                                            setEndDate(null);
                                        }
                                        setFields({ ...fields, progress: !isChecked });
                                    }}
                                    style={{
                                        marginRight: '0.5rem',
                                        appearance: 'none',
                                        width: '16px',
                                        height: '16px',
                                        borderRadius: '50%',
                                        border: '2px solid rgb(226, 226, 226)',
                                        backgroundColor: isChecked ? '#003EFF' : 'transparent',
                                        cursor: 'pointer'
                                    }}
                                />
                                {isExpsScreen ? "Actualmente trabajo aquí" : "Estudios en progreso"}
                            </label>
                        </Col>
                    )}
                </Row>
                <Row className="w-100">
                    {
                        isSkipButton && (
                            <Col xs={12} className="gap-after mb-4">
                                <button
                                    onClick={onClose}
                                    style={{ color: 'rgba(0, 62, 255, 0.25)', border: 'none', background: 'none' }}
                                >
                                    Omitir sección
                                </button>
                            </Col>
                        )
                    }

                    <Col xs={12} className="gap-after mb-4">
                        <ButtonComponent
                            text="Guardar"
                            onClick={handleSave}
                            large
                            isHover
                            version={!allFieldsFilled ? "grey" : "fill"}
                            disabled={!allFieldsFilled}
                        />
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

const EducationScreen = ({ onClose, isEducation }) => {
    const handleSaveEducation = (items) => {
        if (typeof isEducation === 'function') {
            isEducation(items);
        }
    };

    return (
        <GenericScreen
            onClose={onClose}
            onSave={handleSaveEducation}
            headerText="Destaca hasta tres de tus títulos educativos."
            addButtonText="Agregar otro título educativo"
            isProgressCheckable={true}
            isSkipButton={true}
        />
    );
};

const CertsScreen = ({ onClose, isCerts }) => {
    const handleSaveCerts = (items) => {
        if (typeof isCerts === 'function') {
            isCerts(items);
        }
    };

    return (
        <GenericScreen
            onClose={onClose}
            onSave={handleSaveCerts}
            headerText="Destaca hasta tres certificaciones."
            addButtonText="Agregar otra certificación"
            isProgressCheckable={true}
            isSkipButton={true}
        />
    );
};

const ExpsScreen = ({ onClose, isExps }) => {
    const handleSaveExps = (items) => {
        if (typeof isExps === 'function') {
            isExps(items);
        }
    };
    return (
        <GenericScreen
            onClose={onClose}
            onSave={handleSaveExps}
            headerText="Destaca hasta tres experiencias laborales."
            addButtonText="Agregar otra experiencia laboral"
            isProgressCheckable={true}
            isExpsScreen
        />
    );
};

const RefsScreen = ({ onClose, isRefs }) => {
    const [titulos, setTitulos] = useState([]);
    const [tituloAdicionalRefs, setTituloAdicionalRefs] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const cargoRef = useRef();
    const empresaRef = useRef();
    const nombreRef = useRef();
    const emailRef = useRef();
    const telRef = useRef();

    const handleSave = async () => {
        const refs = [cargoRef, empresaRef, emailRef, telRef, ...tituloAdicionalRefs];

        const validations = await Promise.all(refs.map(ref => ref && ref.current && typeof ref.current.validateExternal === 'function' ? ref.current.validateExternal() : { isValid: true }));

        const hasErrors = validations.some(validation => !validation.isValid);

        if (hasErrors) {
            return;
        }

        const formData = {
            cargo: cargoRef.current && typeof cargoRef.current.getValue === 'function' ? cargoRef.current.getValue() : undefined,
            empresa: empresaRef.current && typeof empresaRef.current.getValue === 'function' ? empresaRef.current.getValue() : undefined,
            email: emailRef.current && typeof emailRef.current.getValue === 'function' ? emailRef.current.getValue() : undefined,
            ubicacion: nombreRef.current && typeof nombreRef.current.getValue === 'function' ? nombreRef.current.getValue() : undefined,
            telefono: telRef.current && typeof telRef.current.getValue === 'function' ? telRef.current.getValue() : undefined,
            titulosAdicionales: tituloAdicionalRefs.map(ref => ref.current && typeof ref.current.getValue === 'function' ? ref.current.getValue() : undefined),
        };

        if (typeof isRefs === 'function') {
            isRefs(formData);
        }
        onClose();
    };

    const handleInputChange = (e, index) => {
        const newTitulos = [...titulos];
        if (index >= newTitulos.length) {
            newTitulos.push(e.target.value);
        } else {
            newTitulos[index] = e.target.value;
        }
        setTitulos(newTitulos);
    };

    const handleRemoveClick = index => {
        const newTitulos = [...titulos];
        newTitulos.splice(index, 1);
        setTitulos(newTitulos);
    };

    const handleAddClick = (event) => {
        if (titulos.length < 3) {
            setTitulos([...titulos, '']);
            setTituloAdicionalRefs(refs => [...refs, React.createRef()]);
        } else {
            event.preventDefault();
        }
    };

    const countryCodes = {
        AR: "+54",
        US: "+1",
        BR: "+55",
    };

    const optionToCountryCode = {
        ARS: "AR",
        US: "US",
        BR: "BR",
    };

    const handlePhoneChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleOptionChange = (option) => {
        const countryCode = optionToCountryCode[option];
        const newPhonePrefix = countryCodes[countryCode] || "";
        setPhoneNumber(newPhonePrefix);
    }

    return (
        <div style={fullScreenStyles}>
            <img
                src={BlueLogo}
                alt="BlueLogo"
                style={{ cursor: "pointer", marginBottom: "2rem" }}

            />
            <Container fluid className="h-100 d-flex align-items-center justify-content-between flex-column" >
                <Row>
                    <Col xs={12} className="gap-after">
                        <InputComponent ref={nombreRef} type="text" placeholder="Nombre" />
                    </Col>
                    <Col xs={12} className="gap-after">
                        <InputComponent ref={empresaRef} type="text" placeholder="Selecciona la empresa" />
                    </Col>
                    {titulos.map((titulo, index) => (
                        <Col xs={12} className="gap-after d-flex align-items-center" key={index} style={{ gap: '1rem' }}>
                            <InputComponent value={titulo} onChange={e => handleInputChange(e, index)} type="text" placeholder="Referencia Adicional" ref={tituloAdicionalRefs[index]} />
                            <button onClick={() => handleRemoveClick(index)} style={{ background: 'none', border: 'none' }}>-</button>
                        </Col>
                    ))}
                    <Col xs={12} className="gap-after">
                        <InputComponent ref={cargoRef} placeholder="Cargo" type={'text'} />
                    </Col>
                    <Col xs={12} className="gap-after">
                        <InputComponent ref={emailRef} placeholder="Email de contacto" type={'email'} />
                    </Col>
                    <Col xs={3} className="gap-after">
                        <InputComponent
                            type="text"
                            placeholder="ARS"
                            isDropdown
                            options={["ARS", "US", "BR"]}
                            iconOptions={{ icons: [ARicon, USicon, BRicon] }}
                            onOptionChange={handleOptionChange}
                            ref={telRef}
                        />
                    </Col>
                    <Col xs={9} className="gap-after">
                        <InputComponent
                            type="text"
                            externalValue={phoneNumber}
                            onChange={handlePhoneChange}
                            placeholder={`Teléfono (Obligatorio)`}
                            ref={telRef}
                        />
                    </Col>
                    <Col xs={12} className="gap-after d-flex mt-5 justify-content-center">
                        <button
                            onClick={handleAddClick}
                            style={{ color: titulos.length < 3 ? 'rgba(0, 62, 255, 0.25)' : 'rgba(0, 62, 255, 0.10)', pointerEvents: titulos.length < 3 ? 'auto' : 'none', border: 'none', background: 'none' }}
                        >
                            + Agregar referencia
                        </button>
                    </Col>

                </Row>
                <Row className="w-100">
                    <Col xs={12} className="gap-after mb-4">
                        <ButtonComponent text="Guardar" onClick={handleSave} large isHover />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ProfilePersFour;
