import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  fullScreenStyles,
  logoStyle,
  buttonStyle,
  linkStyle,
} from './ProfilePersStyles';
import StepperComponent from '../../../Shared/StepperComponent/StepperComponent';
import BlueLogo from '../../../../Assets/BlueLogo.svg';
import { Container, Row, Col } from 'react-bootstrap';
import SliderComponent from '../../../Shared/SliderComponent/SliderComponent';
import { useCulturalQuizApi } from '../../../../api/QuizApi';
import ProfilePersSixRenderer from './ProfileSixRenderer';
import BackBlue from '../../../../Assets/BackBlue.svg';
import { useTalentQuizApi } from '../../../../api/TalentQuizApi';
import { UserContext } from '../../../../contexts/UserContext';

const ProfilePersSix = ({ gotostep, corporateCulture, back }) => {
  const [currentStage, setCurrentStage] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [quizData, setQuizData] = useState(null);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const { userData } = useContext(UserContext);

  const { setTalentCulturalQuizAnswers } = useTalentQuizApi();
  const { fetchFullQuiz } = useCulturalQuizApi();

  useEffect(() => {
    const loadData = async () => {
      const data = await fetchFullQuiz();
      if (data) {
        setQuizData(data);
      }
    };
    loadData();
  }, []);

  const handleGoToStep = () => {
    gotostep(16);
    corporateCulture(selectedAnswers);
    console.log(selectedAnswers);
  };

  const handleNextQuestion = useCallback(async () => {
    const stage = quizData?.stages[currentStage];
    if (currentQuestion < stage?.questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else if (currentStage < quizData?.stages.length - 1) {
      setCurrentStage(currentStage + 1);
      setCurrentQuestion(0);
    } else {
      await setTalentCulturalQuizAnswers(
        userData.talent.id,
        Object.entries(selectedAnswers).map(([questionId, answerIds]) => ({
          question_id: parseInt(questionId),
          answer: answerIds,
        }))
      );
      handleGoToStep();
    }
  }, [
    currentQuestion,
    currentStage,
    quizData,
    selectedAnswers,
    setTalentCulturalQuizAnswers,
    userData.talent.id,
    handleGoToStep,
  ]);

  const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    } else if (currentStage > 0) {
      const previousStage = currentStage - 1;
      setCurrentStage(previousStage);
      const previousStageQuestionCount =
        quizData?.stages[previousStage]?.questions.length || 0;
      setCurrentQuestion(previousStageQuestionCount - 1);
    }
  };

  const handleSelectAnswer = (questionId, answerId, type) => {
    setSelectedAnswers((prevSelected) => {
      const prevSelection = prevSelected[questionId] || [];
      if (type === 'MULTIPLE_CHOICE') {
        const newSelection = prevSelection.includes(answerId)
          ? prevSelection.filter((id) => id !== answerId)
          : [...prevSelection, answerId];
        return { ...prevSelected, [questionId]: newSelection };
      } else {
        return { ...prevSelected, [questionId]: [answerId] };
      }
    });
  };

  const handleConfirm = () => {
    setHasConfirmed(true);
  };

  const renderQuestion = (question) => {
    return (
      <div className="selection-container">
        <p>{question.desc_es}</p>
        {question.options.map((option) => (
          <button
            key={option.id}
            className="cardMapped"
            onClick={() =>
              handleSelectAnswer(question.id, option.id, question.type)
            }
          >
            <label className="input_card_mapped">
              <input
                type={
                  question.type === 'MULTIPLE_CHOICE' ? 'checkbox' : 'radio'
                }
                name={`question-${question.id}`}
                value={option.id}
                checked={
                  selectedAnswers[question.id]?.includes(option.id) ?? false
                }
                onChange={() =>
                  handleSelectAnswer(question.id, option.id, question.type)
                }
                className="input_card_mapped"
              />
              <div
                className="card_mapped_content"
                style={{ cursor: 'pointer' }}
              >
                <span className="title_card_mapped">{option.name_es}</span>
                {option.desc_es && (
                  <span className="subtitle_card_mapped">{option.desc_es}</span>
                )}
              </div>
            </label>
          </button>
        ))}
      </div>
    );
  };

  return (
    <div style={fullScreenStyles}>
      <img src={BlueLogo} alt="BlueLogo" style={logoStyle} />
      <Container
        fluid
        className="h-100 d-flex align-items-center justify-content-between flex-column"
      >
        <button style={buttonStyle} onClick={back}>
          <img src={BackBlue} alt="back" style={{ width: '20px' }} />
        </button>
        {hasConfirmed ? (
          <Row className="d-fleX justify-content-center">
            <Col xs={10}>
              <StepperComponent
                currentStep={currentStage + 1}
                borderless
                isSixthStep
                steps={quizData?.stages[currentStage]?.questions.length || 0}
                title={quizData?.stages[currentStage]?.name_es || ''}
              />
            </Col>
            <Col xs={12} className="gap-after">
              <h2>{quizData?.stages[currentStage]?.name_es}</h2>
            </Col>
            {quizData &&
              quizData?.stages[currentStage]?.questions.length > 0 &&
              renderQuestion(
                quizData?.stages[currentStage]?.questions[currentQuestion]
              )}
            <Col xs={12} className="gap-after">
              <SliderComponent
                currentNumber={currentQuestion + 1}
                setNumber={handleNextQuestion}
                maxValue={quizData?.stages[currentStage]?.questions.length || 0}
                disabled={
                  !selectedAnswers[
                    quizData?.stages[currentStage]?.questions[currentQuestion]
                      ?.id
                  ]?.length
                }
              />
            </Col>
            <button onClick={handlePreviousQuestion} style={linkStyle}>
              Volver
            </button>
          </Row>
        ) : (
          <ProfilePersSixRenderer handleConfirm={handleConfirm} />
        )}
      </Container>
    </div>
  );
};

export default ProfilePersSix;
