import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/quiz';

export const useCulturalQuizApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);


    // Obtener el cuestionario completo
    const fetchFullQuiz = async (language = 'es') => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${language}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching full quiz:', error);
            return null;
        }
    };

    // Crear una etapa del cuestionario (Admins)
    const createQuizStage = async (stageData) => {
        try {
            const response = await axios.post(`${apiBaseUrl}/stages`, stageData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz stage created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating quiz stage:', error);
            return null;
        }
    };

    // Actualizar una etapa del cuestionario (Admins)
    const updateQuizStage = async (quizStageId, stageData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/stages/${quizStageId}`, stageData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz stage updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating quiz stage:', error);
            return null;
        }
    };

    // Eliminar una etapa del cuestionario (Admins)
    const deleteQuizStage = async (quizStageId) => {
        try {
            await axios.delete(`${apiBaseUrl}/stages/${quizStageId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz stage deleted');
        } catch (error) {
            toast.error('Error deleting quiz stage:', error);
        }
    };

    // Crear una pregunta del cuestionario (Admins)
    const createQuizQuestion = async (questionData) => {
        try {
            const response = await axios.post(`${apiBaseUrl}/questions`, questionData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz question created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating quiz question:', error);
            return null;
        }
    };

    // Actualizar una pregunta del cuestionario (Admins)
    const updateQuizQuestion = async (quizQuestionId, questionData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/questions/${quizQuestionId}`, questionData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz question updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating quiz question:', error);
            return null;
        }
    };

    // Eliminar una pregunta del cuestionario (Admins)
    const deleteQuizQuestion = async (quizQuestionId) => {
        try {
            await axios.delete(`${apiBaseUrl}/questions/${quizQuestionId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz question deleted');
        } catch (error) {
            toast.error('Error deleting quiz question:', error);
        }
    };

    // Crear una opción de pregunta del cuestionario (Admins)
    const createQuizOption = async (optionData) => {
        try {
            const response = await axios.post(`${apiBaseUrl}/options`, optionData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz option created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating quiz option:', error);
            return null;
        }
    };

    // Actualizar una opción de pregunta del cuestionario (Admins)
    const updateQuizOption = async (quizOptionId, optionData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/options/${quizOptionId}`, optionData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz option updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating quiz option:', error);
            return null;
        }
    };

    // Eliminar una opción de pregunta del cuestionario (Admins)
    const deleteQuizOption = async (quizOptionId) => {
        try {
            await axios.delete(`${apiBaseUrl}/options/${quizOptionId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz option deleted');
        } catch (error) {
            toast.error('Error deleting quiz option:', error);
        }
    };


    return {
        fetchFullQuiz,
        createQuizStage,
        updateQuizStage,
        deleteQuizStage,
        createQuizQuestion,
        updateQuizQuestion,
        deleteQuizQuestion,
        createQuizOption,
        updateQuizOption,
        deleteQuizOption

    };
};