import React, { useState, useRef, useEffect } from "react";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import "./Sidenav.css";
import BlueLogo from "../../../../Assets/BlueLogo.svg";
import DashboardIcon from "../../../../Assets/DashboardIcon.svg";
import ABMUsuarios from "../../../../Assets/ABMUsuarios.svg";
import ABMSuscripciones from "../../../../Assets/ABMSuscripciones.svg";
import ABMDatos from "../../../../Assets/ABMDatos.svg";
import Notificaciones from "../../../../Assets/Notificaciones.svg";
import ABMAvisos from "../../../../Assets/ABMAvisos.svg";
import Ajustes from "../../../../Assets/Ajustes.svg";
import HomeAdmin from "../../../../Assets/HomeAdmin.svg";

const Sidenav = () => {
    let { url } = useRouteMatch();
    let location = useLocation();
    const [indicatorStyle, setIndicatorStyle] = useState({});
    const linksRef = useRef([]);

    const paths = ["", "/Usuarios", "/Suscripciones", "/Datos", "/Notificaciones", "/Avisos", "/Ajustes", "/Home"];
    const icons = [DashboardIcon, ABMUsuarios, ABMSuscripciones, ABMDatos, Notificaciones, ABMAvisos, Ajustes, HomeAdmin];
    const labels = ['Dashboard', 'ABM Usuarios', 'ABM suscripciones', 'ABM de datos', 'Notificaciones', 'ABM avisos', 'Ajustes', 'Home'];

    const isActive = (path) => location.pathname === path;

    const updateIndicator = (index) => {
        const linkElement = linksRef.current[index];
        if (linkElement) {
            const { offsetTop, clientHeight } = linkElement;
            setIndicatorStyle({
                top: offsetTop,
                height: clientHeight,
                opacity: 1,
                transition: 'top 0.3s ease, height 0.3s ease'
            });
        }
    };

    useEffect(() => {
        const activeIndex = paths.findIndex(path => location.pathname === `${url}${path}`);
        updateIndicator(activeIndex);
    }, [location.pathname]);

    const renderNavLink = (path, index) => {
        const isDisabled = index === 1 || index === 2 || index === 3 || index === 4 || index === 5 || index === 6;
        return (
            <NavLink
                to={`${url}${path}`}
                onClick={(e) => {
                    if (isDisabled) {
                        e.preventDefault();
                    } else {
                        updateIndicator(index);
                    }
                }}
                style={{
                    opacity: isDisabled ? 0.5 : 1,
                    pointerEvents: isDisabled ? 'none' : 'auto',
                }}
            >
                <img src={icons[index]} alt="" className="sidenav__icon" />
                {labels[index]}
            </NavLink>
        );
    };

    return (
        <div className="sidenav__container">
            <ul className="sidenav">
                <div className="sidenav__logo">
                    <img src={BlueLogo} alt="Logo" />
                </div>
                <div className="active-indicator" style={indicatorStyle}></div>
                {paths.map((path, index) => (
                    <li key={path} ref={(el) => linksRef.current[index] = el} className={`sidenav__link ${isActive(url + path) ? "active" : ""}`}>
                        {renderNavLink(path, index)}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidenav;
