import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CompanyReusableComponent from '../../Components/CompanyReusable/CompanyReusableComponent';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import SearchComponent from '../../../../Shared/SearchComponent/SearchComponent';
import MapComponent from '../../../../Shared/MapsComponent';
import locateIcon from '../../../../../Assets/locateIcon.svg';

const LocationSection5 = ({ handleSetStep, setCurrentSection, formDataStep5, currentPosition, fetchCurrentLocation, isLoaded, isRemoteChange, handleOptionSelectedLocation, handleSaveLocation }) => {

    return (
        <CompanyReusableComponent
            company="WhiteScreen"
            step={handleSetStep}
            title="Ubicación de la empresa"
            goBack={() => setCurrentSection("main")}
        >
            <Row className="m-0">
                <Col xs={12} className="gap-after py-2">
                    <SearchComponent
                        placeholder={'Idioma preferencial'}
                        options={['Español', 'Inglés', 'Portugués']}
                        onOptionSelected={handleOptionSelectedLocation}
                    />
                </Col>

                <Col xs={12} className="gap-after d-flex justify-content-center">
                    <MapComponent isLoaded={isLoaded} currentPosition={currentPosition} />
                </Col>

                <Col xs={12} className="gap-after d-flex">
                    <input type="checkbox" id="isRemote" name="isRemote" value="isRemote" style={{ marginRight: "10px" }} onChange={isRemoteChange} />
                    <label htmlFor="isRemote" className="form-label mb-0">Todo nuestro equipo opera de forma remota.</label>
                </Col>

                <Col xs={12} className="gap-after py-2">
                    <SearchComponent
                        placeholder={'Ingresa dirección o punto de referencia'}
                        options={['Calle 1 # 1-1', 'Calle 2 # 2-2', 'Calle 3 # 3-3']}
                        onOptionSelected={handleOptionSelectedLocation}
                    />
                </Col>

                <Col xs={12} className="gap-after py-4">
                    <ButtonComponent
                        text="Mi Ubicación actual"
                        version="outline"
                        icon={locateIcon}
                        large
                        onClick={() => fetchCurrentLocation(true, true)}
                        style={{
                            border: 'none',
                            color: 'rgba(0, 62, 255, 0.25)'
                        }}
                    />
                </Col>

                <Col xs={12} className="gap-after pb-5">
                    <ButtonComponent text="Guardar" version={
                        formDataStep5.city && formDataStep5.country ? "fill" : "grey"
                    } large onClick={handleSaveLocation} />
                </Col>
            </Row>
        </CompanyReusableComponent>
    );
}

export default LocationSection5;
