import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { usePressReleasesApi } from '../admin/api/PressReleaseApi';
import './MainScreen.css';

const Heading = ({ children }) => (
  <h1 style={{ fontSize: "1.2rem", paddingBottom: "20px", paddingTop: '20px', fontWeight: 600 }} dangerouslySetInnerHTML={{ __html: children }}></h1>
);

const Box = ({ title, text, img }) => (
  <div style={{
    border: "1px solid rgba(0, 62, 255, 0.25)",
    borderRadius: "10px",
    padding: "20px",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "10px",
    height: "100px",
    overflow: "hidden"
  }}>
    <img src={img} alt="Forbes" style={{ width: "60px" }} />
    <div style={{ width: "1px", height: "16px", backgroundColor: "rgba(0, 62, 255, 0.25)", marginLeft: "10px", marginRight: "10px" }}></div>
    <div style={{ display: "flex", flexDirection: "column", width: '200px' }}>
      <span style={{ fontSize: "0.7rem", fontWeight: '600', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
        {title}
      </span>
      <span title={
        text
      } className="spanNosotros" style={{ cursor: 'pointer', fontSize: "0.7rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: '100%' }} dangerouslySetInnerHTML={{ __html: text }}></span>
    </div>
  </div>
);

function Nosotros({
  data = {
    about_us_title_es: "",
    about_us_desc_es: "",
  }
}) {
  const { getAllPressReleases } = usePressReleasesApi();
  const [pressReleases, setPressReleases] = useState([]);

  useEffect(() => {
    getAllPressReleases().then((data) => {
      console.log('Press releases:', data);
      setPressReleases(data);
    });
  }, []);

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 7000,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      }
    ]
  };

  return (
    <Container fluid className="about-section">
      <Container>
        <Row>
          <Col xs={12}>
            <Heading>
              {data.about_us_title_es || ""}
            </Heading>
            <p className="nosotros-p" dangerouslySetInnerHTML={{ __html: data.about_us_desc_es }}>
            </p>
          </Col>
          <Col xs={12} style={{ position: "relative" }}>
            <Heading>Notas de prensa</Heading>
            <Slider {...settings}>
              {pressReleases.map((pressRelease) => (
                <Box key={pressRelease.id} title={pressRelease.title} text={pressRelease.text} img={pressRelease.image} />
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Nosotros;
