import React, { useState, useEffect } from "react";
import NavBarComponent from "./components/Navbar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Register from "./components/Register/Register";
import ScrollToTop from "./components/ScrollToTop";
import PreHome from './components/Home/PreHome';
import Login from "./components/Login/LoginScreen";
import { AuthProvider } from "./contexts/authContext";
import TalentoRegister from "./components/Register/Talento/TalentoRegister";
import { ProfileDataProvider } from "./contexts/profileContext";
import MainScreen from "./components/Home/MainScreen";
import AdmProjects from "./components/Register/Talento/projects/AdmProjects";
import { RegisterDataProvider } from "./contexts/RegisterDataContext";
import { UserProvider } from "./contexts/UserContext";
import PrivateRoute from "./validations/PrivateRoute";
import { TalentLangProvider } from "./contexts/talentLangContext";
import { WorkPreferencesProvider } from "./contexts/workPreferencesContext";
import PublicRoute from "./validations/PublicRoute";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from "./ErrorBoundary";
import ProjectDetails from "./components/Register/Talento/projects/ProjectDetails";
import DashboardAdmin from "./components/admin/DashboardAdmin";
import ForgotPass from "./components/Login/ForgotPassword";
import { MediaProvider } from "./contexts/mediaContext";
import Empresa from "./components/Register/Empresa/Empresa";
import ProfileCompany from "./components/Register/Empresa/Steps/ProfileCompany";
import PropTypes from "prop-types";
import CreateJob from "./components/Empresa/CreateJob/CreateJob";




function App() {
  const [load, setLoad] = useState(true);


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoad(false);
    }, 1200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <ErrorBoundary>
      <Providers>
        <Router>
          <div className={`App ${load ? 'no-scroll' : 'scroll'}`}>
            <NavBarAndFooterRoutes />

            <ScrollToTop />

            <Switch>
              <Route path="/" exact component={PreHome} />
              <Route path="/home" exact component={Home} />
              <Route path="/forgot-password" exact component={ForgotPass} />
              <Route path="/register/reclutador/" component={TalentoRegister} />
              <PublicRoute path="/register/empresa" restricted={true} component={Empresa} />
              <PublicRoute path="/register/talento" restricted={true} component={TalentoRegister} />
              <PublicRoute path="/register" restricted={true} component={Register} />
              <PublicRoute path="/login" restricted={true} component={Login} />
              <PrivateRoute path="/admin" component={DashboardAdmin} />
              <PrivateRoute path="/profile/adm-projects" component={AdmProjects} />
              <PrivateRoute path="/profile/project/:projectId/:talentId" component={ProjectDetails} />
              <PrivateRoute path="/profile" component={MainScreen} />
              <PrivateRoute path="/company-profile/empleos" component={CreateJob} />
              <PrivateRoute path="/company-profile" component={ProfileCompany} />
            </Switch>

            <FooterRoutes />
            <ToastContainer position="bottom-left" />
          </div>
        </Router>
      </Providers>
    </ErrorBoundary>
  );
}

function Providers({ children }) {
  return (
    <MediaProvider>
      <RegisterDataProvider>
        <UserProvider>
          <WorkPreferencesProvider>
            <TalentLangProvider>
              <AuthProvider>
                <ProfileDataProvider>
                  {children}
                </ProfileDataProvider>
              </AuthProvider>
            </TalentLangProvider>
          </WorkPreferencesProvider>
        </UserProvider>
      </RegisterDataProvider>
    </MediaProvider>
  );
}

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

function NavBarAndFooterRoutes() {
  return (

    <Route path="/home" exact>
      <NavBarComponent />
    </Route>

  );
}

function FooterRoutes() {
  return (
    <Route path="/home" exact>
      <Footer />
    </Route>
  );
}

export default App;