import React, { useState } from 'react';
import CompanyReusableComponent from '../../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import Case3Step0 from './Case3Folder/Case3Step0';
import {
  daysMap,
  reverseDaysMap,
} from '../../../../../functions/SharedFunctions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Case3CreateJob = ({
  modalityData = {},
  setModalityData,
  goBack,
  save,
}) => {
  const initialModality = modalityData?.modality || '';
  const initialTimezone = modalityData?.timezone || null;
  const initialWorkDays = {
    onsite: modalityData?.work_days?.onsite || [],
    remote: modalityData?.work_days?.remote || [],
    from_hour: modalityData?.work_days?.from_hour || '',
    to_hour: modalityData?.work_days?.to_hour || '',
  };

  const [internalSteps, setInternalSteps] = useState(0);
  const [selectedModality, setSelectedModality] = useState(initialModality);
  const [timezone, setTimezone] = useState(initialTimezone);
  const [workDays, setWorkDays] = useState(initialWorkDays);

  const handleModalityChange = (e) => {
    const value = e.target.value;
    setSelectedModality(value);
    setModalityData((prev) => ({
      ...prev,
      modality: value,
      timezone: value === 'TIMEZONE_REMOTE' ? null : prev?.timezone || null,
    }));
  };

  const handleTimezoneChange = (e) => {
    setTimezone(e.target.value);
    setModalityData((prev) => ({ ...prev, timezone: e.target.value }));
  };

  const handleWorkDaysChange = (e) => {
    const { name, checked } = e.target;
    const [type, ...dayParts] = name.split('_');
    const day = dayParts.join('_');
    const dayValue = reverseDaysMap[day];

    if (dayValue) {
      setWorkDays((prev) => {
        const updatedType = prev[type] || [];
        const updatedWorkDays = checked
          ? [...updatedType, dayValue]
          : updatedType.filter((d) => d !== dayValue);

        return { ...prev, [type]: updatedWorkDays };
      });

      setModalityData((prev) => {
        const updatedType = prev?.work_days?.[type] || [];
        const updatedWorkDays = checked
          ? [...updatedType, dayValue]
          : updatedType.filter((d) => d !== dayValue);

        return {
          ...prev,
          work_days: {
            ...prev?.work_days,
            [type]: updatedWorkDays,
          },
        };
      });
    } else {
      console.error(`Day value for ${day} not found in reverseDaysMap`);
    }
  };

  const handleNextStep = () => {
    setInternalSteps(internalSteps + 1);
  };

  switch (internalSteps) {
    case 0:
      return (
        <Case3Step0
          goBack={goBack}
          selectedModality={selectedModality}
          handleModalityChange={handleModalityChange}
          handleTimezoneChange={handleTimezoneChange}
          handleNextStep={handleNextStep}
          timezone={timezone}
        />
      );

    case 1:
      return (
        <CompanyReusableComponent
          goBack={() => setInternalSteps(0)}
          company={'WhiteScreen'}
        >
          <Col xs={12} className="gap-after my-5">
            <span>Por favor, proporciona información sobre los horarios.</span>
          </Col>
          <Col xs={12} className="gap-after" style={{ height: '100%' }}>
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                width: '100%',
                gap: '2rem',
                fontWeight: 'bold',
              }}
              className="gap-after"
            >
              <Form.Label>Días a trabajar presencial</Form.Label>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  width: '100%',
                }}
              >
                {Object.entries(daysMap).map(([value, label]) => (
                  <Form.Check
                    key={`onsite_${label}`}
                    type="radio"
                    id={`onsite_${label}`}
                    name={`onsite_${label}`}
                    label={label}
                    checked={workDays.onsite.includes(value)}
                    onChange={handleWorkDaysChange}
                  />
                ))}
              </div>
              <Form.Label>Días a trabajar remoto</Form.Label>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  width: '100%',
                }}
              >
                {Object.entries(daysMap).map(([value, label]) => (
                  <Form.Check
                    key={`remote_${label}`}
                    type="radio"
                    id={`remote_${label}`}
                    name={`remote_${label}`}
                    label={label}
                    checked={workDays.remote.includes(value)}
                    onChange={handleWorkDaysChange}
                  />
                ))}
              </div>
              <Form.Group className="w-100">
                <Form.Label>Rango de horario</Form.Label>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    width: '100%',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  <DatePicker
                    selected={workDays.from_hour}
                    onChange={(date) => {
                      setWorkDays((prev) => ({ ...prev, from_hour: date }));
                      setModalityData((prev) => ({
                        ...prev,
                        work_days: { ...prev?.work_days, from_hour: date },
                      }));
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Desde"
                    className="DatePicker-date"
                  />
                  <span>a</span>
                  <DatePicker
                    selected={workDays.to_hour}
                    onChange={(date) => {
                      setWorkDays((prev) => ({ ...prev, to_hour: date }));
                      setModalityData((prev) => ({
                        ...prev,
                        work_days: { ...prev?.work_days, to_hour: date },
                      }));
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Hasta"
                    className="DatePicker-date"
                  />
                </div>
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} className="gap-after">
            <ButtonComponent
              text="Guardar"
              version="fill"
              large
              onClick={() => {
                save({
                  ...modalityData,
                  modality: selectedModality,
                  timezone,
                  work_days: workDays,
                });
                goBack();
              }}
              isHover
            />
          </Col>
        </CompanyReusableComponent>
      );

    default:
      return null;
  }
};

export default Case3CreateJob;
