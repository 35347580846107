import React, { useState, useEffect } from 'react';
import './BurgerMenuComponent.css';
import InstagramIcon from '../../../Assets/InstagramIcon.svg';
import TikTokIcon from '../../../Assets/TikTokIcon.svg';
import LinkedInIcon from '../../../Assets/LinkedInIcon.svg';
import YouTubeIcon from '../../../Assets/YouTubeIcon.svg';
import DropdownComponent from '../DropdownComponent';
import WhiteStar from '../../../Assets/WhiteStar.svg';
import GreenPoint from '../../../Assets/GreenPoint.svg';
import YellowPoint from '../../../Assets/YellowPoint.svg';
import BluePoint from '../../../Assets/BluePoint.svg';
import RedPoint from '../../../Assets/RedPoint.svg';
import { useProfileData } from '../../../contexts/profileContext';
import Home from '../../../Assets/Home.svg';
import Search from '../../../Assets/Search.svg';
import Postulaciones from '../../../Assets/Postulaciones.svg';
import NotificationIcon from "../../../Assets/Notification.svg";
import Like from '../../../Assets/Like.svg';
import Config from '../../../Assets/Config.svg';
import { useHistory } from 'react-router-dom';


const options = [
    { title: 'Búsqueda activa', description: 'Aparecerás en las búsquedas relacionadas a tu perfil sin importar el % de match.', icon: GreenPoint },
    { title: 'Escuchando ofertas', description: 'Aparecerás en las búsquedas con mayor de 50% de match.', icon: YellowPoint },
    { title: 'Gran Match', description: 'Solo aparecerás en ofertas de trabajo con más de 85% de match.', icon: BluePoint },
    { title: 'Sin interés', description: 'No aparecerás en las búsquedas', icon: RedPoint }
];

const BurgerMenuComponent = ({ isOpen, height, admin }) => {

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (admin?.user) {
            console.log("Admin:", admin);
            setIsAdmin(admin.user.email === "x345cd@gmail.com");
        } else {
            setIsAdmin(false);
        }
    }, [admin]);



    const history = useHistory();

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('talentLangs');
        localStorage.removeItem('workPreferences');
        localStorage.removeItem('tokenRegister');
        window.location.reload();
        history.push('/login');
    };

    const { updateProfileData } = useProfileData();

    if (!isOpen) return null;

    const handleOptionSelect = (selectedOption) => {
        console.log("Opción seleccionada:", selectedOption);

    };

    const handleOptionSelectIcon = (selectedIcon) => {
        console.log("Icono seleccionado:", selectedIcon);
        if (selectedIcon) {
            console.log("Icono seleccionado:", selectedIcon);
            updateProfileData({ visibility: selectedIcon });
        } else {
            console.log("No hay icono seleccionado");
        }
    }


    return (
        <div className="burgerMenu" style={{ height: height }}>

            <span className="navigationTitle py-4">Navegación</span>

            <a href="/home"><img src={Home} alt="" className="icon" />Inicio</a>
            <a href="/empleos"><img src={Search} alt="" className="icon" />Buscar Empleo</a>
            <a href="/publicaciones"><img src={Postulaciones} alt="" className="icon" />Postulaciones</a>
            <a href="/notificaciones"><img src={NotificationIcon} alt="" className="icon" />Notificaciones</a>
            <a href="/recomendaciones"><img src={Like} alt="" className="icon" />Recomendaciones</a>
            {
                isAdmin && <a href="admin"><img src={Config} alt="" className="icon" />Admin</a>
            }

            <span className='py-5'>Opciones de cuenta</span>
            <a href="configuracion"><img src={Config} alt="" className="icon" />Configurar cuenta</a>
            <DropdownComponent
                isBordered={false}
                starSVG={WhiteStar}
                placeholder="Cambiar Estado"
                options={options}
                onOptionSelect={handleOptionSelect}
                onOptionSelectIcon={handleOptionSelectIcon}
                transparent
                paddingY={'20px'}
                style={{ borderBottom: '1px solid white' }}
            />

            <button className='logoutButton py-5' onClick={handleLogout} >Cerrar sesión</button>

            <div className="footerBurger pt-5">
                <span style={{ fontSize: '.8rem' }}> Siguienos en: </span>
                <div className="socialMediaIcons">
                    <a href="https://instagram.com"><img src={InstagramIcon} alt="Instagram" /></a>
                    <a href="https://tiktok.com"><img src={TikTokIcon} alt="TikTok" /></a>
                    <a href="https://linkedin.com"><img src={LinkedInIcon} alt="LinkedIn" /></a>
                    <a href="https://youtube.com"><img src={YouTubeIcon} alt="YouTube" /></a>
                </div>
            </div>
        </div>
    );
};

export default BurgerMenuComponent;
