import React, { useEffect, useContext, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Sidenav from './components/Sidenav/Sidenav';
import MainDashboard from './pages/MainDashboard/MainDashboard';
import { UserContext } from '../../contexts/UserContext';
import { RegisterDataContext } from '../../contexts/RegisterDataContext';
import { useDashboardApi } from './api/DashboardApi';
import { usePressReleasesApi } from './api/PressReleaseApi';
import PressReleasePage from './pages/PressReleasePage/PressReleasePage';
import HomePage from './pages/HomePage/HomePage';
import HeadlineHomePage from './pages/HeadlineHomePage/HeadlineHomePage';
import CopyHomePage from './pages/CopyHomePage/CopyHomePage';
import DataBoxHomePage from './pages/DataBoxHomePage/DataBoxHomePage';
import LogoModHomePage from './pages/LogoModHomePage/LogoModHomePage';
import VideoHomePage from './pages/VideoHomePage/VideoHomePage';
import ModUsHomePage from './pages/ModUsHomePage/ModUsHomePage';
import ModFaq from './pages/ModFaq/ModFaq';
import ABMUsers from './pages/ABMUsers/ABMUsers';


const getActualDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
};

const DashboardAdmin = () => {
    let { path } = useRouteMatch();
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const { getDashboardStats } = useDashboardApi();
    const [stats, setStats] = useState({});
    const { getAllPressReleases } = usePressReleasesApi();
    const [pressReleases, setPressReleases] = useState([]);

    const firstDate = '2021-01-01';


    useEffect(() => {
        const token = localStorage.getItem('tokenRegister') || registerData.token;
        if (userData.token || registerData.token || token) {
            getDashboardStats(firstDate, getActualDate()).then((data) => {
                console.log('Dashboard stats:', data);
                setStats(data);
            });
            getAllPressReleases().then((data) => {
                console.log('Press releases:', data);
                setPressReleases(data);
            });
        }
    }, []);

    return (
        <div style={{ display: 'flex' }}>
            <Sidenav />
            <div style={{ flex: 1 }}>
                <Switch>
                    <Route exact path={`${path}/`} render={(props) => <MainDashboard {...props} stats={stats} dates={{ firstDate, lastDate: getActualDate() }} pressReleases={pressReleases} />} />
                    <Route path={`${path}/press-release/:releaseId`} render={(props) => <PressReleasePage pressReleaseId={props.match.params.releaseId} />} />
                    <Route path={`${path}/Usuarios`} component={ABMUsers} />
                    <Route path={`${path}/Suscripciones`} component={Seccion2} />
                    <Route path={`${path}/Datos`} component={Seccion3} />
                    <Route path={`${path}/Notificaciones`} component={Seccion4} />
                    <Route path={`${path}/Avisos`} component={Seccion5} />
                    <Route path={`${path}/Ajustes`} component={Seccion5} />
                    <Route path={`${path}/Home/modificar-faq`} component={ModFaq} />
                    <Route path={`${path}/Home/modificar-nosotros`} component={ModUsHomePage} />
                    <Route path={`${path}/Home/modificar-videos`} component={VideoHomePage} />
                    <Route path={`${path}/Home/modificar-logos`} component={LogoModHomePage} />
                    <Route path={`${path}/Home/modificar-cuadros`} component={DataBoxHomePage} />
                    <Route path={`${path}/Home/modificar-copy`} component={CopyHomePage} />
                    <Route path={`${path}/Home/modificar-titular`} component={HeadlineHomePage} />
                    <Route path={`${path}/Home`} component={HomePage} />
                </Switch>
            </div>
        </div>
    );
};


const Seccion1 = () => <div><h2>Sección 1</h2></div>;
const Seccion2 = () => <div><h2>Sección 2</h2></div>;
const Seccion3 = () => <div><h2>Sección 3</h2></div>;
const Seccion4 = () => <div><h2>Sección 4</h2></div>;
const Seccion5 = () => <div><h2>Sección 5</h2></div>;

export default DashboardAdmin;