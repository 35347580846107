import React from "react";

export const TalentLangContext = React.createContext();

export const TalentLangProvider = ({ children }) => {
    const [talentLangs, setTalentLangs] = React.useState(() => {
        const storedTalentLangs = localStorage.getItem('talentLangs');
        return storedTalentLangs ? JSON.parse(storedTalentLangs) : [];
    });


    const updateTalentLangs = React.useCallback((newTalentLangs) => {
        setTalentLangs(newTalentLangs);
        localStorage.setItem('talentLangs', JSON.stringify(newTalentLangs));
    }, []);

    React.useEffect(() => {
        const handleStorageChange = () => {
            const storedTalentLangs = localStorage.getItem('talentLangs');
            if (storedTalentLangs) {
                const newTalentLangs = JSON.parse(storedTalentLangs);
                if (JSON.stringify(newTalentLangs) !== JSON.stringify(talentLangs)) {
                    setTalentLangs(newTalentLangs);
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        }


    }, [talentLangs]);

    const value = React.useMemo(() => ({ talentLangs, updateTalentLangs }), [talentLangs, updateTalentLangs]);

    return (
        <TalentLangContext.Provider value={value}>
            {children}
        </TalentLangContext.Provider>
    );
}

export const useTalentLang = () => {
    const context = React.useContext(TalentLangContext);
    if (!context) {
        throw new Error("useTalentLang must be used within a TalentLangProvider");
    }
    return context;
};
