import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/talent-certifications';

export const useTalentCertificationsApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;

    const createTalentCertification = async (certificationData) => {
        try {
            const response = await axios.post(apiBaseUrl, certificationData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Talent-Certification created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating Talent-Certification:', error.response ? error.response.data : error);
        }
    };

    const fetchTalentCertifications = async () => {
        try {
            const response = await axios.get(apiBaseUrl, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching Talent-Certifications:', error);
            return [];
        }
    };

    const updateTalentCertification = async (certificationId, certificationData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${certificationId}`, certificationData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Talent-Certification updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating Talent-Certification:', error.response ? error.response.data : error);
        }
    };

    const deleteTalentCertification = async (certificationId) => {
        try {
            await axios.delete(`${apiBaseUrl}/${certificationId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Talent-Certification deleted');
        } catch (error) {
            toast.error('Error deleting Talent-Certification:', error.response ? error.response.data : error);
        }
    };

    return {
        createTalentCertification,
        fetchTalentCertifications,
        updateTalentCertification,
        deleteTalentCertification,
    };
};
