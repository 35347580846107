import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../MainDashboard/MainDashboard.css';
import { usePressReleasesApi } from '../../api/PressReleaseApi';
import { toast } from 'react-toastify';
import { useUploadsApi } from '../../../../api/UploadsApi';
import { useHistory } from 'react-router-dom';
import TrashIcon from '../../../../Assets/RedTrash.svg';
import CameraIcon from '../../../../Assets/CameraIcon.svg';
import './PressReleasePage.css';

const PressReleasePage = ({ pressReleaseId }) => {
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [file, setFile] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null);
  const { deletePressRelease, updatePressRelease, getPressRelease } =
    usePressReleasesApi();
  const { uploadPressReleaseImage } = useUploadsApi();
  const history = useHistory();

  const fetchPressRelease = async () => {
    try {
      const pressRelease = await getPressRelease(pressReleaseId);
      setTitle(pressRelease.title);
      setDescription(pressRelease.text);
      setImageUrl(pressRelease.image);
    } catch (error) {
      toast.error('Error fetching press release:', error);
    }
  };

  const handleUpdate = async () => {
    let updatedImageUrl = imageUrl;
    if (file) {
      try {
        const uploadResult = await uploadPressReleaseImage(file);
        updatedImageUrl = uploadResult.url;
      } catch (error) {
        toast.error('Error uploading image:', error);
        return;
      }
    }

    const pressReleaseData = {
      title: title,
      text: description,
      image: updatedImageUrl,
    };

    try {
      await updatePressRelease(pressReleaseId, pressReleaseData);
      toast.success('Update successful');
      history.push('/admin');
      window.location.reload();
    } catch (error) {
      toast.error('Error updating press release:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await deletePressRelease(pressReleaseId);
      toast.success('Delete successful');
      history.push('/admin');
      window.location.reload();
    } catch (error) {
      toast.error('Error deleting press release:', error);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setImageUrl(URL.createObjectURL(event.target.files[0]));
  };

  useEffect(() => {
    fetchPressRelease();
  }, []);

  return (
    <div className="main-dashboard">
      <div className="main-dashboard__header">
        <span className="main-dashboard__title">
          <button
            style={{
              background: 'none',
              border: 'none',
              color: 'white',
            }}
            onClick={() => {
              window.history.back();
            }}
          >
            Super Admin Home{' '}
          </button>{' '}
          / Modificar nota
        </span>
      </div>
      <div className="main-dashboard__content pt-5 pl-4 pr-4">
        <div className="main-dashboard__content__stats w-100">
          <div className="prp-container">
            <div className="prp-actions">
              <h2 className="prp-title">Modificar</h2>
              <div className="prp-actions-container">
                <span>Acciones</span>
                <button className="prp-action-delete" onClick={handleDelete}>
                  <img src={TrashIcon} alt="Delete" />
                </button>
              </div>
            </div>
            <label htmlFor="file-upload" className="prp-upload-label">
              {imageUrl ? (
                <img src={imageUrl} alt="Upload" className="prp-upload-image" />
              ) : (
                <img src={CameraIcon} alt="Upload" />
              )}
              <span>Cargar foto</span>
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              className="prp-file-input"
            />
            <div className="prp-field">
              <span className="prp-field-label">Titulo</span>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="prp-title-input"
              />
            </div>
            <span className="prp-field-label prp-description-label">
              Descripcion
            </span>
            <ReactQuill
              theme="snow"
              value={description}
              onChange={setDescription}
              className="prp-quill-container"
              modules={PressReleasePage.modules}
              formats={PressReleasePage.formats}
            />
            <button onClick={handleUpdate} className="prp-save-button">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

PressReleasePage.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
    ['code-block'],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ direction: 'rtl' }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

PressReleasePage.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'code-block',
  'color',
  'background',
  'align',
  'script',
  'direction',
];

export default PressReleasePage;
