import React, { useEffect, useRef, useState, useCallback } from 'react';
import CompanyReusableComponent from '../../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import { Col } from 'react-bootstrap';
import { useFetchLanguages } from '../../../../../api/LangApi';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import { linkStyle } from '../../../../Register/Talento/talentoStyles';

export const levels = [
  { id: 1, name: 'Básico' },
  { id: 2, name: 'Lectura' },
  { id: 3, name: 'Conversacional' },
  { id: 4, name: 'Nativo' },
];

export const ReusableButtonComponent = React.memo(
  ({ mappedData, onClick, selected, selectedLevels }) => {
    if (!mappedData) return null;
    return mappedData.map((data, index) => {
      const uniqueKey = `${data.id}-${index}`;
      const isSelected =
        selected && selected.includes(data.name_es || data.name);
      return (
        <Col xs={6} key={uniqueKey} className="gap-after">
          <ButtonComponent
            text={data.name_es || data.name}
            version={isSelected ? 'fill' : 'outline'}
            large
            onClick={() => onClick(data.name_es || data.name)}
            isColorBlack
            paddingY="15px"
            style={{ height: '100%' }}
          />
        </Col>
      );
    });
  }
);

const Case1Step3CreateJob = ({ langsData, goBack, setLangsData, save }) => {
  const [internalSteps, setInternalSteps] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedLevels, setSelectedLevels] = useState({});
  const fetchedLanguages = useFetchLanguages();
  const [internalLangs, setInternalLangs] = useState([]);
  const hasFetchedLanguages = useRef(false);

  useEffect(() => {
    const fetchAndSetLanguages = async () => {
      const data = await fetchedLanguages();
      setInternalLangs(data || []);
      hasFetchedLanguages.current = true;
    };

    if (internalSteps === 0 && !hasFetchedLanguages.current) {
      fetchAndSetLanguages();
    }
  }, [fetchedLanguages, internalSteps]);

  useEffect(() => {
    if (internalLangs.length > 0 && langsData && langsData.length > 0) {
      const initialSelectedLevels = langsData.reduce(
        (acc, { langid, levelid }) => {
          const lang = internalLangs.find((l) => l.id === langid);
          const level = levels.find((l) => l.id === levelid);
          if (lang && level) {
            acc[lang.name_es || lang.name] = level.name;
          }
          return acc;
        },
        {}
      );

      setSelectedLevels(initialSelectedLevels);
      setSelectedLanguage(Object.keys(initialSelectedLevels)[0] || null);
    }
  }, [internalLangs, langsData]);

  useEffect(() => {
    const fetchAndSetLanguages = async () => {
      const data = await fetchedLanguages();
      setInternalLangs(data || []);
      hasFetchedLanguages.current = true;
    };

    if (internalSteps === 0 && !hasFetchedLanguages.current) {
      fetchAndSetLanguages();
    }
  }, [fetchedLanguages, internalSteps]);

  const handleLanguageClick = useCallback((lang) => {
    setSelectedLanguage(lang);
    setInternalSteps(1);
  }, []);

  const handleLevelClick = useCallback(
    (level) => {
      setSelectedLevels((prev) => ({
        ...prev,
        [selectedLanguage]: level,
      }));
      setInternalSteps(0);
    },
    [selectedLanguage]
  );

  const getSelectedLanguagesText = useCallback(() => {
    console.log(selectedLevels);
    return Object.entries(selectedLevels)
      .map(([lang, level]) => {
        return `${lang} / ${level}`;
      })
      .join(', ');
  }, [selectedLevels]);

  const handleSave = useCallback(() => {
    const dataToSave = Object.entries(selectedLevels).map(([lang, level]) => {
      const langObj = internalLangs.find(
        (l) => l.name_es === lang || l.name === lang
      );

      const levelObj = levels.find((l) => l.name === level);
      return { langid: langObj.id, levelid: levelObj.id };
    });
    setLangsData(dataToSave);
    save(dataToSave);
    goBack();
  }, [selectedLevels, save, goBack, internalLangs]);

  const deleteLastLangAndLevel = () => {
    const selectedLevelsCopy = { ...selectedLevels };
    delete selectedLevelsCopy[Object.keys(selectedLevels).pop()];
    setSelectedLevels(selectedLevelsCopy);
  };

  if (internalSteps === 1) {
    return (
      <CompanyReusableComponent
        goBack={() => setInternalSteps(0)}
        company={'WhiteScreen'}
      >
        <Col xs={12} className="gap-after my-5">
          <ButtonComponent
            text={`${selectedLanguage} / ${
              selectedLevels[selectedLanguage]?.name || ''
            }`}
            version="outline"
            large
            isLeft
            isColorBlack
          />
        </Col>
        <ReusableButtonComponent
          mappedData={levels}
          onClick={handleLevelClick}
          selectedLevels={selectedLevels}
        />
        <Col xs={12} className="gap-after mt-5">
          <button style={linkStyle} onClick={() => setInternalSteps(0)}>
            Regresar
          </button>
        </Col>
      </CompanyReusableComponent>
    );
  }

  return (
    <CompanyReusableComponent goBack={goBack} company={'WhiteScreen'}>
      <Col xs={12} className="gap-after mt-5">
        <span>
          Marca todos los idiomas que son requeridos para el puesto y su nivel.
        </span>
      </Col>
      {Object.keys(selectedLevels).length > 0 && (
        <Col xs={12} className="gap-after">
          <ButtonComponent
            text={getSelectedLanguagesText() || 'No hay idiomas seleccionados'}
            version="outline"
            large
            isLeft
            isColorBlack
            isEditIcon
            onClick={deleteLastLangAndLevel}
          />
        </Col>
      )}
      <ReusableButtonComponent
        mappedData={internalLangs}
        onClick={handleLanguageClick}
        selected={Object.keys(selectedLevels)}
        selectedLevels={selectedLevels}
      />
      <Col xs={12} className="gap-after mt-5">
        <ButtonComponent
          text="Guardar"
          onClick={handleSave}
          large
          isHover
          version={Object.keys(selectedLevels).length > 0 ? 'fill' : 'grey'}
          disabled={Object.keys(selectedLevels).length === 0}
        />
      </Col>
    </CompanyReusableComponent>
  );
};

export default Case1Step3CreateJob;
