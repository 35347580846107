import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { RegisterDataContext } from '../../../contexts/RegisterDataContext';



const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/customers';

export const useCustomersApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;


    // Create a customer
    const createCustomer = async (customerData) => {
        try {
            const response = await axios.post(apiBaseUrl, customerData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Customer created:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error creating customer:', error.response ? error.response.data : error);
            throw error;
        }
    };

    // Retrieve all customers
    const getCustomers = async () => {
        try {
            const response = await axios.get(apiBaseUrl, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Customers retrieved:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error retrieving customers:', error);
            return [];
        }
    };

    // Retrieve a specific customer
    const getCustomer = async (customerId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Customer retrieved:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error retrieving customer:', error);
            return null;
        }
    };

    // Update a customer
    const updateCustomer = async (customerId, customerData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/${customerId}`, customerData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Customer updated:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error updating customer:', error.response ? error.response.data : error);
            throw error;
        }
    };

    // Delete a customer
    const deleteCustomer = async (customerId) => {
        try {
            const response = await axios.delete(`${apiBaseUrl}/${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Customer deleted');
            return response.data;
        } catch (error) {
            console.error('Error deleting customer:', error.response ? error.response.data : error);
            throw error;
        }
    };

    return {
        createCustomer,
        getCustomers,
        getCustomer,
        updateCustomer,
        deleteCustomer
    };
};
