import axios from 'axios';
import { useContext } from 'react';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';
import { UserContext } from '../contexts/UserContext';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/expertise-areas';


const authHeaders = (token) => ({
    'Authorization': `Bearer ${token}`
});


export const useCreateExpertiseArea = () => {
    const [registerData] = useContext(RegisterDataContext);
    const { userData } = useContext(UserContext);

    const createExpertiseArea = async (data) => {
        try {
            const response = await axios.post(apiBaseUrl, data, {
                headers: authHeaders(registerData.token || userData.token)
            });
            return response.data;
        } catch (error) {
            toast.error('Error creating expertise area:', error.response ? error.response.data.toString() : error);
            throw error;
        }
    };

    return createExpertiseArea;

}

export const useExpertiseAreas = ({
    isResponseArray = false
} = {}) => {
    const [registerData] = useContext(RegisterDataContext);
    const { userData } = useContext(UserContext);

    const fetchExpertiseAreas = async () => {
        try {
            const response = await axios.get(apiBaseUrl, {
                headers: authHeaders(registerData.token || userData.token)
            });

            return isResponseArray ? response.data : response.data.map(expertiseArea => expertiseArea.name_es);
        } catch (error) {
            toast.error('Error fetching expertise areas:', error.response ? error.response.data.toString() : error);
            return [];
        }
    };

    return fetchExpertiseAreas;
}



export const useGetExpertiseAreas = (expertiseAreaId) => {

    const [registerData] = useContext(RegisterDataContext);
    const { userData } = useContext(UserContext);

    const getExpertiseAreas = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${expertiseAreaId}`, {
                headers: authHeaders(registerData.token || userData.token)
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching expertise area:', error.response ? error.response.data.toString() : error);
            throw error;
        }
    };

    return getExpertiseAreas;


}

export const deleteExpertiseArea = (expertiseAreaId, token) => {
    try {
        const response = axios.delete(`${apiBaseUrl}/${expertiseAreaId}`, {
            headers: authHeaders(token)
        });
        return response.data;
    } catch (error) {
        toast.error('Error deleting expertise area:', error.response ? error.response.data.toString() : error);
        throw error;
    }
}