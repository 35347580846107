import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/talent-hard-skills';

export const useTalentHardSkillsApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const token = localStorage.getItem('tokenRegister') || registerData.token;

    const createTalentHardSkillRelation = async (talentId, hardSkillId) => {
        try {
            const response = await axios.post(`${apiBaseUrl}`, {
                talent_id: talentId,
                hard_skill_id: hardSkillId
            }, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Talent-HardSkill relation created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating Talent-HardSkill relation:', error.response ? error.response.data : error);
        }
    };

    const fetchHardSkillsByTalent = async (talentId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/talent/${talentId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching hard skills by talent:', error);
            return [];
        }
    };

    const deleteTalentHardSkillRelation = async (talentId, hardSkillId) => {
        try {
            await axios.delete(`${apiBaseUrl}/talent/${talentId}/hard-skill/${hardSkillId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token || token}`
                }
            });
            console.log('Talent-HardSkill relation deleted');
        } catch (error) {
            toast.error('Error deleting Talent-HardSkill relation:', error.response ? error.response.data : error);
        }
    };

    return {
        createTalentHardSkillRelation,
        fetchHardSkillsByTalent,
        deleteTalentHardSkillRelation,
    };
};