import React from "react";
import CompanyReusableComponent from "../Components/CompanyReusable/CompanyReusableComponent";

const renderStep2 = ({ setStep, handleSetStep }) => (
    <CompanyReusableComponent
        company="LoaderScreen"
        step={handleSetStep}
        title="¡Registro exitoso! Valoramos tu confianza"
        subtitle="Has completado el primer paso para registrar tu perfil."
        goBack={() => setStep(1)}
    />
);


export default renderStep2;