import axios from 'axios';


const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/auth';

export const useAuthApi = () => {

    // Sends reset password token via email
    const sendResetPasswordEmail = async (email, lang = 'en') => {
        try {
            const response = await axios.post(`${apiBaseUrl}/forgot-password-email`, { email, lang });
            console.log('Reset password email sent:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error sending reset password email:', error.response ? error.response.data : error);
            throw error;
        }
    };

    // Reset user password
    const resetPassword = async (email, newPassword) => {
        try {
            const response = await axios.post(`${apiBaseUrl}/reset-password`, {
                email,
                password: newPassword
            });
            console.log('Password reset successful:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error resetting password:', error.response ? error.response.data : error);
            throw error;
        }
    };

    return {
        sendResetPasswordEmail,
        resetPassword
    };
};
