import React, { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import StepperComponent from '../../../Shared/StepperComponent/StepperComponent';
import ProfileViewComponent from '../../../Shared/ProfileViewComponent/ProfileViewComponent';
import CompanyReusableComponent from '../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import NotificationComponent from '../../../Shared/NotificationComponent/NotificationComponent';
import ButtonComponent from '../../../Shared/ButtonComponent';
import CardInfoComponent from '../../../Shared/CardInfoComponent/CardInfoComponent';
import SkillComponent from '../../../Shared/SkillComponent/SkillComponent';

const CompanyPreviewProfile = ({
  age,
  especialitation,
  imgProfile,
  location,
  name,
  salary,
  seniority,
  CurrentStep,
  surname,
  goProfileCardModule,
  isCompany,
  setStep,
  infoNotification,
  boldPart,
  normalText,
  position,
  companyWorkArea,
  CompanySector,
  CompanySize,
  size,
  continueStep,
  goBackStep,
  hiring,
  modHiring,
  goHiringModule,
  borderBlue,
  langs,
  techSkills,
  hardSkills,
  back,
  goLangModule,
  goHardModule,
  goSoftSkillModule,
}) => {
  const [showNotification, setShowNotification] = useState(true);
  return (
    <CompanyReusableComponent
      goBack={goBackStep}
      company={'ProfileScreen'}
      imgProfile={imgProfile}
    >
      {showNotification && <div className="notification-overlay"></div>}
      <Container
        fluid
        className="d-flex flex-column align-items-center pt-2"
        style={{ gap: '1rem' }}
      >
        <StepperComponent
          steps={4}
          currentStep={CurrentStep}
          title="Estado de publicación"
          subtitle="Primera publicación"
        />
        <ProfileViewComponent
          age={age}
          especialitation={especialitation}
          imgProfile={imgProfile}
          localization={location}
          name={name}
          salary={salary}
          seniority={seniority}
          surname={surname}
          goProfileCardModule={goProfileCardModule}
          isCompany={isCompany}
          zIndex={1}
          borderBlue
          position={position}
          companyWorkArea={companyWorkArea}
          CompanySector={CompanySector}
          CompanySize={CompanySize}
          size={size}
        />
        {hiring && (
          <CardInfoComponent
            hiring={hiring}
            modHiring={modHiring}
            goHiringModule={goHiringModule}
            borderBlue={borderBlue}
            zIndex={1}
          />
        )}
        {langs && (
          <SkillComponent
            languages={langs}
            technicalSkills={hardSkills}
            softSkills={techSkills}
            back={goBackStep}
            goLangModule={goLangModule}
            borderBlue={true}
            goHardModule={goHardModule}
            goSoftSkillModule={goSoftSkillModule}
          />
        )}
        {/* 
            {aboutMe && (
                <PersonalInfoCardComponent aboutMe={aboutMe} education={education} certs={certs} exp={exp} refs={refs} back={back} goBackAboutMe={goBackAboutMe} />
            )}
            // {dataSoftSkills && (
            //     <DataSoftSkillsComponent dataSoftSkills={dataSoftSkills} goBack={back} goBackDataSoftSkills={goBackDataSoftSkills} img={'softSkills'} text={'Habilidades blandas'} />
            // )}
            {corporateCulture && (
                <DataSoftSkillsComponent dataSoftSkills={corporateCulture} goBack={back} goBackDataSoftSkills={goBackCorporateCulture} text={'Cultura empresarial'} />
            )} */}
        <Row
          style={{
            marginBottom: '1rem',
            marginTop: '1rem',
            zIndex: 2,
            width: '100%',
          }}
        >
          <NotificationComponent
            icon={infoNotification}
            text={{ boldPart: boldPart, normalText: normalText }}
            onClose={() => setShowNotification(false)}
          />
          <ButtonComponent
            isHover
            text="Confirmar"
            version="fill"
            large
            onClick={continueStep}
          />
        </Row>
      </Container>
    </CompanyReusableComponent>
  );
};

export default CompanyPreviewProfile;
