import React, { useEffect, useState } from 'react';
import DefaultStep3CreateJob from './DefaultStep3CreateJob';
import Case1Step3CreateJob, { levels } from './Case1Step3CreateJob';
import { useFetchLanguageById } from '../../../../../api/LangApi';
import Case2Step3CreateJob from './Case2Step3CreateJob';
import Case3Step3CreateJob from './Case3Step3CreateJob';
import { useHardSkillsApi } from '../../../../../api/HardSkills.api';
import Case4Step3CreateJob from './Case4Step3CreateJob';
import { useToolsApi } from '../../../../../api/ToolsApi';
import Case5Step3CreateJob from './Case5Step3CreateJob';
export const skillsLevels = [
  { id: 1, name: 'Básico' },
  { id: 2, name: 'Intermedio' },
  { id: 3, name: 'Avanzado' },
];

const Step3CreateJob = ({
  isGoBack,
  handleSaveFormStep3,
  langs,
  education,
  tools,
  hardSkills,
  benefits,
}) => {
  const [internalSteps, setInternalSteps] = useState(0);
  const [internalLangs, setInternalLangs] = useState(langs);
  const [translatedLangsByIdAndLevel, setTranslatedLangsByIdAndLevel] =
    useState(langs);
  const [translatedHardSkillsAndLevels, setTranslatedHardSkillsAndLevels] =
    useState(hardSkills);

  const [translatedToolsAndLevels, setTranslatedToolsAndLevels] =
    useState(tools);
  const [translatedBenefits, setTranslatedBenefits] = useState(benefits);

  const { fetchHardSkill } = useHardSkillsApi();
  const { fetchTool } = useToolsApi();
  const fetchLanguageById = useFetchLanguageById();

  const [educationData, setEducationData] = useState(education);
  const [hardSkillsData, setHardSkillsData] = useState(hardSkills);
  const [toolsData, setToolsData] = useState(tools);
  const [benefitsData, setBenefitsData] = useState(benefits);

  const handleSaveCase1 = (selectedLangs) => {
    setInternalLangs(selectedLangs);
  };

  const handleSaveCase2 = (education) => {
    setEducationData(education);
  };

  const handleSaveCase3 = (hardSkills) => {
    setHardSkillsData(hardSkills);
  };

  const handleSaveCase4 = (tools) => {
    setToolsData(tools);
  };

  const handleSaveCase5 = (benefits) => {
    setTranslatedBenefits(benefits.map((benefit) => benefit.name).join(', '));
    setBenefitsData(benefits);
  };

  useEffect(() => {
    const fetchLangs = async () => {
      if (!internalLangs) return;
      try {
        const translations = await Promise.all(
          internalLangs.map(async (lang) => {
            const langData = await fetchLanguageById(lang.langid);
            const levelData = levels.find((level) => level.id === lang.levelid);
            return `${langData.name_es} / ${levelData.name}`;
          })
        );
        setTranslatedLangsByIdAndLevel(translations.join(', '));
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    if (internalSteps === 0) {
      fetchLangs();
    }

    const fetchHardSkills = async () => {
      if (!hardSkillsData) return;
      try {
        const translations = await Promise.all(
          hardSkillsData.map(async (skill) => {
            const skillData = await fetchHardSkill(skill.id);
            return skillData.name_es;
          })
        );
        setTranslatedHardSkillsAndLevels(translations.join(', '));
      } catch (error) {
        console.error('Error fetching hard skills:', error);
      }
    };

    if (internalSteps === 0) {
      fetchHardSkills();
    }

    const fetchTools = async () => {
      if (!toolsData) return;
      try {
        const translations = await Promise.all(
          toolsData.map(async (tool) => {
            const toolData = await fetchTool(tool.id);
            const levelData = skillsLevels.find(
              (level) => level.id === tool.levelId
            );
            return `${toolData.name_es} / ${levelData.name}`;
          })
        );
        setTranslatedToolsAndLevels(translations.join(', '));
      } catch (error) {
        console.error('Error fetching tools:', error);
      }
    };

    if (internalSteps === 0) {
      fetchTools();
    }
  }, [internalSteps, internalLangs]);

  const translateEducationData = () => {
    if (!educationData) return;

    const educationLevel = {
      STUDENT: 'Estudiante',
      PRE_DEGREE: 'Grado',
      DEGREE: 'Licenciatura',
      MASTER_DEGREE: 'Maestría',
      DOCTORATE: 'Doctorado',
    };

    const requiredData = {
      require_certification: educationData.require_certification
        ? 'Requiere certificación'
        : 'No requiere certificación',
      require_education: educationData.require_education
        ? 'Requiere educación'
        : 'No requiere educación',
    };

    const educationLevelString = educationLevel[educationData.education_level];
    const requiredDataString = Object.values(requiredData).join(', ');

    return `${educationLevelString}, ${requiredDataString}`;
  };

  switch (internalSteps) {
    case 0:
      return (
        <DefaultStep3CreateJob
          isGoBack={isGoBack}
          langs={translatedLangsByIdAndLevel}
          education={translateEducationData()}
          tools={translatedHardSkillsAndLevels}
          hardSkills={translatedToolsAndLevels}
          benefits={translatedBenefits}
          goToStep={(step) => {
            setInternalSteps(step);
          }}
          handleSaveFormStep3={() => {
            handleSaveFormStep3(
              internalLangs,
              educationData,
              toolsData,
              hardSkillsData,
              benefitsData
            );
          }}
        />
      );
    case 1:
      return (
        <Case1Step3CreateJob
          goBack={() => setInternalSteps(0)}
          langsData={internalLangs}
          setLangsData={setInternalLangs}
          save={handleSaveCase1}
        />
      );
    case 2:
      return (
        <Case2Step3CreateJob
          goBack={() => setInternalSteps(0)}
          save={handleSaveCase2}
          education={educationData}
          setEducation={setEducationData}
        />
      );
    case 3:
      return (
        <Case3Step3CreateJob
          goBack={() => setInternalSteps(0)}
          save={handleSaveCase3}
          prevHards={hardSkillsData}
        />
      );
    case 4:
      return (
        <Case4Step3CreateJob
          goBack={() => setInternalSteps(0)}
          save={handleSaveCase4}
          tools={toolsData}
        />
      );

    case 5:
      return (
        <Case5Step3CreateJob
          goBack={() => setInternalSteps(0)}
          save={handleSaveCase5}
          benefits={benefits}
        />
      );

    default:
      return null;
  }
};

export default Step3CreateJob;
