import React from "react";
import './GoBackButton.css';
import Back from '../../../../../Assets/Back.svg';
import BackBlue from '../../../../../Assets/BackBlue.svg';
import { useMedia } from "../../../../../contexts/mediaContext";

const GoBackButton = ({
    type,
    goBack,
}) => {

    const media = useMedia();

    return (
        <button
            className={`goBackButton ${type === 'white' ? ' white' : ''
                } ${media}`}
            onClick={goBack}
        >
            <img src={type === 'white' ? Back : BackBlue} alt="back" />
        </button>
    )

}


export default GoBackButton;