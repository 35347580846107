import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/talent-quiz';

export const useTalentQuizApi = () => {
    const [registerData] = useContext(RegisterDataContext);
    const { userData } = useContext(UserContext);

    // Configurar las respuestas del cuestionario cultural para un talento
    const setTalentCulturalQuizAnswers = async (talentId, answers) => {
        try {
            const response = await axios.post(`${apiBaseUrl}`, {
                talent_id: talentId,
                answers: answers
            }, {
                headers: {
                    'Authorization': `Bearer ${registerData.token || userData.token}`
                }
            });
            console.log('Talent cultural quiz answers set:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error setting talent cultural quiz answers:', error.response ? error.response.data : error);
            console.log(registerData.token || userData.token);
            return null;
        }
    };

    // Obtener las respuestas del cuestionario cultural para un talento específico
    const getTalentCulturalQuizAnswers = async (talentId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${talentId}`, {
                headers: {
                    'Authorization': `Bearer ${registerData.token || userData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error getting talent cultural quiz answers:', error);
            return null;
        }
    };

    return {
        setTalentCulturalQuizAnswers,
        getTalentCulturalQuizAnswers
    };
};
