import React from 'react';
import IsEditIcon from '../../Assets/isEditIcon.svg';
import PropTypes from 'prop-types';
const ButtonComponent = ({
  version = 'fill',
  disabled = false,
  large = false,
  href,
  choice = false,
  text,
  chip = false,
  top = false,
  back = false,
  status,
  input,
  icon,
  secondIcon,
  slide = false,
  paddingX,
  paddingY,
  isFontBold = false,
  isBoxShadow = false,
  isFontBlue = false,
  onClick,
  isLeft = false,
  validate,
  height,
  isColorBlack = false,
  isEditIcon = false,
  style,
  zindex,
  isHover,
  className,
  gapIcon,
  textOverflowed,
  ...props
}) => {
  const getButtonStyle = () => {
    switch (version) {
      case 'fill':
        return {
          backgroundColor: '#003EFF',
          color: 'white',
          borderRadius: '8px',
          border: '1px solid transparent',
        };
      case 'outline':
        return {
          backgroundColor: 'white',
          color: isColorBlack ? 'black' : '#003EFF',
          border: '1px solid #003EFF ',
        };
      case 'grey':
        return {
          backgroundColor: 'white',
          border: isFontBlue ? 'none' : '1px solid #E2E2E2',
          boxShadow: isBoxShadow
            ? ' 0px 8px 24px -4px rgba(0, 62, 255, 0.15)'
            : 'none',
          color: isFontBlue ? 'rgba(0, 62, 255, 0.25)' : '#e2e2e2',
        };
      default:
        return {};
    }
  };

  const buttonStyle = {
    opacity: disabled ? 0.5 : 1,
    width: large ? '100%' : 'auto',
    paddingTop: paddingY || '10px',
    paddingBottom: paddingY || '10px',
    borderRadius: '8px',
    paddingLeft: paddingX || '20px',
    paddingRight: paddingX || '20px',
    fontWeight: isFontBold ? 'bold' : 'normal',
    border: isFontBlue ? 'none' : '',
    position: 'relative',
    textDecoration: 'none',
    textAlign: isLeft ? 'start' : '',
    height: height || '',
    zIndex: zindex || '',
    overflow: textOverflowed ? 'hidden' : '',
    textOverflow: textOverflowed ? 'ellipsis' : '',
    whiteSpace: textOverflowed ? 'nowrap' : '',

    ...getButtonStyle(),
  };
  let justifyContent;
  if (isLeft && isEditIcon) {
    justifyContent = 'space-between';
  } else if (isLeft) {
    justifyContent = 'flex-start';
  } else {
    justifyContent = 'center';
  }

  const renderContent = () => (
    <div
      style={{
        gap: gapIcon ? gapIcon : icon ? '10px' : '0px',
        display: textOverflowed ? '' : 'flex',
        alignItems: icon ? 'center' : '',
        overflow: textOverflowed ? 'hidden' : '',
        textOverflow: textOverflowed ? 'ellipsis' : '',
        justifyContent: justifyContent,
      }}
    >
      {isEditIcon ? (
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          {icon && (
            <img
              src={icon}
              alt="icon"
              style={{
                maxHeight: '50px',
                borderRadius: '12px',
              }}
            />
          )}
          {text}
        </div>
      ) : (
        <>
          {icon && (
            <img
              src={icon}
              alt="icon"
              style={{
                maxHeight: '50px',
                borderRadius: '12px',
              }}
            />
          )}
          {text}
        </>
      )}
      {isEditIcon && (
        <img
          src={IsEditIcon}
          alt="icon"
          style={{
            maxHeight: '50px',
            borderRadius: '12px',
          }}
        />
      )}
      {secondIcon && <img src={secondIcon} alt="icon" />}
    </div>
  );

  if (href) {
    return (
      <a
        href={href}
        style={buttonStyle}
        {...props}
        className={`myButton ${className ? className : ''} ${
          isHover ? 'hover' : ''
        }`}
      >
        {renderContent()}
      </a>
    );
  }

  return (
    <button
      type={input ? 'submit' : 'button'}
      disabled={disabled}
      style={{ ...buttonStyle, ...style }}
      {...props}
      onClick={onClick}
      className={`myButton ${isHover ? 'hover' : ''} ${
        className ? className : ''
      }`}
    >
      {renderContent()}
    </button>
  );
};

ButtonComponent.propTypes = {
  version: PropTypes.oneOf(['fill', 'outline', 'grey']),
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  href: PropTypes.string,
  choice: PropTypes.bool,
  text: PropTypes.node,
  chip: PropTypes.bool,
  top: PropTypes.bool,
  back: PropTypes.bool,
  status: PropTypes.any,
  input: PropTypes.bool,
  icon: PropTypes.string,
  secondIcon: PropTypes.string,
  slide: PropTypes.bool,
  paddingX: PropTypes.string,
  isFontBold: PropTypes.bool,
  isBoxShadow: PropTypes.bool,
  isFontBlue: PropTypes.bool,
  onClick: PropTypes.func,
  isLeft: PropTypes.bool,
  paddingY: PropTypes.string,
  validate: PropTypes.bool,
  height: PropTypes.string,
  isColorBlack: PropTypes.bool,
  isEditIcon: PropTypes.bool,
  style: PropTypes.object,
  zindex: PropTypes.string,
  isHover: PropTypes.bool,
  className: PropTypes.string,
};

export default ButtonComponent;
