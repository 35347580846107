import React, { useState } from "react";
import PressReleaseCard from "./PressReleaseCard";
import PressReleaseCreate from "./PressReleaseCreate";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './PressRelease.css';


const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
};

const PressRelease = ({ pressReleases }) => {
    const [showCreateForm, setShowCreateForm] = useState(false);

    const handleAddPressRelease = () => {
        setShowCreateForm(true);
    };

    const handleCloseForm = () => {
        setShowCreateForm(false);
    };

    return (
        <div className="d-flex flex-column" style={{ gap: '20px' }}>
            <div className="d-flex" style={{ gap: '20px' }}>
                <span className="title_press_r">Notas de prensa</span>
                <button className="button_press_r" onClick={handleAddPressRelease}>Agregar Nota</button>
            </div>
            {showCreateForm && <PressReleaseCreate handleClose={handleCloseForm} />}
            <div className="press-release-slider">
                <Slider {...settings}>
                    {pressReleases.map((pressRelease) => (
                        <PressReleaseCard key={pressRelease.id} pressRelease={pressRelease} />
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default PressRelease;
