import React from "react";
import "./FilterSelectAdmin.css";



const FilterSelectAdmin = ({ title, options, selected, setSelected }) => {
    return (
        <div className="filter-select-admin">
            <span className="filter-select-admin__title">{title}</span>
            <select className="filter-select-admin__select" value={selected} onChange={(e) => setSelected(e.target.value)}>
                {options.map((option) => <option key={option} value={option}>{option}</option>)}
            </select>
        </div>
    );
}

export default FilterSelectAdmin;