import React, { useState, useRef } from "react";
import InterfaceAdmProject from "../InterfaceAdmProject";
import { Col } from "react-bootstrap";
import ButtonComponent from "../../../../../Shared/ButtonComponent";
import InputComponent from "../../../../../Shared/InputComponent";
import '../AdmStyles.css';
import { useUploadsApi } from "../../../../../../api/UploadsApi";
import { toast } from "react-toastify";

const UsedToolsProject = ({ handleBackStep, dataTools, nextStep }) => {
    const [dataImage, setDataImage] = useState([]);
    const [linkData, setLinkData] = useState([]);
    const [linkCount, setLinkCount] = useState(1);
    const [imagesCount, setImagesCount] = useState(1);
    const inputRefs = useRef([]);

    const handleSave = () => {
        console.log("Guardado");
        console.log({
            images: dataImage.map(img => img.url),
            links: linkData
        });
        if (dataTools) {
            dataTools({
                images: dataImage.map(img => img.url),
                links: linkData
            });
        }

        nextStep();
    };

    const handleChangeLink = (value, index) => {
        const newLinkData = [...linkData];
        newLinkData[index] = value;
        setLinkData(newLinkData);
    };

    const addImageHandle = () => {
        setImagesCount(imagesCount + 1);
    };

    const addLinkHandle = () => {
        setLinkCount(linkCount + 1);
        setLinkData([...linkData, ""]);
    };

    const addImageData = (imageData) => {
        setDataImage([...dataImage, imageData]);
    };

    return (
        <InterfaceAdmProject
            title="Carga tus imágenes para mostrar tus trabajos"
            textLastButton="Siguiente"
            versionLastButton={ 
                dataImage.length === imagesCount && linkData.length === linkCount  ? "fill" : "grey"}
            actionLastButton={handleSave}
            flexEndLastButton={false}
            handleBack={handleBackStep}
        >
            {Array.from({ length: imagesCount }).map((_, index) => (
                <Col xs={12} className="gap-after" key={index}>
                    <DropImageToolProject addImageData={addImageData} />
                </Col>
            ))}
            <Col xs={12} className="gap-after">
                <ButtonComponent
                    text="+ Agregar Imagen"
                    large
                    version="outline"
                    isFontBold
                    isFontBlue
                    onClick={addImageHandle}
                    className="button-outline-custom"
                />
            </Col>
            {Array.from({ length: linkCount }).map((_, index) => (
                <Col xs={12} className="gap-after" key={index}>
                    <InputComponent placeholder="Links"
                        ref={el => inputRefs.current[index] = el}
                        value={linkData[index] || ""}
                        onChange={(e) => handleChangeLink(e.target.value, index)}
                    />
                </Col>
            ))}
            <Col xs={12} className="gap-after">
                <ButtonComponent
                    text="+ Agregar link"
                    large
                    version="outline"
                    isFontBold
                    isFontBlue
                    onClick={addLinkHandle}
                    className="button-outline-custom"
                />
            </Col>
        </InterfaceAdmProject>
    )
};

const DropImageToolProject = ({ addImageData }) => {
    const { uploadTalentProjectImage } = useUploadsApi();
    const [image, setImage] = useState(null);

    const handleDrop = async (e) => {
        e.preventDefault();

        const file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
        if (!file) return;

        if (!file.type.startsWith('image/')) {
            toast.error('El archivo seleccionado no es una imagen');
            return;
        }

        const resizedImage = await resizeImage(file);
        const newFile = new File([resizedImage], file.name, { type: file.type });


        if (newFile) {
            const response = await uploadTalentProjectImage(newFile)
                .catch((error) => {
                    toast.error('Error al subir la imagen' + ' ' + error);
                });
            console.log(response);
            setImage(file);
            if (addImageData) {
                addImageData(response);
            }
        }
    };

    async function resizeImage(file) {
        const MAX_WIDTH = 800; // Ancho máximo para la imagen
        const MAX_HEIGHT = 600; // Alto máximo para la imagen
        const MIME_TYPE = "image/jpeg"; // Tipo de imagen de salida
        const QUALITY = 0.7; // Calidad de la imagen de salida

        const img = document.createElement('img');
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const imgPromise = new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = URL.createObjectURL(file);
        });

        const imgResult = await imgPromise;

        let width = imgResult.width;
        let height = imgResult.height;

        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(imgResult, 0, 0, width, height);
        URL.revokeObjectURL(img.src);

        return new Promise((resolve, reject) => {
            canvas.toBlob(resolve, MIME_TYPE, QUALITY);
        });
    }

    const handleDeleteImage = () => {
        setImage(null);
    };

    return (
        image ? (
            <div className="dropzone__container_image">
                <div className="dropzone__image">
                    <button className="buttonDeleteImage" onClick={handleDeleteImage}>X</button>
                    <img src={URL.createObjectURL(image)} alt="Imagen" />
                </div>
            </div>
        ) : (
            <div className="dropzone__container"
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
            >
                <span className="dropzone__text">Arrastra y suelta tus imágenes aquí</span>
                <label htmlFor="fileInput" className="dropzone__input">
                    <span>o haga clic para buscar un archivo</span>
                    <input type="file" id="fileInput" className="dropzone__file" onChange={handleDrop} />
                </label>
            </div>
        )
    );
};

export default UsedToolsProject;
