import React, { useState, useCallback, useEffect } from "react";
import ButtonComponent from "../../../Shared/ButtonComponent";
import { useHomeApi } from "../../api/HomeDataApi";
import TrashIcon from '../../../../Assets/RedTrash.svg';
import { toast } from "react-toastify";

const InputField = React.memo(({ label, value, onChange, id }) => (
    <div className="d-flex w-100 align-items-center" style={{ gap: '40px' }}>
        <span className="prp-field-label" style={{ wordWrap: 'break-word', whiteSpace: 'nowrap' }}>{label}</span>
        <input
            type="text"
            id={id}
            className="prp-title-input"
            value={value}
            onChange={onChange}
        />
    </div>
));

const HeadlineHomePage = () => {
    const { setHomeData, getHomeData } = useHomeApi();
    const [titles, setTitles] = useState({ title_es: "", title_en: "", title_pt: "" });

    const handleInputChange = useCallback((lang) => (e) => {
        setTitles(prevTitles => ({ ...prevTitles, [lang]: e.target.value }));
    }, []);

    const handleSave = useCallback(() => {
        setHomeData(titles).then(() => {
            toast.success('Titular inicial guardado correctamente.');
        }).catch((error) => {
            toast.error('Error guardando titular inicial:', error.response ? error.response.data.toString() : error);
        });
    }, [titles, setHomeData]);

    const handleDelete = async () => {
        try {
            await setHomeData({ title_es: "", title_en: "", title_pt: "" });
            setTitles({ title_es: "", title_en: "", title_pt: "" });
            toast.success('Titular inicial eliminado correctamente.');
        } catch (error) {
            toast.error('Error eliminando titular inicial:', error.response ? error.response.data.toString() : error);
        }
    };

    useEffect(() => {
        getHomeData().then((data) => {
            if (data) {
                setTitles({
                    title_es: data.title_es || "",
                    title_en: data.title_en || "",
                    title_pt: data.title_pt || ""
                });
            }
        });
    }, []);

    return (
        <div className="main-dashboard">
            <div className="main-dashboard__header">
                <span className="main-dashboard__title"><button style={{
                    background: 'none',
                    border: 'none',
                    color: 'white',
                }}
                    onClick={
                        () => {
                            window.history.back();
                        }
                    }
                >Super Admin Home </button>/ Titular inicial</span>
            </div>
            <div className="main-dashboard__content pl-4 pr-4 pt-4" style={{ gap: '40px', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <div className="d-flex w-100 justify-content-between">
                    <h2>Modificar</h2>
                    <div className="prp-actions-container">
                        <span>Acciones</span>
                        <button className="prp-action-delete" onClick={handleDelete}>
                            <img src={TrashIcon} alt="Delete" />
                        </button>
                    </div>
                </div>
                <InputField label="Titulo Español" id="titleEs" value={titles.title_es} onChange={handleInputChange('title_es')} />
                <InputField label="Titulo Ingles" id="titleEn" value={titles.title_en} onChange={handleInputChange('title_en')} />
                <InputField label="Titulo Portugues" id="titlePt" value={titles.title_pt} onChange={handleInputChange('title_pt')} />
                <ButtonComponent text="Guardar" isHover onClick={handleSave} />
            </div>
        </div>
    );
}

export default HeadlineHomePage;
