import React, { useEffect } from "react";
import SiteStatsSpinner from "./SiteStatsSpinner";
import SiteStatsElement from "./SiteStatsElement";
import './SiteStats.css';


const SiteStats = ({ data, dates }) => {

    useEffect(() => {
        console.log('SiteStats data:', data);
        console.log('SiteStats dates:', dates);
    }, []);

    if (!data) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <span className="site-stats__dates d-flex">Fecha de visualizacion: {dates.firstDate} - {dates.lastDate}</span>
            <div className="d-flex" style={{ gap: '2rem', alignItems: 'center' }}>
                <SiteStatsSpinner data={data} />
                <SiteStatsElement data={data} />
            </div>
        </div>
    );
}

export default SiteStats;