import React, { useState, useCallback, useEffect } from "react";
import ButtonComponent from "../../../Shared/ButtonComponent";
import { useDataBoxesApi } from "../../api/DataBoxesApi";
import TrashIcon from '../../../../Assets/RedTrash.svg';
import CameraIcon from '../../../../Assets/CameraIcon.svg';
import { toast } from "react-toastify";
import VisibleIcon from '../../../../Assets/VisibleIcon.svg';
import { useUploadsApi } from '../../../../api/UploadsApi';
import DataBox from "../../components/DataBox/DataBox";
import eyeIcon from '../../../../Assets/EyeIcon.svg';

async function resizeImage(file) {
    const MAX_WIDTH = 800; // Ancho máximo para la imagen
    const MAX_HEIGHT = 600; // Alto máximo para la imagen
    const MIME_TYPE = "image/jpeg"; // Tipo de imagen de salida
    const QUALITY = 0.7; // Calidad de la imagen de salida

    const img = document.createElement('img');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const imgPromise = new Promise((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
    });

    const imgResult = await imgPromise;

    let width = imgResult.width;
    let height = imgResult.height;

    if (width > height) {
        if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
        }
    } else {
        if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
        }
    }

    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(imgResult, 0, 0, width, height);
    URL.revokeObjectURL(img.src);

    return new Promise((resolve, reject) => {
        canvas.toBlob(resolve, MIME_TYPE, QUALITY);
    });
}

const InputField = React.memo(({ label, value, onChange, id }) => (
    <div className="d-flex w-100 align-items-center" style={{ gap: '40px' }}>
        <span className="prp-field-label" style={{ wordWrap: 'break-word', whiteSpace: 'nowrap' }}>{label}</span>
        <input type="text" id={id} className="prp-title-input" value={value || ''} onChange={onChange} />
    </div>
));

const DataBoxHomePage = () => {
    const { createDataBox, deleteDataBox, getDataBoxes, updateDataBox } = useDataBoxesApi();
    const { uploadDataBoxImage } = useUploadsApi();
    const [imageFile, setImageFile] = useState(null);
    const [imageURL, setImageURL] = useState('');
    const [copy, setCopy] = useState({ id: null, title_es: "", title_en: "", title_pt: "", amount: "", image: "", visible: true });
    const [allDataBoxes, setAllDataBoxes] = useState([]);

    useEffect(() => {
        const fetchDataBoxes = async () => {
            try {
                const dataBoxes = await getDataBoxes();
                setAllDataBoxes(dataBoxes);
            } catch (error) {
                toast.error(`Error fetching data boxes: ${error}`);
            }
        };
        fetchDataBoxes();
    }, []);

    const handleInputChange = useCallback((field) => (e) => {
        setCopy(prevCopy => ({ ...prevCopy, [field]: e.target.value }));
    }, []);

    const handleSave = useCallback(async () => {

        if (!copy.title_es || !copy.title_en || !copy.title_pt || !copy.amount) {
            toast.error('All fields are required');
            return;
        }

        let imageUrl = null;
        if (imageFile) {
            try {
                const resizedImage = await resizeImage(imageFile);
                const uploadResult = await uploadDataBoxImage(resizedImage);
                imageUrl = uploadResult.url;
            } catch (error) {
                toast.error(`Error uploading image: ${error}`);
                return;
            }
        }

        const dataBoxData = {
            ...copy,
            image: imageUrl || copy.image || 'URL existente o default si es necesario',
        };

        if (copy.id) {
            try {
                await updateDataBox(copy.id, dataBoxData);
                toast.success('Data box updated');
                window.location.reload();
            } catch (error) {
                toast.error(`Error updating data box: ${error.toString()}`);
            }
        } else {
            try {
                await createDataBox(dataBoxData);
                toast.success('Data box created');
                window.location.reload();
            } catch (error) {
                toast.error(`Error creating data box: ${error}`);
            }
        }
    }, [copy, createDataBox, updateDataBox, imageFile, imageURL, uploadDataBoxImage]);

    const handleDataBoxClick = useCallback((data) => {
        setCopy({
            id: data.id,
            title_es: data.title_es,
            title_en: data.title_en,
            title_pt: data.title_pt,
            amount: data.amount,
            image: data.image,
            visible: data.visible
        });
        setImageURL(data.image);
        setImageFile(null);
    }, []);

    const handleFileChange = useCallback(event => {
        setImageFile(event.target.files[0]);
        setImageURL(URL.createObjectURL(event.target.files[0]));
    }, []);

    const handleClickVisible = useCallback(() => {
        setCopy(prevCopy => ({ ...prevCopy, visible: !prevCopy.visible }));
    }, []);


    const labels = {
        amount: "Cifra",
        title_es: "Título Español",
        title_en: "Título Inglés",
        title_pt: "Título Portugués"
    };

    return (
        <div className="main-dashboard">
            <div className="main-dashboard__header">
                <span className="main-dashboard__title"><button style={{
                    background: 'none',
                    border: 'none',
                    color: 'white',
                }}
                    onClick={
                        () => {
                            window.history.back();
                        }
                    }
                >Super Admin Home </button> / Cuadros de datos</span>
            </div>
            <div className="main-dashboard__content pl-4 pr-4 pb-4" style={{ gap: '40px', alignItems: 'flex-start', justifyContent: 'flex-start', height: 'auto', overflowY: 'scroll' }}>
                <div className="d-flex w-100 justify-content-between">
                    <h2 className="pt-4">Modificar</h2>
                    <div className="pt-3 prp-actions-container">
                        <button className="prp-action-visible" onClick={
                            handleClickVisible
                        }>
                            <img src={
                                copy.visible ? eyeIcon : VisibleIcon
                            } alt="Visible" />
                        </button>
                        <button className="prp-action-delete" onClick={async () => {
                            if (copy.id) {
                                await deleteDataBox(copy.id);
                                window.location.reload();
                            }
                        }}>
                            <img src={TrashIcon} alt="Delete" />
                        </button>
                    </div>
                </div>
                <label htmlFor="file-upload" className="prp-upload-label">
                    {imageURL ? <img src={imageURL} alt="Upload" className="prp-upload-image" /> : <img src={CameraIcon} alt="Upload" />}
                    <span>Cargar foto</span>
                </label>
                <input id="file-upload" type="file" onChange={handleFileChange} className="prp-file-input" />
                {['amount', 'title_es', 'title_en', 'title_pt'].map(key => (
                    <InputField key={key} label={labels[key]} id={`title-${key}`} value={copy[key]} onChange={handleInputChange(key)} />
                ))}
                <ButtonComponent text="Guardar" isHover onClick={handleSave} />
                <DataBox dataBox={allDataBoxes} onClick={handleDataBoxClick} />
            </div>
        </div>
    );
}

export default DataBoxHomePage;