import React, { useCallback, useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import HiringWindow from './HiringWindow';
import { UserContext } from '../../../../../contexts/UserContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import { RegisterDataContext } from '../../../../../contexts/RegisterDataContext';
import { WorkPreferencesContext } from '../../../../../contexts/workPreferencesContext';

export default function HiringModule({
    isHiring,
    isModHiring,
    isSalary,
    gotostep,
    previousData,
    isMainScreen
}) {
    const [isProfilePersWindow, setIsProfilePersWindow] = React.useState(false);
    const [step, setStep] = React.useState(1);
    const [currentSection, setCurrentSection] = React.useState(null);
    const [hiringOptions, setHiringOptions] = React.useState(previousData.hiring.join(', '));
    const [modHiringOptions, setModHiringOptions] = React.useState(previousData.modHiring);
    const [salaryOptions, setSalaryOptions] = React.useState(`${previousData.salary[0]} / ${previousData.salary[1]} - ${previousData.salary[2]} ${previousData.salary[3]}`);
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);
    const { workPreferences } = useContext(WorkPreferencesContext);

    const apiClient = axios.create({
        baseURL: 'https://api-stg.empleosmarketingdigital.com/v1/',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${registerData.token || userData.token}`,
        },
    });


    const isHiringHandle = useCallback((option) => {
        setHiringOptions(prevOptions => {
            let optionsArray = typeof prevOptions === 'string' ? prevOptions.split(', ') : [];
            if (optionsArray[0] === "Tipo de contratación") {
                optionsArray = [];
            }
            optionsArray.push(option);
            if (optionsArray.length > 3) {
                optionsArray.shift();
            }
            setModHiringOptions('Modalidad de la contratación');
            setSalaryOptions('Pretensión salarial');
            if (typeof isHiring === 'function') {
                isHiring(optionsArray);
            }
            return optionsArray.join(', ');
        });
    }, [setHiringOptions, setModHiringOptions, setSalaryOptions, isHiring]);

    const modHiringHandle = (option) => {
        setModHiringOptions(option);
        console.log("Modalidad de contratación seleccionada: " + option);
        if (typeof isModHiring === 'function') {
            isModHiring(option);
        }
    };

    const salaryHandle = (salary) => {
        setSalaryOptions(`${salary[0]} / ${salary[1]} - ${salary[2]} ${salary[3]}`);
        if (typeof isSalary === 'function') {
            isSalary(salary);
        }
    };

    const handleButtonClick = (section) => {
        setIsProfilePersWindow(true);
        setCurrentSection(section);
        setStep(1);
    };

    const onCloseWindow = () => {
        setIsProfilePersWindow(false);
        setCurrentSection(null);
    };

    if (isProfilePersWindow) {
        return (
            <HiringWindow
                step={step}
                setStep={setStep}
                isHiring={currentSection === 'hiring' && isHiringHandle}
                isModHiring={currentSection === 'modHiring' && modHiringHandle}
                isSalary={currentSection === 'salary' && salaryHandle}
                onClose={onCloseWindow}
            />
        );
    }


    const handleButtonSave = () => {
        const translateValues = {
            contract: {
                "Relación de dependencia": "IN_RELATION",
                "Freelance / Autónomo": "FREELANCE",
                "Prácticas / Pasantía": "PRACTICE"
            },
            duration: {
                "Puesto fijo": "LONG_TERM",
                "Proyecto": "PROJECT",
                "Temporal": "PERIOD"
            },
            dedication: {
                "Full Time": "FULL_TIME",
                "Part Time": "PART_TIME",
                "Horas estipuladas": "SPECIFIC_HOURS",
                "Turnos": "SHIFTS",
                "Voluntariado": "VOLUNTEERING"
            },
            modality: {
                "Presencial": "ON_SITE",
                "Híbrido": "HIBRYD",
                "Remoto global": "GLOBAL_REMOTE",
                "Remoto nacional": "LOCAL_REMOTE",
                "Horas estipuladas": "TIMEZONE_REMOTE"
            },
            payment: {
                "Por proyecto": "PROJECT",
                "Pago mensual": "MONTHLY",
                "Pago anual": "YEARLY",
                "Indiferente": "INDIFERENT"
            }
        };

        const hiringOptionsArray = hiringOptions.split(', ');

        const translatedHiringOptions = hiringOptionsArray.map(option => {
            for (let category in translateValues) {
                if (translateValues[category][option]) {
                    return translateValues[category][option];
                }
            }
            return option;
        });

        const translatedSalaryOptions = salaryOptions.split(' - ');

        const translatedSalaryFirstPart = translatedSalaryOptions[0].split(' / ');

        const translatedSalarySecondPart = translatedSalaryOptions[1].split(' ');

        function getFirstElementOfString(string) {
            return string.split(' ')[0];
        }

        const fetchData = async () => {

            try {
                const hiringType = translatedHiringOptions[1]

                let fromDate = null;
                let toDate = null;

                if (hiringType.startsWith("Temporal")) {
                    const datePattern = /(\d{2})\/(\d{2})\/(\d{2}) - (\d{2})\/(\d{2})\/(\d{2})/;
                    const match = hiringType.match(datePattern);

                    if (match) {
                        fromDate = `20${match[3]}-${match[2]}-${match[1]}T00:00:00.000Z`;
                        toDate = `20${match[6]}-${match[5]}-${match[4]}T00:00:00.000Z`;
                    }
                }

                const response = await apiClient.put(`/talent-work-preferences/${workPreferences.id}`, {
                    id: userData.talent.id,
                    contract: translatedHiringOptions[0],
                    dedication: translatedHiringOptions[2],
                    modality: translatedHiringOptions[3],
                    payment: translateValues.payment[translatedSalaryFirstPart[0]],
                    max_salary: translatedSalarySecondPart[0],
                    min_salary: translatedSalaryFirstPart[1],
                    currency: translatedSalarySecondPart[1],
                    from_date: fromDate,
                    to_date: toDate,
                    duration: translateValues.duration[getFirstElementOfString(translatedHiringOptions[1])]
                });
                console.log('Info', response.data);
                localStorage.removeItem('workPreferences');
            } catch (error) {
                toast.error('Error al registrar usuario:', error.response.data.toString());
            }
        };

        fetchData();

        setTimeout(() => {
            if (typeof gotostep === 'function') {
                gotostep();
            }
        }, 1000);

    };


    return (
        <div style={isMainScreen ? {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: 'white',
            padding: '0 20px'
        } : {}}>
            <Row>
                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        version={'outline'}
                        text={hiringOptions}
                        isLeft
                        large
                        onClick={() => handleButtonClick('hiring')}
                        isEditIcon={hiringOptions !== 'Tipo de contratación'}
                        isColorBlack
                    />
                </Col>
                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        version={'outline'}
                        text={modHiringOptions}
                        isLeft
                        large
                        onClick={() => handleButtonClick('modHiring')}
                        isEditIcon={modHiringOptions !== 'Modalidad de la contratación'}
                        isColorBlack
                    />
                </Col>
                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        version={'outline'}
                        text={salaryOptions}
                        isLeft
                        large
                        onClick={() => handleButtonClick('salary')}
                        isEditIcon={salaryOptions !== 'Pretensión salarial'}
                        isColorBlack
                    />
                </Col>

                <Col xs={12} className="gap-after">
                    <ButtonComponent
                        version={'fill'}
                        text={'Continuar'}
                        isHover
                        large
                        onClick={isMainScreen ? handleButtonSave : gotostep}
                    />
                </Col>
            </Row>
        </div>
    );
}