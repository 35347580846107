import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { UserContext } from '../contexts/UserContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { userData } = useContext(UserContext);
  const { registerData } = useContext(RegisterDataContext);

  const token = localStorage.getItem('tokenRegister');
  const isLoggedIn =
    (userData && !!userData.token) ||
    (registerData && !!registerData.token) ||
    !!token;
  const isSpecialUser = userData?.user?.email === 'x345cd@gmail.com';

  return (
    <Route
      {...rest}
      render={(props) => {
        const tryingToAccessAdmin =
          !isSpecialUser && props.location.pathname === '/admin';

        if (
          isLoggedIn &&
          !tryingToAccessAdmin &&
          (!isSpecialUser || props.location.pathname !== '/profile')
        ) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
