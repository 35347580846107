import React from "react";
import { Row, Col } from "react-bootstrap";
import CompanyReusableComponent from "../Components/CompanyReusable/CompanyReusableComponent";
import InputComponent from "../../../Shared/InputComponent";
import ButtonComponent from "../../../Shared/ButtonComponent";


const renderCompanyRegistered = ({ setStep, handleSetStep, adminEmail, setAdminEmail, handleNextStep, loading }) => (
    <CompanyReusableComponent
        company="BlueScreen"
        step={handleSetStep}
        title="¡Estamos listos!"
        subtitle="Por favor, proporciona unos pocos datos esenciales sobre ti y tu empresa."
        goBack={() => setStep(3)}
    >
        <Row>
            <Col xs={12} className="text-center">
                <span className="mb-2 color-black">Escribe el mail del admin de tu empresa. Le enviaremos un link de autorización.</span>
            </Col>
            <Col xs={12} className="gap-after">
                <InputComponent type="email" placeholder="Correo electrónico del admin" autoComplete="email" value={adminEmail}
                    onChange={(e) => setAdminEmail(e.target.value)} />
            </Col>
            <Col xs={12} className="gap-after">
                <ButtonComponent
                    text="Continuar"
                    version="fill"
                    large
                    isFontBold
                    isHover
                    onClick={handleNextStep}
                    disabled={loading}
                />
            </Col>
        </Row>
    </CompanyReusableComponent>
);


export default renderCompanyRegistered;