import React from 'react';
import PropTypes from 'prop-types';
import CompanyReusableComponent from '../../../../Register/Empresa/Components/CompanyReusable/CompanyReusableComponent';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import ButtonComponent from '../../../../Shared/ButtonComponent';
import StepperComponent from '../../../../Shared/StepperComponent/StepperComponent';
import ReusableButton from '../../../components/ReusableButtonsComponent';

const DefaultStep2CreateJob = ({
  isGoBack,
  vacancy,
  contrat,
  modality,
  CurrentStep,
  goToStep,
  handleSaveFormStep2,
}) => {
  const history = useHistory();
  const handleGoBack = isGoBack || history.goBack;

  return (
    <CompanyReusableComponent
      goBack={handleGoBack}
      subtitle="Tienes la oportunidad de subir un anuncios gratis. Para más, considera nuestra versión premium."
      title="Crea tu propuesta de empleo"
      company="BlueScreen"
    >
      <Row className="w-100">
        <Col xs={12} className="gap-after">
          <StepperComponent
            steps={4}
            currentStep={CurrentStep}
            title="Primer publicación"
            subtitle="Estado de publicación"
          />
        </Col>
        <ReusableButton
          text={vacancy || 'Cantidad de vacantes'}
          version={vacancy ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(1)}
          isColorBlack={!!vacancy && vacancy !== 'Cantidad de vacantes'}
        />
        <ReusableButton
          text={contrat || 'Tipo de contratación'}
          version={contrat ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(2)}
          isColorBlack={!!contrat && contrat !== 'Tipo de contratación'}
        />
        <ReusableButton
          text={modality || 'Modalidad de empleo'}
          version={modality ? 'outline' : 'grey'}
          isLeft
          large
          onClick={() => goToStep(3)}
          isColorBlack={!!modality && modality !== 'Modalidad de empleo'}
        />
        <Col xs={12} className="gap-after">
          <ButtonComponent
            text="Confirmar"
            version={vacancy && contrat && modality ? 'fill' : 'grey'}
            large
            onClick={handleSaveFormStep2}
            disabled={!vacancy || !contrat || !modality}
            isHover
          />
        </Col>
      </Row>
    </CompanyReusableComponent>
  );
};

DefaultStep2CreateJob.propTypes = {
  isGoBack: PropTypes.func,
  vacancy: PropTypes.string,
  contrat: PropTypes.string,
  modality: PropTypes.string,
  CurrentStep: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
  handleSaveFormStep2: PropTypes.func,
};

export default DefaultStep2CreateJob;
