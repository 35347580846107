import React, { useState, useEffect } from "react";
import White from "../../../Assets/White.png";
import Blue from "../../../Assets/Blue.svg";
import NotificationIcon from "../../../Assets/Notification.svg";
import BurgerWhite from "../../../Assets/BurgerWhite.svg";
import BurgerBlue from "../../../Assets/BurgerBlue.svg";
import "./NavBarComponent.css";
import BurgerMenuComponent from "../BurgerMenuComponent/BugerMenuComponent";

const NavBarComponentProfile = ({ imgProfile, darkLogo, notifications, zindex, position, admin }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [burgerMenuHeight, setBurgerMenuHeight] = useState('0px');


    useEffect(() => {
        const calculateHeight = () => {
            const navbarHeight = document.querySelector('.navBarComponentMain')?.offsetHeight || 0;
            const screenHeight = window.innerHeight;
            const remainingHeight = `${screenHeight - navbarHeight + 40}px`;
            setBurgerMenuHeight(remainingHeight);
        };

        window.addEventListener('resize', calculateHeight);
        calculateHeight();

        return () => window.removeEventListener('resize', calculateHeight);
    }, []);

    useEffect(() => {
        if (imgProfile === undefined) {
            imgProfile = "https://www.gravatar.com/avatar/";
        }
    }, [imgProfile]);

    return (
        <div style={{ zIndex: zindex, position: position }}>
            <div className="navBarComponentMain">
                <img src={darkLogo ? Blue : White} alt="logo" style={{ width: "100px" }} />
                <div className="iconSection">
                    <a className="notificationButton" href="notification"> <img src={NotificationIcon} alt="notification" /> </a>
                    <img src={imgProfile} alt="profile" className="imgProfile" />
                    <button className="burgerButton p-0" onClick={() => setIsOpen(!isOpen)}> <img src={darkLogo ? BurgerBlue : BurgerWhite} alt="burger" /> </button>
                </div>
            </div>
            <BurgerMenuComponent isOpen={isOpen} height={burgerMenuHeight} admin={admin} />
        </div>
    );
}

export default NavBarComponentProfile;
