import axios from 'axios';
import { useContext, useMemo } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { RegisterDataContext } from '../../../../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/auth';
const apiBaseUrlCompanies =
  'https://api-stg.empleosmarketingdigital.com/v1/companies';
const apiBaseUrlWorkAreas =
  'https://api-stg.empleosmarketingdigital.com/v1/company-work-areas';
const apiBaseUrlEmployment =
  'https://api-stg.empleosmarketingdigital.com/v1/employment-expertise-areas';

export const useAuthApi = () => {
  const { userData } = useContext(UserContext);
  const [registerData, setRegisterData] = useContext(RegisterDataContext);

  const axiosInstanceCompanies = useMemo(
    () =>
      axios.create({
        baseURL: apiBaseUrlCompanies,
        headers: {
          Authorization: `Bearer ${userData.token || registerData.token}`,
        },
      }),
    [userData.token, registerData.token]
  );

  const axiosInstanceWorkAreas = useMemo(
    () =>
      axios.create({
        baseURL: apiBaseUrlWorkAreas,
        headers: {
          Authorization: `Bearer ${userData.token || registerData.token}`,
        },
      }),
    [userData.token, registerData.token]
  );

  const axiosInstanceEmployment = useMemo(
    () =>
      axios.create({
        baseURL: apiBaseUrlEmployment,
        headers: {
          Authorization: `Bearer ${userData.token || registerData.token}`,
        },
      }),
    [userData.token, registerData.token]
  );

  const handleApiError = (error) => {
    toast.error(`${error.response?.data}`);
    throw error;
  };

  const registerUser = async (userData) => {
    try {
      const response = await axios.post(`${apiBaseUrl}/register`, userData);
      setRegisterData({ ...registerData, token: response.data.token });
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const validateEmail = async (email, lang = 'en') => {
    try {
      const response = await axios.post(`${apiBaseUrl}/validate-email`, {
        email,
        lang,
      });
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const createCompany = async (companyData) => {
    try {
      const response = await axiosInstanceCompanies.post('/', companyData);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const getCompany = async (companyId) => {
    try {
      const response = await axiosInstanceCompanies.get(`/${companyId}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const updateCompany = async (companyId, companyData) => {
    try {
      const response = await axiosInstanceCompanies.put(
        `/${companyId}`,
        companyData
      );
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const getCompanyFullProfile = async (companyId) => {
    try {
      const response = await axiosInstanceCompanies.get(
        `/${companyId}/full-profile`
      );
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const requestCollaboration = async (adminEmail) => {
    try {
      const response = await axiosInstanceCompanies.post(
        '/request-collaboration',
        { admin_email: adminEmail }
      );
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const createCompanyWorkArea = async (workAreaData) => {
    try {
      const response = await axiosInstanceWorkAreas.post('/', workAreaData);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const getCompanyWorkAreas = async (params) => {
    try {
      const response = await axiosInstanceWorkAreas.get('/', { params });
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const getCompanyWorkArea = async (companyWorkAreaId) => {
    try {
      const response = await axiosInstanceWorkAreas.get(
        `/${companyWorkAreaId}`
      );
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const updateCompanyWorkArea = async (companyWorkAreaId, workAreaData) => {
    try {
      const response = await axiosInstanceWorkAreas.put(
        `/${companyWorkAreaId}`,
        workAreaData
      );
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const deleteCompanyWorkArea = async (companyWorkAreaId) => {
    try {
      await axiosInstanceWorkAreas.delete(`/${companyWorkAreaId}`);
    } catch (error) {
      handleApiError(error);
    }
  };

  const getEmploymentExpertiseAreas = async () => {
    try {
      const response = await axiosInstanceEmployment.get('/');
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const getEmploymentExpertiseArea = async (employmentId) => {
    try {
      const response = await axiosInstanceEmployment.get(`/${employmentId}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const getIdListEmploymentExpertiseArea = async (expertiseAreaId) => {
    const baseUrl =
      'https://api-stg.empleosmarketingdigital.com/v1/expertise-areas';
    const queryParams = `?filter_by=work_area_id&filter_value=${expertiseAreaId}`;

    const url = `${baseUrl}${queryParams}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${userData.token || registerData.token}`,
        },
      });
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const deleteEmploymentExpertiseArea = async (
    employmentId,
    expertiseAreaId
  ) => {
    try {
      await axiosInstanceEmployment.delete(
        `/ ${employmentId} / expertise - areas / ${expertiseAreaId}`
      );
    } catch (error) {
      handleApiError(error);
    }
  };

  return {
    registerUser,
    validateEmail,
    createCompany,
    getCompany,
    requestCollaboration,
    createCompanyWorkArea,
    getCompanyWorkAreas,
    getCompanyWorkArea,
    updateCompanyWorkArea,
    deleteCompanyWorkArea,
    getCompanyFullProfile,
    updateCompany,
    getEmploymentExpertiseAreas,
    getEmploymentExpertiseArea,
    deleteEmploymentExpertiseArea,
    getIdListEmploymentExpertiseArea,
  };
};
